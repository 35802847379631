import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaRegBell } from 'react-icons/fa6';
import { FiLogOut, FiEdit } from 'react-icons/fi';
import Dropdown from '../Dropdown/Dropdown';
import { RxHamburgerMenu } from 'react-icons/rx';
import { HiOutlineLocationMarker, HiChevronDown } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import closeOpenedDropdown from '../../../utilFunctions/DropdownCloser';
import { envVariables } from '../../../config';
import {
  authenticationActions,
  hospitalInformationActions,
  portalActions,
  searchbarActions,
  bookAppointmentActions,
  modalActions,
  sidebarActions,
} from '../../../store';
import LanguageSwitch from '../../LanguageSwitch';

import './style.css';
import { selectLanguage } from '../../../store/languageSlice';
import {
  clearLocalStorageExcept,
  currentUser,
} from '../../../utilFunctions/SessionExpire';
import { removeBadge } from '../../../store/badgesSlice';
import Modal from '../../Modal/Modal';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import { MobileNav } from './MobileNav';
import { getTValue } from '../../../translations/common';
import HospSwitcherDropdown from './HospSwitcherDropdown';

export const Navigation = ({ hospitalsList }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile, isTablet } = useBreakpoint();
  const { t, isRtl } = useSelector(selectLanguage);
  const { modalType } = useSelector((state) => state.modal);
  const [hospList, setHospList] = useState(hospitalsList);
  const [currentSelectedHospital, setCurrentSelectedHospital] = useState(null);
  const [currentImgUrl, setCurrentImgUrl] = useState(
    `${process.env.PUBLIC_URL}/images/male-avatar.png`
  );
  let currentLanguage = localStorage.getItem('language');
  // console.log(currentLanguage, 'currentLanguage');
  const [userName, setUserName] = useState('');
  const selectedHospId = useSelector((state) => state.hospitalInfo.hospId);
  const navigate = useNavigate();
  // const { notificationData } = useLoaderData();
  // console.log("notificationData", notificationData);
  // const host = process.env.REACT_APP_HOST;

  const logoutHandler = () => {
    dispatch(authenticationActions.authenticateToggle(false));
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(searchbarActions.selectedDoctorCode(null));
    dispatch(searchbarActions.handleSeacrhByClinic(false));
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.selectedDocCode(null));
    dispatch(removeBadge());
    dispatch(modalActions.closeModal());
    dispatch(authenticationActions.updateRedirected(null));
    dispatch(removeBadge());
    clearLocalStorageExcept();
    navigate('/login');
  };

  const editProfileHandler = () => {
    dispatch(portalActions.toggleEditDrawer());
    closeOpenedDropdown();
  };
  const handleToggleMenuDrawer = () => {
    dispatch(sidebarActions.toggleMobile());
  };

  const changeHospitalHandler = (name, code, id) => {
    closeOpenedDropdown();
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(searchbarActions.selectedDoctorCode(null));
    dispatch(searchbarActions.handleSeacrhByClinic(false));
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.selectedDocCode(null));
    dispatch(removeBadge());
    dispatch(hospitalInformationActions.selectHospital({ name, code, id }));
    const hospObj = {
      name,
      code,
      id,
    };
    // const selectedHospitalData = hospList.find((x)=>x.id === id);
    localStorage.setItem('selectedHopitalInfo', JSON.stringify(hospObj));
    localStorage.removeItem('doctorsInfomation');
    localStorage.removeItem('clinicsList');
    localStorage.removeItem('symptomsList');
    // console.log('localStorage.getItem("doctorsInfomation")', localStorage.getItem("doctorsInfomation"))
    // console.log('localStorage.getItem("clinicsList")', localStorage.getItem("clinicsList"))
    navigate('/dashboard');
  };

  const onYesClick = () => {
    const { hospitalName, hospitalCode, hospitalId } = currentSelectedHospital;
    changeHospitalHandler(hospitalName, hospitalCode, hospitalId);
    dispatch(modalActions.toggle(''));
  };

  useEffect(() => {
    if (localStorage) {
      const userInfo = currentUser();
      // Set User Name of the basis of language change

      const firstName = getTValue(userInfo, 'arabic_first_name', 'firstName', isRtl);
      const lastName = getTValue(userInfo, 'arabic_last_name', 'lastName',isRtl);
      const isFemale = userInfo?.gender?.toLowerCase() === 'female';
      setUserName(`${firstName} ${lastName}`);
      dispatch(
        authenticationActions.updateUserFullName(`${firstName} ${lastName}`)
      );

      const imgUrl =
        userInfo && userInfo?.profileImageUrl && userInfo?.profileImageUrl !== 'null'
          ? `${envVariables.assetsRouteUser}${userInfo?.profileImageUrl}`
          : `${process.env.PUBLIC_URL}/images/${isFemale ? 'female-avatar':'male-avatar'}.png`

      setCurrentImgUrl(imgUrl);
    }
  }, [isRtl]);
  
  return (
    <>
      <div className="nav">
        <div
          className={`container flex flex-wrap items-center justify-between`}
        >
          <div className="inline-flex flex-wrap items-center gap-x-2">
            {isMobile && (
              <RxHamburgerMenu
                className="text-xl"
                onClick={handleToggleMenuDrawer}
              />
            )}
            <div className="logo-circle my-first-step">
              {hospList && (
                <>
                  <Dropdown extraClass="site-switer">
                    <button>
                      <HiOutlineLocationMarker />
                      <Link to={'/'} className="logo">
                        {hospList.map((hospital) =>
                          hospital.hospitalId === selectedHospId ? (
                            <img
                              src={hospital.hospitalLogo}
                              alt={hospital.hospitalCode}
                            />
                          ) : null
                        )}
                      </Link>
                      <HiChevronDown />
                    </button>
                    <div className="dd-menu !px-0">
                      <ul>
                        {hospList.map((hospital, index) => (
                          <li key={index}>
                            <button
                              className={
                                selectedHospId === hospital.hospitalId
                                  ? 'active !px-3'
                                  : '!px-3'
                              }
                              onClick={() => {
                                closeOpenedDropdown();
                                if (hospital.hospitalCode === 'KH') {
                                  // dispatch(modalActions.toggle('kaust'));
                                  setTimeout(() => {
                                    dispatch(modalActions.toggle('kaust'));
                                  }, 200);
                                  setCurrentSelectedHospital(hospital);
                                } else {
                                  changeHospitalHandler(
                                    hospital.hospitalName,
                                    hospital.hospitalCode,
                                    hospital.hospitalId
                                  );
                                }
                              }}
                            >
                              <img
                                src={hospital.hospitalLogoSmall}
                                alt={hospital.hospitalCode}
                              />
                              <span>
                                {isRtl
                                  ? hospital.hospitalName_ar
                                  : hospital.hospitalName}
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
          <div className="inline-flex flex-wrap items-center gap-x-6">
            {/* <Searchbar /> */}
            <ul className="nav-items inline-flex flex-wrap items-center gap-x-5">
              <li>
                <LanguageSwitch />
              </li>
              {isMobile ? (
                <MobileNav
                  currentImgUrl={currentImgUrl}
                  userName={userName}
                  editProfileHandler={editProfileHandler}
                  logoutHandler={logoutHandler}
                />
              ) : (
                <>
                  <li>
                    <FaRegBell />
                  </li>
                  {/* <li><LuLayoutGrid /></li> */}

                  <li className="flex items-center profile-circle">
                    <Dropdown extraClass="logout">
                      <button className="avatar">
                        <img
                          // src={`${process.env.PUBLIC_URL}/images/avatarmain.svg`}
                          src={currentImgUrl}
                          alt="user-img"
                        />
                        <div className="overflow-hidden">
                          <div className="userNameField-header">
                            {userName}
                          </div>
                        </div>
                        <span>
                          <HiChevronDown />
                        </span>
                      </button>
                      <div className="dd-menu">
                        <button onClick={editProfileHandler}>
                          <FiEdit /> {t.Edit_Profile}
                        </button>
                        {/* <p style={{color: "#ADADAD", paddingInline: "15px"}}>Account Settings</p> */}
                        <button onClick={logoutHandler}>
                          <FiLogOut /> {t.SignOut}
                        </button>
                      </div>
                    </Dropdown>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {modalType === 'kaust' && (
        <Modal title={t.Confirm}>
          <p className="leading-8">{t.kaustMsg}</p>
          <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
            <button
              className="bg-tertiary text-white btn"
              onClick={() => {
                dispatch(modalActions.toggle(''));
              }}
            >
              {t.Cancel}
            </button>
            <button
              className="bg-secondary text-white btn"
              onClick={onYesClick}
            >
              {t.ok}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

// export default Navigation;
