import React from "react";
import { useSelector } from "react-redux";
import { getIconByCode } from "../../../utilFunctions/Vitals";
import SingleVRChart from "./SingleVRChart";

const vitalIcons = {
    "BMI": "bmi.svg",
    "Height (Ht)": "rular.svg",
    "Temperature": "thermometer.svg"
}

const SingleVReport = ({index, report}) => {
    const dateIndex = useSelector(state => state.vitalReport.dashboardSelectdDateIndex)

    return (
        <div className="sngVtl">
            <SingleVRChart index={index} report={report} />
            <div className="cntnt">
                <span>
                    {/* <img src={`${process.env.PUBLIC_URL}/images/icons/${vitalIcons[report.Vital_Signs] ?? "bmi.svg"}`} alt="icon" /> */}
                    <img src={getIconByCode(report.code)} alt="icon" />
                </span>
                <div className="txt">
                    <h4 dangerouslySetInnerHTML={{ __html: report.Vital_Signs.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}}></h4>
                    <p dir="ltr">{report[`date${dateIndex}`]?? '-'}</p>
                </div>
            </div>
        </div>
    )
};

export default SingleVReport;