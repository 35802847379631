import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapEmbed from '../components/MapEmbed';
import Loader from '../components/UI/Loader';
import { selectLanguage } from '../store/languageSlice';
import { getTValue } from '../translations/common';

const hospitalDataItem = {
  hospitalId: '1',
  hospitalCode: 'IMC',
  hospitalName: 'International Medical Center',
  hospitalName_ar: 'المركز الطبي الدولي',
  hospitalLogo:
    'https://patientportal.imc.med.sa/uploads/hospitalimages/small-logo-imc.png',
  hospitalLargeImage:
    'https://patientportal.imc.med.sa/uploads/hospitalimages/Pic4.jpg',
  hospitalLogo_white:
    'https://patientportal.imc.med.sa/uploads/hospitalimages/imc-offwhite.png',
  specialMessage: '',
  specialMessage_ar: '',
  latitude: '21.5137652',
  longitude: '39.174638501139256',
  isHomeHealthCare: 'true',
  hospitalLogoSmall:
    'https://patientportal.imc.med.sa/uploads/hospitalimages/imc_small.png',
};

const MAP_URLS = [
  {
    code: 'IMC',
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.8511183274168!2d39.17194271494084!3d21.51355078573784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cf97ce9b38e7%3A0x33f98d24e3f20523!2sInternational%20Medical%20Center%20Hospital!5e0!3m2!1sen!2s!4v1613650112720!5m2!1sen!2s',
  },
  {
    code: 'FC',
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3710.2599044318977!2d39.12534871494195!3d21.57577568570464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dae993064b9f%3A0x247e0acf322e4029!2sEtoile%20Center%2C%20King%20Abdulaziz%20Service%20Rd%2C%20Al%20Zahra%2C%20Jeddah%2023424%2C%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1613650383012!5m2!1sen!2s',
  },
  {
    code: 'KH',
    url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25375.538338193597!2d39.09954659351489!3d22.305695435922992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c11c505768a2f1%3A0x36139c007214e6bc!2sKing%20Abdullah%20University%20of%20Science%20and%20Technology%20(KAUST)!5e0!3m2!1sen!2ssa!4v1684672448442!5m2!1sen!2ssa',
  },
  {
    code: 'RSC',
    url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14189.754959687483!2d33.83452!3d27.236766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145287b6f15c6e21%3A0xdc4eab3f7941498d!2sRed%20Sea%20Hospital!5e0!3m2!1sen!2sus!4v1688740632353!5m2!1sen!2sus',
  },
];

const LocationMap = (props) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { list } = useSelector((state) => state.hospitalInfo);
  const [selectedHosp, setSelectedHosp] = useState(hospitalDataItem); //for later to make dynamic MapUrl from this data
  const [selectedUrl, setSelectedUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const hospitalCode = localStorage.selectedHopitalInfo
      ? JSON.parse(localStorage.selectedHopitalInfo).code
      : 'IMC';

    setTimeout(() => {
      // const selectedItem = list?.find((x) => x.hospitalCode == hospitalCode);
      // setSelectedHosp(selectedItem);
      const foundItem = MAP_URLS?.find((x) => x.code == hospitalCode);
      setSelectedUrl(foundItem?.url);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading)
    return (
      <div className="w-full justify-center">
        <Loader roundLoader />
      </div>
    );

  return (
    <>
      {!selectedUrl ? (
        <div>{t.MapError}</div>
      ) : (
        <MapEmbed
          url={selectedUrl}
          name={getTValue(
            selectedHosp,
            'hospitalName_ar',
            'hospitalName',
            isRtl
          )}
          lat={selectedHosp.latitude}
          lng={selectedHosp.longitude}
        />
      )}
    </>
  );
};

export default LocationMap;
