import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  erroMessage: null,
};

const loaderErrorSlice = createSlice({
  name: 'loaderAndError',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loading = true;
    },
    hideLoader: (state) => {
      state.loading = false;
    },
    showError: (state, action) => {
      state.error = true;
      state.erroMessage = action?.payload || '';
    },
    hideError: (state) => {
      state.error = null;
      state.erroMessage = null;
    },
  },
});

export const { showLoader, hideLoader, showError, hideError } = loaderErrorSlice.actions;

export const selectLoaderError= (state) => state.loaderAndError;

export default loaderErrorSlice;
