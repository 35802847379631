export const createRoomResponse = {
    "status": true,
    "message": "Success",
    "data": {
        "accessToken": "eyJjdHkiOiJ0d2lsaW8tZnBhO3Y9MSIsInR5cCI6IkpXVCIsImFsZyI6IkhTMjU2In0.eyJpc3MiOiJTS2U2ZDE3ZjIyZTZhZTk1M2Y4NThlOTA0YTVmZjhhYWM5IiwiZXhwIjoxNzE3NDIwNzE5LCJncmFudHMiOnsiaWRlbnRpdHkiOiJhdHN1bHRhbkBpbWMubWVkLnNhIiwidmlkZW8iOnsicm9vbSI6IjcwNDUzMC1Nb21lciJ9fSwianRpIjoiU0tlNmQxN2YyMmU2YWU5NTNmODU4ZTkwNGE1ZmY4YWFjOS0xNzE3NDE3MDg4Iiwic3ViIjoiQUMxMGUxM2Y1NjlmNzU2MjAxMzZjZjMwMDA2ZThlZGU2OCJ9.Sv0K1WrWzCIqh6mOWIXx3bpTZenb64aTm2te9LPsDcw",
        "room": {
            "sid": "RM2d5fec8eed05c2d76a54ed70420165d3",
            "status": "IN_PROGRESS",
            "duration": null,
            "type": "GROUP",
            "url": "https://video.twilio.com/v1/Rooms/RM2d5fec8eed05c2d76a54ed70420165d3",
            "links": {
                "recordings": "https://video.twilio.com/v1/Rooms/RM2d5fec8eed05c2d76a54ed70420165d3/Recordings",
                "participants": "https://video.twilio.com/v1/Rooms/RM2d5fec8eed05c2d76a54ed70420165d3/Participants",
                "recording_rules": "https://video.twilio.com/v1/Rooms/RM2d5fec8eed05c2d76a54ed70420165d3/RecordingRules"
            },
            "dateCreated": "2024-06-03T12:18:39Z",
            "dateUpdated": "2024-06-03T12:18:39Z",
            "accountSid": "AC10e13f569f75620136cf30006e8ede68",
            "enableTurn": true,
            "uniqueName": "704530-Momer",
            "statusCallback": null,
            "statusCallbackMethod": "POST",
            "endTime": null,
            "maxParticipants": 50,
            "maxParticipantDuration": 14400,
            "maxConcurrentPublishedTracks": 170,
            "recordParticipantsOnConnect": false,
            "videoCodecs": [
                "H264",
                "VP8"
            ],
            "mediaRegion": "us1",
            "audioOnly": false,
            "emptyRoomTimeout": 5,
            "unusedRoomTimeout": 5
        }
    },
    "bufferRangeBefore": 86400,
    "bufferRangeAfter": 86400,
    "bufferRangeMessage": "You can join the conversation call before and after 86400 min of your appointment slot.",
    "bookingCompletedStatus": false,
    "bookingCompletedMessage": null,
    "validBufferRange": true
}

export const generateRoomAccessTokenRespone = {
    "status": true,
    "message": "Success",
    "data": {
        "accessToken": "eyJjdHkiOiJ0d2lsaW8tZnBhO3Y9MSIsInR5cCI6IkpXVCIsImFsZyI6IkhTMjU2In0.eyJpc3MiOiJTS2U2ZDE3ZjIyZTZhZTk1M2Y4NThlOTA0YTVmZjhhYWM5IiwiZXhwIjoxNzE3NDIwNzE5LCJncmFudHMiOnsiaWRlbnRpdHkiOiJhdHN1bHRhbkBpbWMubWVkLnNhIiwidmlkZW8iOnsicm9vbSI6IjcwNDUzMC1Nb21lciJ9fSwianRpIjoiU0tlNmQxN2YyMmU2YWU5NTNmODU4ZTkwNGE1ZmY4YWFjOS0xNzE3NDE3MDg4Iiwic3ViIjoiQUMxMGUxM2Y1NjlmNzU2MjAxMzZjZjMwMDA2ZThlZGU2OCJ9.Sv0K1WrWzCIqh6mOWIXx3bpTZenb64aTm2te9LPsDcw",
        "conversationSid": "RM2d5fec8eed05c2d76a54ed70420165d3"
    }
}
