import React from 'react';

const MapEmbed = ({url, name, lat, lng }) => {
  const urlfromLatLong = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.8511183274168!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cf97ce9b38e7%3A0x33f98d24e3f20523!2s${encodeURIComponent(name)}!5e0!3m2!1sen!2s!4v1613650112720!5m2!1sen!2s`;

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <iframe
        title="Google Map"
        src={url || urlfromLatLong}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
