function listenForOutsideClicks(menuRef, setIsOpen) {
  const handleClickOutside = (evt) => {
    if (menuRef.current && !menuRef.current.contains(evt.target)) {
      setIsOpen(false);
    }
  };

  return handleClickOutside;
}

export default listenForOutsideClicks;