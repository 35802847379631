import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ChatIcon from './ChatIcon';
import { useClickOutside } from '../../utilFunctions/useClickOutside';
import useBreakpoint from '../../utilFunctions/useBreakpoint';
import './styles.css';

const ChatModal = ({ isRtl }) => {
  const location = useLocation();
  const { isMobile } = useBreakpoint();
  const [showChatbot, setShowChatbot] = useState(false);

  const containerRef = useRef(null);

  useClickOutside(containerRef, () => {
    setShowChatbot(false);
  });

  const handleClick = () => {
    setShowChatbot(!showChatbot);
  };

  if (location.pathname === '/video-call') return null;

  return (
    <div dir="ltr">
      <div
        className="relative hello-tars-chatbot chat_btn cursor-pointer"
        onClick={handleClick}
      >
        <ChatIcon />
      </div>

      <div
        ref={containerRef}
        className={`hello-tars-chatbot-container absolute right-0 bottom-16 ${
          isRtl ? 'left-0' : 'right-0'
        }`}
        style={{
          visibility: showChatbot ? 'visible' : 'hidden',
          opacity: showChatbot ? 1 : 0,
          transition: 'opacity 0.3s ease',
        }}
      >
        <iframe
          src="https://imc.hellotars.com/conv/41rZBz/"
          style={{
            width: isMobile ? '344px' : '400px',
            height: isMobile ? '500px' : '600px',
            boxShadow: '0 20px 80px rgba(0,0,0,.6)',
            borderRadius: '5px',
            overflow: 'hidden',
            border: 'none',
          }}
          title="tars-chatbot"
        ></iframe>
      </div>
    </div>
  );
};

export default ChatModal;
