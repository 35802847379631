export const LabReports = {
  order_list: [
    {
      order_id: '9117063||4',
      order_description: 'Anti-Gastric Parietal Cell SEND OUT UN',
      order_accession_number: '9117063/47',
      department_code: 'SERO',
      department_description: 'Serology',
      ordered_doctor: 'Test Patient (Not  for Booking )',
      order_status: 'Executed',
      collected_date: '2024-06-25',
      collected_time: '12:26',
      labepisode_number: '2406250718',
      specimen_number: '2406250718-1',
      authorized_user: 'Rashed Sukainah',
      authorized_date: '2024-07-02',
      authorized_time: '09:18',
      smart_report: false,
      result_list: [
        {
          result_id: '2467589||13048472',
          test_item_description: 'Anti-Gastric Parietal Cell SEND OUT UN',
          result_value: 'Testing',
          result_units: '',
          result_normal_range: '',
          result_interpretation: '',
        },
      ],
    },
    {
      order_id: '9117063||3',
      order_description: 'Amoebic Antibody SEND OUT UN',
      order_accession_number: '9117063/38',
      department_code: 'SERO',
      department_description: 'Serology',
      ordered_doctor: 'Test Patient (Not  for Booking )',
      order_status: 'Executed',
      collected_date: '2024-06-25',
      collected_time: '12:26',
      labepisode_number: '2406250718',
      specimen_number: '2406250718-1',
      authorized_user: 'Rashed Sukainah',
      authorized_date: '2024-07-02',
      authorized_time: '09:18',
      smart_report: false,
      result_list: [
        {
          result_id: '2467588||13048471',
          test_item_description: 'Amoebic Antibody SEND OUT UN',
          result_value: 'Testing',
          result_units: '',
          result_normal_range: '',
          result_interpretation: '',
        },
      ],
    },
    {
      order_id: '9117063||2',
      order_description: 'Thyroid Stimulating Hormone, (TSH)',
      order_accession_number: '9117063/29',
      department_code: 'CHEM1',
      department_description: 'Chemistry',
      ordered_doctor: 'Test Patient (Not  for Booking )',
      order_status: 'In Progress',
      collected_date: '2024-06-25',
      collected_time: '12:13',
      labepisode_number: '2406250691',
      specimen_number: '2406250691-1',
      authorized_user: '',
      authorized_date: '',
      authorized_time: '',
      smart_report: false,
      result_list: [],
    },
  ],
  item_count: 3150,
  page: 1,
  total: 10,
  status: 'Success',
};

export const historyLabReports = {
  status: 'true',
  message: 'Lab Reports',
  data: [
    {
      B11248016: [
        {
          mainTest: 'LA2093',
          longName: 'COVID-19 Real-Time PCR 24 hour',
          specimenNum: 'CG2204832',
          accessionNum: 'B11248016', // in case of smartReport
          dateTaken: 1645024063000,
          patId: '442603',
          trackingNum: '12633393',
          orderNum: '27572696',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15896975',
          docName: 'NASHAAT  SOBHI  HAMZA',
          docNameAr: 'نشأت    حمزة',
          aptTime: '16-Feb-2022, 07:30 PM',
          aptTimeAr: '16-فبراير-2022, 07:30 م',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B11001071: [
        {
          mainTest: 'LA0684',
          longName: 'HIV 1/2 Antibody Screen',
          specimenNum: 'SE08213833',
          accessionNum: 'B11001071',
          dateTaken: 1629962845000,
          patId: '442603',
          trackingNum: '12280841',
          orderNum: '26390371',
          orderNumLine: '5',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15311928',
          docName: 'RASHA  MAHMOUD  GAMIL',
          docNameAr: 'رشا    جميل',
          aptTime: '26-Aug-2021, 11:00 AM',
          aptTimeAr: '26-أغسطس-2021, 11:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0692',
          longName: 'Hepatitis B Virus Surface Antigen (HBs Ag)',
          specimenNum: 'SE08213832',
          accessionNum: 'B11001071',
          dateTaken: 1629962845000,
          patId: '442603',
          trackingNum: '12280839',
          orderNum: '26390371',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15311928',
          docName: 'RASHA  MAHMOUD  GAMIL',
          docNameAr: 'رشا    جميل',
          aptTime: '26-Aug-2021, 11:00 AM',
          aptTimeAr: '26-أغسطس-2021, 11:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0690',
          longName: 'Hepatitis B Virus Surface Antibody (HBS AB) ',
          specimenNum: 'SE08213832',
          accessionNum: 'B11001071',
          dateTaken: 1629962845000,
          patId: '442603',
          trackingNum: '12280839',
          orderNum: '26390371',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15311928',
          docName: 'RASHA  MAHMOUD  GAMIL',
          docNameAr: 'رشا    جميل',
          aptTime: '26-Aug-2021, 11:00 AM',
          aptTimeAr: '26-أغسطس-2021, 11:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0700',
          longName: 'Hepatitis C Virus Antibody (HCV Ab)',
          specimenNum: 'SE08213832',
          accessionNum: 'B11001071',
          dateTaken: 1629962845000,
          patId: '442603',
          trackingNum: '12280839',
          orderNum: '26390371',
          orderNumLine: '4',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15311928',
          docName: 'RASHA  MAHMOUD  GAMIL',
          docNameAr: 'رشا    جميل',
          aptTime: '26-Aug-2021, 11:00 AM',
          aptTimeAr: '26-أغسطس-2021, 11:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0001',
          longName: 'ABO/Rh Type',
          specimenNum: 'BB08211934',
          accessionNum: 'B11001071',
          dateTaken: 1629962845000,
          patId: '442603',
          trackingNum: '12280840',
          orderNum: '26390371',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '15311928',
          docName: 'RASHA  MAHMOUD  GAMIL',
          docNameAr: 'رشا    جميل',
          aptTime: '26-Aug-2021, 11:00 AM',
          aptTimeAr: '26-أغسطس-2021, 11:00 ص',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B9944815: [
        {
          mainTest: 'LA0307',
          longName: 'Thyroxin T4  Free',
          specimenNum: 'CH032013607',
          accessionNum: 'B9944815',
          dateTaken: 1585225950000,
          patId: '442603',
          trackingNum: '11177824',
          orderNum: '23394207',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13766839',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '25-Mar-2020, 02:15 PM',
          aptTimeAr: '25-مارس-2020, 02:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0305',
          longName: 'Thyroid Stimulating Hormone, (TSH)',
          specimenNum: 'CH032013607',
          accessionNum: 'B9944815',
          dateTaken: 1585225950000,
          patId: '442603',
          trackingNum: '11177824',
          orderNum: '23394207',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13766839',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '25-Mar-2020, 02:15 PM',
          aptTimeAr: '25-مارس-2020, 02:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0309',
          longName: 'T3  Free',
          specimenNum: 'CH032013607',
          accessionNum: 'B9944815',
          dateTaken: 1585225950000,
          patId: '442603',
          trackingNum: '11177824',
          orderNum: '23394207',
          orderNumLine: '4',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13766839',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '25-Mar-2020, 02:15 PM',
          aptTimeAr: '25-مارس-2020, 02:15 م',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B9943673: [
        {
          mainTest: 'LA0122',
          longName: 'Glycosylated Hemoglobin (HbA1c)',
          specimenNum: 'CH032013213',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176486',
          orderNum: '23385910',
          orderNumLine: '7',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA1527',
          longName: 'Creatinine, serum + GFR',
          specimenNum: 'CH032013214',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176487',
          orderNum: '23385910',
          orderNumLine: '4',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0287',
          longName: 'Vitamin D, Total (25 OH vitamin D3 + 25 OH vitamin D2)',
          specimenNum: 'CH032013216',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176490',
          orderNum: '23393047',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '0',
          docName: 'IBRAHIM    MANSOOR',
          docNameAr: 'IBRAHIM    MANSOOR',
          aptTime: null,
          aptTimeAr: null,
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0328',
          longName: 'Complete blood count, CBC With Diff. and blood film',
          specimenNum: 'HA0320008190',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176489',
          orderNum: '23385910',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0305',
          longName: 'Thyroid Stimulating Hormone, (TSH)',
          specimenNum: 'CH032013214',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176487',
          orderNum: '23385910',
          orderNumLine: '5',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0220',
          longName: 'Lipid Profile (LPT) (8 Hrs Fasting)',
          specimenNum: 'CH032013214',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176487',
          orderNum: '23385910',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0119',
          longName: 'Glucose, Fasting (FBS)',
          specimenNum: 'CH032013215',
          accessionNum: 'B9943673',
          dateTaken: 1585122202000,
          patId: '442603',
          trackingNum: '11176488',
          orderNum: '23385910',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13763600',
          docName: 'BADR  GHAZI  BIN HIMD',
          docNameAr: 'بدر  غازي  بن حمد',
          aptTime: '23-Mar-2020, 10:00 AM',
          aptTimeAr: '23-مارس-2020, 10:00 ص',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B9513075: [
        {
          mainTest: 'LA1120',
          longName: 'Glucose, Random',
          specimenNum: 'CH091914576',
          accessionNum: 'B9513075',
          dateTaken: 1569152687000,
          patId: '442603',
          trackingNum: '10814778',
          orderNum: '22394038',
          orderNumLine: '5',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13288944',
          docName: 'ZAFARULLAH    BAKHSH',
          docNameAr: 'ظفر الله    بخش',
          aptTime: '22-Sep-2019, 12:45 PM',
          aptTimeAr: '22-سبتمبر-2019, 12:45 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0287',
          longName: 'Vitamin D, Total (25 OH vitamin D3 + 25 OH vitamin D2)',
          specimenNum: 'CH091914576',
          accessionNum: 'B9513075',
          dateTaken: 1569152687000,
          patId: '442603',
          trackingNum: '10814778',
          orderNum: '22394038',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13288944',
          docName: 'ZAFARULLAH    BAKHSH',
          docNameAr: 'ظفر الله    بخش',
          aptTime: '22-Sep-2019, 12:45 PM',
          aptTimeAr: '22-سبتمبر-2019, 12:45 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0332',
          longName: 'Erythrocyte Sedimentation Rate (ESR)',
          specimenNum: 'HA0919008526',
          accessionNum: 'B9513075',
          dateTaken: 1569152687000,
          patId: '442603',
          trackingNum: '10814776',
          orderNum: '22394038',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13288944',
          docName: 'ZAFARULLAH    BAKHSH',
          docNameAr: 'ظفر الله    بخش',
          aptTime: '22-Sep-2019, 12:45 PM',
          aptTimeAr: '22-سبتمبر-2019, 12:45 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0328',
          longName: 'Complete blood count, CBC With Diff. and blood film',
          specimenNum: 'HA0919008527',
          accessionNum: 'B9513075',
          dateTaken: 1569152687000,
          patId: '442603',
          trackingNum: '10814777',
          orderNum: '22394038',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '13288944',
          docName: 'ZAFARULLAH    BAKHSH',
          docNameAr: 'ظفر الله    بخش',
          aptTime: '22-Sep-2019, 12:45 PM',
          aptTimeAr: '22-سبتمبر-2019, 12:45 م',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B8842976: [
        {
          mainTest: 'LA1696',
          longName: 'Mumps IgG Antibody',
          specimenNum: 'SE10182253',
          accessionNum: 'B8842976',
          dateTaken: 1539606894000,
          patId: '442603',
          trackingNum: '10087462',
          orderNum: '20557274',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '12426306',
          docName: 'ABDUL HAMEED    HASSAN',
          docNameAr: 'عبدالحميد    حسن',
          aptTime: '15-Oct-2018, 05:15 PM',
          aptTimeAr: '15-أكتوبر-2018, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA1020',
          longName: 'Varicella zoster IGG Antibody',
          specimenNum: 'SE10182252',
          accessionNum: 'B8842976',
          dateTaken: 1539606894000,
          patId: '442603',
          trackingNum: '10087461',
          orderNum: '20557274',
          orderNumLine: '5',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '12426306',
          docName: 'ABDUL HAMEED    HASSAN',
          docNameAr: 'عبدالحميد    حسن',
          aptTime: '15-Oct-2018, 05:15 PM',
          aptTimeAr: '15-أكتوبر-2018, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA1697',
          longName: 'Measles IgG Antibody',
          specimenNum: 'SE10182251',
          accessionNum: 'B8842976',
          dateTaken: 1539606894000,
          patId: '442603',
          trackingNum: '10087460',
          orderNum: '20557274',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '12426306',
          docName: 'ABDUL HAMEED    HASSAN',
          docNameAr: 'عبدالحميد    حسن',
          aptTime: '15-Oct-2018, 05:15 PM',
          aptTimeAr: '15-أكتوبر-2018, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA1048',
          longName: 'RUBELLA IGG ANTIBODY',
          specimenNum: 'SE10182253',
          accessionNum: 'B8842976',
          dateTaken: 1539606894000,
          patId: '442603',
          trackingNum: '10087462',
          orderNum: '20557274',
          orderNumLine: '4',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '12426306',
          docName: 'ABDUL HAMEED    HASSAN',
          docNameAr: 'عبدالحميد    حسن',
          aptTime: '15-Oct-2018, 05:15 PM',
          aptTimeAr: '15-أكتوبر-2018, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
    {
      B7691148: [
        {
          mainTest: 'LA0690',
          longName: 'Hepatitis B Virus Surface Antibody (HBS AB) ',
          specimenNum: 'SE02172036',
          accessionNum: 'B7691148',
          dateTaken: 1487246795000,
          patId: '442603',
          trackingNum: '8526756',
          orderNum: '16999406',
          orderNumLine: '2',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '10698182',
          docName: 'HIBA  MAAN  BZEIH',
          docNameAr: 'هبة    بزية',
          aptTime: '16-Feb-2017, 05:15 PM',
          aptTimeAr: '16-فبراير-2017, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0692',
          longName: 'Hepatitis B Virus Surface Antigen (HBs Ag)',
          specimenNum: 'SE02172036',
          accessionNum: 'B7691148',
          dateTaken: 1487246795000,
          patId: '442603',
          trackingNum: '8526756',
          orderNum: '16999406',
          orderNumLine: '3',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '10698182',
          docName: 'HIBA  MAAN  BZEIH',
          docNameAr: 'هبة    بزية',
          aptTime: '16-Feb-2017, 05:15 PM',
          aptTimeAr: '16-فبراير-2017, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0001',
          longName: 'ABO/Rh Type',
          specimenNum: 'BB02170974',
          accessionNum: 'B7691148',
          dateTaken: 1487246795000,
          patId: '442603',
          trackingNum: '8526758',
          orderNum: '16999406',
          orderNumLine: '1',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '10698182',
          docName: 'HIBA  MAAN  BZEIH',
          docNameAr: 'هبة    بزية',
          aptTime: '16-Feb-2017, 05:15 PM',
          aptTimeAr: '16-فبراير-2017, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0700',
          longName: 'Hepatitis C Virus Antibody (HCV Ab)',
          specimenNum: 'SE02172036',
          accessionNum: 'B7691148',
          dateTaken: 1487246795000,
          patId: '442603',
          trackingNum: '8526756',
          orderNum: '16999406',
          orderNumLine: '4',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '10698182',
          docName: 'HIBA  MAAN  BZEIH',
          docNameAr: 'هبة    بزية',
          aptTime: '16-Feb-2017, 05:15 PM',
          aptTimeAr: '16-فبراير-2017, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
        {
          mainTest: 'LA0684',
          longName: 'HIV 1/2 Antibody Screen',
          specimenNum: 'SE02172037',
          accessionNum: 'B7691148',
          dateTaken: 1487246795000,
          patId: '442603',
          trackingNum: '8526757',
          orderNum: '16999406',
          orderNumLine: '5',
          reportType: null,
          status: 1,
          statusWeb: 1,
          episodeNo: '10698182',
          docName: 'HIBA  MAAN  BZEIH',
          docNameAr: 'هبة    بزية',
          aptTime: '16-Feb-2017, 05:15 PM',
          aptTimeAr: '16-فبراير-2017, 05:15 م',
          smartReport: 0,
          hosp: 1,
        },
      ],
    },
  ],
  total_items: '7',
  total_pages: '1',
};

export const historyRadiologyReports = {
  status: 'true',
  message: 'Medical Reports',
  data: [
    {
      id: {
        orderNo: '26390371',
        orderNoLine: '6',
      },
      patId: '442603',
      prodcutCode: 'XY0016',
      orderDate: 1629962379000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'X-RAY - CHEST (PA ONLY)',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '15311928',
      hosp: 1,
      isCareStream: 'Y',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\5002085082_442603_1.pdf',
    },
    {
      id: {
        orderNo: '23394207',
        orderNoLine: '1',
      },
      patId: '442603',
      prodcutCode: 'XY0576',
      orderDate: 1585136458000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'US - KIDNEYS AND BLADDER',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '13766839',
      hosp: 1,
      isCareStream: 'N',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\ULTRASOUND KIDNEYS \\T\\ BLADDER\r\n\r\n\r\nBoth kidneys are seen normal site, size and shape with average parenchymal thickness and normal echogenicity. No detectable sizable stones. Subtle fullness of the right renal pelvis..\r\nUrinary bladder is seen distended with clear urine with no detectable stones.\r\nPre-void urine volume about 229 ml and post void urine volume about 9 ml. \r\nProstate is seen average size with estimated volume 13 ml.  \r\n\r\nOpinion:\r\nSubtle fullness of the right renal pelvis.\r\n\r\n\r\n\r\nReport Created by AYMAN ABD EL-AZIZ',
    },
    {
      id: {
        orderNo: '22394038',
        orderNoLine: '4',
      },
      patId: '442603',
      prodcutCode: 'XY0074',
      orderDate: 1569144977000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'X-RAY - LUMBAR SPINE (ROUTINE)',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '13288944',
      hosp: 1,
      isCareStream: 'N',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\X-RAY -  LUMBAR SPINE\r\n\r\nFindings:\r\nPreserved lumbar lordosis.\r\nNormal radiographic appearance of the examined vertebrae.\r\nMaintained disc spaces.\r\nIntact bilateral sacroiliac joints.\r\n \r\n\r\n\r\n\r\n\r\n\r\n\r\nReport Created by SHERIF M. ABOLYAZID',
    },
    {
      id: {
        orderNo: '20557274',
        orderNoLine: '1',
      },
      patId: '442603',
      prodcutCode: 'XY0014',
      orderDate: 1539604993000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'X-RAY - CHEST (PA, LAT)',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '12426306',
      hosp: 1,
      isCareStream: 'N',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\X-RAY - CHEST\r\n\r\nFindings:\r\nNormal cardiac size and configuration.\r\nClear lung fields and costophrenic angles.\r\nNo focal lung lesion seen.\r\n\r\nConclusion:\r\nNormal Chest radiograph.\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\nReport Created by OMNIA H. AFIFI',
    },
    {
      id: {
        orderNo: '19721057',
        orderNoLine: '1',
      },
      patId: '442603',
      prodcutCode: 'XY0073',
      orderDate: 1525590914000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'X-RAY - CERVICAL SPINE (AP, LAT, FLEX, EXT)',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '12010052',
      hosp: 1,
      isCareStream: 'N',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\DYNAMIC X-RAY CERVICAL SPINE\r\n\r\nFair alignment of the examined vertebrae.  No signs of instability detected.\r\nIntact disc spaces.\r\nDiminished density of examined bones. \r\nNo bony cervical rib noted. \r\n\r\n\r\n\r\n\r\n/mjd.\r\n\r\n\r\n\r\n\r\nReport Created by AHMED HAMIMI ABDULLAH\r\nReport Date \\T\\ Time 06/05/2018 17:35',
    },
    {
      id: {
        orderNo: '16999406',
        orderNoLine: '6',
      },
      patId: '442603',
      prodcutCode: 'XY0016',
      orderDate: 1487244445000,
      deliveringDept: 'RAD',
      orderingDept: 'RAD',
      desc: 'X-RAY - CHEST (PA ONLY)',
      orderFlag: 'Y',
      resultAvailable: 1,
      orderStatusWeb: 1,
      episodeNo: '10698182',
      hosp: 1,
      isCareStream: 'N',
      careStreamReportPath:
        '\\\\10.11.12.110\\SX_DOCSCAN\\442603\\DOC018\\X-RAY - CHEST\r\n\r\nFindings:\r\nClear both lung fields and both CP angles.\r\nNormal cardiac size and configuration.\r\n\r\n \r\n\r\n\r\n\r\n\r\n\r\nReport Created by SHERIF M. ABOLYAZID\r\nReport Date \\T\\ Time 16/02/2017 20:24',
    },
  ],
  total_items: '6',
  total_pages: '1',
};

export const historyOperativeReports = {
  status: 'true',
  message: 'Operative Reports',
  data: [
    {
      id: 4464490,
      patId: '000265',
      status: 'Y',
      createdBy: 'Adian Wahhyas',
      createdDate: '27-Apr-21 02:57 PM',
      verifyBy: 'Adian Wahhyas',
      verifyDate: '05-May-21 10:39 AM',
      reportType: 'DICTA',
    },
    {
      id: 20650,
      patId: '000265',
      status: 'Y',
      createdBy: 'DRZIAT',
      createdDate: '25-Jun-20 02:02 PM',
      verifyBy: 'DRZIAT',
      verifyDate: '28-Jun-20 07:10 PM',
      reportType: 'OP',
    },
    {
      id: 20145,
      patId: '000265',
      status: 'Y',
      createdBy: 'DRZIAT',
      createdDate: '21-Jun-20 09:22 AM',
      verifyBy: 'DRZIAT',
      verifyDate: '28-Jun-20 09:45 PM',
      reportType: 'OP',
    },
    {
      id: 6996,
      patId: '000265',
      status: 'Y',
      createdBy: 'DRFAKA',
      createdDate: '09-Dec-19 11:26 AM',
      verifyBy: 'DRFAKA',
      verifyDate: '09-Dec-19 01:49 PM',
      reportType: 'OP',
    },
    {
      id: 2857928,
      patId: '000265',
      status: 'Y',
      createdBy: 'Tahir Yunus',
      createdDate: '27-Apr-16 04:36 PM',
      verifyBy: 'Tahir Yunus',
      verifyDate: '04-May-16 02:51 PM',
      reportType: 'DICTA',
    },
  ],
  total_items: '5',
  total_pages: '1',
};

export const SickReports = {
  document_list: [
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '911931||13',
      authorized_user: 'Abdul Hameed Hassan',
      authorized_date: '2023-10-12',
      authorized_time: '14:53',
      clinic_code: '3029',
      clinic_description: 'Internal Medicine ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '911931||11',
      authorized_user: 'Abdul Hameed Hassan',
      authorized_date: '2023-10-12',
      authorized_time: '14:53',
      clinic_code: '3029',
      clinic_description: 'Internal Medicine ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '911931||7',
      authorized_user: 'SALEH ASEM',
      authorized_date: '2023-10-12',
      authorized_time: '12:49',
      clinic_code: '3029',
      clinic_description: 'Internal Medicine ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '911931||2',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-07-31',
      authorized_time: '11:11',
      clinic_code: '3029',
      clinic_description: 'Internal Medicine ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||15',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-28',
      authorized_time: '10:15',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||13',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-28',
      authorized_time: '10:06',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||11',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-28',
      authorized_time: '10:04',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '457512||2',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-23',
      authorized_time: '10:56',
      clinic_code: '3058',
      clinic_description: 'OB/Gyne',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||8',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-23',
      authorized_time: '10:54',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||6',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-23',
      authorized_time: '10:51',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '498083||4',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2023-03-06',
      authorized_time: '15:21',
      clinic_code: '3052',
      clinic_description: 'General Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '12||4',
      authorized_user: '',
      authorized_date: '2022-11-01',
      authorized_time: '18:01',
      clinic_code: '3054',
      clinic_description: 'Vascular Surgery ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '364||2',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2022-10-18',
      authorized_time: '12:03',
      clinic_code: 'FC-DENT',
      clinic_description: 'FC-Dental Clinic',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '14391||2',
      authorized_user: 'HEGAZY HAYTHAM',
      authorized_date: '2022-10-18',
      authorized_time: '11:56',
      clinic_code: 'MAIN LAB',
      clinic_description: 'IMC Main Lab',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '83619||2',
      authorized_user: 'NASSAR JIHAD',
      authorized_date: '2022-10-18',
      authorized_time: '11:47',
      clinic_code: '3044',
      clinic_description: 'Rehabilitation ',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '32872||2',
      authorized_user: 'NASSAR JIHAD',
      authorized_date: '2022-10-17',
      authorized_time: '11:32',
      clinic_code: 'MAIN LAB',
      clinic_description: 'IMC Main Lab',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '31048||4',
      authorized_user: '',
      authorized_date: '2022-10-05',
      authorized_time: '19:12',
      clinic_code: 'MAIN LAB',
      clinic_description: 'IMC Main Lab',
      document_status: 'Authorised',
    },
    {
      mrn: null,
      reportType: null,
      hosp: null,
      oldData: null,
      document_id: '11193||2',
      authorized_user: 'Emad Sagr',
      authorized_date: '2022-10-02',
      authorized_time: '09:56',
      clinic_code: 'MAIN LAB',
      clinic_description: 'IMC Main Lab',
      document_status: 'Authorised',
    },
  ],
  status: 'Success',
};

export const CardiologyReportsMock = {
  "order_list": [
    {
      "order_id": "3250573||7",
      "episode_number": "EM0000044217",
      "episode_id": "873855",
      "order_description": "ECG (Electrocardiogram)",
      "ordered_doctor": "JAHA NERMEEN",
      "order_status": "Verified",
      "approval_status": "NA",
      "result_attached": "false",
      "authorized_user": "",
      "result_date": "",
      "result_time": ""
    },
    {
      "order_id": "512658||3",
      "episode_number": "OP0000130788",
      "episode_id": "147700",
      "order_description": "Echo (2D Echocardiogram)",
      "ordered_doctor": "Khalid Tammam",
      "order_status": "Executed",
      "approval_status": "MEXX.05",
      "result_attached": "true"
    },
    {
      "order_id": "512658||4",
      "episode_number": "OP0000130788",
      "episode_id": "147700",
      "order_description": "Stress Test ECG",
      "ordered_doctor": "Khalid Tammam",
      "order_status": "Executed",
      "approval_status": "MEXX.05",
      "result_attached": "false",
      "authorized_user": "",
      "result_date": "",
      "result_time": ""
    },
    {
      "order_id": "512658||2",
      "episode_number": "OP0000130788",
      "episode_id": "147700",
      "order_description": "ECG (Electrocardiogram)",
      "ordered_doctor": "Khalid Tammam",
      "order_status": "Executed",
      "approval_status": "MEXX.05",
      "result_attached": "false",
      "authorized_user": "",
      "result_date": "",
      "result_time": ""
    }
  ],
  "status": "Success"
};
