import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import ReportsTypeSlider from '../components/Reports/ReportsTypeSlider';
import Loader from '../components/UI/Loader';
import LabReportCard from '../components/Reports/LabReport';
import LabHistory from '../components/Reports/LabReport/LabHistory';
import RadiologyReport from '../components/Reports/RadiologyReport';
import RadiologyHistory from '../components/Reports/RadiologyReport/RadiologyHistory';
import CardiologyReport from '../components/Reports/CardiologyReport';
import OperativeReport from '../components/Reports/OperativeReport';
import DischargeReport from '../components/Reports/DischargeReport';
import SickReport from '../components/Reports/SickReport';
import { getReportByType } from '../utilFunctions/reports/reports';
import { selectLanguage } from '../store/languageSlice';
import '../components/Reports/style.css';
import { useDispatch } from 'react-redux';
import { portalActions } from '../store';
import { selectReports } from '../store/reportsSlice';
import { hideLoader, showLoader } from '../store/globalLoaderError';
import ReportSwitch from '../components/Reports/ReportSwitch';
import useBreakpoint from '../utilFunctions/useBreakpoint';

export const REPORT_TYPES = {
  LAB: 'laboratory-results',
  RADI: 'radiology-results',
  OPER: 'operative-reports',
  CARD: 'cardiology-results',
  SICK: 'sick-leave-reports',
  DISCHARGE: 'discharge-summary-reports',
  REQUEST: 'request-a-reports',
};

const Reports = () => {
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const { isMobile, isDesktop } = useBreakpoint();
  const { t } = useSelector(selectLanguage);
  const { oldData } = useSelector(selectReports);
  const [previousOldValue, setPreviousOldValue] = useState(oldData);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedType, setSelectedType] = useState(REPORT_TYPES.LAB);
  const [currentData, setCurrentData] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const isFirstRender = useRef(true);

  const checkAndLoadMore = async () => {
    if (listInnerRef.current) {
      let { scrollHeight, clientHeight } = listInnerRef.current;
      let currentPage = currPage; // Track the page within the loop
  
      while (scrollHeight <= clientHeight && currentPage < totalPages) {
        currentPage += 1;
        currentPage = await fetchMoreOnScroll(currentPage); // Update current page and wait for data
        await new Promise(resolve => setTimeout(resolve, 200)); // Allow DOM to update
  
        // Update scrollHeight after re-render
        scrollHeight = listInnerRef.current.scrollHeight;
      }
    }
  };

  const handleOnReportTypeClick = async (type) => {
    if (type === REPORT_TYPES.REQUEST) {
      setSelectedType(type);
      dispatch(portalActions.toggleRequestReportDrawer());
      return;
    }
    setShowLoader(true);
    setCurrentData(null);
    const pageNo = 1;
    if (type !== selectedType || oldData !== previousOldValue) {
      setCurrentData(null);
      setSelectedType(type);
      setPreviousOldValue(oldData);
      setCurrPage(pageNo);
    }

    try {
      const data = await getReportByType(type, oldData, pageNo);
      setShowLoader(false);
      if (data) {
        setCurrentData(data);
        setTotalPages(data.total_pages || data.pages || data.total || 1);
        // checkAndLoadMore();
      }
    } catch (error) {
      if (error?.name !== 'AbortError') {
        setCurrentData({});
        setShowLoader(false);
      }
    }
  };

  const fetchMoreOnScroll = async (nextPage) => {
    setCurrPage(nextPage);
    setShowLoader(true);
    const data = await getReportByType(selectedType, oldData, nextPage);
    setShowLoader(false);
    // checkAndLoadMore();
    if (data) {
      setCurrentData(data);
    } else {
      setCurrentData({});
    }
  
    return nextPage; // Return the next page number
  };

  const onScroll = () => {
    if (listInnerRef.current && !showLoader) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const threshold = 50;
      if (
        scrollTop + clientHeight >= scrollHeight - threshold &&
        currPage < totalPages
      ) {
        const nextPage = currPage + 1;
        fetchMoreOnScroll(nextPage);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleOnReportTypeClick(selectedType);
  }, [oldData]);

  useEffect(() => {
    checkAndLoadMore();
  }, [currentData]);

  useEffect(() => {
    const handleWindowScroll = (e) => {
      if (listInnerRef.current) {
        listInnerRef.current.scrollTop += e.deltaY;
        e.preventDefault(); // Prevent the default window scroll behavior
      }
    };
    if (selectedType === REPORT_TYPES.REQUEST) {
      window.removeEventListener('wheel', handleWindowScroll);
      return;
    }

    window.addEventListener('wheel', handleWindowScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleWindowScroll);
    };
  }, [selectedType]);

  return (
    <div
      className="rprtsList vr2"
      onScroll={onScroll}
      ref={listInnerRef}
      style={{
        height: `calc(100vh - 190px)`,
        overflowY: 'auto',
      }}
    >
      <ReportsTypeSlider
        type={selectedType}
        onTypeChange={handleOnReportTypeClick}
      />
      <div className="gridResponsive">
        <div className="dataGrid">
          {!isMobile && (
            <div className="rportsHead">
              <div className="no whitespace-nowrap">{t.Sno}</div>
              <div className="date">{t.Date}</div>
              {selectedType !== REPORT_TYPES.SICK && (
                <div className="year">{t.Year}</div>
              )}
              <div className="id">{t.Order_Id}</div>
              <div className="by">{t.Ordered_By}</div>
              <div className="time">{t.Time}</div>
              {oldData !== 1 && <div className="action">{t.Action}</div>}
              {isDesktop && (
                <div className="toggle">
                  <ReportSwitch />
                </div>
              )}
            </div>
          )}
          {selectedType == REPORT_TYPES.LAB && (
            <>
              {oldData === 1 ? (
                <LabHistory
                  currentData={currentData}
                  listData={currentData?.data || []}
                />
              ) : (
                <LabReportCard
                  currentData={currentData}
                  listData={currentData?.order_list || []}
                />
              )}
            </>
          )}
          {selectedType == REPORT_TYPES.RADI && !showLoader && (
            <>
              {oldData === 1 ? (
                <RadiologyHistory
                  currentData={currentData}
                  listData={currentData?.data || []}
                />
              ) : (
                <RadiologyReport
                  currentData={currentData}
                  listData={currentData?.order_list || []}
                />
              )}
            </>
          )}
          {/* {selectedType == REPORT_TYPES.RADI && (
          <>
            {oldData === 1 ? (
              <RadiologyHistory
                currentData={currentData}
                listData={currentData?.data || []}
              />
            ) : (
              <RadiologyReport
                currentData={currentData}
                listData={currentData?.order_list || []}
              />
            )}
          </>
        )} */}
          {selectedType == REPORT_TYPES.OPER && !showLoader && (
            <OperativeReport
              currentData={currentData}
              listData={currentData?.document_list || []}
            />
          )}
          {selectedType == REPORT_TYPES.CARD && !showLoader && (
            <CardiologyReport
              currentData={currentData}
              listData={currentData?.order_list || []}
              // currentData={CardiologyReportsMock}
              // listData={CardiologyReportsMock?.order_list || []}
            />
          )}
          {selectedType == REPORT_TYPES.DISCHARGE && !showLoader && (
            <DischargeReport
              currentData={currentData}
              listData={currentData?.document_list || []}
            />
          )}
          {selectedType == REPORT_TYPES.SICK && !showLoader && (
            <SickReport
              currentData={currentData}
              listData={currentData?.document_list || []}
            />
          )}
        </div>
      </div>
      {showLoader && (
        <div className="w-full my-8">
          <Loader roundLoader />
        </div>
      )}
    </div>
  );
};

export default Reports;

export const reportsLoader = async () => {
  try {
    const data = await getReportByType(REPORT_TYPES.LAB);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
