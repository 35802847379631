import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from '../../../store';
import { selectLanguage } from '../../../store/languageSlice';
import {
  getReportTypeDropdown,
  getRequestedReport,
  getVistTypeDropdown,
} from '../../../utilFunctions/reports/request-report';
import useFetchData from '../../../utilFunctions/useFetchData';
import Loader from '../../UI/Loader';
import './style.css';

export default function RequestReport() {
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const PHONE_CODE = '+966';
  const { data: visitsTypeList } = useFetchData(getVistTypeDropdown);
  const {
    data: reportTypeList,
    loading,
    error,
  } = useFetchData(getReportTypeDropdown);

  const [messageRequire, setMessageRequire] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [message1, setMessage1] = useState();
  const [error1, setError1] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    reportType: '',
    visitType: '',
    deliveryMethod: 'email',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    messageRequire && setMessageRequire(false);
  };

  const handleRequestReport = async (event) => {
    event.preventDefault();
    if (formData?.message == '') return setMessageRequire(true);
    const visitType = visitsTypeList?.episode_list.find(
      (x) => x.episode_id == formData.visitType
    );

    if (visitType) {
      const requestPayload = {
        requestType: 'Request Report',
        subject: 'Request Report',
        appointmentDate: visitType.episode_date,
        outPatient:
          visitType?.care_provider_description + visitType.episode_type,
        message: formData.message,
        pickupId: '',
        pickupDob: '',
        episodeNo: visitType?.episode_number,
        episodeKey: '',
        episode: visitType.episode_id,
        hosp: 1,
        copyType: formData.deliveryMethod,
        pickupName: 'fullname',
        email: formData.email,
        reportType: formData.reportType,
        patientId: 'PATIENT_ID',
        mrNumber: 'USER_MRN',
        consentChecked: true,
        accept: true,
        phoneNumber: formData.phone,
      };
      const newPayload = {
        subject: 'Request Report',
        appointmentDate: visitType.episode_date,
        outPatient:`${visitType?.episodeType}-${visitType?.care_provider_description}`,
        message: formData.message,
        pickupId: '',
        pickupDob: '',
        episodeNo: visitType?.episode_number,
        episode: visitType.episode_id,
        episodeKey: '',
        hosp: 1,
        copyType: formData.deliveryMethod,
        pickupName: '',
        mrNumber: '',
        email: formData.email,
        patientId: '',
        reportType: formData.reportType,
        consentChecked: true,
        accept: true,
      };

      setLoading1(true);
      setMessage1();
      setError1();

      const responseData = await getRequestedReport(newPayload);
      setLoading1(false);

      if (responseData?.status == "true") {
        setMessage1(t.FormSubmitted);
        setTimeout(() => {
          dispatch(portalActions.toggleRequestReportDrawer());
        }, 2000);
      } else {
        setError1(true);
        setMessage1(t.FormFailed);
      }
      // dispatch(portalActions.toggleRequestReportDrawer());
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      setFormData({
        ...formData,
        email: userInfo?.email || '',
        phone: userInfo?.mobileNumber || '',
      });
    }
  }, []);

  if (loading) return <Loader />;

  if (error)
    return <div className="p-8">{t.Something_Wrong}</div>;

  const sortedReportTypeList = reportTypeList?.report_type_list?.sort(
    (a, b) => a.report_code - b.report_code
  );

  return (
    <div>
      <div className="px-8">
        <form onSubmit={handleRequestReport}>
          <label htmlFor="email">
            {t.EmailAddress} <br />
            <input
              id="email"
              type="email"
              name="email"
              placeholder={t.EnterYourEmailAddress}
              defaultValue={formData?.email}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="phone">
            {t.CellPhoneNumber}* <br />
            <div dir="ltr" className="countrycode">
              <div className="code text-secondary">({PHONE_CODE})</div>
              <input
                id="phone"
                type="number"
                name="phone"
                placeholder="5xxxxxxxx"
                defaultValue={formData?.phone}
                onChange={handleChange}
              />
            </div>
          </label>
          <label htmlFor="reportType">
            {t.ChooseAReportType} <br />
            <div className="selectWithName relative">
              <select
                name="reportType"
                id="reportType"
                defaultValue={formData?.reportType}
                onChange={handleChange}
              >
                <option value="">{t.SelectYourReportType}</option>
                {sortedReportTypeList?.map((x) => {
                  // const x = {
                  //   report_code: 64,
                  //   report_description: 'Medical Report',
                  //   report_description_ar: 'تقرير طبي',
                  // };
                  const label = isRtl
                    ? x.report_description_ar
                    : x.report_description;
                  const value = x.report_code;

                  return <option value={value}>{label}</option>;
                })}
                {/* <option value="1">1</option>
                <option value="2">2</option> */}
              </select>
              <span className="selectName text-secondary">{t.Type}</span>
            </div>
          </label>
          <label htmlFor="visitType">
            {t.SelectAVisit} <br />
            <div className="selectWithName relative">
              <select
                name="visitType"
                defaultValue={formData?.visitType}
                id="visitType"
                onChange={handleChange}
              >
                <option value="">{t.SelectAVisit}</option>

                {visitsTypeList?.episode_list?.map((x) => {
                  // const x = {
                  //   hospital: 'International Medical Center',
                  //   department: 'Ophthalmology',
                  //   episode_number: 'OP0000109360',
                  //   episode_id: '123372',
                  //   episode_date: '2022-11-03',
                  //   episode_time: '15:30',
                  //   episodeType: 'Outpatient',
                  //   care_provider_code: '565',
                  //   care_provider_description: 'Nizamuddin Shaik ',
                  //   discharge_date: null,
                  //   discharge_time: null,
                  // };
                  const label = `${x?.episode_date}-${x?.episodeType}-${
                    x.care_provider_description ?? ''
                  }`;
                  const value = x.episode_id;

                  return <option value={value}>{label}</option>;
                })}
              </select>
              <span className="selectName text-secondary">{t.Visit}</span>
            </div>
          </label>
          <label htmlFor="reviewEmail">
            <input
              type="radio"
              name="deliveryMethod"
              id="reviewEmail"
              value="email"
              checked={formData?.deliveryMethod === 'email'}
              onChange={() =>
                setFormData({ ...formData, deliveryMethod: 'email' })
              }
            />
            {t.ReceiveByEmail}
          </label>
          <label htmlFor="reviewInPerson">
            <input
              type="radio"
              name="deliveryMethod"
              id="reviewInPerson"
              value="pickup"
              checked={formData?.deliveryMethod === 'pickup'}
              onChange={() =>
                setFormData({ ...formData, deliveryMethod: 'pickup' })
              }
            />
            {t.ReceiveByPersonal}
          </label>
          <label htmlFor="message">
            {t.Message}* <br />
            <textarea
              id="message"
              name="message"
              placeholder={t.TypeMessage}
              defaultValue={formData?.message}
              onChange={handleChange}
            ></textarea>
            <br />
            {messageRequire && (
              <label className="text-tertiary">{t.MessageFieldRequired}</label>
            )}
          </label>
          <div className="flex justify-center mt-3">
            <button type="submit" className="request_btn">
              {loading1 ? <Loader size="small" roundLoader /> : t.Request}
            </button>
          </div>
          {message1 && (
            <p className={`${error1 ? 'errorMsg' : 'successMsg'} my-2`}>
              <span>{message1}</span>
            </p>
          )}
        </form>
      </div>
    </div>
  );
}
