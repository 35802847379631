import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
  name: 'reports',
  initialState: {
    oldData: 0, // O for New and 1 for History
  },
  reducers: {
    handleUpdateReportData(state, action) {
      state.oldData = action.payload;
    },
  },
});

export const selectReports= (state) => state.reports;

export default reportSlice;
