import React from 'react';

import { FaRegBell } from 'react-icons/fa6';
import { FiLogOut, FiEdit } from 'react-icons/fi';
import Dropdown from '../Dropdown/Dropdown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import './style.css';
import { selectLanguage } from '../../../store/languageSlice';

export const MobileNav = ({
  userName,
  currentImgUrl,
  editProfileHandler,
  logoutHandler,
}) => {
  const { t, isRtl } = useSelector(selectLanguage);

  return (
    <li className="flex items-center">
      <Dropdown extraClass="logout">
        <button style={{minWidth:'initial'}}>
          <BsThreeDotsVertical className="text-xl" />
        </button>
        <div className="dd-menu">
          <button className="avatar">
            <img
              className='rounded-full'
              style={{ height: '36px' }}
              src={currentImgUrl}
              alt="user-img"
            />
            <div className="overflow-hidden">
              <div className="userNameField-header"> {userName} </div>
            </div>
          </button>
          <button onClick={editProfileHandler}>
            <FiEdit /> {t.Edit_Profile}
          </button>
          <button onClick={() => {}}>
            <FaRegBell /> {t.Notifications}
          </button>
          {/* <p style={{color: "#ADADAD", paddingInline: "15px"}}>Account Settings</p> */}
          <button onClick={logoutHandler}>
            <FiLogOut /> {t.SignOut}
          </button>
        </div>
      </Dropdown>
    </li>
  );
};

// export default Navigation;
