import React, { useEffect, useState } from 'react';

const SubQuestionItem = ({ isRtl ,data , selectedIds, onToggle}) => {
  const { questionEn, questionAr, id } = data;
  const [isChecked, setIsChecked] = useState(selectedIds.includes(id));

  const question = isRtl ? questionAr : questionEn;
  const keyId = `${id}${question}`;

  // console.log('yy SubQuestionItem',selectedIds)

  useEffect(() => {
    setIsChecked(selectedIds.includes(id));
  }, [selectedIds, id]);


  return (
    <li key={questionEn} className='ml-7'>
      <input
        type="checkbox"
        id={keyId}
        checked={isChecked}
        onChange={()=>onToggle(id)}
      />
      <label htmlFor={keyId}>
        <span className="check"></span> {question || ''}
      </label>
    </li>
  );
};

export default SubQuestionItem;
