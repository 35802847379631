import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { updatdSelectedDateIndex } from '../../../store/vitalReportSlice';

const DatesSlider = ({selectedMonthDates}) => {
    const dispatch = useDispatch();
    const { t, isRtl } = useSelector(selectLanguage);
    const dateIndex = useSelector(state => state.vitalReport.dashboardSelectdDateIndex)
    const locale = isRtl ? 'ar' : 'en-US';
    // dispatch(bookAppointmentActions.updateSelectedMonthDates(item.allDates));
    // const { selectedMonthDates, selectedDate } = useSelector(
    //     (state) => state.bookAppointment
    // );

    // const [ selectedMonthDates, setselectedMonthDates ] = useState(["2024-09-01","2024-09-02","2024-09-03","2024-09-04","2024-09-15","2024-09-16","2024-09-17","2024-09-18","2024-09-22","2024-09-23","2024-09-24","2024-09-25"])
    // const [ selectedMonthDates, setselectedMonthDates ] = useState(dates)
    const [ selectedDate, setSelectedDate ] = useState(dateIndex)

    useEffect(()=>{
        setSelectedDate(dateIndex)
    },[dateIndex])

  //   useEffect(()=>{
  //     setSelectedDate(dateIndex)
  // },[selectedMonthDates])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        isRtl: false,
        responsive: [
          {
            breakpoint: 1640,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
            },
          },
        //   {
        //     breakpoint: 480,
        //     settings: {
        //       slidesToShow: 4,
        //     },
        //   },
        ],
    };

    return (
    <>
        {selectedMonthDates && selectedMonthDates.length > 0 && (
            <>
            <Slider className="dayDates-slider" {...settings} >
                {selectedMonthDates.slice(1).map((date, index) => (
                // <button key={index} className={date === selectedDate ? "active" : ""} onClick={()=>setSelectedDate(datesList[index])}>{date.toLocaleString('default', { month: 'short' })} <span>{date.getDate()}</span></button>
                <button
                    key={index}
                    className={index === selectedDate ? 'active' : ''}
                    onClick={() => dispatch(updatdSelectedDateIndex(index))}
                >
                    {new Date(date.headerName).getDate()}
                    <span>{new Date(date.headerName).toLocaleString(locale, { month: 'short' })}{' '}</span>
                    <h2>{new Date(date.headerName).getFullYear()}</h2>
                </button>
                ))}
            </Slider>
            </>
        )}
    </>
    );
};

export default DatesSlider;