import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import DaysSlider from './DaysSlider';
import VitalChart from './VitalChart';

const ModalChart = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [graphData, setGraphData] = useState([]);

  const updateGraphData = (date) => {
    // Generate or fetch data dynamically based on the selected date
    const series = [
      {
        name: 'Systolic',
        data: Array.from({ length: 24 }, () => Math.floor(Math.random() * 800)),
      },
    ];
    setGraphData(series);
  };

  useEffect(() => {
    updateGraphData(selectedDate);
  }, [selectedDate]);

  return (
    <div>
      <Dropdown selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <DaysSlider
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <VitalChart
        xAxisTitle="Time (Hours)"
        yaxisTitle="Systolic (mmHg)"
        data={graphData}
      />
    </div>
  );
};

export default ModalChart;
