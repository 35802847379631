import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DoctorCard from './DoctorCard';
// import DoctorCard from './DoctorCard(Wide Card)';

import formatDate from '../../../utilFunctions/DateFormatter';
import Modal from '../../Modal/Modal';
import PatientConsentModal from '../PatientConsentModal';
import Loader from '../../UI/Loader';
import bookAppointmentAPI, {
  resheduleAookAppointmentAPI,
} from '../../../utilFunctions/bookAppointment/bookAppointmentAPI';
import getServicePrice from '../../../utilFunctions/bookAppointment/getServicePrice';
import generatePaymentUrl from '../../../utilFunctions/bookAppointment/generatePaymentUrl';

import { envVariables } from '../../../config';
import {
  modalActions,
  bookAppointmentActions,
  searchbarActions,
} from '../../../store';

import './style.css';
import { selectLanguage } from '../../../store/languageSlice';
import { addBadge, removeBadge } from '../../../store/badgesSlice';
import { sessionsMiddleWare } from '../../../utilFunctions/SessionExpire';
import {
  fetchUpcommingAppointments,
  selectAppointments,
  updateSelectedAppontemnt,
} from '../../../store/appointmentSlice';
import { getTValue } from '../../../translations/common';
import { getSelectLanguage } from '../../../utilFunctions/getCurrentSelectLanguage';

const DoctorList = ({
  doctorsInfoList,
  extraDetails,
  availableDatesHandler,
  availableDatesArray,
  rootElement,
  handleSearchDoctors
}) => {
  const dispatch = useDispatch();
  const { apiRoute } = envVariables;
  const { t, isRtl } = useSelector(selectLanguage);
  // const [fadeInIndex, setFadeInIndex] = useState(0);
  const modalType = useSelector((state) => state.modal.modalType);
  const { selectedAppontemnt } = useSelector(selectAppointments);
  const selectedSpecialityCode = useSelector(
    (state) => state.searchbar.specialityCode
  );
  const selectedType = useSelector((state) => state.searchbar.selectedType);
  const searchText = useSelector((state) => state.searchbar.searchText);
  const selectedDoctorCode = useSelector((state) => state.searchbar.docCode);
  // book appointment vars
  const selectedSlotTime = useSelector(
    (state) => state.bookAppointment.slotTime
  );
  const docCode = useSelector((state) => state.bookAppointment.docCode);
  const serviceCode = useSelector((state) => state.bookAppointment.serviceCode);
  const specialityCode = useSelector(
    (state) => state.bookAppointment.specialityCode
  );
  const selectedDate = useSelector(
    (state) => state.bookAppointment.selectedDate
  );
  const hospitalName = useSelector((state) => state.hospitalInfo.hospName);
  const hospId = useSelector((state) => state.hospitalInfo.hospId);
  const appointmentType = useSelector(
    (state) => state.bookAppointment.appointmentType
  );
  const docName = useSelector((state) => state.bookAppointment.doctorName);
  const docNameAr = useSelector((state) => state.bookAppointment.doctorNameAr);
  const selectedDocCard = useSelector((state) => state.bookAppointment.selectedDocCard);
  const selectedSlotId = useSelector((state) => state.bookAppointment.slotId);
  const clinicName = useSelector((state) => state.bookAppointment.clinicName);
  // book appointment vars
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  const hospitalList = useSelector((state) => state.hospitalInfo.list);
  const confirmation = useSelector(
    (state) => state.bookAppointment.confirmBooking
  );

  const [doctorsList, setDoctorsList] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  const [firstCheckbox, setFirstCheckbox] = useState(false);
  const [secondCheckbox, setSecondCheckbox] = useState(false);
  const [userAgree, setUserAgree] = useState(false);
  const [servicePriceInfo, setServicePriceInfo] = useState(null);
  const [canProceedToPayment, setCanProceedToPayment] = useState(true);
  const [loadingPriceINfo, setLoadingPriceINfo] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [alreadyBookingMessage, setAlreadyBookingMessage] = useState(null);

  useEffect(() => {
    setDoctorsList(doctorsInfoList);
  }, [doctorsInfoList]);

  const getDocEarliestSlotAndServices = async (specialityCode, docCode) => {
    const docServicesList = async (specialityCode, docCode) => {

      const endpoint = `${apiRoute}api/bookings/get-doctor-services?`;
      const url = new URL(endpoint);

      const params = {
        providerCode: docCode,
        specialityCode: specialityCode,
      };

      // url.searchParams.append('doctorCode', docCode);
      for (const key in params) {
        url.searchParams.append(key, params[key]);
      }

      try {
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
              }`,
            "Accept-Language": getSelectLanguage(),
          },
        });
        if (!response.ok || response.status !== 200) {
          if (response.status === 403) {
            sessionsMiddleWare();
          }
          throw new Error(
            `HTTP error! status get-doctor-services: ${response.status}`
          );
        }
        // return await response.json();
        const data = await response.json();
        return data.serviceList;
      } catch (error) {
        console.error('Error fetching data get-doctor-services:', error);
        return null;
      }
    };

    const earliestSlotReq = async (specialityCode) => {
      const endpoint = `${apiRoute}appointment/docEarliestSlot?`;
      const url = new URL(endpoint);
      const today = new Date();
      const futureDate = new Date();
      futureDate.setDate(today.getDate() + 100);
      // Format the dates as 'YYYY-MM-DD'
      const todayFormatted = formatDate(today);
      const futureDateFormatted = formatDate(futureDate);

      const params = {
        specialtycode: specialityCode,
        datefrom: todayFormatted,
        dateto: futureDateFormatted,
      };

      // url.searchParams.append('doctorCode', docCode);
      for (const key in params) {
        url.searchParams.append(key, params[key]);
      }

      try {
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
              }`,
            "Accept-Language": getSelectLanguage(),


          },
        });
        if (!response.ok) {
          if(response.status === 403) {
            sessionsMiddleWare();
        }
          throw new Error(
            `HTTP error! status earliestSlotReq: ${response.status}`
          );
        }
        // return await response.json();
        const data = await response.json();
        return data.docList;
      } catch (error) {
        console.error('Error fetching data earliestSlotReq:', error);
        return null;
      }
    };

    // return await earliestSlotReq(specialityCode);

    // return await docServicesList(specialityCode, docCode);

    const [docServicesListValue, earliestSlotReqValue] = await Promise.all([
      docServicesList(specialityCode, docCode),
      earliestSlotReq(specialityCode),
    ]);

    return {
      docServicesListValue,
      earliestSlotReqValue,
    };
  };

  const getDoctorAvailablity = async (
    providercode,
    servicecode,
    specialitycode
  ) => {
    const endpoint = `${apiRoute}api/bookings/get-doctor-availability?`;
    const url = new URL(endpoint);

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 100);
    // Format the dates as 'YYYY-MM-DD'
    const todayFormatted = formatDate(today);
    const futureDateFormatted = formatDate(futureDate);

    const params = {
      providercode: providercode,
      servicecode: servicecode,
      specialitycode: specialitycode,
      datefrom: todayFormatted,
      dateto: futureDateFormatted,
    };

    // url.searchParams.append('doctorCode', docCode);
    for (const key in params) {
      url.searchParams.append(key, params[key]);
    }

    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
            }`,
          "Accept-Language": getSelectLanguage(),

        },
      });
      if (!response.ok || response.status === 403) {
        if (response.status === 403) {
          sessionsMiddleWare();
        }
        throw new Error(
          `HTTP error! status get-doctor-availability: ${response.status}`
        );
      }
      // return await response.json();
      const data = await response.json();
      return availableDatesHandler(data.slotList);
    } catch (error) {
      console.error('Error fetching data get-doctor-availability:', error);
      return null;
    }
  };

  const docCardClickHandler = (doc) => {
    setSelectedCard(doc.docCode);
    dispatch(searchbarActions.selectedSpecialityCode(doc.specialityCode));

    const newBadge = {
      textEng: doc?.specialityDescription,
      textAr: doc?.specialityDescriptionAR,
      code: doc?.specialityCode,
      docCode: doc?.docCode,
      type: selectedType,
    };

    dispatch(addBadge(newBadge));
  };

  const searchDoctorBySpecialityCode = (code, docCode) => {
    const allDoctorsList = JSON.parse(localStorage.doctorsInfomation);
    let filteredDoctors = [];
    if (docCode) {
      filteredDoctors = allDoctorsList.filter((doctor) => {
        return doctor.specialityCode === code && doctor.docCode === docCode;
      });
    } else {
      filteredDoctors = allDoctorsList.filter((doctor) => {
        return doctor.specialityCode === code;
      });
    }
    setDoctorsList(filteredDoctors);
    
  };

  useEffect(() => {
    if (selectedSpecialityCode) {
      searchDoctorBySpecialityCode(selectedSpecialityCode, selectedDoctorCode);
    } else {
      setDoctorsList(doctorsInfoList);
    }
  }, [selectedSpecialityCode]);

  const searchByText=(list,text)=>{

    let searchTerms = text.toLowerCase().split(' ').filter(term => term);

    const matchesSearchTerms = (doctor) => {
      return searchTerms.some(term => 
        isRtl 
          ? doctor.docNameAR.toLowerCase().includes(term) 
          : doctor.docName.toLowerCase().includes(term)
      );
    };
    if (list && text.length>0) {
      return list.filter((doc)=> matchesSearchTerms(doc))
    }

    return list;
  }

  const appointmentConfirmationHandler = async () => {

    const bookAppointmentRes = await bookAppointmentAPI(
      docCode,
      serviceCode,
      specialityCode,
      selectedSlotTime,
      selectedDate,
      hospCode,
      selectedSlotId,
      appointmentType,
      docName,
      docNameAr,
      hospId
    );

    if (bookAppointmentRes) {
      if (bookAppointmentRes.englishMessage) {
        setAlreadyBookingMessage(isRtl ? bookAppointmentRes.arabicMessage : bookAppointmentRes.englishMessage)
        // alert(bookAppointmentRes.englishMessage);
      } else {
        dispatch(modalActions.toggle(''));
        setTimeout(() => {
          dispatch(modalActions.toggle('ServicePriceModal'));
        }, 200);
        setLoadingPriceINfo(true);
        const servicesPrice = await getServicePrice(selectedSlotId, hospId);
        setServicePriceInfo(servicesPrice);
        setLoadingPriceINfo(false);
        // const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || appointmentType == "Offline" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
        // const allowedToProceed = false;
        
        if (appointmentType === 'Online') {
          const allowedToProceed = servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
          setCanProceedToPayment(allowedToProceed);
          if (allowedToProceed) {
          const paymentUrlRes = await generatePaymentUrl(
            selectedSlotId,
            servicesPrice,
            selectedDate,
            docName,
            hospId
          );
           setPaymentUrl(paymentUrlRes?.data.url);
          }
        }
      }
    }
  };

  const closeSelectedDocCard = ()=>{
    dispatch(removeBadge());
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(bookAppointmentActions.selectedDocCode(null))
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.updatePrimaryOrLastVisited(null));
    handleSearchDoctors('', {code:null,docCode:null})
  }
  
  const appointmentResheduleConfirmationHandler = async () => {
    const oldSlotBookingId = selectedAppontemnt.slotBookingId;
    const bookAppointmentRes = await resheduleAookAppointmentAPI(
      oldSlotBookingId,
      selectedSlotId
    );

    if (bookAppointmentRes) {
      if (bookAppointmentRes.englishMessage) {
        alert(bookAppointmentRes.englishMessage);
      } else {
        dispatch(modalActions.toggle(''));
        setTimeout(() => {
          dispatch(modalActions.toggle('ServicePriceModal'));
        }, 200);
        setLoadingPriceINfo(true);
        const servicesPrice = await getServicePrice(selectedSlotId, hospId);
        setServicePriceInfo(servicesPrice);
        setLoadingPriceINfo(false);
        // const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || appointmentType == "Offline" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
        // const allowedToProceed = false;

        if (appointmentType === 'Online') {
          const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
          setCanProceedToPayment(allowedToProceed);

          if (allowedToProceed) {
          const paymentUrlRes = await generatePaymentUrl(
            selectedSlotId,
            servicesPrice,
            selectedDate,
            docName,
            hospId
          );
          setPaymentUrl(paymentUrlRes?.data.url);
        }
      }
      }
    }
  };

  const cancelBtnClickHandler = () => {
    dispatch(bookAppointmentActions.clickConfirmButton(false));
    dispatch(fetchUpcommingAppointments())

    dispatch(modalActions.closeModal());
   
    // setTimeout(()=>{ closeSelectedDocCard()},2000);
    setAlreadyBookingMessage(null);
    
    if (selectedAppontemnt) {
      dispatch(updateSelectedAppontemnt(null));
      dispatch(searchbarActions.selectedSpecialityCode(null));
      dispatch(searchbarActions.selectedDoctorCode(null));
    }
  };

  const confirmBtnClickHandler = () => {
    dispatch(bookAppointmentActions.clickConfirmButton(true));
  };

  const secondCheckboxChangeHandler = () => {
    setSecondCheckbox(!secondCheckbox);
    setUserAgree(false);
  };

  const agreeBtnClickHandler = () => {
    setSecondCheckbox(true);
    setUserAgree(true);
  };

  const TeleProceedBtnClickHandler = () => {
    dispatch(modalActions.toggle(""))
    setTimeout(() => {
      dispatch(modalActions.toggle('ConfirmBookingModal'));
    }, 200);
  };

  const payNowClickHandler = () => {
    dispatch(modalActions.toggle(''));
    window.location.href = paymentUrl
    // alert('Online Payments Disabled.');
  };

  useEffect(() => {
    // book new one
    if (confirmation && !selectedAppontemnt) {
      appointmentConfirmationHandler();
    }

    //reshedules
    if (confirmation && selectedAppontemnt) {
      appointmentResheduleConfirmationHandler();
    }
  }, [confirmation]);

  const extraDummyData = Array.from({ length: 8 }, (_, i) => `Item ${i + 1}`);

  let finalFiltered = searchByText(doctorsList,searchText);
  const selectedHospInfo = hospitalList?.find((x) => x.hospitalCode == hospCode);
  return (
    <>
      {/* <div className="ba-docLst"> */}
      {finalFiltered &&
        finalFiltered.length > 0 &&
        finalFiltered.map((doctorInfo, index) => (
          <Fragment key={index}>
            <DoctorCard
              doctorInfo={doctorInfo}
              docExtraDetails={extraDetails}
              cardIndex={index}
              getDocEarliestSlotAndServices={getDocEarliestSlotAndServices}
              getDoctorAvailablity={getDoctorAvailablity}
              selectedCard={selectedCard}
              docCardClickHandler={docCardClickHandler}
              rootElement={rootElement}
            />
          </Fragment>
        ))}
      {/* handleScroll to top for  */}
      {
        doctorsList &&
        doctorsList.length > 0 &&
        extraDummyData.map((index) => (
          <Fragment key={index}>
            <div className={`card mb-5 fade-in `} />
          </Fragment>
        ))
      }
      {/* {
                    doctorsInfoList.slice(0, 2).map((doctorInfo, index) => (
                        <Fragment key={index}>
                            {(index < fadeInIndex) && <DoctorCard key={index} doctorInfo={doctorInfo} index={index} getDocEarliestSlotAndServices={getDocEarliestSlotAndServices} getDoctorAvailablity={getDoctorAvailablity} selectedCard={selectedCard} docCardClickHandler={docCardClickHandler} selectedDate={selectedDate} />}
                        </Fragment>
                    ))
                } */}
      {/* {
                    docsDummyList.map((doctorInfo, index) => (
                        <Fragment key={index}>
                            <DoctorCard doctorInfo={doctorInfo} index={index} getDocEarliestSlotAndServices={getDocEarliestSlotAndServices} getDoctorAvailablity={getDoctorAvailablity} selectedCard={selectedCard} docCardClickHandler={docCardClickHandler} />
                        </Fragment>
                    ))
                } */}
      {/* </div> */}
      {/* let message = `CONFIRM BOOKING \nClinic: Clinic\nPhysician: ${docName}\nDate: ${selectedDate}\nTime: ${selectedSlotTime}\nConsultation Type: ${appointmentType}` */}
      {modalType === 'ConfirmBookingModal' && (
        <Modal title={t.ConfirmBooking}>
          {!alreadyBookingMessage ? <ul className="appntInfo">
            <li>
              {t['Hospital']}: {isRtl ? selectedHospInfo?.hospitalName_ar : hospitalName} 
            </li>
            <li>
              {t['Clinic']}: {getTValue(selectedDocCard, 'specialityDescriptionAR', 'specialityDescription', isRtl)}
            </li>
            <li>
              {t['Physician']}: {isRtl ? docNameAr : docName}
            </li>
            <li>
              {t['Date']}: {selectedDate}
            </li>
            <li>
              {t['Time']}: {selectedSlotTime}
            </li>
            <li>
              {t['ConsultationType']}:{' '}
              {appointmentType === 'Offline' ? t.InPerson : t.Telemedicine}
            </li>
          </ul>: <div style={{lineHeight: '23px'}}>{alreadyBookingMessage}</div>}
          <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
            <button
              className="bg-tertiary text-white btn"
              onClick={cancelBtnClickHandler}
            >
              {t.Cancel}
            </button>
           {!alreadyBookingMessage && <button
              className="bg-secondary text-white btn"
              onClick={confirmBtnClickHandler}
            >
              {selectedAppontemnt ? t.Reschedule : t.ConfirmBooking}
            </button>}
          </div>
        </Modal>
      )}
      {modalType === 'OnlineConfirmBookingModal' && (
        <Modal
          title={t.TeleMedicineConsent}
          width="md"
          mainClass="consentModal"
        >
          <p className="text-secondary font-semibold">{t.optionsTitle}</p>
          <ul className="list-disc my-4 px-4">
            <li>{t.TraumaticBrainOrSpinalCordInjury}</li>
            <li>{t.ChestPainAndOrNumbness}</li>
            <li>{t.VomitingOrCoughingBlood}</li>
            <li>{t.Lacerations}</li>
            <li>{t.LossOfConsciousness}</li>
            <li>{t.BrokenBones}</li>
            <li>{t.SevereBurns}</li>
            <li>{t.AcuteVisionLoss}</li>
            <li>{t.ChestPain}</li>
            <li>{t.AlteredMentalStatus}</li>
            <li>{t.BloodInStool}</li>
            <li>{t.HomicidalOrSuicidalThoughts}</li>
            <li>{t.DrugOverdose}</li>
          </ul>
          <p className="font-semibold mb-4">{t.NoteMsg00}</p>
          <ul>
            <li>
              <label htmlFor="firstCheckbox">
                <input
                  type="checkbox"
                  name="firstCheckbox"
                  id="firstCheckbox"
                  checked={firstCheckbox}
                  onChange={() => setFirstCheckbox(!firstCheckbox)}
                />{' '}
                {t.NoteMsg11}
              </label>
            </li>
            <li>
              <label htmlFor="secondCheckbox">
                <input
                  type="checkbox"
                  name="secondCheckbox"
                  id="secondCheckbox"
                  checked={secondCheckbox}
                  onChange={secondCheckboxChangeHandler}
                />{' '}
                {t.NoteMsg12}
              </label>
            </li>
          </ul>
          <p className="text-16 font-semibold my-4 text-red-700">{t.NoteMsg2}</p>
          <div className="btns flex flex-wrap justify-end gap-x-3">
            <button
              className="bg-tertiary text-white btn"
              onClick={cancelBtnClickHandler}
            >
              {t.Cancel}
            </button>
            <button
              className={
                firstCheckbox && userAgree
                  ? 'bg-secondary text-white btn'
                  : 'bg-secondary text-white btn disabled'
              }
              onClick={TeleProceedBtnClickHandler}
            >
              {t.ProceedtoBooking}
            </button>
          </div>
        </Modal>
      )}
      {firstCheckbox && secondCheckbox && !userAgree && (
        <PatientConsentModal title="Terms Modal" width="lg">
          <iframe
            src={`https://www.imc.med.sa/TeleHealth/consent.html?lang=${isRtl ? 'ar' : 'en'}`}
            frameBorder="0"
            style={{ width: '100%', height: '600px' }}
          ></iframe>
          <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
            <button
              className="bg-tertiary text-white btn"
              onClick={() => setSecondCheckbox(!secondCheckbox)}
            >
              {t.Idisagree}
            </button>
            <button
              className="bg-secondary text-white btn"
              onClick={agreeBtnClickHandler}
            >
              {t.Iagree}
            </button>
          </div>
        </PatientConsentModal>
      )}
      {modalType === 'ServicePriceModal' && (
        <Modal title={servicePriceInfo && servicePriceInfo?.orderPrice ? t.Confirmed : t.Error} width="md" mainClass="priceModal" onClose={cancelBtnClickHandler}>
         {servicePriceInfo?.orderPrice && <div className="alert success bg-secondary">
            <p className="text-16 font-medium">{t.ConfirmedMessage}</p>
          </div>}
          {servicePriceInfo && servicePriceInfo?.orderPrice ? (
            <ul className="appntInfo mt-3">
              <li>
                {t['Hospital']}: {isRtl ? selectedHospInfo?.hospitalName_ar : hospitalName}
              </li>
              <li>
                {t['Clinic']}: {getTValue(selectedDocCard, 'specialityDescriptionAR', 'specialityDescription', isRtl)}
              </li>
              <li>
                {t['Physician']}: {isRtl ? docNameAr : docName}
              </li>
              <li>
                {t['Date']}: {selectedDate}
              </li>
              <li>
                {t['Time']}: {selectedSlotTime}
              </li>
              <li>
                {t['ConsultationType']}:
                {appointmentType === 'Offline' ? t.InPerson : t.Telemedicine}
              </li>
              <li>
                {t['PatientPay']}: {servicePriceInfo?.patientShare}
              </li>
              <li>
                {t['TotalAmount']}: {servicePriceInfo?.orderPrice}
              </li>
              {!canProceedToPayment && <p className="errorMsg my-8">
                    <span>{t.InsuranceMessage}</span>
                  </p>}
            </ul>
          ) : loadingPriceINfo ? (
            <div className="my-4">
              <Loader roundLoader />
            </div>
          ) : (
            <div className="my-4">{t.CouldNotloaddata}</div>
          )}
          {(canProceedToPayment && paymentUrl) ? (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              {/* <button
                className="bg-tertiary text-white btn"
                onClick={cancelBtnClickHandler}
              >
                {t.PayLater}
              </button> */}
              <button
                className="bg-secondary text-white btn"
                onClick={payNowClickHandler}
              >
                {t.PayNow}
              </button>
            </div>
          ) : (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              {/* <button
                className="bg-tertiary text-white btn"
                onClick={cancelBtnClickHandler}
              >
                {t.Close}
              </button> */}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default DoctorList;
