import React, { useState } from 'react';
import '../components/Register/register.css';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { onlyLetters } from '../utilFunctions/validations/number';
import { envVariables } from '../config';
import LanguageSwitch from '../components/LanguageSwitch';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../store/languageSlice';
import CountriesDropdown from '../components/Register/CountriesDropdown';
import { registerUser, validateCurrentData } from '../utilFunctions/register';
import Loader from '../components/UI/Loader';
import { getSelectLanguage } from '../utilFunctions/getCurrentSelectLanguage';

export const countriesListLoader = async () => {
  try {
    const { apiRoute } = envVariables;
    const endpoint = `${apiRoute}api/yakeen-nationality`;

    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0NDI2MDMiLCJleHAiOjE3MTYyMzI4OTIsImlhdCI6MTcxNjIwNDA5Mn0.-bBvkY8bw0sFbOP_pn8saHxvJ4DR79YyKSV_H6yvORBXEB0QRhwvF9o-8cbFk_R2Dh7g7WBEHEd7IJ5sjTEfgw',
        Hospitalid: '1',
        Mrn: '',
        Patient_id: '',
        Platform: 'Web',
        "Accept-Language": getSelectLanguage(),
      },
    });
    if (!response.ok && response.status !== 200) {
      throw new Error(`HTTP error! status medication: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Errro on Medication', error);
    return null;
  }
};

const Register = () => {
  const state = useSelector((state) => state)
  const { apiRoute } = envVariables;
  const countriesData = useLoaderData();
  const { t, isRtl } = useSelector(selectLanguage);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(false);
  const [apiRes, setApiRes] = useState({
    loading: false,
    error: false,
    message: '',
    firstStepData: null,
  });
  const [regsitrationForm, setRegistrationForm] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    mobileNumber: '',
    idNumber: '',
    idType: '',
    IdExpiryDate: '',
    gender: '',
    dob: '',
    nationality: '',
  });

  // handle Change Fields
  const handleChangeStep1 = (e) => {
    const { name, value } = e.target;

    if (value.trim() === '') {
      // If the input value is empty, clear the corresponding field in the state
      setRegistrationForm({
        ...regsitrationForm,
        [name]: '',
      });
    } else if (name === 'firstName') {
      let valueCheck = onlyLetters(value);
      setRegistrationForm({
        ...regsitrationForm,
        firstName: valueCheck.trimStart(),
      });
    } else if (name === 'middleName') {
      let valueCheck = onlyLetters(value);
      setRegistrationForm({
        ...regsitrationForm,
        middleName: valueCheck.trimStart(),
      });
    } else if (name === 'lastName') {
      let valueCheck = onlyLetters(value);
      setRegistrationForm({
        ...regsitrationForm,
        lastName: valueCheck.trimStart(),
      });
    } else if (name === 'mobileNumber') {
      setRegistrationForm({
        ...regsitrationForm,
        mobileNumber: value,
      });
    } else if (name === 'idNumber' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        idNumber: value.trimStart(),
      });
    } else if (name === 'typeID' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        idType: value,
      });
    } else if (name === 'nationality' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        nationality: value,
      });
    } else if (name === 'gender' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        gender: value,
      });
    } else if (name === 'DOB' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        dob: value,
      });
    } else if (name === 'expDate' && value !== '') {
      setRegistrationForm({
        ...regsitrationForm,
        IdExpiryDate: value,
      });
    }
  };

  // handle Click go to Current Step = 1
  const handleNext = async () => {
    if (
      regsitrationForm.idType !== '' &&
      regsitrationForm.idNumber !== '' &&
      regsitrationForm.nationality !== '' &&
      regsitrationForm.dob !== ''
    ) {
      setError(false);

      setApiRes({
        loading: true,
        error: false,
        message: '',
      });

      const res = await validateCurrentData({
        docType: regsitrationForm.idType,
        docNumber: regsitrationForm.idNumber,
        yakeenNationality: regsitrationForm.nationality,
        dob: regsitrationForm.dob,
      });

      if (!res || res?.status == 'false') {
        setApiRes({
          loading: false,
          error: true,
          message: res?.message || 'Could not validate info',
        });

        return;
      }
      setApiRes({
        ...apiRes,
        loading: false,
        firstStepData: res.yakeenData,
      });

      const yakeenData = res.yakeenData;
      const firstName = isRtl ? yakeenData.firstName : yakeenData.firstNameT;
      const lastName = isRtl ? yakeenData.familyName : yakeenData.familyNameT;
      const middleName = isRtl ? yakeenData.fatherName : yakeenData.fatherNameT;
      const gender = isRtl ? yakeenData.genderAr : yakeenData.genderEn;

      setRegistrationForm({
        ...regsitrationForm,
        firstName,
        middleName,
        lastName,
        gender,
      });

      setCurrentStep(2);
    } else {
      setError(true);
    }
  };

  // handle Click go to Current Step = 1
  const handlegoBack = () => {
    setCurrentStep(1);
  };


  const handleClickSignUp = async () => {
    if (
      regsitrationForm.IdExpiryDate == '' ||
      regsitrationForm.mobileNumber == ''
    ) {
      setError(true);

      return
    }

    const selectedCountry = countriesData.find((x) => x.countryCode == regsitrationForm.nationality)

    const signUpPayload = {
      fatherName: apiRes.firstStepData.fatherNameT,
      fatherNameAr: apiRes.firstStepData.fatherName,
      docType: regsitrationForm.idType,
      dob: regsitrationForm.dob,
      docExpiryDate: regsitrationForm.date,
      firstNameAr: apiRes.firstStepData.firstName,
      telMobile: regsitrationForm.mobileNumber,
      firstName: apiRes.firstStepData.firstNameT,
      platform: 'Web',
      gender: apiRes.firstStepData.genderEn,
      familyName: apiRes.firstStepData.familyNameT,
      familyNameAr: apiRes.firstStepData.familyName,
      docNumber: regsitrationForm.idNumber,
      nationality: selectedCountry?.countryName,
      yakeenNationality: regsitrationForm.nationality,
    };

    setApiRes({
      ...apiRes,
      loading: true,
    });

    const data = await registerUser(signUpPayload);

    if (!data || data.status == 'false') {
      setApiRes({
        ...apiRes,
        loading: false,
        error: true,
        message: data.message || 'Could Not register',
      });

      return;
    }

    setApiRes({
      ...apiRes,
      loading: false,
      SecondStepData: data,
    });

    localStorage.setItem('registrationResponse', JSON.stringify(data));

    navigate('/otp');
  };

  return (
    <>
      <div className="relative">
        <div className="absolute right-32 top-20 z-10">
          <LanguageSwitch />
        </div>
      </div>
      <div className="register_container">
        <div className="register_box">
          {/* <div className="Bar_Indicator">
            <span
              className={currentStep === 1 ? 'Bar_active' : 'Bar_InActive'}
            ></span>
            <span
              className={currentStep === 2 ? 'Bar_active' : 'Bar_InActive'}
            ></span>
          </div> */}
          {currentStep === 2 && (
            <p className="gobackBtn" onClick={handlegoBack}>
              <img src={`${process.env.PUBLIC_URL}/images/Back.svg`} alt="" />
              Back
            </p>
          )}

          <section className="RegisterForm_Bpx">
            <div className="imc_logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/bigLogo.png`}
                alt="ImcLogo"
              />
            </div>
            <h3 className="new_registration_heading">{t.NewRegistration}</h3>
            {currentStep === 1 ? (
              <>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">{t.IDType}</label>
                  <span className="inputBox_register">
                    <select
                      // type="text"
                      name="typeID"
                      placeholder={'DD-MMM-YYYY'}
                      className="register_form_Div_input customSelect"
                      onChange={handleChangeStep1}
                      value={regsitrationForm.idType}
                    >
                      <option selected value={''} disabled>
                        {t.Select}
                      </option>
                      <option value="Others">{t.IqamaNo}</option>
                      <option value="National ID">{t.NationalID}</option>
                      <option value="Passport">{t.Passport}</option>
                      <option value="Birth Notification">
                        {t.BirthNotification}
                      </option>
                    </select>
                    {regsitrationForm.idType === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.IDTypeRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.IDNumber}*
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="number"
                      max={1}
                      min={1}
                      name="idNumber"
                      placeholder={t.EnterNumberHere}
                      className="register_form_Div_input noArrow"
                      onChange={handleChangeStep1}
                      value={regsitrationForm.idNumber}
                    />
                    {regsitrationForm.idNumber === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.IDNumberRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.Nationality}*
                  </label>
                  {/* <CountriesDropdown
                    isRtl={isRtl}
                    data={countriesData}
                    onSelect={(item) =>
                      handleChangeStep1({
                        target: { name: 'nationality', value: item.value },
                      })
                    }
                  /> */}
                  <span className="inputBox_register">
                    <select
                      // type="text"
                      name="nationality"
                      placeholder={'DD-MMM-YYYY'}
                      className="register_form_Div_select customSelect"
                      onChange={handleChangeStep1}
                      defaultValue={regsitrationForm?.nationality}
                    >
                      <option selected value={''} disabled>
                        {t.Select}
                      </option>
                      {countriesData?.map((x) => {
                        const label = isRtl ? x.countryNameAr : x.countryName;
                        const value = x.countryCode;

                        return <option value={value}>{label}</option>;
                      })}
                    </select>
                    {regsitrationForm.nationality === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.NationalityIsRequired}
                      </span>
                    ) : null}
                  </span>
                </div>

                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.DateofBirth}
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="date"
                      placeholder={t.SelectDateBirth}
                      name="DOB"
                      value={regsitrationForm.dob}
                      onChange={handleChangeStep1}
                      className="register_form_Div_input customDateInput"
                    />
                    {regsitrationForm.dob === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.DateBirthRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.FirstName}*
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="text"
                      placeholder={t.EnterYourFirstName}
                      className="register_form_Div_input"
                      name="firstName"
                      disabled
                      onChange={handleChangeStep1}
                      value={regsitrationForm.firstName}
                    />
                    {regsitrationForm.firstName === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.FirstNameisRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.FatherMiddleName}
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="text"
                      name="middleName"
                      disabled
                      placeholder={t.EnterYourFatherMiddleName}
                      className="register_form_Div_input"
                      onChange={handleChangeStep1}
                      value={regsitrationForm.middleName}
                    />
                    {regsitrationForm.middleName === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.MiddleNameRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.FamilyLastName}
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="text"
                      name="lastName"
                      disabled
                      placeholder={t.EnteryourFamilyLastName}
                      className="register_form_Div_input"
                      onChange={handleChangeStep1}
                      value={regsitrationForm.lastName}
                    />
                    {regsitrationForm.lastName === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.LastNameisRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">Gender</label>
                  <span className="inputBox_register">
                    <select
                      // type="text"
                      name="gender"
                      disabled
                      placeholder={t.SelectGender}
                      className="register_form_Div_input customSelect"
                      onChange={handleChangeStep1}
                      value={regsitrationForm.gender}
                    >
                      <option selected disabled value={''}>
                        {t.SelectGender}
                      </option>
                      <option value="male">{t.Male}</option>
                      <option value="female">{t.Female}</option>
                    </select>
                    {regsitrationForm.gender === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.GenderRequired}
                      </span>
                    ) : null}
                  </span>
                </div>
                <div className="register_form_Div">
                  <label className="register_form_Div_label">
                    {t.IDExpiryDate}*
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="date"
                      placeholder={t.SelectExpirySDate}
                      name="expDate"
                      onChange={handleChangeStep1}
                      className="register_form_Div_input customDateInput"
                      value={regsitrationForm.IdExpiryDate}
                    />
                    {regsitrationForm.IdExpiryDate === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.ExpiryDateRequired}
                      </span>
                    ) : null}
                  </span>
                </div>

                <div className="register_form_Div phone_number">
                  <label className="register_form_Div_label">
                    {t.CellPhoneNumber}*
                  </label>
                  <span className="inputBox_register">
                    <input
                      type="text"
                      name="mobileNumber"
                      placeholder={`**********`}
                      value={regsitrationForm.mobileNumber}
                      className={`${'register_form_Div_input'} ${'phone_number_input'}`}
                      onChange={handleChangeStep1}
                    />
                    {regsitrationForm.mobileNumber === '' && error ? (
                      <span className="errorMessage_inputRegister">
                        {t.MobileNumberRequired}
                      </span>
                    ) : null}
                    <span className="mobile_code">+966</span>
                  </span>
                </div>
              </>
            )}
            {apiRes.error && (
              <h5
                className="text-blue-700 my-4"
                style={{
                  color: apiRes.error ? 'var(--color-tertiary)' : 'green',
                }}
              >
                {apiRes.message}
              </h5>
            )}
            <div className="RegisterDiv">
              {currentStep === 1 ? (
                <button className="Register_btn" onClick={handleNext}>
                  {apiRes.loading ? <Loader roundLoader /> : t.Next}
                </button>
              ) : (
                <button className="Register_btn" onClick={handleClickSignUp}>
                  {apiRes.loading ? <Loader roundLoader /> : t.SignUp}
                </button>
              )}
            </div>
            <p className="registerLink">
              {t.Alreadyaccount} <Link to="/login">{t.SignIn}</Link>
            </p>
          </section>
          <img
            src={`${process.env.PUBLIC_URL}/images/menu-banner-bottom.svg`}
            className="register_banner_bottm"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Register;
