import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';

const LanguageSwitch = () => {
  const { language, isRtl } = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    dispatch(languageActions.changeLanguage(newLanguage))
  };

  const arabicStyle = {
    "display": "block",
    "marginTop": "-4px",
    "fontFamily": "TheSans, sans-serif"
  }
  return (
    <div dir='ltr'>
      {/* <label className="themeSwitcherThree relative inline-flex cursor-pointer select-none items-center">
        <input
          type="checkbox"
          checked={isRtl}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <div className="shadow-card flex h-[29px] w-[72px] items-center justify-center rounded-xl bg-gray-300">
          <span
            className={`flex h-9 w-9 items-center justify-center rounded-full ${
              !isRtl ? 'bg-primary text-white' : 'text-body-color'
            }`}
          >
            EN
          </span>
          <span
            className={`flex h-9 w-9 items-center justify-center rounded-full ${
              isRtl ? 'bg-primary text-white' : 'text-body-color'
            }`}
            style={arabicStyle}
          >
            ع
          </span>
        </div>
      </label> */}


      <label className={isRtl ? "checked customToggle" : "customToggle"} htmlFor="themeTgle">
        <input
          id='themeTgle'
          type="checkbox"
          checked={isRtl ? true : false}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <span className="text">{isRtl ? "EN" : <span style={arabicStyle}>ع</span> }</span>
        <span className="circle"></span>
      </label>
    </div>
  );
};

export default LanguageSwitch;
