import React, { useEffect, useState } from 'react';
import MyDependents from './MyDependents';
import MyGuardians from './MyGuardians';
import useFetchData from '../../../utilFunctions/useFetchData';
import {
  gaurdianDetailReq,
  dependentsReq,
  addGaurdianDetailReq,
  updateGaurdianDetailReq,
  deleteGaurdianDetailReq,
  getGuardianRelationsList
} from '../../../utilFunctions/profile/MyFamilyProfile';
import { useDispatch,useSelector } from 'react-redux';
import { modalActions, portalActions } from '../../../store';
import Loader from '../../UI/Loader';
import { selectLanguage } from '../../../store/languageSlice';
import { selectModal } from '../../../store/globalModalSlice';

export default function FamilyProfile() {
  const dispatch = useDispatch();
  const { t } = useSelector(selectLanguage);
  const { data: gaurdianDetails, refetch: refetchGuardianDetails } =
    useFetchData(gaurdianDetailReq);
    const { data: patientsRelations } = useFetchData(getGuardianRelationsList);
  const { data: dependentsData, loading, error } = useFetchData(dependentsReq);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { switchPatient } = useSelector(selectModal);
  const handleOnAddGuardian = async (guardianData) => {
    const requestBody = {
      patId: userInfo?.mrn || '22',
      hosp: '1',
      guardianId: guardianData?.mrNumber,
      noOfDays: guardianData?.noOfDays,
      consent: gaurdianDetails?.consent || 1,
      isActive: gaurdianDetails?.isActive || 1,
      guardianRelation: guardianData?.guardianRelation
    };

    if (guardianData.type == 'add') {
      await addGaurdianDetailReq(requestBody);
    }

    if (guardianData.type == 'edit') {
      await updateGaurdianDetailReq(requestBody);
    }
    dispatch(portalActions.closeDrawer());
  };

  const handleOnDependentClick = (selectedDependant) => {
    // can not switch again if already dependent switched
    if(!switchPatient) {
      dispatch(portalActions.updatedSelectedDependent(selectedDependant));
    }
    dispatch(modalActions.toggle('dependentModal'));
  };

  const handleOnDeleteGuardian = async (data) => {
    await deleteGaurdianDetailReq(gaurdianDetails?.guardianId);
    dispatch(portalActions.closeDrawer());
  };

  if (loading) return <Loader />;

  if (error) return <div className="p-8">{t.Something_Wrong}</div>;

  return (
    <div>
      <div className="px-4">
        <MyGuardians
          data={gaurdianDetails}
          onAddGuadrian={handleOnAddGuardian}
          onDeletGuadrian={handleOnDeleteGuardian}
          patientsRelations={Array.isArray(patientsRelations) ? patientsRelations : []}
        />
        <MyDependents
          data={dependentsData}
          onClickDependent={handleOnDependentClick}
        />
      </div>
    </div>
  );
}
