import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import { bookAppointmentActions } from '../../../store';

const DatesSlider = ({isRtl}) => {
  const dispatch = useDispatch();
  const locale = isRtl ? 'ar' : 'en-US';
  // dispatch(bookAppointmentActions.updateSelectedMonthDates(item.allDates));
  const { selectedMonthDates, selectedDate } = useSelector(
    (state) => state.bookAppointment
  );

  const dateClickHandler = (e, date) => {
    e.stopPropagation();
    dispatch(bookAppointmentActions.dateSelector(date));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: selectedMonthDates?.length < 12 ? selectedMonthDates.length: 12,
    slidesToScroll: 1,
    initialSlide:  0,
    responsive: [
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: selectedMonthDates?.length < 10 ? selectedMonthDates.length: 10,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: selectedMonthDates?.length < 8 ? selectedMonthDates.length: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: selectedMonthDates?.length < 6 ? selectedMonthDates.length: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: selectedMonthDates?.length < 4 ? selectedMonthDates.length: 4,
        },
      },
    ],
  };

  return (
    <>
      <div className="sldrRow px-4 mt-4">
        {selectedMonthDates && selectedMonthDates.length > 0 && (
          <>
            <Slider className="dayDates-slider" {...settings}>
              {selectedMonthDates.map((date, index) => (
                // <button key={index} className={date === selectedDate ? "active" : ""} onClick={()=>setSelectedDate(datesList[index])}>{date.toLocaleString('default', { month: 'short' })} <span>{date.getDate()}</span></button>
                <button
                  key={index}
                  className={date === selectedDate ? 'active' : ''}
                  onClick={() => {
                    dispatch(bookAppointmentActions.dateSelector(date));
                  }}
                >
                  {new Date(date).toLocaleString(locale, { weekday: 'short' })}{' '}
                  <span>{new Date(date).getDate()}</span>
                </button>
              ))}
            </Slider>
            {/* <Slider className="dayDates-slider" {...settings}>
              {selectedMonthDates.map((date, index) => (
                <div key={index}>
                  <button
                    onClick={(e) => {
                      dateClickHandler(e, date);
                    }}
                  >
                    {date}
                  </button>
                </div>
              ))}
            </Slider> */}
          </>
        )}
        {selectedMonthDates?.length < 1 && (
          <p style={{ margin: '10px -16px 0' }}>No Dates Available .</p>
        )}
      </div>
    </>
  );
};

export default DatesSlider;
