import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Dropdown from '../../UI/Dropdown/Dropdown';
import { FaChevronDown } from 'react-icons/fa6';
import {
  LiaChevronCircleLeftSolid,
  LiaChevronCircleRightSolid,
} from 'react-icons/lia';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { defs } from '../../../utilFunctions/GridDefaultColumnDefs';
import { formatData, getIconByCode, getYearsFromData } from '../../../utilFunctions/Vitals';


const VitalReportGrid = React.memo(({ data, isRtl, t }) => {
  // const {t,isRtl} = useSelector(selectLanguage)
  // const filterYears = getfilterYears();
  const filterYears = getYearsFromData(data);
  const [selectedYear, setSelectedYear] = useState(filterYears[0].value || 2024);

  const handleYearChange = (value) => {
    setSelectedYear(value);
    if(document.querySelector(".dropdown.show > :first-child") !== null) {
      document.querySelector(".dropdown.show > :first-child").click();
    }
  };

  const vitalSignsRenderer = (params) => {
    if (params.data.Vital_Signs === '' || params.data.Vital_Signs === undefined)
      return;

    const icon = getIconByCode(params.data.code);

    return (
      <div className="flex flex-wrap items-center gap-x-2">
        <span className="inline-flex items-center justify-center iconSpan">
          <img
            style={{height:'24px'}}
            src={`${icon}`}
            alt="icon"
          />
        </span>
       <span style={{maxWidth:'120px', lineHeight:'1.4',textWrap:'pretty'}} dangerouslySetInnerHTML={{ __html: params.data.Vital_Signs.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}}></span> 
      </div>
    );
  };


  const defaultColDef = useMemo(() => defs, [isRtl]);

  const gridComponents = useMemo(() => ({ vitalSignsRenderer }), [isRtl]);


  const onGridReady = (params) => {
    const { api, columnApi } = params;
    api.sizeColumnsToFit();
  };

  const { columnDefs, rowData } = formatData(data, selectedYear, isRtl);

  useEffect(()=>{},[isRtl])

  return (
    <Fragment>
      <div className="flex flex-wrap justify-between items-center">
        <div className="inline-flex flex-wrap items-center gap-x-8">
          <h5 className="text-24 font-medium">{t.Assessments}</h5>
          <Dropdown>
            <button>
              <span className="text-20 font-semibold text-primary">
                {selectedYear}
              </span>
              <FaChevronDown className="text-secondary text-20 ml-2" />
            </button>
            <div className="dd-menu">
              <ul>
                {filterYears.map((x) => (
                  <li
                    className="p-2 ml-2 cursor-pointer"
                    onClick={() => handleYearChange(x.value)}
                  >
                    {x.label}
                  </li>
                ))}
              </ul>
            </div>
          </Dropdown>
        </div>
        <div
        style={{opacity: 0}}
         className="tabBtns inline-flex flex-wrap gap-x-1">
          <button>
            <LiaChevronCircleLeftSolid />
          </button>
          <button>
            <LiaChevronCircleRightSolid />
          </button>
        </div>
      </div>
      <div className="card mt-4 !px-0">
        <div
          className={`ag-theme-alpine mv-alternative-tbl ${isRtl ? 'ag-rtl' : 'ag-ltr'}`}
          style={{ height: '100vh', minHeight: '300px', width: '100%'}}
        >
          <AgGridReact
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            suppressExcelExport={true}
            headerHeight={50}
            components={gridComponents}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            onGridReady={onGridReady}
            overlayNoRowsTemplate={'Loading Data...'}
            enableRtl={isRtl}
          />
        </div>
      </div>
    </Fragment>
  );
});

export default VitalReportGrid;