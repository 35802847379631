import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';

const RtlLayout = (props) => {
  const { isRtl } = useSelector(selectLanguage);

  useEffect(() => {
    const root = document.documentElement;
    if (isRtl) {
      root.style.setProperty('--font-family', 'TheSans, sans-serif');
    } else {
      root.style.setProperty('--font-family', 'LamaSans, sans-serif');
    }
  }, [isRtl]);

  return (
    // <div style={{ direction: isRtl ? 'rtl' : 'ltr' }}>
    <div dir={isRtl ? 'rtl' : 'ltr'}>{props.children}</div>
  );
};

export default RtlLayout;
