import React from 'react';
import { FaCheck } from "react-icons/fa6";
import Tooltip from '../../UI/Sidebar/Tooltip';

import './completedAppointment.css';

const StatusItem = () => {
  return (
    <Tooltip text={'Insurance Tax'}>
    <div className="statusContainer flex flex-row gap-2 px-1">
      <span className="statusTitle">Approved</span>
      <span className="statusIcon"><FaCheck /></span>
    </div>
    </Tooltip>
  );
};

export default StatusItem;
