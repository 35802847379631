import { Fragment, useState, useEffect } from "react";
import { FaXmark } from "react-icons/fa6";

import "../../Modal/style.css"

const PatientConsentModal = (props) => {
    const { width, height } = props;
    const [showModalClass, setShowModalClass] = useState(null);
    const [block, setBlock] = useState(false)

    const ModelCloseHandler = () => {
        setShowModalClass(false)
    }
    const stopPropagtionHandler = (e) => {
        e.stopPropagation()
    }

    const showClassHandler = () => {
        setTimeout(() => {
            setShowModalClass(true)
        }, 200);
    }

    useEffect(()=>{
        setBlock(true)
    },[])

    useEffect(()=>{
        if(block) {
            showClassHandler()
        }
    },[block])

    return (
        <Fragment>
            <div className={`modal fade patientConsentModalIframe ${showModalClass ? "show" : ""}`} tabIndex="-1" role="dialog" aria-labelledby="overlay" style={{display: `${block ? "block" : ""}`}}>
                <div className={`modal-content ${width ? width : "sm"} ${height ? height : ""}`} onClick={stopPropagtionHandler}>
                    {/* <div className="head">
                        <h3 className="text-primary">{props.title ? props.title : ""}</h3>
                        <button onClick={ModelCloseHandler}><FaXmark /></button>
                    </div> */}
                    <div className="body">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default PatientConsentModal;