import React, { useState } from 'react';
import {
  FaClock,
  FaBolt,
  FaExchangeAlt,
  FaLightbulb,
  FaCalendarAlt,
} from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import StatusItem from '../Status';
import { selectLanguage } from '../../../../store/languageSlice';

import './reports.css';
import { useSelector } from 'react-redux';

const ReportItem = () => {
  const { t, isRtl } = useSelector(selectLanguage);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const reportsType = [
    {
      name: t.LabReports,
      type: 'laboratory-results',
      icon: 'ic_lab_report.svg',
      iconHov: 'ic_lab_report_hov.svg',
    },
    {
      name: t.RadiologyReport,
      type: 'radiology-results',
      icon: 'ic_radiology_report.svg',
      iconHov: 'ic_radiology_report_hov.svg',
    },
    {
      name: t.OperativeReport,
      type: 'operative-reports',
      icon: 'ic_operative_report.svg',
      iconHov: 'ic_operative_report_hov.svg',
    },
    {
      name: t.CardiologyReport,
      type: 'cardiology-results',
      icon: 'ic_cardiology_report.svg',
      iconHov: 'ic_cardiology_report_hov.svg',
    },
    {
      name: t.DischargeReports,
      type: 'discharge-summary-reports',
      icon: 'ic_discharge_report.svg',
      iconHov: 'ic_discharge_report_hov.svg',
    },
    {
      name: t.SickReport,
      type: 'sick-leave-reports',
      icon: 'ic_sick_report.svg',
      iconHov: 'ic_sick_report_hov.svg',
    },
    {
      name: t.RequestReports,
      type: 'request-a-reports',
      icon: 'ic_request_report.svg',
      iconHov: 'ic_request_report_hov.svg',
    },
  ];

  return (
    <div className="mresContainer w-full gap-4">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex gap-3">
          <div className="flex flex-col items-center">
            <span className="text-sm font-light leading-4">Wed</span>
            <span className="text-base font-bold text-primary leading-4">
              11
            </span>
            <span className="text-sm font-light leading-4">2024</span>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="statusContainer flex flex-row gap-2 px-1">
                <span className="statusIcon">
                  <img
                    alt={reportsType[0].name}
                    className="statusIcon"
                    style={{ height: '14px' }}
                    src={`${process.env.PUBLIC_URL}/images/icons/${reportsType[0].icon}`}
                  />
                </span>
                <span className="statusTitle">Lab Report</span>
              </div>
              <StatusItem />
            </div>

            <span className="titleDoc">Dr Amir T Banan</span>
            <div className="mvalue flex items-center gap-1 text-gray-500 text-sm mt-1">
              <FaClock />
              <span>at 3:00 am</span>
            </div>
          </div>
        </div>
        <div className="downloadButtonIcon">
          <FiDownload onClick={toggleExpanded} />
        </div>
      </div>

      {expanded && <div
        className={`w-full p-4 rounded-3xl text-gray-700 `}
        style={{ background: '#F0F0F0' }}
      >
        <div className="flex items-center gap-2 mb-2">
          <FaBolt className="rCardIcon" />
          <span className="rCardLable">Value:</span>
          <span className="rCardValue ml-auto">112 K/Mc</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <FaExchangeAlt className="rCardIcon" />
          <span className="rCardLable">Reference Range:</span>
          <span className="rCardValue ml-auto">112 K/Mc</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <FaLightbulb className="rCardIcon" />
          <span className="rCardLable">Interpretation:</span>
          <span className="rCardValue ml-auto">112 K/Mc</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <FaCalendarAlt className="rCardIcon" />
          <span className="rCardLable">Collection Date & Time:</span>
          <span className="rCardValue ml-auto">112 K/Mc</span>
        </div>
        <div
          className="mt-3 text-gray-600 text-sm px-2 py-1 rounded-full flex items-center gap-1"
          style={{ background: '#F5F5F5' }}
        >
          <FaCalendarAlt className="rCardIcon" />
          <span className="rCardIcon">Cardiology Report New Line Xyz</span>
        </div>
      </div>}
    </div>
  );
};

export default ReportItem;
