import { envVariables } from '../../config';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';

export const gaurdianDetailReq = async () => {
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { apiRoute } = envVariables;

  const endpoint = `${apiRoute}api/guardian/getDetails/${userInfo?.mrn}/${hospitalId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    // await sessionsMiddleWare();
    console.error('Error fetching gaurdian details:', error);
    return error;
  }
};

export const addGaurdianDetailReq = async (reqBody) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/add-guardian`;

  const updatedPayload = {
    ...reqBody,
    patId: userInfo?.mrn,
    hosp: hospitalId,
  };

  try {
    // const response = await fetch(endpoint, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${userInfo?.accessToken}`,
    //   },
    //   body: JSON.stringify(reqBody),
    // });

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(updatedPayload),
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching gaurdian details:', error);
    return error;
  }
};

export const updateGaurdianDetailReq = async (reqBody) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/guardian/editGuardian`;

  const updatedPayload = {
    ...reqBody,
    patId: userInfo?.mrn,
    hosp: hospitalId,
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching gaurdian details:', error);
    return error;
  }
};

export const deleteGaurdianDetailReq = async (guardianId) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/remove-guardian`;

  const payload = {
    patId: userInfo?.mrn,
    hosp: hospitalId,
    guardianId,
    noOfDays: 0,
    consent: 0,
    isActive: 0,
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching gaurdian details:', error);
    return error;
  }
};

export const dependentsReq = async () => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // const endpoint = `${host}api/user/getDetails/000348/1`;
  const endpoint = `${apiRoute}api/user/getDetails/${userInfo?.mrn}/${hospitalId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }
    const data = await response.json();

    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching dependents list:', error);
    return error;
  }
};

export const fectNotificationsReq = async (mrNmuber) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/notification/unread/count/${mrNmuber}/${hospitalId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching dependents list:', error);
    return error;
  }
};

export const getGuardianRelationsList = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/patient-relation`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching patient-relation:', error);
    return null;
  }
};
