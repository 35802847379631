import moment from 'moment';
import React from 'react';
import { FiClock } from "react-icons/fi";

const SingleAssesmentRprtDb = ({ t, isRtl, assessment, index }) => {
  let dateObj = new Date(assessment.updateDateTime);
  const locale = isRtl ? 'ar' : 'en-US';
  const formatResult = (result) => {
    if(!result  || result === '' || result === 'none') {
      return '0';
    }
    return result;
  }
  return (
    <li key={index}>
      <div className="card">
        <div className="info">
            <h4>{assessment.formName}</h4>
            {isRtl ? <p>{t[assessment.status] || assessment.status}  {moment(assessment.updateDateTime).locale("ar-SA").format("YYYY-MM-DD")}</p>:<p>{t[assessment.status] || assessment.status} {dateObj.getDate()} {dateObj.toLocaleString(locale, { month: 'long' })} {dateObj.toLocaleString(locale, { year: '2-digit' })}</p>}
            <span><div dir='ltr' style={{display:'flex', alignItems:'flex-end', gap:"5px"}}><FiClock /> <span>{dateObj.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[0]}{' '} : {(dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes()} {dateObj.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[1]}</span></div></span>
        </div>        
        <div className="scoreInfo">
          <span>{assessment.result ? formatResult(assessment.result) : 'X'}</span>
          {t.Score}
        </div>
      </div>
    </li>
  );
};

export default SingleAssesmentRprtDb;