import React, { useState, memo, useEffect } from 'react';
import { FiBell, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';
import { fectNotificationsReq } from '../../../../utilFunctions/profile/MyFamilyProfile';

const DependentItem = React.memo(({ data, onClickDependent }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { patient_exist, mrn, first_name, last_name, mobile_number,patientRelation } = data;
  const [notifications, setNotifications] = useState();
  const handleOnDependentClick = () => {
    onClickDependent && onClickDependent(data);
  };

  const getNotification = async () => {
    const noticationCount = await fectNotificationsReq(mrn);
    setNotifications(noticationCount);
  };

  useEffect(() => {
    !notifications && getNotification();
  }, []);

  const mapped = {
    patient_exist,
    mrn,
    first_name: isRtl ? data.arabic_first_name : first_name,
    last_name: isRtl ? data.arabic_last_name : last_name,
    mobile_number,
    relation: isRtl ? patientRelation?.relationNameAr : patientRelation?.relationName,
  };

  return (
    <div
      onClick={handleOnDependentClick}
      className="flex mt-2 justify-between items-center rounded-md p-4 max-w-md w-full bg-white"
    >
      <div className="flex items-center gap-6 cursor-pointer">
        <div className="bg-zinc-200 rounded-full w-12 h-12 flex justify-center items-center">
          <FiUser className="w-6 h-6 text-gray-500" />
        </div>
        <div className="flex flex-col gap-1.5">
          <h3 className="font-semibold text-lg text-gray-700 leading-4">{`${mapped.first_name} ${mapped.last_name}`}</h3>
          <p className="ext-base leading-4 text-gray-700">
            {mapped.mobile_number}
          </p>
          <p className="ext-base leading-4 text-gray-700">{mapped.relation}</p>
        </div>
      </div>

      <div className="flex items-center">
        <div className="relative">
          <FiBell className="w-7 h-7 text-gray-700" />
          <span
            className={`h-2 w-2 rounded-full ${
              mapped.patient_exist ? 'bg-red-600' : 'bg-gray-200'
            } absolute top-0 right-1`}
          ></span>
        </div>
        {/* <p className="text-lg text-gray-700">{'3+'}</p> */}
        {notifications?.status !== '0' && (
          <p className="text-lg">{notifications?.status}</p>
        )}
      </div>
    </div>
  );
});

export default DependentItem;
