import { createSlice, configureStore } from '@reduxjs/toolkit';
import vitalReportSlice from './vitalReportSlice';
import modalSlice from './globalModalSlice';
import languageSlice from './languageSlice';
import reportSlice from './reportsSlice';
import globalLoaderErrorSlice from './globalLoaderError';
import badgesSlice from './badgesSlice';
import appointmentSlice from './appointmentSlice';
import toastSlice from './toastSlice';
import assessmentSlice from './assessmentSlice';

const selectedHospInfo = JSON.parse(
  localStorage.getItem('selectedHopitalInfo')
);
const hospitalInformationSlice = createSlice({
  name: 'hospitalInfo',
  initialState: {
    hospName: selectedHospInfo
      ? selectedHospInfo.name
      : 'International Medical Center',
    hospCode: selectedHospInfo ? selectedHospInfo.code : 'IMC',
    hospId: selectedHospInfo ? selectedHospInfo.id : '1',
    selectedHospital: null,
    list: [],
  },
  reducers: {
    selectHospital(state, action) {
      state.hospCode = action.payload.code;
      state.hospId = action.payload.id;
      state.selectedHospital = state.list?.find((x) => x.hospitalId == action.payload.id);
    },
    updateList(state, action) {
      const storeId = selectedHospInfo ? selectedHospInfo.id : '1';
      state.list = action.payload;
      state.selectedHospital = action.payload?.find((x) => x.hospitalId == storeId);
    },
  },
});

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: { sidebarOpen: true, openOnMobile: false },
  reducers: {
    toggle(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    toggleMobile(state) {
      state.openOnMobile = !state.openOnMobile;
    },
    closeMobile(state) {
      state.openOnMobile = false;
    },
  },
});

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    loggedIn: userInfo?.status === 'Success' ? true : null,
    redirectedUrl: null,
    userFullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
  },
  reducers: {
    authenticateToggle(state, action) {
      state.loggedIn = action.payload;
    },
    updateRedirected(state, action) {
      state.redirectedUrl = action.payload;
    },
    updateUserFullName(state, action) {
      state.userFullName = action.payload;
    },
  },
});

const bookAppointmentSlice = createSlice({
  name: 'bookAppointment',
  initialState: {
    doctorName: null,
    doctorNameAr: null,
    selectedDate: null,
    availableDatesArray: null,
    docCode: null,
    specialityCode: null,
    serviceCode: null,
    appointmentType: 'Offline',
    slotTime: null,
    slotId: null,
    clinicName: null,
    confirmBooking: false,
    selectedDocCard: null,
    lastVisitedDocs:null,
    primaryCareDocs:null,
    primaryOrLastVisited:null,
    selectedMonthDates:null,
    docsPersonalInfoList: []
  },
  reducers: {
    docName(state, action) {
      state.doctorName = action.payload;
    },
    docNameAr(state, action) {
      state.doctorNameAr = action.payload;
    },
    docAvailableDates(state, action) {
      state.availableDatesArray = action.payload;
    },
    dateSelector(state, action) {
      state.selectedDate = action.payload;
    },
    selectedDocCode(state, action) {
      state.docCode = action.payload;
    },
    selectedDocSpecialityCode(state, action) {
      state.specialityCode = action.payload;
    },
    selectedServiceCode(state, action) {
      state.serviceCode = action.payload;
    },
    selectedAppointmentType(state, action) {
      state.appointmentType = action.payload;
    },
    selectedSlot(state, action) {
      state.slotTime = action.payload.time;
      state.slotId = action.payload.id;
    },
    selectedClinicName(state, action) {
      state.clinicName = action.payload;
    },
    clickConfirmButton(state, action) {
      state.confirmBooking = action.payload;
    },
    updateSelectedDocCard(state, action) {
      state.selectedDocCard = action.payload;
    },
    clearDocCard(state, action) {
      state.docCode = null;
      state.availableDatesArray = null;
    },
    updateLastVisitedDocs(state, action) {
      state.lastVisitedDocs = action.payload;
    },
    updatePrimaryCareDocs(state, action) {
      state.primaryCareDocs = action.payload;
    },
    updatePrimaryOrLastVisited(state, action) {
      state.primaryOrLastVisited = action.payload;
    },
    updateSelectedMonthDates(state, action) {
      state.selectedMonthDates = action.payload;
    },
    addToDocPersonalInfoList: (state, action) => {
      state.docsPersonalInfoList.push(action.payload)
    },
  },
});

const searchbarSlice = createSlice({
  name: 'searchbar',
  initialState: {
    specialityCode: null,
    docCode: null,
    searchByClinic: false,
    selectedType: 'Clinic',
    selectedItem: null,
    searchText: '',
  },
  reducers: {
    selectedSpecialityCode(state, action) {
      state.specialityCode = action.payload;
    },
    selectedDoctorCode(state, action) {
      state.docCode = action.payload;
    },
    updateSelectedType(state, action) {
      state.selectedType = action.payload;
    },
    updateSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },
    updateSearchText(state, action) {
      state.searchText = action.payload;
    },
    handleSeacrhByClinic(state, action) {
      state.searchByClinic = action.payload;
    },
  },
});

const portalSlice = createSlice({
  name: 'portal',
  initialState: {
    showMyFamily: false,
    showEditProfile: false,
    showRequestReport: false,
    showContactUs: false,
    showSecondOpinion: false,
    showDoctorProfile: false,
    drawerTitle: 'Portal Title',
    selectedDependent: null,
    showDocInfoCode: null,
    drawerId: null
  },
  reducers: {
    toggleMyFamilyDrawer(state, action) {
      const previous = { ...state };
      state.showMyFamily = !previous.showMyFamily;
      state.drawerTitle = 'Family_Profile';
    },
    toggleEditDrawer(state, action) {
      const previous = { ...state };
      state.showEditProfile = !previous.showEditProfile;
      state.drawerTitle = 'Edit_Profile';
    },
    toggleRequestReportDrawer(state, action) {
      const previous = { ...state };
      state.showRequestReport = !previous.showRequestReport;
      state.drawerTitle = 'Request_A_Report';
    },
    toggleContactUsDrawer(state, action) {
      const previous = { ...state };
      state.showContactUs = !previous.showContactUs;
      state.drawerTitle = 'contactUs'; // this is id in eng or arabic
    },
    toggleSecondOpinionDrawer(state, action) {
      const previous = { ...state };
      state.showSecondOpinion = !previous.showSecondOpinion;
      state.drawerTitle = 'secondOpinion'; // this is id in eng or arabic
    },
    toggleDoctorProfileDrawer(state, action) {
      const previous = { ...state };
      state.showDoctorProfile = !previous.showDoctorProfile;
      state.showDocInfoCode = action.payload;
      state.drawerTitle = '';
      state.drawerId = "docProfileDrawer"
    },
    updatedSelectedDependent(state, action) {
      state.selectedDependent = action.payload;
    },
    closeDrawer(state, action) {
      const previous = { ...state };
      state.showMyFamily = false;
      state.showEditProfile = false;
      state.showRequestReport = false;
      state.showContactUs=false;
      state.showSecondOpinion = false;
      state.showDoctorProfile=false;
      state.drawerId=null;
    },
  },
});

export const hospitalInformationActions = hospitalInformationSlice.actions;
export const sidebarActions = sidebarSlice.actions;
export const authenticationActions = authenticationSlice.actions;
export const bookAppointmentActions = bookAppointmentSlice.actions;
export const searchbarActions = searchbarSlice.actions;
export const modalActions = modalSlice.actions;
export const portalActions = portalSlice.actions;
export const languageActions = languageSlice.actions;
export const reportActions = reportSlice.actions;
export const assessmentActions = assessmentSlice.actions;

const store = configureStore({
  reducer: {
    hospitalInfo: hospitalInformationSlice.reducer,
    sidebar: sidebarSlice.reducer,
    authentication: authenticationSlice.reducer,
    bookAppointment: bookAppointmentSlice.reducer,
    searchbar: searchbarSlice.reducer,
    [vitalReportSlice.name]: vitalReportSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
    [portalSlice.name]: portalSlice.reducer,
    [languageSlice.name]: languageSlice.reducer,
    [reportSlice.name]: reportSlice.reducer,
    [globalLoaderErrorSlice.name]: globalLoaderErrorSlice.reducer,
    [badgesSlice.name]: badgesSlice.reducer,
    [appointmentSlice.name]: appointmentSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [assessmentSlice.name]:assessmentSlice.reducer,
  },
});

export default store;
