import React, { useRef, useState, useEffect } from 'react';
import {
  SlickNextArrow,
  SlickPrevArrow,
} from '../UI/SlickSlider/SlickSliderArrows';
import './style.css';

const HorizontalSlider = ({ children, isRTL = false }) => {
  const sliderRef = useRef(null);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const handleNext = () => {
    if (sliderRef.current) {
      const scrollAmount = isRTL ? -200 : 200;
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      const scrollAmount = isRTL ? 200 : -200;
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const checkButtons = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      const maxScroll = scrollWidth - clientWidth;

      // Adjust logic for RTL
      if (isRTL) {
        setDisablePrev(scrollLeft >= 0);
        setDisableNext(scrollLeft <= -maxScroll);
      } else {
        setDisablePrev(scrollLeft <= 0);
        setDisableNext(scrollLeft >= maxScroll);
      }
    }
  };

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed

    // Adjust for RTL
    if (isRTL) {
      sliderRef.current.scrollLeft = scrollLeft + walk;
    } else {
      sliderRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    checkButtons();
    sliderRef.current.addEventListener('scroll', checkButtons);

    return () => {
      sliderRef?.current?.removeEventListener('scroll', checkButtons);
    };
  }, [isRTL]);

  return (
    <div className="relative flex items-center">
      <button
        onClick={handlePrev}
        disabled={disablePrev}
        className={`prev-button ${disablePrev ? 'disabled' : ''}`}
      >
        {isRTL ? <SlickNextArrow /> : <SlickPrevArrow />}
      </button>
      <div
        ref={sliderRef}
        tabIndex={0} // Make div focusable
        className={`slider-container overflow-x-scroll whitespace-nowrap scrollbar-hide ${
          isRTL ? 'rtl' : ''
        }`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        onMouseMove={onDragging}
        onWheel={(e) => {
          if (!isFocused) return;
          e.preventDefault();
          const scrollAmount = isRTL ? -e.deltaY : e.deltaY;
          sliderRef.current.scrollBy({
            left: scrollAmount,
            behavior: 'smooth',
          });
        }}
      >
        <div className='flex gap-2'>{children}</div>
      </div>
      <button
        onClick={handleNext}
        disabled={disableNext}
        className={`next-button ${disableNext ? 'disabled' : ''}`}
      >
        {isRTL ? <SlickPrevArrow /> : <SlickNextArrow />}
      </button>
    </div>
  );
};

export default HorizontalSlider;
