import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDataFetching from '../../../utilFunctions/useFetchData';
import {
  authenticationActions,
  modalActions,
  portalActions,
} from '../../../store';
import { envVariables } from '../../../config';
import {
  userProfileDetailReq,
  validateEmail,
  verifyEmail,
} from '../../../utilFunctions/profile/EditProfile';
import Loader from '../../UI/Loader';
import { selectLanguage } from '../../../store/languageSlice';
import '../../../components/Otp/style.css';

const EmailOtpVerify = () => {
  const { apiRoute } = envVariables;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, isRtl } = useSelector(selectLanguage);
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [verifyRes, setVerifyRes] = useState({
    error: false,
    message: '',
  });
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const { response , isMayoForm} = useSelector((state) => state.modal);
  const {
    data: profileData,
    loading,
    error,
  } = useDataFetching(userProfileDetailReq);

  useEffect(() => {
    let intervalId;
    if (minutes === 0 && seconds === 0 && isResendDisabled) {
      setIsResendDisabled(false);
    } else if (minutes !== 0 || seconds !== 0) {
      intervalId = setTimeout(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [minutes, seconds, isResendDisabled]);

  const handleClickResend = async () => {
    if (response) {
      // Send request to generate OTP
      setShowLoader(true)
      const data = await verifyEmail(response?.newEmail);
      setOtp('');
      setShowLoader(false)
      if (data) {
        setMinutes(1);
        setSeconds(0);
        setIsResendDisabled(true);
      }
    }
  };

  const handleClickVerify = async (data) => {
    if(showLoader) return
    try {
      setShowLoader(true)
      const getResponseOtpVerify = await validateEmail({
        patid: profileData?.patientProfile?.patId,
        email: response?.newEmail,
        patient_id: profileData?.tcsLoginResponse?.patient_id ,
        otp: data || otp,
      });
      setShowLoader(false)

      if (!getResponseOtpVerify || getResponseOtpVerify.status == 'false') {
        setVerifyRes({
          error: true,
          message: getResponseOtpVerify?.message || t.CouldNotValidateEmail,
        });

        return;
      }

      setVerifyRes({
        error: false,
        message: getResponseOtpVerify?.message || '',
      });

      dispatch(modalActions.closeModal());
      if(!isMayoForm) dispatch(portalActions.closeDrawer());
    } catch (e) {
      throw new Error(`Error validating email otp`);
    }
  };

  const handleOnInputChange = async (data) => {
    setOtp(data);

    // if (data && inputRefs.current[data.length]) {
    //   inputRefs.current[data.length].focus();
    // }

    if (data?.length >= 4) {
      await handleClickVerify(data);
    }
  };

  if (loading)
    return (
      <div className="w-full h-full flex justify-center">
        <Loader />
      </div>
    );

  return (
    <>
      <div className="OtpInput_Box">
        <h2>{t.OTPVerification}</h2>
        <p>{t.WeHaveSendAnOTPOnYourNumber}</p>
        <div dir='ltr'>
        <OtpInput
          value={otp}
          onChange={handleOnInputChange}
          numInputs={4}
          inputType="numeric"
          inputStyle={'inputStyle'}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        </div>
        <p className="resendCode_line">
          <span
            className={
              isResendDisabled ? 'pointer_event_block' : 'pointer_event_UnBlock'
            }
            onClick={handleClickResend}
          >
            {t.ResendCode}
          </span>
          <span className="mx-1">{t.In}</span>
          <span className="resendCode_timer">
            {minutes.toString().padStart(2, '0')}:
            {seconds.toString().padStart(2, '0')}
          </span>
        </p>
        <div className="signBtnDiv"></div>
      </div>

      <div className="w-full flex flex-col items-center">
        {verifyRes?.message !== '' && (
          <h5
            className="text-blue-700 my-4"
            style={{ color: verifyRes?.error ? 'var(--color-tertiary)' : 'green' }}
          >
            {verifyRes?.message }
          </h5>
        )}
        <button className="signIn_btn" onClick={handleClickVerify}>
          {showLoader ? <Loader roundLoader /> : t.Verify}
        </button>
      </div>
    </>
  );
};

export default EmailOtpVerify;
