import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import {
  FiDownload,
  FiChevronDown,
  FiChevronUp,
  FiClock,
} from 'react-icons/fi';
import { CgSoftwareDownload } from 'react-icons/cg';
import { downloadOperativeReport } from '../../../utilFunctions/reports/reports';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { formatDate } from '../../../translations/common';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';

const ReportItem = ({ item }) => {
  const formatedItem = {
    reportName: item?.order_description,
    value: item?.order_status || '',
    referenceRange: '',
    Interpretation: item?.authorized_user || '',
    dateTime: `${item?.result_date || ''} ${item?.result_time || ''}`,
  };
  return (
    <div>
      <div className="type">
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/mainRprtIcon_hov.svg`}
          alt="report icon"
        />{' '}
        {formatedItem.reportName}
      </div>
      <div className="val">
        Value:{' '}
        <span className="text-secondary font-medium">{formatedItem.value}</span>
      </div>
      <div className="rng">
        Reference Range:{' '}
        <span className="text-secondary font-medium">
          {formatedItem.referenceRange}
        </span>
      </div>
      <div className="intp">
        Interpretation:{' '}
        <span className="text-secondary font-medium">
          {formatedItem.Interpretation}
        </span>
      </div>
      <div className="clctDate">
        Collection Date & Time:{' '}
        <span className="text-secondary font-medium">
          {formatedItem.dateTime}
        </span>
      </div>
    </div>
  );
};

const OperativeReport = ({ listData, currentData }) => {
  const { isMobile } = useBreakpoint();
  const { t, isRtl } = useSelector(selectLanguage);
  const [reportData, setReportData] = useState([]);
  const [showRecords, setShowRecord] = useState([]);

  const handleDownloadReport = async (orderId) => {
    if (!orderId && orderId == '') return;

    await downloadOperativeReport(orderId);
  };

  const toggleShowRecords = (indexId) => {
    if (showRecords.includes(indexId)) {
      setShowRecord(showRecords.filter((x) => x !== indexId));
    } else {
      setShowRecord([...showRecords, indexId]);
    }
  };

  useEffect(() => {
    if (listData?.length > 0) {
      const sortedByDate = listData
        .slice()
        .sort((a, b) =>
          moment(b.collected_date).diff(moment(a.collected_date))
        );

      setReportData(sortedByDate);
    }
  }, [listData]);

  if (!currentData) return null;

  if (listData.length === 0)
    return (
      <div className="w-full h-full mt-14 flex justify-center">
        <div className="font-semibold text-2xl text-blue-900">
          {t.NoDataFound}
        </div>
      </div>
    );

  return (
    <Fragment key={'OperativeLab'}>
      {reportData.map((item, index) => {
        const orderItem = {
          date: item?.authorized_date,
          oderId: item?.document_id,
          docName: item?.authorized_user,
          time: item?.authorized_time,
        };

        const fDate = formatDate(orderItem.date, isRtl);
        const time =
          orderItem.time !== ''
            ? moment(orderItem.time, 'HH:mm').format('hh:mm A')
            : '';
        const show = showRecords.includes(index);

        return (
          <>
            {!isMobile && (
              <div key={'radio' + index} className="sngRpt">
                <div className="info">
                  <div className="no">{index + 1}.</div>
                  <div className="date">
                    <span>{fDate.weekName}</span>
                    {`${fDate.monthDate} ${fDate.monthNameShort}`}
                    {/* <span>{`${fDate.year}`}</span> */}
                  </div>
                  <div className="year">{`${fDate.year}`}</div>
                  <div className="id">{orderItem?.oderId || ''}</div>
                  <div className="by">{orderItem?.docName || ''}</div>
                  <div className="time">{time || ''}</div>
                  <div
                    onClick={() => handleDownloadReport(orderItem.oderId)}
                    className="download"
                  >
                    <button>
                      {t.Download} <CgSoftwareDownload />
                    </button>
                  </div>
                  {/* <button
                    onClick={() => toggleShowRecords(index)}
                    className="collapseBtn"
                  >
                    {show ? <FiChevronUp /> : <FiChevronDown />}
                  </button> */}
                </div>
                {/* {show && (
                  <div className="otherInfo">
                    <ReportItem item={item} key={index} />
                  </div>
                )} */}
              </div>
            )}
            {isMobile && (
              <div className="snglRprt">
                <div className="card">
                  <div className="date text-gray-500">
                    <span className="day font-medium text-slate-950">
                      {fDate.monthNameShort}
                    </span>
                    <span className="date font-bold text-24 text-primary">
                      {fDate.monthDate}
                    </span>
                    {fDate.year}
                  </div>
                  <div className="info">
                    <h3 className="text-slate-950">
                      Order ID: {orderItem?.oderId || ''}
                    </h3>
                    <h4 className="text-green">{orderItem?.docName || ''}</h4>
                    <p className=" text-gray-500">
                      <FiClock /> at {time || ''}
                    </p>
                  </div>
                  <div className="btns">
                    <button
                      className="dwnld"
                      onClick={() => handleDownloadReport(orderItem.oderId)}
                    >
                      {t.Download} <CgSoftwareDownload />
                    </button>
                    {/* <button className="collapseBtn" onClick={() => toggleShowRecords(index)}>{show ? <FiChevronDown /> : <FiChevronLeft />}</button> */}
                  </div>
                </div>
                {/* {show && (
                  <div className="moreInfo">
                    <ReportItem t={t} isRtl={isRtl} item={item} key={index} />
                  </div>
                  )} */}
              </div>
            )}
          </>
        );
      })}
    </Fragment>
  );
};

export default OperativeReport;
