import { envVariables } from '../config';
import { sessionsMiddleWare } from './SessionExpire';
import { getSelectLanguage } from './getCurrentSelectLanguage';
import { mockFetchRequest } from './VitalsReportList';

export const hhcBookSerivce = async (updatedBody) => {
  const { apiRoute } = envVariables;
  const endpoint = `${apiRoute}web/hhc_form/submissions`;

  const userInfo = JSON.parse(localStorage.userInfo);
  const hospCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';

  const reqBody = {
    HHCFormId: 1,
    hosp: '1',
    patId: userInfo?.mrn,
    submittedFor: userInfo?.firstName,
    contact: '+966' + userInfo?.mobileNumber,
    email: userInfo?.email,
    ...updatedBody,
  };

  // return mockFetchRequest({})

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('userInfo')).accessToken
        }`,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      if(response.status === 403) {
        sessionsMiddleWare();
    }
      throw new Error(`HTTP error! hhc_form/submissions: ${response.status}`);
    }

    const apiData = await response.json();

    // if (!apiData.data) {
    //   throw new Error(`HTTP error! hhc_form/submissions: ${response.status}`);
    // }

    return apiData;
  } catch (error) {
    console.error('Error fetching hhc_form/submissions:', error);
    return null;
  }
};
