import React, { useState, useRef, useEffect } from "react";
import listenForOutsideClicks from "./dropdown-onclickoutside";
import "./Dropdown.css"

const Dropdown = (props) => {
    const { extraClass } = props;
    const ddRef = useRef(null);
    // const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
      if(document.querySelector(".dropdown.show > :first-child") !== null) {
        document.querySelector(".dropdown.show > :first-child").click();
      }
      setIsOpen(!isOpen)
    }

    // Dropdown.hamdleClickOutside = () => setIsOpen(false)

    // useEffect(()=>{
    //   listenForOutsideClick(ddRef,setIsOpen)
    // }, []);
    useEffect(() => {
      const handleClickOutside = listenForOutsideClicks(ddRef, setIsOpen); // Get the click outside handler
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
  
      return () => {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }, []); // Only run this effect when the component mounts

    return (
        <div ref={ddRef} className={`${isOpen ? `dropdown ${extraClass ? extraClass : ""} show` : `dropdown ${extraClass ? extraClass : ""}`}`}>
          {/* {props.children} */}
          {React.Children.map(props.children, (child, index) =>
            React.cloneElement(child, {
              onClick: (e) => {
                // Prevent click event from propagating to the dropdown itself
                e.stopPropagation();
                if (child.props.onClick) {
                  child.props.onClick();
                }
                // Close the dropdown when clicking on the first child element
                if (index === 0) {
                  toggle()
                }
              },
            })
          )}
        </div>
    )
};

// const clickOutsideConfig = {
//   handleClickOutside: () => Dropdown.handleClickOutside,
// }

export default Dropdown;