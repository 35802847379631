import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from '../../../store';
import { selectLanguage } from '../../../store/languageSlice';
import ContactUs from '../../ContactUs';
import EditProfile from '../../Profile/EditProfile/EditProfile';
import FamilyProfile from '../../Profile/FamilyProfie/FamilyProfile';
import RequestReport from '../../Reports/RequestReport';
import RightDrawer from '../RightDrawer';
import RightSidebar from '../../BookAppointment/RightSidebar';
import {DrawerPortalDom} from './Portals';
import SecondOpinion from '../../SecondOpinion';

const DrawerPortal = () => {
  const dispatch = useDispatch();
  const {t} = useSelector(selectLanguage);
  const { showMyFamily, drawerTitle, showEditProfile, showRequestReport, showContactUs, showDoctorProfile, drawerId, showSecondOpinion } = useSelector(
    (state) => state.portal
  );

  const handleOnCloseDrawer = () => {
    dispatch(portalActions.closeDrawer());
  };

  return (
    <DrawerPortalDom>
      <RightDrawer
        title={t[drawerTitle]}
        isOpen={showMyFamily || showEditProfile || showRequestReport || showContactUs || showSecondOpinion || showDoctorProfile }
        setIsOpen={handleOnCloseDrawer}
        id={drawerId}
      >
        {showMyFamily && <FamilyProfile />}
        {showEditProfile && <EditProfile />}
        {showRequestReport && <RequestReport />}
        {showContactUs && <ContactUs />}
        {showSecondOpinion && <SecondOpinion />}
        {showDoctorProfile && <RightSidebar />}
      </RightDrawer>
    </DrawerPortalDom>
  );
};

export default DrawerPortal;
