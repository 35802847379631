import React, { useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import closeOpenedDropdown from '../../../../utilFunctions/DropdownCloser';
import { getTValue } from '../../../../translations/common';

const EditGuardian = ({ data, onAddGuadrian, patientsRelations }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const [isDurationInputVisible, setIsDurationInputVisible] = useState(false);
  const [mrNumber, setMrNumber] = useState('');
  const [noOfDays, setNoOfDays] = useState(0);
  const [type, setType] = useState('add');
  const [setectedRelation, setSetectedRelation] = useState();

  const handleRadioChange = () => {
    setIsDurationInputVisible(!isDurationInputVisible);
    setNoOfDays(0);
  };

  const handleMrNumberChange = (event) => {
    setMrNumber(event.target.value);
  };

  const handleAddButtonClick = () => {
    onAddGuadrian &&
      onAddGuadrian({
        type,
        mrNumber,
        noOfDays: noOfDays,
        guardianRelation: setectedRelation?.relationCode || '',
      });
  };

  const changeOptionsHandler = (selected) => {
    setSetectedRelation(selected);
    closeOpenedDropdown();
  };

  useEffect(() => {
    if (data && data?.guardianId) {
      if (data?.noOfDays > 0) setIsDurationInputVisible(true);

      setNoOfDays(data?.noOfDays);
      setMrNumber(data?.guardianId);
      const code = data.userDetails?.guardianRelation;
      const relationItem = patientsRelations.find(
        (x) => x.relationCode == code
      );

      relationItem && setSetectedRelation(relationItem);
      setType('edit');
    }
  }, [data]);
  
  return (
    <div className="border bg-white shadow rounded-md p-8 max-w-md w-full mx-auto">
      <div className="flex items-center space-x-4 gap-y-2.5">
        <div className="w-8 h-8 rounded-full bg-gray-200 flex justify-center items-center ">
          <FiUser className="w-6 h-6" />
        </div>
        <h1 className=" mt-1 text-xl text-grey-700">
          {data?.userDetails ? t.Update_A_Guardian : t.Add_A_Guardian}
        </h1>
      </div>
      <div className="w-full mt-5">
        <div className="flex flex-col gap-2 ml-12">
          <label
            htmlFor="mrNumber"
            className="text-base font-extrabold leading-6 text-gray-900 float-left"
          >
            {t.MR_Number}
          </label>
          <div>
            <input
              type="text"
              id="mrNumber"
              name="mrNumber"
              value={mrNumber || ''}
              disabled={type == 'edit'}
              onChange={handleMrNumberChange}
              className="block w-50 rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t.Enter_MR_Number}
            />
          </div>
          <Dropdown extraClass="site-switer">
            <div>
              <input
                type="text"
                id="relation"
                name="relation"
                value={setectedRelation?.relationName || ''}
                onChange={(e) => {
                  const relationItem = patientsRelations.find(
                    (x) => x.relationName == e.target.value
                  );
                  setSetectedRelation(relationItem);
                  closeOpenedDropdown();
                }}
                className="block w-50 rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={t.SelectRelation}
              />
            </div>

            <div
              className="dd-menu !px-0"
              style={{ minWidth: '188px', right: 'auto' }}
            >
              <ul>
                {patientsRelations?.map((item, index) => (
                  <li key={index}>
                    <button
                      className={
                        setectedRelation?.relationCode === item.relationCode
                          ? 'active !px-3'
                          : '!px-3 text-grey-700'
                      }
                      onClick={() => changeOptionsHandler(item)}
                    >
                      {getTValue(item,'relationNameAr','relationName',isRtl)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Dropdown>
          <h1 className="font-extrabold text-base">{t.Duration_Of_Guardian}</h1>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              id="unlimited"
              checked={!isDurationInputVisible}
              onChange={handleRadioChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="unlimited"
              className="!m-0 text-sm font-medium text-gray-900 dark:text-black"
            >
              {t.Unlimited}
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              id="numberOfDays"
              value="Number of Days"
              checked={isDurationInputVisible}
              onChange={handleRadioChange}
              className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            {isDurationInputVisible ? (
              <div>
                <input
                  type="text"
                  id="numberOfDaysInput"
                  name="numberOfDaysInput"
                  defaultValue={noOfDays}
                  className="block w-35 rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder={t.Enter_Number_of_Days}
                  onChange={(e) => setNoOfDays(Number(e.target.value) || '')}
                />
              </div>
            ) : (
              <label
                htmlFor="numberOfDays"
                className="!m-0 text-sm font-medium text-gray-900 dark:text-black"
              >
                {t.Number_of_Days}
              </label>
            )}
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <button
            type="button"
            onClick={handleAddButtonClick}
            className="btn bg-sky-400 p-2 rounded text-white w-20"
          >
            {data?.userDetails ? t.Update : t.Add}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditGuardian;
