import React from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectVital } from '../../../../store/appointmentSlice';
import { selectLanguage } from '../../../../store/languageSlice';
import { getIconByCode } from '../../../../utilFunctions/Vitals';
import CustomeModal from '../../../Modal/CustomeModal';
import ModalChart from './ModalChart';

const VitalChartModal = ({ vital, height = 120 }) => {
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);

  const handleOnClose = () => {
    dispatch(updateSelectVital(null));
  };

  return (
    <CustomeModal
      // title={t.Error}
      title="Systolic"
      isOpen={true}
      onCancel={handleOnClose}
      icon={
        <img style={{ height: '18px' }} src={getIconByCode('bmi')} alt="icon" />
      }
      width={'md'}
      hideFooter
    >
      <ModalChart />
    </CustomeModal>
  );
};

export default VitalChartModal;
