import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchDropdown from './SearchDropdown';
import { bookAppointmentActions, searchbarActions } from '../../../store';

import { selectLanguage } from '../../../store/languageSlice';
import BadgesList from './BadgesList';
import { addBadge, removeBadge } from '../../../store/badgesSlice';
import MultiLevelDropdown from '../MultiDropdown';
import { getTValue } from '../../../translations/common';
import './style.css';
import ClincSymptonSlider from './ClincSymptonSlider';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';
import { updateSelectedAppontemnt } from '../../../store/appointmentSlice';

const Toolbar = ({ clinicsList, symptomsList, handleSearchDoctors }) => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = useState('Clinic');
  const { t, isRtl } = useSelector(selectLanguage);
  const [seleledOption, setSeleledOption] = useState('');
  const [filteredListing, setFilteredListing] = useState(clinicsList);
  const [searchText, setSearchText] = useState('');
  const [showClass, setShowClass] = useState(false);
  const [item, setItem] = useState({code:null,docCode:null});

  const getInputValueHandler = (value) => {
    setSearchText(value);
    filterListingHandler(value);
  };

  const onclickBadgeHandler = (item) => {
    const { textEng, textAr, code, docCode, type } = item;
    setSearchType(type);

    // setSeleledOption(textEng);
    dispatch(searchbarActions.selectedSpecialityCode(code));
    dispatch(searchbarActions.selectedDoctorCode(docCode));
    dispatch(bookAppointmentActions.selectedDocCode(null))
    // setFilteredListing(null);
    filterListingHandler(searchText);
  };

  const handleAddNewBadge = (desc, code, docCode, docName, symptomName) => {
    const newBadge = {
      textEng: docName ? docName : desc,
      textAr: docName ? docName : desc,
      code: code,
      docCode: docCode,
      type: searchType,
    };

    if (['Clinic', 'Symptoms'].includes(searchType)) {
      dispatch(addBadge(newBadge));
    }
  };

  const flatClinicList = (data)=> {
    const flatList = data.flatMap((clinic) => {
      if (clinic.isGroup && clinic.subClinic) {
        return clinic.subClinic;
      }
      return [clinic];
    });

    return flatList;
  }

  const formatClinicsList = (clinicsList) => {
    let formattedList = [];
  
    clinicsList.forEach(clinic => {
      // Add the parent clinic without isGroup and subClinic properties
      const { isGroup, subClinic, ...parentClinic } = clinic;
      formattedList.push(parentClinic);
  
      // Add sub-clinics with appended parent description and without isGroup and subClinic properties
      if (subClinic && subClinic.length > 0) {
        subClinic.forEach(sub => {
          formattedList.push({
            ...sub,
            specialityDescription: `${parentClinic.specialityDescription} - ${sub.specialityDescription}`,
            arabicSpecialityDescription: `${parentClinic.arabicSpecialityDescription} - ${sub.arabicSpecialityDescription}`
          });
        });
      }
    });
  }

  const filterListingHandler = (value) => {
    if (isRtl) {
      arabicFilterListingHandler(value);
    } else {
      engFilterListingHandler(value);
    }

    handleSearchDoctors && handleSearchDoctors(value, item)
  };

  const engFilterListingHandler = (value) => {
    if (value.length > 0) {
      const searchTerms = value.toLowerCase().split(' ').filter(term => term);
  
      const matchesSearchTerms = (text) => {
        return searchTerms.some(term => text.includes(term));
      };


      let filteredData;
  
      if (searchType === 'Clinic') {
        // let fullList = flatClinicList(clinicsList)
        // filteredData = fullList.filter((clinic) => {
        //   return matchesSearchTerms(clinic.specialityDescription.toLowerCase());
        // });
        const groupedData = clinicsList.reduce((acc, clinic) => {
          const parentMatch = matchesSearchTerms(clinic.specialityDescription.toLowerCase());
          const matchingSubClinics = clinic.subClinic?.filter(sub => matchesSearchTerms(sub.specialityDescription.toLowerCase())) || [];
  
          if (parentMatch) {
            acc.push({
              ...clinic,
              subClinic: clinic.subClinic
            });
          } else if (matchingSubClinics.length > 0) {
            acc.push({
              ...clinic,
              subClinic: matchingSubClinics
            });
          }
  
          return acc;
        }, []);
  
        filteredData = groupedData;
      } else if (searchType === 'Symptoms') {
        filteredData = symptomsList.filter((singleSymptom) => {
          return matchesSearchTerms(singleSymptom.symptom.toLowerCase());
        });

      } else {
        const allDoctorData = JSON.parse(localStorage.doctorsInfomation);
        filteredData = allDoctorData.filter((doctor) => {
          return matchesSearchTerms(doctor.docName.toLowerCase());
        });

      }

      setFilteredListing(filteredData);
    } else {
      if (searchType === 'Clinic') setFilteredListing(flatClinicList(clinicsList));
      if (searchType === 'Symptoms') setFilteredListing(symptomsList);
    }
  };
  

  const arabicFilterListingHandler = (value) => {
    if (value.length > 0) {
      const searchTerms = value.split(' ').filter(term => term);
  
      const matchesSearchTerms = (text) => {
        return searchTerms.some(term => text.includes(term));
      };
  
      let filteredData;
  
      if (searchType === 'Clinic') {
        filteredData = clinicsList.filter((clinic) => {
          return matchesSearchTerms(clinic.arabicSpecialityDescription);
        });

      } else if (searchType === 'Symptoms') {
        filteredData = symptomsList.filter((singleSymptom) => {
          return matchesSearchTerms(singleSymptom.symptomArabic);
        });

      } else {
        const allDoctorData = JSON.parse(localStorage.doctorsInfomation);
        filteredData = allDoctorData.filter((doctor) => {
          return matchesSearchTerms(doctor.docNameAR || '');
        });

      }
      setFilteredListing(filteredData);
    } else {
      if (searchType === 'Clinic') setFilteredListing(clinicsList);
      if (searchType === 'Symptoms') setFilteredListing(symptomsList);
    }
  };

  const onFocusDropdwon = () => {
    let fullList = [];
    if (searchType === 'Clinic') {
      fullList = clinicsList;
    } else if (searchType === 'Symptoms') {
      fullList = symptomsList;
    } else {
      const allDoctorData = JSON.parse(localStorage.doctorsInfomation);
      fullList = allDoctorData;
    }

    setFilteredListing(fullList);
    setShowClass(!showClass);
  };

  const optionSelectHandler = (desc, code, docCode, docName, symptomName) => {
    setSeleledOption(docName ? docName : desc);
    setItem({code: code, docCode:null})
    dispatch(bookAppointmentActions.clearDocCard())
    dispatch(searchbarActions.updateSearchText(''));
    dispatch(searchbarActions.selectedSpecialityCode(code));
    if(searchType === 'Clinic') {
      dispatch(searchbarActions.selectedDoctorCode(docCode));
      setItem({code: code, docCode:docCode})
    }else {
      dispatch(searchbarActions.selectedDoctorCode(null));
    }
    
    // setFilteredListing(null);
    handleAddNewBadge(desc, code, docCode, docName, symptomName);
    setShowClass(false);
  };

  const searchTypeSelectionHandler = (type) => {
    setSearchType(type);
    dispatch(searchbarActions.updateSelectedType(type));
    dispatch(removeBadge());
    setFilteredListing(null);
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(bookAppointmentActions.selectedDocCode(null))
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.updatePrimaryOrLastVisited(null));
    setSearchText('');
    if (type === 'Clinic') setFilteredListing(clinicsList);
    if (type === 'Symptoms') setFilteredListing(symptomsList);
  };

  const onOverlayClickHandler = () => {
    setSeleledOption('overlay');
    setShowClass(false)
    setTimeout(() => {
      setSeleledOption('');
    }, 1000);
  };

  const sortClinicListAlphabatically = (list) => {
    const listCopy = [...list];

    if(isRtl) {
      return listCopy?.sort((a, b) => {
        return a?.arabicSpecialityDescription?.localeCompare(b.arabicSpecialityDescription, 'ar', { sensitivity: 'base' });
      });
    }
    else {
      return listCopy?.sort((a, b) => {
        return a?.specialityDescription?.localeCompare(b.specialityDescription, 'en', { sensitivity: 'base' });
      });
    }
    
  };

  const sortSymptonsListAlphabatically = (list ) => {
    const listCopy = [...list];
    if(isRtl) {

      return listCopy?.sort((a, b) => {
        return a?.symptomArabic?.localeCompare(b.symptomArabic, 'ar', { sensitivity: 'base' });
      });
    }
    else {
      return listCopy?.sort((a, b) => {
        return a?.symptom?.localeCompare(b.symptom, 'en', { sensitivity: 'base' });
      });
    }
    
  };

  useEffect(() => {
    clinicsList && setFilteredListing(sortClinicListAlphabatically(clinicsList));
  }, [clinicsList]);

  // useEffect(() => {
  //   handleSearchDoctors(searchText, item);
  // }, [specialityCode]);

  return (
    <>
      {showClass && filteredListing && filteredListing.length > 0 && (
        <div className="dropdownOverlay" onClick={onOverlayClickHandler}></div>
      )}
      <div className="relative">
        <div className="ba-toolbar flex flex-wrap items-center gap-4 relative">
          <div className="searchAndBadges">
            <SearchDropdown
              getInputValueHandler={getInputValueHandler}
              searchType={searchType}
              onFocus={onFocusDropdwon}
            />
            <BadgesList onRemoveItem={()=>{
              setItem({code:null,docCode:null})
              dispatch(updateSelectedAppontemnt(null));
              dispatch(searchbarActions.selectedSpecialityCode(null));
              dispatch(searchbarActions.selectedDoctorCode(null));
              handleSearchDoctors('', {code:null,docCode:null})
              if (searchType === 'Clinic') setFilteredListing(clinicsList);
              if (searchType === 'Symptoms') setFilteredListing(symptomsList);
              }}
              onClickItem={onclickBadgeHandler} />
          </div>

          <label className={searchType === 'Clinic' ? "checked customToggle" : "customToggle"} htmlFor="searchTypeTgle">
            <input
              id='searchTypeTgle'
              type="checkbox"
              checked={searchType === 'Clinic' ? true : false}
              onChange={()=> searchTypeSelectionHandler(searchType === 'Clinic' ? 'Symptoms' : 'Clinic')}
              className="sr-only"
            />
            <span className="text">{searchType === 'Clinic' ? t.Clinic : t.Symptoms}</span>
            <span className="circle"></span>
          </label>
          {/* <button
            className={searchType === 'Clinic' ? 'active' : ''}
            onClick={() => searchTypeSelectionHandler('Clinic')}
          >
            {t.Clinic}
          </button>
          <button
            className={searchType === 'Symptoms' ? 'active' : ''}
            onClick={() => searchTypeSelectionHandler('Symptoms')}
          >
            {t.Symptoms}
          </button> */}
          {/* <Datepicker /> */}
        </div>

        {showClass  && (
          <>
            <MultiLevelDropdown
             hideDropdown={()=> setShowClass(false)}
              searchTerm={searchText}
              filteredClinics={searchType === 'Clinic' ? sortClinicListAlphabatically(filteredListing) : null}
              filteredSymptons={
                searchType === 'Symptoms' ? sortSymptonsListAlphabatically(filteredListing) : null
              }
              filteredDoctors={searchType === null ? filteredListing : null}
              onItemClick={(item) => {
                if (searchType === 'Clinic') {
                  optionSelectHandler(
                    getTValue(
                      item,
                      'arabicSpecialityDescription',
                      'specialityDescription',
                      isRtl
                    ),
                    item.specialityCode
                  );
                }
                if (searchType === 'Symptoms') {
                  optionSelectHandler(
                    getTValue(item, 'symptomArabic', 'symptom', isRtl),
                    item.clinicCode
                  );
                }

                if (searchType === null) {
                  optionSelectHandler(
                    getTValue(
                      item,
                      'specialityDescriptionAR',
                      'specialityDescription',
                      isRtl
                    ),
                    item.specialityCode,
                    item.docCode,
                    getTValue(item, 'docNameAR', 'docName', isRtl)
                  );
                }
              }}
            />
          </>
        )}
      </div>
      {/* <BadgesList onRemoveItem={()=>{
        setItem({code:null,docCode:null})
        handleSearchDoctors(searchText, {code:null,docCode:null})
        if (searchType === 'Clinic') setFilteredListing(clinicsList);
        if (searchType === 'Symptoms') setFilteredListing(symptomsList);
        }}
         onClickItem={onclickBadgeHandler} /> */}
      {filteredListing && (
        <ClincSymptonSlider
          onClickItem={(item)=>{
            if (searchType === 'Clinic') {
              optionSelectHandler(
                getTValue(
                  item,
                  'arabicSpecialityDescription',
                  'specialityDescription',
                  isRtl
                ),
                item.specialityCode
              );
            }
            if (searchType === 'Symptoms') {
              optionSelectHandler(
                getTValue(item, 'symptomArabic', 'symptom', isRtl),
                item.clinicCode
              );
            }
          }}
          list={searchType === 'Clinic'  ? sortClinicListAlphabatically(filteredListing):sortSymptonsListAlphabatically(filteredListing)}
          // list={filteredListing}
          handleSearchDoctors={handleSearchDoctors}
          type={searchType}
          isRtl={isRtl}
        />
      )}
    </>
  );
};

export default Toolbar;
