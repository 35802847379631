import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import DatesBar from '../components/Medication/DatesBar';
import { selectLanguage } from '../store/languageSlice';
import '../components/Medication/style.css';
import { useLoaderData } from 'react-router-dom';
import { baseFetch, envVariables } from '../config';
import {
  getDateDay,
  getDay,
  getDayAr,
  getFullMonth,
  getFullMonthAr,
} from '../utilFunctions/validations/dateMonthShow';
import { useSelector } from 'react-redux';
import store from '../store';
import { getSelectLanguage } from '../utilFunctions/getCurrentSelectLanguage';
import useDataFetching from '../utilFunctions/useFetchData';
import Loader from '../components/UI/Loader';
import { sessionsMiddleWare } from '../utilFunctions/SessionExpire';

const preprocessData = (medicationList) => {
  const groupedData = {};

  medicationList.forEach((item) => {
    const date = new Date(item.order_start_date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-based index
    const day = date.getDate();

    if (!groupedData[year]) {
      groupedData[year] = {};
    }

    if (!groupedData[year][month]) {
      groupedData[year][month] = {};
    }

    if (!groupedData[year][month][day]) {
      groupedData[year][month][day] = [];
    }

    groupedData[year][month][day].push(item);
  });

  return groupedData;
};

const medicationLoader = async (page = 1) => {
  try {
    let userData = JSON.parse(localStorage.getItem('userInfo'));

    const endpoint = `api/health/patient/medication?patientId=${userData?.patientId}&mrn=${userData?.mrn}&page=${page}`;

    const data = await baseFetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
          }`,
        "Accept-Language": getSelectLanguage(),
      },
    });

    return data;
  } catch (error) {
    console.error('Error on Medication', error);
    throw error;
  }
};

const Medication = () => {
  const listInnerRef = useRef();
  const { t, isRtl } = useSelector(selectLanguage);
  const [isYearList, setYearList] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadData = async (pageCount) => {
    if (!hasMore) return;
    setLoading(true);
    try {
      const data = await medicationLoader(pageCount);
      if (!data || !data.medication_list || data.medication_list.length === 0) {
        setHasMore(false);
      } else {
        setMedicationData((prevData) => [...prevData, ...data.medication_list]);
        const uniqueYears = new Set([...isYearList]);
        data.medication_list.forEach((medication) => {
          const medicationDate = new Date(medication.order_start_date);
          const year = medicationDate.getFullYear();
          uniqueYears.add(year);
        });
        const yearArray = Array.from(uniqueYears);
        const sortedYearsDescending = yearArray?.sort((a, b) => b - a);
        setYearList(sortedYearsDescending);
        if(sortedYearsDescending?.length>0) {
          setSelectedYear(sortedYearsDescending[0]);
        }
        // if (!selectedYear) setSelectedYear(yearArray[0]);

        setTimeout(()=>{
           setPage(pageCount+1);
        },1000)
      }
    } catch (error) {
      setHasMore(false);
      console.error('Error loading data', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(!loading) {
      loadData(page);
    }
  }, [loading, page]);

  // useEffect(() => {
  //   if(isYearList?.length>0 && !hasMore) {
  //     const lastYearIndex = isYearList?.length -1
  //     setSelectedYear(isYearList[lastYearIndex]);
  //   }
  // }, [isYearList, hasMore]);

  const onScroll = () => {
    if (listInnerRef.current && !loading) {

      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const threshold = 50;
      if (
        scrollTop + clientHeight >= scrollHeight - threshold &&
        hasMore
      ) {
        const count = page + 1;
        setPage(count)
        loadData(count);
      }
    }
  }


  const handleChangeYear = (yearVal) => {
    setSelectedYear(yearVal);
  };

  const filteredData = useMemo(() => {
    if (medicationData.length > 0 && selectedYear) {
      return medicationData
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => {
          // Sort by order start date in descending order
          const dateA = new Date(a.order_start_date);
          const dateB = new Date(b.order_start_date);
          return dateB - dateA;
        })
        .filter((medication) => {
          const medicationDate = new Date(medication.order_start_date);
          const year = medicationDate.getFullYear();
          return year === selectedYear;
        });
    } else {
      return [];
    }
  }, [medicationData, selectedYear]);

  // Sort filteredData by order_start_time in descending order
  const sortedData = [...filteredData].sort((a, b) => {
    const timeA = new Date(`${a.order_start_date}T${a.order_start_time}`);
    const timeB = new Date(`${b.order_start_date}T${b.order_start_time}`);
    return timeB - timeA;
  });

  // Remove duplicate ids from sortedData
  const uniqueSortedData = sortedData.filter(
    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  );

  const groupedData = preprocessData(uniqueSortedData);
  const groupedYearData = groupedData[selectedYear];


  return (
    <div className="medPge" 
      // onScroll={onScroll}
      // ref={listInnerRef}
      // style={{ 
      //    height: `calc(100vh - 190px)`,
      //    overflowY: 'auto' 
      //   }}
     >
         <DatesBar
           view={2}
          //  yearsData={[...isYearList].reverse()}
           yearsData={isYearList}
           selectYear={selectedYear}
           handleClickYear={handleChangeYear}
           showLoader={loading && hasMore}
         />
      <div className='flex justify-center items-center gap-4'>
        <img src={`${process.env.PUBLIC_URL}/images/icons/medication.png`} style={{width:'24px',height: '30px'}} alt={'secondOpinion'} />
        <h2 className="text-center text-24 font-medium mt-5 mb-4">
          {t.Timeline} <span className='text-18'>({t.Delivery})</span>
        </h2>
      </div>
      <div dir="ltr" className="medicList" >
        {groupedYearData && Object.keys(groupedYearData).reverse().map((monthKey, index) => {

           const currentMonthData = groupedYearData[monthKey];

           return Object.keys(currentMonthData).reverse().map((dayKey, i)=>{

             const todayMedicine = currentMonthData[dayKey];

             return <div key={index} className="sngMed">{todayMedicine.reverse().map((medication, index)=><>
             
             {index === 0 && <div className="date">
               {isRtl
                 ? getDayAr(medication.order_start_date)
                 : getDay(medication.order_start_date)}
               <span>{getDateDay(medication.order_start_date)}</span>
               <span>
                 {isRtl
                   ? getFullMonthAr(medication.order_start_date)
                   : getFullMonth(medication.order_start_date)
                  }
               </span>
             </div>}
             <div className='cardItem'>
             <div dir={isRtl ? 'rtl' : ''}>
               <div className="nameVrf mt-3">
                 <div className="nmeFrm">
                   <h3>{medication.medication_description}</h3>
                   <p>{medication.formula}</p>
                 </div>
                 <span
                   className={
                     medication.order_status === 'Verified'
                       ? 'vrfBdg bg-secondary'
                       : 'vrfBdg bg-tertiary'
                   }
                 >
                   {medication.order_status === 'Verified'
                     ? t.Verified
                     : t.NotVerified}
                 </span>
               </div>
               <div className="info flex flex-wrap gap-x-4 justify-between">
                 <div>
                   {t.Description} <span style={{width:'90px', textAlign:'start'}}>{medication.route}</span>
                 </div>
                 <div>
                   {t.NoOfdays} <span>{medication.duration}</span>
                 </div>
                 <div>
                   {t.Quantity} <span>{medication.quantity}</span>
                 </div>
                 {/* <div>
                   {t.DeliveryDate} <span>{medication.deliveryDate}</span>
                 </div>*/}
                 <div>
                   {t.DeliveryNumber} <span>{medication.prescription_number}</span>
                 </div> 
               </div>
               <div className="usage">
                 <h4>{t.HowtoUse}</h4>
                 <p>
                   {isRtl
                     ? medication.arabic_instruction
                     : medication.english_instruction}
                 </p>
               </div>
             </div>
             </div>
               </>)}</div>
           }
           )
        })}
      </div>
      {loading && <div className="w-full justify-center"><Loader roundLoader /></div>}
    </div>
  );
};

export { Medication, medicationLoader };
