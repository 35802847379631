import React, { useState, useEffect } from 'react';
import Loader from '../UI/Loader';

const MediaMessage = ({ message }) => {
  const [mediaUrl, setMediaUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [contentType, setContentType] = useState();

  useEffect(() => {
    const handleMediaUrl = async () => {
      setLoading(true);
      setContentType(message.media.contentType);
      const mediaUrl1 = await message.media.getContentTemporaryUrl();
      setMediaUrl(mediaUrl1);
      setLoading(false);
    };

    if (message?.media) {
      handleMediaUrl();
    }
  }, [message]);

  if (loading)
    return (
      <div>
        <Loader roundLoader size="small" />
      </div>
    );

  const isImage = contentType?.startsWith('image') ? true : false;

  return (
    <div className="mt-2">
      {isImage ? (
        <a href={mediaUrl} target="_blank">
          <img
            src={
              mediaUrl ?? `${process.env.PUBLIC_URL}/images/image-preview.png`
            }
            alt="uploadedImage"
            style={{ height: '100px' }}
            className="max-w-full h-auto rounded-lg"
          />
        </a>
      ) : (
        <a
          href={mediaUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {message.media?.filename}
        </a>
      )}
    </div>
  );
};

export default MediaMessage;
