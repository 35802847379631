import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { formatDate, getTValue } from '../../../translations/common';
import CollapsibleWrapper from '../CollapsibleWrapper';

import './completedAppointment.css';
import InvoiceCard from './invoices/InvoiceCard';
import Medications from './medications';
import PrescriptionItem from './prescriptions/PrescriptionItem';
import ReportItem from './reports/ReportItem';
import Vitals from './vitals';

const CompletedAppointment = ({ appointment }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  console.log('yy 11', { appointment });

  const { date } = appointment;
  const fDate = formatDate(date, isRtl);

  return (
    <div className="col-span-2 db-card mngapt apntsLstINfo grid max-[767px]:grid-cols-1 max-[1199px]:grid-cols-1 max-[1199px]:col-span-1 gap-2 grid-cols-2">
      <div className="flex flex-col gap-2 ">
        <div className="flex flex-col gap-2 px-4">
          <span className="titleDate">{`${fDate.monthDate} ${fDate.monthNameShort} ${fDate.year}`}</span>
          <span className="titleDoc">{getTValue(appointment,'arabicProviderDescription','careProviderDescription',isRtl)}</span>
        </div>

        {/* <CollapsibleWrapper title="Vitals">
          <VitalItem />
        </CollapsibleWrapper> */}
        <Vitals />
        <CollapsibleWrapper title="Prescriptions">
          <PrescriptionItem />
        </CollapsibleWrapper>
        <CollapsibleWrapper title="Reports">
          <ReportItem />
        </CollapsibleWrapper>
        <CollapsibleWrapper title="Invoices">
          <InvoiceCard />
        </CollapsibleWrapper>
      </div>
      <Medications />
      {/* <CollapsibleWrapper title="Medications">
        <MedicationItem />
      </CollapsibleWrapper> */}
    </div>
  );
};

export default CompletedAppointment;
