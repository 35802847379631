import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assessmentActions } from '../../store';
import { selectAssessment } from '../../store/assessmentSlice';
import { selectLanguage } from '../../store/languageSlice';
import { convertToArabicMonth } from '../../translations/common';
import Loader from '../UI/Loader';

// selectYear={selectedYear}
const DatesBar = ({
  view,
  list,
  handleClickMonth,
  yearsData,
  selectYear,
  handleClickYear,
  selectedYear,
  setselectedYear,
  handleChangeYear,
  selecetdMonth,
  showLoader,
}) => {
  // const [isData, setData] = useState([]);
  const dispatch = useDispatch();
  const { years: isData } = useSelector(selectAssessment);
  const { t, isRtl } = useSelector(selectLanguage);
  const [YearsList, setYearsList] = useState([]);

  useEffect(() => {
    if (list && list.length > 0) {
      // setData(list);
      // setselectedYear(list[0].year);
      dispatch(assessmentActions.updateYearsList(list));
      dispatch(assessmentActions.setselectedYear(list[0].year));
      // handleChangeYear({target:{value: list[0].year}})
    }
  }, [list]);

  useEffect(() => {
    if (yearsData && yearsData.length > 0) {
      setYearsList(yearsData);
    }
  }, [yearsData]);

  return (
    <>
      {view && Number(view) === 1 ? (
        <section className="flex justify-between mb-5">
          <div className="dates flex flex-row-reverse justify-center flex-wrap gap-x-4">
            {[...isData]?.reverse().map((yearwiseData, index) => {
              if (Number(selectedYear) === Number(yearwiseData.year)) {
                const selectedYearMonths = [...yearwiseData.months, 'All'];
                return selectedYearMonths?.map((monthData, monthIndex) => {
                  return (
                    <button
                    style={{width:'auto', minWidth: '80px'}}
                      onClick={() => {
                        if (monthData === 'All') {
                          handleChangeYear(selectedYear);
                          handleChangeYear(selectedYear);
                        } else {
                          handleClickMonth(yearwiseData.year, monthData);
                        }
                      }}
                      key={monthIndex}
                      className={monthData === selecetdMonth ? 'button-active' : null}
                    >
                      {monthData === 'All'
                        ? t.All
                        : convertToArabicMonth(monthData, isRtl)}
                    </button>
                  );
                });
              }
            })}
          </div>
        </section>
      ) : view && Number(view) === 2 ? (
        <div className="dates flex flex-wrap justify-center gap-x-3">
          {YearsList.map((yearwiseData, index) => {
            return (
              <button
                onClick={() => handleClickYear(yearwiseData)}
                key={yearwiseData}
                className={selectYear === yearwiseData ? 'button-active' : null}
              >
                {yearwiseData}
              </button>
            );
          })}
          {showLoader && <div className="flex justify-center"><Loader roundLoader size='small' /></div>}
        </div>
      ) : null}
    </>
  );
};

export default DatesBar;
