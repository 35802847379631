import { createSlice } from '@reduxjs/toolkit';

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    years: [],
    selectedYear: null,
  },
  reducers: {
    updateYearsList(state, action) {
      state.years = [...action.payload];
    },
    setselectedYear(state, action) {
      state.selectedYear = action.payload;
    },
  },
});

export const selectAssessment = (state) => state.assessment;


export default assessmentSlice;
