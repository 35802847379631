import React, { useEffect, useRef, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { FaXmark, FaCheck } from 'react-icons/fa6';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';

const InputField = ({
  type = 'text',
  onChange,
  placeholder,
  editable = false,
  name,
  label,
  value,
  disabled = false,
  onCheckClick,
  onCrossClick,
}) => {
  const inputRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState();
  const [oldValue, setOldValue] = useState();

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    onChange && onChange(e);
  };

  useClickOutside(inputRef, () => {
    setCurrentValue(oldValue);
    setFocused(false);
  });

  const handleFocusClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOnCrossClick = () => {
    setCurrentValue(oldValue);
    onCrossClick && onCrossClick();
    setFocused(false);
  };

  const handleOnCheckClick = () => {
    onCheckClick && onCheckClick();
  };

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
      !oldValue &&setOldValue(value);
    }
  }, [value]);

  return (
    <div className="w-full mb-4">
      <label
        htmlFor="mrNumber"
        className="block mb-1 font-bold text-sm text-gray-600"
      >
        {label && label}
      </label>
      <div className="relative">
        <input
          ref={inputRef}
          type={type}
          id={name}
          name={name}
          disabled={disabled}
          defaultValue={currentValue}
          onChange={handleChange}
          // ${disabled ? "border-gray-300":"border-gray-500"}
          className={`w-full border-b-2 py-1.5 px-3 border-gray-500 focus:border-blue-500 bg-zinc-100`}
          placeholder={placeholder && placeholder}
        />
        {editable && (
          <>

              <FiEdit
                onClick={handleFocusClick}
                style={{ right: '0px' , zIndex:0}}
                className="absolute top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              />
          </>
        )}
      </div>
    </div>
  );
};

export default InputField;
