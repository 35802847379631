import React from "react";
import { useSelector } from "react-redux";
import AssessmentYearsDropdown from "../../AssessmentReport/AssessmentYearsDropdown";
import { assessemnetLoader } from "../../../pages/AssessmentReport";
import AssessmentsListDb from "./AssesmentsListDb";
import Loader from "../../UI/Loader";
import useDataFetching from "../../../utilFunctions/useFetchData";
import { selectLanguage } from "../../../store/languageSlice";

const Assesments = (props) => {
    const { t, isRtl } = useSelector(selectLanguage);
    const {
        data,
        loading,
        error,
      } = useDataFetching(assessemnetLoader);

    return (
        <div className="db-card asmntRpt max-[767px]:col-span-1 max-[1199px]:col-span-2">
            <div className="flex flex-wrap justify-between card-head">
                <h3 className="!m-0">{t.AssessmentReport}</h3>
                <AssessmentYearsDropdown />
            </div>
            {data && <AssessmentsListDb assessementData={data}/>}
            {
              loading && <div className="w-full justify-center"><Loader roundLoader/> </div>
            }
            {
                !loading &&  (!data?.promisFormAssessmentList || data?.promisFormAssessmentList?.length ==0) && <div className="w-full flex justify-center">
                    <span className="mt-8">{t.NoRecord}</span>
                 </div>
            }
        </div>
    )
};

export default Assesments;