import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions, portalActions } from '../../../store';
import { IoWarning } from 'react-icons/io5';
import { selectLanguage } from '../../../store/languageSlice';
import { getTValue } from '../../../translations/common';

export default function WarnigSwitchModal() {
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const { selectedDependent } = useSelector((state) => state.portal);

  const handleSwitchBackClick = () => {
    let previousUserInfo = JSON.parse(localStorage.getItem('previousUserInfo'));
    localStorage.setItem('userInfo', JSON.stringify(previousUserInfo));
    localStorage.removeItem('previousUserInfo');

    setTimeout(() => {
      dispatch(modalActions.setSwitchPatient(false));
    }, 1000);

    window.location.reload();
  };

  const fullName = `${getTValue(
    selectedDependent,
    'arabic_first_name',
    'firstName',
    isRtl
  )}  ${getTValue(selectedDependent, 'arabic_last_name', 'lastName', isRtl)}`;

  return (
    <div
      dir={isRtl ? 'rtl' : 'ltr'}
      className="w-full flex items-center justify-between border-0 p-2 rounded-lg shadow-lg relative bg-white outline-none focus:outline-none"
    >
      <div className="flex items-center gap-4 ">
        <IoWarning className="w-7 h-7 text-yellow-500" />
        <h3 className="flex text-gray-700 gap-4 ">
          <div className="font-bold text-gray-700">{t.Note}</div>
          {`${t.Yourareviewing} ${fullName}`}
        </h3>
      </div>
      <button
        className="bg-[#1da1f2] text-white py-1 px-5 border-[#1da1f2] border rounded"
        onClick={handleSwitchBackClick}
      >
        {t.SwitchBack}
      </button>
    </div>
  );
}
