import React from "react";
import { useSelector } from "react-redux";

import { envVariables } from "../../config";
import { selectLanguage } from "../../store/languageSlice";

const SingleAppointment = ({appointment, index}) => {
    const {t, isRtl} = useSelector(selectLanguage);
    const { assetsRoute } = envVariables;
    const hospCode = useSelector(state => state.hospitalInfo.hospCode);
    const locale = isRtl ? 'ar':'en-US';
    
    return (
        <li>
            <span className="status" style={{"--status-color": appointment.bookingStatus === 4 ? "var(--color-secondary)" : appointment.bookingStatus === 3 ? "var(--color-tertiary)" : "#414D55"}}>{appointment.bookingStatus === 4 ? t["Completed"] : appointment.bookingStatus === 3 ? t["Cancelled"] : t["No_Show"] }</span>
            <div className="dateTime">
                <div className="date">
                    {appointment.date.getDate()}
                    <span>{appointment.date.toLocaleString(locale, { month: 'short' })}</span>
                </div>
                <div dir="ltr" className="time">
                    {appointment.date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[0]} : {appointment.date.getMinutes().toString().padStart(2, '0')}
                    <span>{appointment.date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[1]}</span>
                </div>
            </div>
            <div className="docInfo">
                <div className="info">
                    <h5>{isRtl ? appointment.arabicProviderDescription :  appointment.careProviderDescription}</h5>
                    <p>{isRtl ? appointment.arabicServiceDescription : appointment.serviceDescription}</p>
                </div>
                {
                    appointment.docCode ? 
                    <img src={`${assetsRoute}${hospCode}/${appointment.docCode}.jpg`}  onError={(e)=>{ if (e.target.src !== `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`){e.target.src=`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;}}} alt={appointment.careProviderDescription} />
                    :
                    <img src={`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`} alt={appointment.careProviderDescription} />
                }
            </div>
        </li>
    )
};

export default SingleAppointment;