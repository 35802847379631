import moment from 'moment';

function getLanguagesInText(text) {
  if (!text) return [];

  const LANGUAGES = [
    {
      nameEn: 'English',
      nameAr: 'اللغة الإنجليزية',
      flag: 'ukFlg.svg',
    },
    {
      nameEn: 'Arabic language',
      nameAr: 'العربية',
      flag: 'saFlg.svg',
    },
    {
      nameEn: 'Arabic',
      nameAr: 'العربية',
      flag: 'saFlg.svg',
    },
    {
      nameEn: 'French',
      nameAr: 'اللغة الفرنسية',
      flag: 'frFlg.png',
    },
    {
      nameEn: 'German',
      nameAr: 'ألمانية',
      flag: 'grmnFlg.png',
    },
  ];

  return LANGUAGES.filter((language) => {
    return text.includes(language.nameEn);
  });
}

function formatToTwoDecimalPlaces(value) {
  // Check if the value is a valid number
  const number = parseFloat(value);

  if (isNaN(number)) {
    return { value: value, isString: true }; // or return the original value, or handle the error as needed
  }

  return { value: number.toFixed(2), isString: false };
}

{
  /* <ul><li class="ql-direction-rtl ql-align-right"><span class="ql-size-large">الإنجليزية</span></li><li class="ql-direction-rtl ql-align-right"><span class="ql-size-large">عربى</span></li></ul> */
}
export { getLanguagesInText, formatToTwoDecimalPlaces };
