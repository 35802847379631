import React, { useEffect, useState } from 'react';
import SingleAssessment from './SingleAssessment';
import './AssessmentsList.css';
import DatesBar from '../Medication/DatesBar';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../store/languageSlice';
import { selectAssessment } from '../../store/assessmentSlice';
import { assessmentActions } from '../../store';


const AssessmentsList = (props) => {
  const {assessementData} = props;
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const { selectedYear } = useSelector(selectAssessment);
  const locale = isRtl ? 'ar' : 'en-US';
  const [sortedData, setSortedData] = useState([]);
  const [data, setData] = useState(null);
  const [monthYearWiseData, setMonthYearWiseData] = useState([]);
  const [isDefaultYear, setDefaultYear] = useState(null);
  const [currentSelecetdMonth, setCurrentSelecetdMonth] = useState('All');
  // const [selectedYear, setselectedYear] = useState(null);

  useEffect(() => {
    if (
      assessementData &&
      assessementData.promisFormAssessmentList.length > 0
    ) {
      // Process assessment data
      const assessmentsByYearMonth = {};
      const yearsSet = new Set();
      const monthsSet = new Set();
      assessementData.promisFormAssessmentList.forEach((assessment) => {
        // Extract year and month from assessment date
        let dateObj = new Date(assessment.updateDateTime);
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1; // getMonth() returns zero-based month

        // Add year and month to the sets
        yearsSet.add(year);
        monthsSet.add(month);

        // Group assessments by year and month
        if (!assessmentsByYearMonth[year]) {
          assessmentsByYearMonth[year] = {};
        }
        if (!assessmentsByYearMonth[year][month]) {
          assessmentsByYearMonth[year][month] = [];
        }
        assessmentsByYearMonth[year][month].push(assessment);
      });

      // Convert sets to arrays and sort years in descending order
      const years = Array.from(yearsSet).sort((a, b) => b - a);

      // Process data for display
      const monthsData = [];
      years.forEach((year, index) => {
        const yearData = assessmentsByYearMonth[year];
        const sortedMonths = Object.keys(yearData).sort((a, b) => b - a);

        // Check if there are any months present for the year
        if (sortedMonths.length > 0) {
          const monthNames = sortedMonths.map((month) => {
            return new Date(year, month - 1, 1).toLocaleString("default", {
              month: "long",
            });
          });

          // Store data for each year
          const yearEntry = {
            year: year,
            months: monthNames,
          };
          monthsData.push(yearEntry);

          // Select default first year and first month
          if (index === 0) {
            setDefaultYear(year);
            // setDefaultMonth(sortedMonths[0]);
            setCurrentSelecetdMonth('All');
          }
        }
      });

      // Update state variables
      setData(assessmentsByYearMonth);
      setMonthYearWiseData(monthsData);
      setSortedData(years);
    }
  }, [assessementData]);


  const handleClickMonth = (selectedYear, selectedMonth) => {
    setCurrentSelecetdMonth(selectedMonth);

    if (
      assessementData &&
      assessementData.promisFormAssessmentList.length > 0
    ) {
      // Filter assessments from the existing data
      const filteredAssessments =
        assessementData.promisFormAssessmentList.filter((assessment) => {
          const dateObj = new Date(assessment.updateDateTime);
          const year = dateObj.getFullYear();
          const options = { month: 'long' };
          const month = dateObj.toLocaleString('en-US', options);

          return (
            Number(selectedYear) === Number(year) && selectedMonth === month
          );
        });

      if (filteredAssessments && filteredAssessments.length > 0) {
        // Process assessment data
        const assessmentsByYearMonth = {};
        let years = [];
        let months = [];
        filteredAssessments.forEach((assessment) => {
          // Extract year and month from assessment date
          let dateObj = new Date(assessment.updateDateTime);
          const year = dateObj.getFullYear();
          const month = dateObj.getMonth() + 1; // getMonth() returns zero-based month
          years.push(year);
          months.push(month);

          // Group assessments by year and month
          if (!assessmentsByYearMonth[year]) {
            assessmentsByYearMonth[year] = {};
          }
          if (!assessmentsByYearMonth[year][month]) {
            assessmentsByYearMonth[year][month] = [];
          }
          assessmentsByYearMonth[year][month].push(assessment);
        });

        const sortedYears = Object.keys(assessmentsByYearMonth).sort(
          (a, b) => b - a,
        );
        setSortedData(sortedYears);
        setData(assessmentsByYearMonth);
      }

      // Update state with the filtered assessments
      // setData(filteredAssessments);
    }
  };

  const handleChangeYear = (yearValue) => {
    // let yearValue = event.target.value;
    // setselectedYear(yearValue);
    dispatch(assessmentActions.setselectedYear(yearValue));
    setCurrentSelecetdMonth('All')
    if (
      assessementData &&
      assessementData.promisFormAssessmentList.length > 0
    ) {
      // Filter assessments from the existing data
      const filteredAssessments =
        assessementData.promisFormAssessmentList.filter((assessment) => {
          const dateObj = new Date(assessment.updateDateTime);
          const year = dateObj.getFullYear();


          return (
            Number(yearValue) === Number(year)
          );
        });

      if (filteredAssessments && filteredAssessments.length > 0) {
        // Process assessment data
        const assessmentsByYearMonth = {};
        let years = [];
        let months = [];
        filteredAssessments.forEach((assessment) => {
          // Extract year and month from assessment date
          let dateObj = new Date(assessment.updateDateTime);
          const year = dateObj.getFullYear();
          const month = dateObj.getMonth() + 1; // getMonth() returns zero-based month
          years.push(year);
          months.push(month);

          // Group assessments by year and month
          if (!assessmentsByYearMonth[year]) {
            assessmentsByYearMonth[year] = {};
          }
          if (!assessmentsByYearMonth[year][month]) {
            assessmentsByYearMonth[year][month] = [];
          }
          assessmentsByYearMonth[year][month].push(assessment);
        });
        const sortedYears = Object.keys(assessmentsByYearMonth).sort(
          (a, b) => b - a,
        );
        setSortedData(sortedYears);
        setData(assessmentsByYearMonth);
      }

      // Update state with the filtered assessments
      // setData(filteredAssessments);
    }
  }

  useEffect(()=>{
    handleChangeYear(selectedYear)
  },[selectedYear, assessementData])
 
  return (
    <>
    <DatesBar
        view={1}
        list={monthYearWiseData}
        handleClickMonth={handleClickMonth}
        selectedYear={selectedYear}
        // setselectedYear={setselectedYear}
        selecetdMonth={currentSelecetdMonth}
        handleChangeYear={handleChangeYear}
      />
    <div className='apntsLstSqr'>
    <ul>
      {sortedData.map((year, index) => {
        return (
          <>
            {Object.keys(data[year])
              .sort((a, b) => b - a)
              .map((month) => (                  
                <>
                    {data[year][month].map((assessment, index) => (
                      <SingleAssessment
                        key={index}
                        assessment={assessment}
                        index={index}
                        t={t}
                        isRtl={isRtl}
                      />
                    ))}                  
                    </>
            ))}          
            </>
        );
      })}
      </ul>
    </div>
    </>
  );
};

export default AssessmentsList;
