import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../store/languageSlice';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './ReportsTypeSlider.css';

const ReportsTypeSlider = ({ type, onTypeChange }) => {
  const [isHovered, setIsHovered] = useState([]);
  const sliderRef = useRef(null);
  const {t, isRtl} = useSelector(selectLanguage);
  const toggleHover = (indexId) => {
    if (isHovered.includes(indexId)) {
      setIsHovered([]);
    } else {
      setIsHovered([indexId]);
    }
  };

  const handleOnClick = (type) => {
    onTypeChange && onTypeChange(type);
  };

  const reportsType = [
    {
      name: t.LabReports,
      type: 'laboratory-results',
      icon: 'ic_lab_report.svg',
      iconHov: 'ic_lab_report_hov.svg',
    },
    {
      name: t.RadiologyReport,
      type: 'radiology-results',
      icon: 'ic_radiology_report.svg',
      iconHov: 'ic_radiology_report_hov.svg',
    },
    {
      name: t.OperativeReport,
      type: 'operative-reports',
      icon: 'ic_operative_report.svg',
      iconHov: 'ic_operative_report_hov.svg',
    },
    {
      name: t.CardiologyReport,
      type: 'cardiology-results',
      icon: 'ic_cardiology_report.svg',
      iconHov: 'ic_cardiology_report_hov.svg',
    },
    {
      name: t.DischargeReports,
      type: 'discharge-summary-reports',
      icon: 'ic_discharge_report.svg',
      iconHov: 'ic_discharge_report_hov.svg',
    },
    {
      name: t.SickReport,
      type: 'sick-leave-reports',
      icon: 'ic_sick_report.svg',
      iconHov: 'ic_sick_report_hov.svg',
    },
    {
      name: t.RequestReports,
      type: 'request-a-reports',
      icon: 'ic_request_report.svg',
      iconHov: 'ic_request_report_hov.svg',
    },
    // {
    //   name: t.SickReport,
    //   type: 'sick-leave-reports',
    //   icon: 'ic_sick_report.svg',
    //   iconHov: 'ic_sick_report_hov.svg',
    // },
    // {
    //   name: t.OperativeReport,
    //   type: 'operative-reports',
    //   icon: 'ic_operative_report.svg',
    //   iconHov: 'ic_operative_report_hov.svg',
    // },
  ];

  const settings = {
    swipeToSlide: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: !isRtl, // if rtl don't show variable width
    initialSlide:  0,
    rtl: isRtl,
    // nextArrow: <SlickNextArrow />,
    // prevArrow: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(isRtl ? 2: 0);
    }
  }, [isRtl]);

  return (
    <Slider ref={sliderRef}  className="rprtsSld" {...settings}>
      {reportsType.map((reportType, index) => (
        <div key={index}>
          <button
            onMouseEnter={() => toggleHover(index)}
            onMouseLeave={() => toggleHover(index)}
            onClick={() => handleOnClick(reportType.type)}
            className={type == reportType.type ? 'button-active' : ''}
          >
            <img
              alt={reportType.name}
              className="svg"
              src={`${process.env.PUBLIC_URL}/images/icons/${
                (isHovered.includes(index) || type == reportType.type)
                  ? reportType.iconHov
                  : reportType.icon
              }`}
            />
            {reportType.name}
          </button>
        </div>
      ))}
    </Slider>
  );
};

export default ReportsTypeSlider;
