import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import TimeSlotSlider from './TimeSlotSlider';
import useInView from '../../../utilFunctions/useInView';
import { getDoctorPersonalInfo } from '../../../utilFunctions/bookAppointment/getDoctorPersonalInfo';
import { bookAppointmentActions, portalActions } from '../../../store';
import { HiOutlineVideoCamera } from 'react-icons/hi';

import './DoctorCard.css';

import { envVariables } from '../../../config';
import { selectLanguage } from '../../../store/languageSlice';
import { getTValue } from '../../../translations/common';
import MonthsDropdown from '../MonthsDropdown/MonthsDropdown';
import DatesSlider from './DatesSlider';
import { getLanguagesInText } from '../../../utilFunctions/common';
import moment from 'moment';

const DoctorCard = ({
  doctorInfo,
  docExtraDetails,
  getDocEarliestSlotAndServices,
  getDoctorAvailablity,
  selectedCard,
  docCardClickHandler,
  availableDatesArray,
  cardIndex,
  rootElement,
}) => {
  const { assetsRoute } = envVariables;
  const dispatch = useDispatch();
  // const filterTypeValue = useSelector(state => state.searchbar.searchByClinic);
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  const selectedDoc = useSelector((state) => state.bookAppointment.docCode);
  const selectedDate = useSelector(
    (state) => state.bookAppointment.selectedDate
  );
  const appointmentType = useSelector(
    (state) => state.bookAppointment.appointmentType
  );
  const docsPersonalInfoList = useSelector(
    (state) => state.bookAppointment.docsPersonalInfoList
  );

  const { t, isRtl } = useSelector(selectLanguage);
  const [fadeIn, setFadeIn] = useState(false);
  const [servicesList, setServicesList] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [earliestSlot, setEarliestSlot] = useState(null);
  const [docServiceType, setDocServiceType] = useState('Offline');
  const [ref, isInView] = useInView({
    root: rootElement,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasBeenInView, setHasBeenInView] = useState(false);
  const [doctorPersonalInfo, setdoctorPersonalInfo] = useState(null);

  const cardClickHandler = async (e) => {
    // setServicesList(null);
    handleCardScrolling(e);
    if (selectedCard === doctorInfo.docCode) return;
    // setSelectedService('Offline')
    const currentDocExtraDetails = isFoundInExtraDetails();
    if (!currentDocExtraDetails) {
      try {
        const { docServicesListValue, earliestSlotReqValue } =
          await getDocEarliestSlotAndServices(
            doctorInfo?.specialityCode,
            doctorInfo?.docCode
          );
        setServicesList(docServicesListValue);
        setEarliestSlot(earliestSlotReqValue);
        docCardClickHandler(doctorInfo);
        dispatch(bookAppointmentActions.dateSelector(null));
        dispatch(bookAppointmentActions.docAvailableDates(null));
        dispatch(bookAppointmentActions.docName(doctorInfo.docName));
        dispatch(bookAppointmentActions.docNameAr(doctorInfo.docNameAR));
        dispatch(bookAppointmentActions.updateSelectedDocCard(doctorInfo));
        dispatch(bookAppointmentActions.selectedDocCode(doctorInfo.docCode));
        dispatch(
          bookAppointmentActions.selectedDocSpecialityCode(
            doctorInfo?.specialityCode
          )
        );
        dispatch(
          bookAppointmentActions.selectedClinicName(
            doctorInfo?.specialityDescription
          )
        );
      } catch (error) {
        console.error('Error in cardClickHandler:', error);
      }
    }
    else {
      setServicesList(currentDocExtraDetails.serviceList);
      docCardClickHandler(doctorInfo);
      dispatch(bookAppointmentActions.dateSelector(null));
      dispatch(bookAppointmentActions.docAvailableDates(null));
      dispatch(bookAppointmentActions.docName(doctorInfo.docName));
      dispatch(bookAppointmentActions.docNameAr(doctorInfo.docNameAR));
      dispatch(bookAppointmentActions.updateSelectedDocCard(doctorInfo));
      dispatch(bookAppointmentActions.selectedDocCode(doctorInfo.docCode));
      dispatch(
        bookAppointmentActions.selectedDocSpecialityCode(
          doctorInfo.specialityCode
        )
      );
      dispatch(
        bookAppointmentActions.selectedClinicName(
          doctorInfo.specialityDescription
        )
      );
    }
  };

  const autoScrollCardClick = async (docCode, noScroll) => {
    if (doctorInfo?.docCode !== docCode) return;
    !noScroll && handleAutoCardScrolling(docCode);
    const found = isFoundInExtraDetails();
    if (docExtraDetails || noScroll) {
      try {
        const { docServicesListValue, earliestSlotReqValue } =
          await getDocEarliestSlotAndServices(
            doctorInfo.specialityCode,
            doctorInfo.docCode
          );
        setServicesList(docServicesListValue);
        setEarliestSlot(earliestSlotReqValue);
        found ? docCardClickHandler(doctorInfo) : docCardClickHandler(null);
        dispatch(bookAppointmentActions.dateSelector(null));
        dispatch(bookAppointmentActions.docAvailableDates(null));
        dispatch(bookAppointmentActions.docName(doctorInfo.docName));
        dispatch(bookAppointmentActions.docNameAr(doctorInfo.docNameAR));
        dispatch(bookAppointmentActions.updateSelectedDocCard(doctorInfo));
        dispatch(bookAppointmentActions.selectedDocCode(doctorInfo.docCode));
        dispatch(
          bookAppointmentActions.selectedDocSpecialityCode(
            doctorInfo.specialityCode
          )
        );
        dispatch(
          bookAppointmentActions.selectedClinicName(
            doctorInfo.specialityDescription
          )
        );
      } catch (error) {
        console.error('Error in cardClickHandler:', error);
      }
    }
  };

  const handleCardScrolling = (e) => {
    const parentDiv = document.querySelector('.docsListSide');
    const cardElement = e.target;
    const offsetTop = cardElement.offsetTop - parentDiv.offsetTop;
    parentDiv.scrollTo({
      top: 0, // 25 = card padding + top margin
      behavior: 'smooth',
    });
  };

  // scroll doen to selelctedCard
  const handleAutoCardScrolling = (className) => {
    const parentDiv = document.querySelector('.docsListSide');
    const cardElement = parentDiv.querySelector(`.${className}`);
    if (cardElement) {
      const offsetTop = cardElement.offsetTop - parentDiv.offsetTop;
      parentDiv.scrollTo({
        top: 0, // 25 = card padding + top margin
        behavior: 'smooth',
      });
    }
  };

  const findDocService = (type) => {
    // type = 'Online' , 'Offline'
    const foundItem = docExtraDetails?.find(
      (x) => x.providerCode === doctorInfo.docCode
    );

    if (foundItem) {
      const foundTele = foundItem.serviceList?.find((x) => x.type === type);

      return foundTele;
    }

    return null;
  };

  const serviceClickHandler = async (e) => {
    e.stopPropagation();
    const servicecode = e.target.value;
    setSelectedService(servicecode);
    if (docExtraDetails) {
      docCardClickHandler(doctorInfo.docCode);
    }
  };

  const changeServiceTypeHandler = (e, type) => {
    e.stopPropagation();
    // dispatch(bookAppointmentActions.selectedAppointmentType(type))
    const currentFoundService = findDocService(type);
    // if(currentFoundService) {
    if (currentFoundService && appointmentType !== type) {
      setDocServiceType(type);
      setSelectedService(currentFoundService.serviceCode);
      dispatch(bookAppointmentActions.dateSelector(null));
      dispatch(bookAppointmentActions.docAvailableDates(null));
      // dispatch(bookAppointmentActions.dateSelector(null));
      // dispatch(bookAppointmentActions.updateSelectedMonthDates([]));
      dispatch(bookAppointmentActions.selectedDocCode(doctorInfo.docCode));
      dispatch(
        bookAppointmentActions.selectedDocSpecialityCode(
          doctorInfo.specialityCode
        )
      );
      dispatch(bookAppointmentActions.selectedAppointmentType(type));
    }
  };

  // const getDoctorAvailablity = async() => {
  //     try {
  //         const availableDates = await getDoctorAvailablity(doctorInfo.docCode, selectedService, doctorInfo.specialityCode);
  //     } catch (error) {
  //         console.error('Error in serviceClickHandler:', error);
  //     }
  // }

  useEffect(() => {
    if (servicesList && servicesList.length > 0) {
      setSelectedService(servicesList[0].serviceCode);
      setDocServiceType(servicesList[0].type)
    }
  }, [servicesList]);

  useEffect(() => {
    if (selectedService) {
      dispatch(bookAppointmentActions.selectedServiceCode(selectedService));
      dispatch(bookAppointmentActions.selectedAppointmentType(docServiceType));

      getDoctorAvailablity(
        doctorInfo.docCode,
        selectedService,
        doctorInfo.specialityCode
      );
    }
  }, [selectedService]);

  useEffect(() => {
    if (docExtraDetails) {
      const foundItem = docExtraDetails?.find(
        (x) => x.providerCode === doctorInfo.docCode
      );
      const telemedicine = foundItem?.serviceList?.some(
        (service) =>
          service.type === 'Online' &&
          service.serviceDescription === 'Consultation - Telemedicine'
      );

      // telemedicine ? setDocServiceType('Online') : setDocServiceType('Online');
    }
  }, [doctorInfo]);

  // "serviceList": [
  //     {
  //         "serviceCode": "CON002",
  //         "serviceDescription": "Consultation - Consultant",
  //         "arabicServiceDescription": "كشفية-استشاري",
  //         "type": "Offline"
  //     },
  //     {
  //         "serviceCode": "CON006",
  //         "serviceDescription": "Consultation - Consultant - Follow Up",
  //         "arabicServiceDescription": "متابعة الكشف-استشاري",
  //         "type": "Offline"
  //     },
  //     {
  //         "serviceCode": "VIPCNOS",
  //         "serviceDescription": "VIP Consultation",
  //         "arabicServiceDescription": "",
  //         "type": "Offline"
  //     },
  //     {
  //         "serviceCode": "VIPCNSOS",
  //         "serviceDescription": "VIP Consultation - Self",
  //         "arabicServiceDescription": "",
  //         "type": "Offline"
  //     }
  // ]

  // /docEarliestSlot ====> This is for earliest slot
  // /get-doctor-availability? ====> This is for Time slots

  useEffect(() => {
    if (selectedCard !== doctorInfo.docCode) {
      setSelectedService(null);
      if (docExtraDetails) {
        setServicesList(null);
      }
    }
  }, [selectedCard]);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const getDoctorInfo = async () => {
    if (!doctorInfo) return;
    const info = await getDoctorPersonalInfo(doctorInfo.docCode);
    info && setdoctorPersonalInfo(info);
  };

  const isFoundInExtraDetails = () => {
    const foundItem = docExtraDetails?.find(
      (x) => x.providerCode === doctorInfo?.docCode
    );

    return foundItem ?? null;
  };

  const isFoundInDocsPernoalList = () => {
    const foundItem = docsPersonalInfoList?.find(
      (x) => x.docCode === doctorInfo.docCode
    );

    return foundItem ?? null;
  };

  useEffect(() => {
    const isLoaded = isFoundInDocsPernoalList()
    if (isInView && !isLoaded) {
      getDoctorInfo();
      // setHasBeenInView(true);
    }
  }, [isInView]);

  useEffect(() => {
    selectedDoc &&
      docExtraDetails &&
      doctorInfo &&
      autoScrollCardClick(selectedDoc);
  }, [docExtraDetails]);

  const currentExtraInfo = isFoundInExtraDetails();

  const showDoctorProfileHandler = (e, docCode) => {
    e.stopPropagation();
    dispatch(portalActions.toggleDoctorProfileDrawer(docCode));
  };

  const docHaveTeleServices = (docCode) => {
    const foundItem = docExtraDetails?.find(
      (x) => x.providerCode === docCode
    );
    const telemedicine = foundItem?.serviceList?.some(
      (service) =>
        service.type === 'Online'
    );
    return telemedicine;
  }

  const docPersnoalInfo = isFoundInDocsPernoalList();

  return (
    <>
      {docExtraDetails && currentExtraInfo && (
        <div className={`sorted card-2 fade-in ${fadeIn ? 'active' : '' } ${doctorInfo.docCode} ${selectedCard === doctorInfo.docCode ? 'selected' : ''}`} ref={ref} onClick={(e) => cardClickHandler(e)}>
            <img
              src={`${assetsRoute}${hospCode}/${doctorInfo.docCode}.jpg`}
              onError={(e) => {
                if (
                  e.target.src !==
                  `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                ) {
                  e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                }
              }}
              alt={doctorInfo.name}
            />
            <div className="content">
                <h4 className="text-14 lg:text-20 font-medium text-primary">{getTValue(doctorInfo, 'docNameAR', 'docName', isRtl)}</h4>
                <p className="text-10 lg:text-12 mt-1 mb-2" >
                {getTValue(
                    docPersnoalInfo,
                    'clincNameAr',
                    'clinicNameEn',
                    isRtl
                  )}
                </p>

                <p className="text-10 lg:text-14 mt-1 mb-2 text-primary">
                   {getTValue(
                    doctorInfo,
                    'specialityDescriptionAR',
                    'specialityDescription',
                    isRtl
                  )}
                </p>                
                {docExtraDetails && (
                    <p className="!text-tertiary mt-1 mb-2 text-13">

                    {t.EarliestAppointment} : <span dir='ltr'>{`${moment(currentExtraInfo?.slotDate).format('DD-MMM-YYYY')} 
                                ${moment(currentExtraInfo?.slotTime,'HH:mm:ss').format('HH:mm') }`}</span>
                    </p>
                )}
                {
                  docPersnoalInfo?.languagesTxt &&
                    <ul>
                    {getLanguagesInText(docPersnoalInfo?.languagesTxt).map(
                      (language, index) => (
                        <li key={index}>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/${language.flag}`}
                              alt={language.nameEn}
                            />
                          </span>{' '}
                          {isRtl ? language.nameAr : language.nameEn}
                        </li>
                      )
                    )}
                  </ul>
                }
                <button className='viewButton' onClick={(e)=>showDoctorProfileHandler(e, doctorInfo.docCode)}>{t.ViewProfile}</button>
                {docHaveTeleServices(doctorInfo.docCode) ? <div className='camIcon'><HiOutlineVideoCamera style={{color: "#464A53"}} /></div> : null}
            </div>
        </div>
      )}
      {!docExtraDetails && (
        <div className={`sorted card-2 fade-in ${fadeIn ? 'active' : ''} ${doctorInfo.docCode}  ${selectedCard === doctorInfo.docCode ? 'selected' : ''}`} ref={ref} onClick={(e) => cardClickHandler(e)}>
            <img
              src={`${assetsRoute}${hospCode}/${doctorInfo.docCode}.jpg`}
              onError={(e) => {
                if (
                  e.target.src !==
                  `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                ) {
                  e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                }
              }}
              alt={doctorInfo.name}
            />
            <div className="content">
                <h4 className="text-14 lg:text-20 font-medium text-primary">{getTValue(doctorInfo, 'docNameAR', 'docName', isRtl)}</h4>
                <p className="text-10 lg:text-12 mt-1 mb-2" >
                {getTValue(
                    docPersnoalInfo,
                    'clincNameAr',
                    'clinicNameEn',
                    isRtl
                  )}
                </p>
                <p className="text-14 my-3 text-primary">
                  {getTValue(
                    doctorInfo,
                    'specialityDescriptionAR',
                    'specialityDescription',
                    isRtl
                  )}
                </p>
                {
                  docPersnoalInfo?.languagesTxt &&
                  <ul>
                    {getLanguagesInText(docPersnoalInfo?.languagesTxt).map(
                      (language, index) => (
                        <li key={index}>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/${language.flag}`}
                              alt={language.nameEn}
                            />
                          </span>{' '}
                          {isRtl ? language.nameAr : language.nameEn}
                        </li>
                      )
                    )}
                  </ul>
                }
                <button className='viewButton'  onClick={(e)=>showDoctorProfileHandler(e, doctorInfo.docCode)}>{t.ViewProfile}</button>
            </div>
        </div>
      )}
    </>
  );
};

export default DoctorCard;
