import React from 'react';

const ChatIcon = ({ width = '24px', height = '24px' }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 128 128"
  >
    <path
      d="M0 0 C1.3865928 1.00503039 2.76286959 2.02455663 4.125 3.0625 C5.15625 3.805 6.1875 4.5475 7.25 5.3125 C17.70125227 15.30935 22.96418487 28.1145725 23.4375 42.4375 C23.11054497 56.09268077 17.7004533 68.09388613 8.3828125 77.99609375 C-0.17475309 85.82819098 -11.23693111 91.20042082 -22.875 92.0625 C-23.51308594 92.1140625 -24.15117188 92.165625 -24.80859375 92.21875 C-31.04523906 92.49339126 -36.63998022 91.04595534 -42.640625 89.42578125 C-46.55966134 88.98559963 -47.72341323 89.83629525 -50.875 92.0625 C-56.79386858 94.11966774 -64.61594662 94.17144981 -70.50390625 92.16796875 C-72.6875 91.0625 -72.6875 91.0625 -75.875 89.0625 C-75.41968725 84.8736227 -74.62905395 81.23603265 -73.25 77.25 C-71.04705758 70.10123587 -72.18190207 64.11843283 -74.4375 57.125 C-77.81695843 45.34708547 -77.42144321 31.0580804 -71.875 20.0625 C-68.54594288 14.46147972 -64.77831679 9.3820886 -59.875 5.0625 C-59.27558594 4.50949219 -58.67617188 3.95648438 -58.05859375 3.38671875 C-42.06542223 -10.36799782 -17.58343197 -11.24758376 0 0 Z M-58.1875 15.375 C-64.807332 23.73886706 -68.37335992 33.54492513 -68.2109375 44.18359375 C-67.73092084 48.29722492 -66.48608341 52.13033531 -65.30932617 56.09179688 C-63.01656415 64.34615494 -63.01482327 73.03421855 -64.45703125 81.46484375 C-64.59496094 82.32207031 -64.73289062 83.17929688 -64.875 84.0625 C-63.892008 85.27870026 -63.892008 85.27870026 -62.01953125 85.08203125 C-56.46423113 84.58884715 -53.52507187 83.26588284 -48.875 80.0625 C-45.0233671 79.4633571 -42.36328827 80.20827601 -38.8125 81.5625 C-29.70812419 84.78121872 -19.65811254 83.84709321 -10.875 80.0625 C1.69872728 73.90041381 8.28208124 65.57829883 13.4375 52.8125 C15.50702777 41.52416672 14.69777403 30.4715516 8.58203125 20.5625 C1.39857667 10.83113819 -7.49631838 3.64339587 -19.5390625 1.3828125 C-34.35328975 -0.64856983 -47.98926083 4.40024516 -58.1875 15.375 Z "
      fill="#FFFFFF"
      transform="translate(75.875,6.9375)"
    />
    <path
      d="M0 0 C2.125 -0.4375 2.125 -0.4375 5 0 C11.82648462 5.20028017 15.24984381 14.86808484 17 23 C17.91532611 34.16824105 17.38606386 44.35917413 13.8359375 54.984375 C11.78770425 62.37332858 13.9754351 68.29335609 17 75 C17.1875 77.9375 17.1875 77.9375 17 80 C9.89608669 84.54650452 3.32913229 85.02793213 -5 84 C-7.48046875 82.98828125 -7.48046875 82.98828125 -9.625 81.8125 C-14.48377059 79.23492669 -17.82775802 80.65130723 -22.89453125 82.1796875 C-35.65228901 85.54966125 -48.04271316 81.32524539 -59.40625 75.59765625 C-62.05457751 73.96638223 -63.99534023 72.37467874 -66 70 C-66.25 67.25 -66.25 67.25 -66 65 C-63.9296875 63.765625 -63.9296875 63.765625 -61 63 C-58.46937641 64.13668672 -56.4175091 65.24818276 -54.125 66.75 C-47.00401682 71.28864862 -40.70797439 73.378674 -32.125 73.3125 C-31.42463623 73.31121094 -30.72427246 73.30992188 -30.00268555 73.30859375 C-25.17147614 73.2190751 -21.21268685 72.45007396 -16.75 70.68359375 C-14.29889061 69.72612915 -12.59117344 69.509778 -10 70 C-8.63875 70.9590625 -8.63875 70.9590625 -7.25 71.9375 C-3.15397456 74.53690076 0.24083247 74.78848144 5 75 C4.84144531 74.16339844 4.68289062 73.32679687 4.51953125 72.46484375 C3.11770698 63.11583526 3.31623136 54.16707544 6 45.125 C8.94141401 35.1388471 8.87463565 26.76620494 5 17 C3.17940336 13.82562637 1.27817073 10.75928679 -0.76953125 7.7265625 C-2 5 -2 5 -1.19921875 2.1484375 C-0.80347656 1.43945312 -0.40773438 0.73046875 0 0 Z "
      fill="#FFFFFF"
      transform="translate(111,44)"
    />
    <path
      d="M0 0 C2.7912125 1.98322993 3.82597224 3.12986121 4.5 6.5 C4 9 4 9 2.4375 11.375 C0 13 0 13 -3.625 12.9375 C-7 12 -7 12 -9 9 C-9.32502803 5.87973093 -9.51151479 3.85252465 -7.875 1.125 C-5.07553259 -0.55468045 -3.20195936 -0.45742277 0 0 Z "
      fill="#FFFFFF"
      transform="translate(74,43)"
    />
    <path
      d="M0 0 C3 2 3 2 4 4.8125 C4 8 4 8 1.9375 10.9375 C-1 13 -1 13 -4.1875 12.9375 C-7 12 -7 12 -9 10 C-9.55021834 3.94759825 -9.55021834 3.94759825 -7.875 1.0625 C-5.14015561 -0.48724515 -3.0875593 -0.32162076 0 0 Z "
      fill="#FFFFFF"
      transform="translate(52,43)"
    />
    <path
      d="M0 0 C1.875 1.0625 1.875 1.0625 3 3 C3.4375 6.5 3.4375 6.5 3 10 C1.375 12 1.375 12 -1 13 C-4.0625 13 -4.0625 13 -7 12 C-9 9.8125 -9 9.8125 -10 7 C-9.3125 3.75 -9.3125 3.75 -8 1 C-5.20129973 -0.39935013 -3.09501208 -0.25265405 0 0 Z "
      fill="#FFFFFF"
      transform="translate(30,43)"
    />
  </svg>
);

export default ChatIcon;
