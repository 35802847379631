// src/features/toast/toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    message: '',
    visible: false,
    autoClose: true,
  },
  reducers: {
    showToast: (state, action) => {
      state.message = action.payload;
      state.visible = true;
      state.autoClose = true;
    },
    showToastNoAutoCLose: (state, action) => {
        state.message = action.payload;
        state.visible = true;
        state.autoClose = false;
      },
    hideToast: (state) => {
      state.visible = false;
    },
  },
});

export const { showToast, hideToast , showToastNoAutoCLose} = toastSlice.actions;

export default toastSlice;
