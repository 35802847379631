import React, { useState, useRef, useEffect } from 'react';
import { MdAttachFile } from 'react-icons/md';
import { IoMdSend } from 'react-icons/io';
import { FaXmark } from 'react-icons/fa6';
import { Client as TwilioChatClient } from 'twilio-chat';
import Loader from '../UI/Loader';
import { currentUser } from '../../utilFunctions/SessionExpire';
import MediaMessage from './MediaMessage';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../store/languageSlice';

const getUniqueMessages = (messages) => {
  const seenSids = new Set();

  return messages.filter((message) => {
    if (seenSids.has(message.sid)) {
      return false;
    }
    seenSids.add(message.sid);
    return true;
  });
};

const Chat = ({
  toggleChatBox,
  chatAccessToken,
  roomName,
  handleNotificatinCount,
  showChat,
}) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const [messages, setMessages] = useState([]);
  const [client, setClient] = useState(null);
  const [botTyping, setBotTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [channel, setChannel] = useState(null);
  const [userName, setUserName] = useState('');
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollChat = () => {
    const messagesContainer = document.getElementById('messages');

    // Use scrollTo with smooth behavior
    messagesContainer?.scrollTo({
      top: messagesContainer.scrollHeight - messagesContainer.clientHeight,
      behavior: 'smooth',
    });

    setTimeout(() => {
      messagesContainer?.scrollTo({
        top: messagesContainer.scrollHeight - messagesContainer.clientHeight,
        behavior: 'smooth',
      });
    }, [1000]);
  };

  const addChat = async (input) => {
    try {
      setTextLoader(true);
      await channel.sendMessage(input);
      setTextLoader(false);
    } catch (e) {
      console.error('Error sending message:', e);
      setTextLoader(false);
    }
  };

  const addFileMessage = async (selectedFile) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setFileLoader(true);
      await channel.sendMessage(formData);
      setFileLoader(false);
    } catch (e) {
      console.error('Error sending file:', e);
      setFileLoader(false);
    }
  };

  const updateChat = () => {
    if (textLoader) return;
    const input = inputRef.current.value;
    if (input.trim()) {
      addChat(input.trim());
      inputRef.current.value = '';
    }
  };

  const handleFileChange = (event) => {
    if (fileLoader) return;
    const file = event.target.files[0];
    if (file) {
      addFileMessage(file);
    }
  };

  const initChat = async () => {
    try {
      setLoading(true);
      const chatClient = await TwilioChatClient.create(chatAccessToken);
      setClient(chatClient);

      // Check if the channel exists
      let subscribedChannel = await chatClient.getChannelByUniqueName(roomName);
      if (!subscribedChannel) {
        subscribedChannel = await chatClient.createChannel({
          uniqueName: roomName,
          friendlyName: roomName,
        });
      }

      // Join the channel if not already joined
      if (subscribedChannel.status !== 'joined') {
        await subscribedChannel.join();
      }

      setChannel(subscribedChannel);

      // Fetch and display previous messages
      const messageHistory = await subscribedChannel.getMessages();
      setMessages(getUniqueMessages(messageHistory.items));

      setTimeout(() => {
        scrollChat();
      }, [1000]);
      // Listen for new messages
      subscribedChannel.on('messageAdded', (message) => {
        setMessages((prevMessages) =>
          getUniqueMessages([...prevMessages, message])
        );
        // update count
        if (!showChat && handleNotificatinCount) handleNotificatinCount();
        scrollChat();
      });

      setLoading(false);
    } catch (error) {
      console.error('Error initializing chat:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const userInfo = currentUser();
    if (userInfo?.mrn) {
      setUserName(userInfo.mrn);
    }
    if (chatAccessToken) {
      initChat();
    }
  }, []);

  useEffect(() => {
    if (showChat) {
      scrollChat();
    }
  }, [showChat]);

  if (!showChat) return null;

  return (
    <div
      style={{ marginTop: '4px' }}
      className="hidden md:flex flex-col w-full md:w-1/3 bg-white border-l border-gray-300"
    >
      <div className="w-full">
        <div className="chat-header px-8 h-16 w-full text-white">
          <div>{t.Messages}</div>
          <button onClick={toggleChatBox}>
            <FaXmark />
          </button>
        </div>
        <div
          className="flex-1 p-2 sm:p-6 justify-between flex flex-col bg-white"
          style={{ height: '72vh' }}
        >
          <div
            id="messages"
            className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {messages.map((message, key) => (
                  <div key={key}>
                    <div
                      className={`flex items-end ${
                        message.author !== userName ? '' : 'justify-end'
                      }`}
                    >
                      <div
                        className={`flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 ${
                          message.author !== userName
                            ? 'order-2 items-start'
                            : 'order-1 items-end'
                        }`}
                      >
                        <div>
                          {message.body && (
                            <span
                              className={`px-4 py-3 rounded-xl inline-block break-words ${
                                message.author !== userName
                                  ? 'rounded-bl-none bg-gray-100 text-gray-600'
                                  : 'rounded-br-none bg-color text-white'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: message.body,
                              }}
                            />
                          )}
                          {message?.media && <MediaMessage message={message} />}
                        </div>
                        <div className="text-xs mt-1 text-right">
                          {new Date(message.dateCreated).toLocaleString()}
                        </div>
                      </div>
                      <img
                        src={
                          message.author !== userName
                            ? `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                            : `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                        }
                        alt=""
                        className={`w-6 h-6 rounded-full ${
                          message.author === userName ? 'order-2' : 'order-1'
                        }`}
                      />
                    </div>
                  </div>
                ))}
                {botTyping && (
                  <div className="flex items-end">
                    <div className="flex flex-col space-y-2 text-md leading-tight mx-2 order-2 items-start">
                      <div>
                        <img
                          src="https://support.signal.org/hc/article_attachments/360016877511/typing-animation-3x.gif"
                          alt="..."
                          className="w-16 ml-6"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className={`border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0 ${
              isRtl ? 'text-right' : 'text-left'
            }`}
          >
            <div className="inputContainer relative flex h-12">
              <textarea
                type="text"
                placeholder={t.ChatPlaceholder}
                autoComplete="off"
                autoFocus
                className={`text-md w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 ${
                  isRtl ? 'pr-5 pl-24' : 'pl-5 pr-24'
                } bg-gray-100 border-2 border-gray-200 rounded-full py-2`}
                ref={inputRef}
                style={{ direction: isRtl ? 'rtl' : 'ltr' }} // Dynamically set text direction
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    updateChat(e);
                  }
                }}
              />
              <div
                className={`absolute ${
                  isRtl ? 'left-3' : 'right-3'
                } items-center inset-y-0 hidden sm:flex`}
              >
                <div className="mx-2">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <button
                    type="button"
                    className="bg-color inline-flex items-center justify-center rounded-full h-8 w-8 transition duration-200 ease-in-out text-white focus:outline-none"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <i className="text-xl leading-none">
                      {fileLoader ? (
                        <Loader roundLoader size="small" />
                      ) : (
                        <MdAttachFile />
                      )}
                    </i>
                  </button>
                </div>

                <button
                  type="button"
                  className="bg-color inline-flex items-center justify-center rounded-full h-8 w-8 transition duration-200 ease-in-out text-white focus:outline-none"
                  onClick={updateChat}
                >
                  <i className="mdi mdi-arrow-right text-xl leading-none">
                    {textLoader ? (
                      <Loader roundLoader size="small" />
                    ) : (
                      <IoMdSend  style={{transform:isRtl ? 'rotate(180deg)' : ''}}/>
                    )}
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
