import { envVariables } from "../../config";
import { sessionsMiddleWare } from "../SessionExpire";
import { getSelectLanguage } from "../getCurrentSelectLanguage";

const getServicePrice = async (slotId, hospId) => {
    const {apiRoute} = envVariables;
    // http://10.11.28.74:8080/imc-tc-wrapper-service-v4/api/appointment/book?providerCode=DR2099&serviceCode=CON002&specialityCode=3030&slotFromTime=18:00&slotDate=2023-12-24&hospitalCode=IMC
    const endpoint = `${apiRoute}api/bookings/get-service-price?`;
    const url = new URL(endpoint);

    const params = {
        slotBookingId: slotId,
        is_final_price: true
    }

    for (const key in params) {
        url.searchParams.append(key, params[key]);
    }

    // "https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/appointment/book?providerCode=ARammal&serviceCode=CON002&specialityCode=FC-GASTRO&slotFromTime=19%3A00&slotDate=2024-04-29&hospitalCode=FC"

    // const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)

    // return {
    //     "slotBookingId": "3478||3135-67143-1715-12623-1",
    //     "orderPrice": "230",
    //     "payorShare": "0",
    //     "patientShare": "230",
    //     "status": "Success",
    //     "is_final_price": true
    // }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            }
        });
        if (!response.ok && response.status !== 200) {
            if(response.status === 403) {
                sessionsMiddleWare();
            }
            throw new Error(`HTTP error! status get-service-price: ${response.status}`);
        }
        // return await response.json();
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data get-service-price:', error);
        return null;
    }
};

export default getServicePrice;