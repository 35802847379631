import { envVariables } from '../config';
import { sessionsMiddleWare } from './SessionExpire';
import { getSelectLanguage } from './getCurrentSelectLanguage';
import moment from 'moment';

export const getUpcomingAppointments = async () => {
  const { apiRoute } = envVariables;
  // https://intgtest01.imc.med.sa/imc-tc-wrapper-service-v4/api/bookings/dashboard
  const endpoint = `${apiRoute}api/bookings/dashboard`;

  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  // const hospitalId = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).id : "1";

  const { patientId, mrn } = JSON.parse(localStorage.userInfo);

  const toDate = moment();
  const fromDate = toDate.clone().subtract(1, 'year').format('DD-MMM-YYYY');

  const reqBody = {
    hospitalCode: hospitalCode,
    patientId: patientId,
    mrNumber: mrn,
    fromDate: fromDate,
    toDate: toDate.format('DD-MMM-YYYY'),
    bookingStatus: '',
    physicianCode: '',
    clinicCode: '',
    page: '0',
    itemCount: '10000',
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('userInfo')).accessToken
        }`,
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
      }

      throw new Error(`bookings/dashboard: Session Expired ${response.status}`);
    }
    const data = await response.json();
    return data.bookings;
    // await sessionsMiddleWare(data);
    // setBookedAppointments(data.bookings)
  } catch (error) {
    console.error('Error fetching bookings/dashboard:', error);
    return null;
  }
};
