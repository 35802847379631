import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiSearch, FiChevronDown } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';

import { searchbarActions, bookAppointmentActions } from '../../../store';

import './SearchDropdown.css';
import { selectLanguage } from '../../../store/languageSlice';

const SearchDropdown = ({
  getInputValueHandler,
  searchType,
  onFocus,
}) => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.searchbar.searchText);
  const { t, isRtl } = useSelector(selectLanguage);

  const clearInputFieldHandler = () => {
    getInputValueHandler('');
    dispatch(searchbarActions.updateSearchText(''));
    // dispatch(bookAppointmentActions.docAvailableDates(null));
  };

  const chnageInputFieldHandler = (e) => {
    const typedValue = e.target.value
    getInputValueHandler(typedValue);
    dispatch(searchbarActions.updateSearchText(typedValue));
  };

  const focusHandler = () => {
    onFocus && onFocus();
  };

  useEffect(()=>{
    clearInputFieldHandler();
  },[isRtl])

  return (
    <div className="docOrClincSrch">
      <div className="flex flex-wrap items-center">
        <span className="text-primary">
          <FiSearch />
        </span>
        <input
          type="text"
          value={searchText}
          onChange={(e) => {
            chnageInputFieldHandler(e)
          }}
          placeholder={`${t.Searchby} ${t.Doctor}/ ${
            searchType === 'Clinic'
              ? t.Clinic
              : t.Symptoms
          }`}
        />

        <span className="text-secondary cursor-pointer" onClick={focusHandler}>
          <FiChevronDown />
        </span>
      </div>
    </div>
  );
};

export default SearchDropdown;
