import moment from 'moment';

//dateString = 2024-10-20
export function formatDate(dateString, isRtl) {
  const dateObj = moment(dateString);
  const momentDate = moment(dateString, 'YYYY-MM-DD', true);
  const momentDate1 = moment(dateString, "YYYY-MM-DD HH:mm:ss");
  if (!momentDate.isValid() && !momentDate1.isValid()) {
    return {
      weekName: '',
      weekNameShort: '',
      year: '',
      monthName: '',
      monthNameShort: '',
      monthDate: '',
    };
  }

  const dayOfWeek = dateObj.format('dddd');
  const monthDate = dateObj.format('DD');
  const monthName = dateObj.format('MMMM');
  const monthNameShort = dateObj.format('MMM');
  const year = dateObj.format('yyyy');

  return {
    weekName: convertToArabicDay(dayOfWeek, isRtl), //Sunday
    weekNameShort: convertToArabicDay(dayOfWeek, isRtl), // Sun
    year, //2024
    monthName: convertToArabicMonth(monthName, isRtl), //October
    monthNameShort: convertToArabicMonth(monthNameShort, isRtl), // Oct
    monthDate, //20
  };
}

export function convertToArabicDay(englishDay, isRtl) {
  if (!isRtl) return englishDay;

  switch (englishDay.toLowerCase()) {
    case 'sunday':
    case 'sun':
      return 'الأحد';
    case 'monday':
    case 'mon':
      return 'الاثنين';
    case 'tuesday':
    case 'tue':
      return 'الثلاثاء';
    case 'wednesday':
    case 'wed':
      return 'الأربعاء';
    case 'thursday':
    case 'thu':
      return 'الخميس';
    case 'friday':
    case 'fri':
      return 'الجمعة';
    case 'saturday':
    case 'sat':
      return 'السبت';
    default:
      return englishDay;
  }
}

export function convertToArabicMonth(englishMonth, isRtl) {
  if (!isRtl) return englishMonth;

  switch (englishMonth.toLowerCase()) {
    case 'january':
    case 'jan':
      return 'يناير';
    case 'february':
    case 'feb':
      return 'فبراير';
    case 'march':
    case 'mar':
      return 'مارس';
    case 'april':
    case 'apr':
      return 'أبريل';
    case 'may':
      return 'مايو';
    case 'june':
    case 'jun':
      return 'يونيو';
    case 'july':
    case 'jul':
      return 'يوليو';
    case 'august':
    case 'aug':
      return 'أغسطس';
    case 'september':
    case 'sep':
      return 'سبتمبر';
    case 'october':
    case 'oct':
      return 'أكتوبر';
    case 'november':
    case 'nov':
      return 'نوفمبر';
    case 'december':
    case 'dec':
      return 'ديسمبر';
    default:
      return englishMonth;
  }
}

export function getTValue(object, arabicKey, engKey, isRtl) {
  if(!object) return '';
  
  const arabicText = object[arabicKey];
  const engText = object[engKey];

  if (isRtl && arabicText && arabicText !== '') return arabicText;

  return engText;
}

export function getLanguage() {
  const storedLanguage = localStorage.getItem('language');

  if (storedLanguage && storedLanguage.toLowerCase() === 'ar') {
    return 'Arabic';
  }

  return 'English';
}
