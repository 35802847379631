import React from 'react';
import { FiDownload } from 'react-icons/fi';
import StatusItem from '../Status';
import { getIconByCode } from '../../../../utilFunctions/Vitals';

import './prescriptions.css';

const PrescriptionItem = () => {
  return (
    <div className="presContainer w-full gap-4">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex gap-3">
          <img className="iconImg" src={`${process.env.PUBLIC_URL}/images/icons/medication.png`} alt="icon" />
          <div className="flex flex-col gap-1">
            <span className="pname">Prescription Number</span>
            <span className="pvalue">O0000001</span>
            <StatusItem />
          </div>
        </div>
        <div className="downloadButtonIcon">
          <FiDownload />
        </div>
      </div>
      <div className="flex w-full gap-2 flex-col">
        <div className="listItem">
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/reporticon.svg`}
            alt="icon"
          />
          <div>Amoxilillin</div>
        </div>
        <div className="listItem">
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/reporticon.svg`}
            alt="icon"
          />
          <div>Peracetemol</div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionItem;
