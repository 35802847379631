import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';

const MultiLevelDropdown = ({
  filteredClinics,
  filteredSymptons,
  filteredDoctors,
  onItemClick,
  searchTerm,
  hideDropdown
}) => {
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState({});
  const { isRtl } = useSelector(selectLanguage);

  const inputContainerRef = useRef();

  useClickOutside(inputContainerRef, () => {
    hideDropdown && hideDropdown()
  });

  const [openGroups, setOpenGroups] = useState([]);

  const toggleGroup = (specialityCode) => {
    setOpenGroups((prev) =>
      prev.includes(specialityCode)
        ? prev.filter((code) => code !== specialityCode)
        : [...prev, specialityCode]
    );
  };

  const isGroupOpen = (specialityCode) => openGroups.includes(specialityCode);

  const handleItemClick = (item) => {
    onItemClick(item);
  };

  const uniqueSpecialityCodes = new Set();

  const buttonClassName = `flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
    isRtl ? 'text-right' : 'text-left'
  }`;

  return (
    <div
      id="multi-dropdown"
      className="z-10 absolute mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 max-h-96 overflow-y-auto w-full"
      ref={inputContainerRef}
    >
      {filteredClinics && filteredClinics.length > 0 && (
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
          {filteredClinics.map((clinic) => (
            <li key={clinic.specialityCode}>
              {clinic.isGroup && clinic.subClinic?.length > 0 ? (
                <>
                  <button
                    type="button"
                    onClick={() => toggleGroup(clinic.specialityCode)}
                    className={`${buttonClassName} text-gray-500`}
                  >
                    <span>
                      {isRtl
                        ? clinic.arabicSpecialityDescription
                        : clinic.specialityDescription}
                    </span>
                    <svg
                      className={`w-4 h-4 transform rtl:rotate-180 ${
                        isGroupOpen(clinic.specialityCode)
                          ? isRtl ? 'rtl:rotate-90 ':'rotate-90'
                          : ''
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                  {isGroupOpen(clinic.specialityCode) && (
                    <ul className={`px-4 mt-2 text-sm text-gray-700 dark:text-gray-200`}>
                      {clinic.subClinic.map(
                        (sub) =>
                          (sub.specialityDescription
                            .toLowerCase()
                            .includes(searchTerm?.toLowerCase()) ||
                            sub.arabicSpecialityDescription
                              .toLowerCase()
                              .includes(searchTerm?.toLowerCase())) && (
                            <li key={sub.specialityCode}>
                              <button
                                // className="w-full p-2 text-left"
                                className={buttonClassName}
                                onClick={() => handleItemClick(sub)}
                              >
                                {isRtl
                                  ? sub.arabicSpecialityDescription
                                  : sub.specialityDescription}
                              </button>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </>
              ) : (
                <button
                  // className="w-full p-2 text-left"
                  className={buttonClassName}
                  onClick={() => handleItemClick(clinic)}
                >
                  {isRtl
                    ? clinic.arabicSpecialityDescription
                    : clinic.specialityDescription}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
      {filteredSymptons && filteredSymptons.length > 0 && (
        <ul className="py-2 text-sm text-gray-700 max-h-120 overflow-y-auto dark:text-gray-200">
          {filteredSymptons.map((x) => (
            <li key={x.symptom} className="relative">
              <button
                className={buttonClassName}
                onClick={() => handleItemClick(x)}
              >
                {isRtl ? x.symptomArabic : x.symptom}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiLevelDropdown;
