import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export const SlickNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
          className={`slickCustomArrow ${className}`}
          style={{ ...style, display: "block" }}
          onClick={onClick}
        ><FiChevronRight /></div>
    );
}

export const SlickPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
          className={`slickCustomArrow ${className}`}
          style={{ ...style, display: "block" }}
          onClick={onClick}
        ><FiChevronLeft /></div>
    );
}