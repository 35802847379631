import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaXmark } from 'react-icons/fa6';
import { PiWarningOctagonDuotone } from 'react-icons/pi';


import './style.css';
import { modalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';
// import { useEffect } from "react";

// const Backdrop = (props) => {
//     return <div className={`backdrop ${props.visible ? "show" : ""}`} onClick={()=>props.modalClose()}></div>
// };


const Modal = (props) => {
  const { width, height, mainClass, onClose, customIcon } = props;
  const dispatch = useDispatch();
  const {t,isRtl} = useSelector(selectLanguage);
  const modalVisible = useSelector(state => state.modal.modalVisible);
  const [showModalClass, setShowModalClass] = useState(null);

  const ModelCloseHandler = () => {
    setShowModalClass(false);
    setTimeout(() => {
        dispatch(modalActions.closeModal());
        onClose && onClose()
    }, 200);
  };
  const stopPropagtionHandler = (e) => {
    e.stopPropagation();
  };

  const testingFunc = () => {
    setTimeout(() => {
      setShowModalClass(true);
    }, 200);
  };
  if (modalVisible) {
    testingFunc();
  }

  return modalVisible ? (
    <Fragment>
      <div
        dir={isRtl ? 'rtl' : ''}
        className={`modal fade ${mainClass ? mainClass : ''} ${
          showModalClass ? 'show' : ''
        }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="overlay"
        onClick={ModelCloseHandler}
        style={{
          display: `${modalVisible ? 'block' : ''}`,
        }}
      >
        <div
          className={`modal-content ${width ? width : 'sm'} ${
            height ? height : ''
          }`}
          onClick={stopPropagtionHandler}
        >
          <div className="flex items-center justify-between head">
            <h3 className="flex items-center gap-4 text-gray-700 text-xl">
              <span className="p-2 bg-gray-300 rounded-full">
                {customIcon ? customIcon : <PiWarningOctagonDuotone color="#374151de" />}
              </span>
              {props.title ? props.title : ''}
            </h3>
            <button onClick={ModelCloseHandler}>
              <FaXmark />
            </button>
          </div>
          <div className="body">{props.children}</div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default Modal;