import React from 'react';
import ReactApexChart from 'react-apexcharts';

const VitalChart = ({
  xAxisTitle = 'X AxisTitle',
  yaxisTitle = 'Y AxisTitle',
  data,
}) => {
  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
    },
    xaxis: {
      categories: Array.from({ length: 25 }, (_, i) => i), // x-axis from 0 to 23
      title: {
        text: xAxisTitle,
      },
    },
    yaxis: {
      title: {
        text: yaxisTitle,
      },
      min: 0,
      max: 800, // adjust max value as needed for systolic data range
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#007bff'], // Line color
  };

  let series = data;

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default VitalChart;
