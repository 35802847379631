import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import Modal from '../../Modal/Modal';
import EmailOtpVerify from '../../Profile/EditProfile/EmailOtpVerify';
import DepentedModal from '../../Profile/FamilyProfie/DepentedModal';

import { ModalPortalDom } from './Portals';
import SessionExpireModal from './SessionExpireModal';

const ModalPortal = () => {
  const { t } = useSelector(selectLanguage);
  const { modalType } = useSelector((state) => state.modal);

  if (
    [
      'ConfirmBookingModal',
      'OnlineConfirmBookingModal',
      'ServicePriceModal',
      'ConfirmCanellation',
      'kaust',
      'Reschedule',
      'ServicePriceModal2',
      'secondOpinion',
      'secondOpinionSuccess'
    ].includes(modalType)
  )
    return null;

  const isErrorModal = ['sessionExpire','error'].includes(modalType);

  return (
    <ModalPortalDom>
      <Modal title={isErrorModal ? t.Error : t.Confirmation}>
        {modalType === 'dependentModal' && <DepentedModal />}
        {modalType === 'emailOtp' && <EmailOtpVerify />}
        {/* {modalType === 'sessionExpire' && <SessionExpireModal />} */}
      </Modal>
    </ModalPortalDom>
  );
};

export default ModalPortal;
