export const allnumeric = (inputtxt) => {
  let numbers = /^[0-9]+$/;
  return numbers.test(inputtxt);
};

export const allnumeric2 = (inputtxt) => {
  var numbers = /^[0-9]+$/;
  if (inputtxt.match(numbers)) {
    return true;
  } else {
    return false;
  }
};

export const onlyOneNumeric = (inputtxt) => {
  let numbers = /^[0-9]$/;
  let count = 0;
  for (let i = 0; i < inputtxt.length; i++) {
    if (inputtxt[i].match(numbers)) {
      count++;
    } else {
      return false; // If any non-numeric character found, return false
    }
    if (count > 1) {
      return false; // More than one numeric character found
    }
  }
  return count === 1; // True only if exactly one numeric character found and no non-numeric characters
};

export const onlyLetters = (value) => {
  // Regular expression to match English and Arabic letters
  const regex = /[^a-zA-Z\u0600-\u06FF\s]/g;
  
  // Remove characters other than English and Arabic letters and whitespace
  let valueCheck = value.replace(regex, "");
  
  return valueCheck;
};

