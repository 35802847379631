import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookAppointmentActions } from '../../../store';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';

import './style.css'; // Import the CSS file
import { selectLanguage } from '../../../store/languageSlice';
import { convertToArabicMonth } from '../../../translations/common';

const MonthsDropdown = ({ data, onSelect }) => {
  const dispatch = useDispatch();
  const { t,isRtl } = useSelector(selectLanguage);
    const locale = isRtl ? 'ar' : 'en-US';

  const availableDatesArray = useSelector(
    (state) => state.bookAppointment.availableDatesArray
  );

  const [selectedValue, setSelectedValue] = useState('');
  const [items, setItems] = useState([{ label: 'Name', value: 'value' }]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputContainerRef = useRef();

  const formatSlotData = (data) => {
    // Helper function to get month name from date
    const getMonthName = (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleString('default', { month: 'long' });
    };

    // Group data by month
    const groupedData = data.reduce((acc, item) => {
      const monthName = getMonthName(item.slotDate);
      if (!acc[monthName]) {
        acc[monthName] = {};
      }

      if (!acc[monthName][item.slotDate]) {
        acc[monthName][item.slotDate] = [];
      }

      acc[monthName][item.slotDate].push({
        time: `${item.slotFromTime} - ${item.slotToTime}`,
        slotBookingId: item.slotBookingId,
      });

      return acc;
    }, {});

    // Format the data
    const formattedData = Object.keys(groupedData).map((month) => {
      const allDates = [];
      const dates = Object.keys(groupedData[month]).map((date) => {
        const slots = groupedData[month][date];
        allDates.push(date)
        return { date, slots };
      });
      return { month, dates, allDates };
    });

    return formattedData;
  };

  useClickOutside(inputContainerRef, () => {
    setDropdownVisible(false);
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setDropdownVisible(true);
  };

  const handleFocus = () => {
    setDropdownVisible(true);
    // setItems(items);
  };

  const handleItemClick = (item) => {
    setSelectedValue(item.month);
    setDropdownVisible(false);
    dispatch(bookAppointmentActions.updateSelectedMonthDates(item?.allDates));
    dispatch(bookAppointmentActions.dateSelector(item?.allDates[0]));
    setDropdownVisible(false);
    onSelect && onSelect(item);
  };


  useEffect(() => {
    if (availableDatesArray?.length > 0) {
      const formatedData = formatSlotData(availableDatesArray);
      if (formatedData[0]) {
        setSelectedValue(formatedData[0].month);
        const initialDate = formatedData[0].allDates[0]; // selectFirstDate
        dispatch(bookAppointmentActions.dateSelector(initialDate));
        dispatch(bookAppointmentActions.updateSelectedMonthDates(formatedData[0].allDates));
      }

      setItems(formatedData || []);
    }else {
      dispatch(bookAppointmentActions.dateSelector(null));
      dispatch(bookAppointmentActions.updateSelectedMonthDates(null));
      setSelectedValue('');
      setItems([]);
    }
  }, [availableDatesArray, isRtl]);

  // if(items.length === 0) return null;

  return (
    <div className="dropdown1-container">
      <div onClick={handleFocus} className="input1-wrapper">
        <input
          type="text"
          className="search1-input"
          placeholder={t.Select}
          defaultValue={convertToArabicMonth(selectedValue,isRtl)}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <span className="dropdown1-arrow">&#9662;</span>
      </div>
      {dropdownVisible && (
        <ul ref={inputContainerRef} className="dropdown1-list">
          {items.map((item, index) => (
            <li
              key={index}
              className="dropdown1-item"
              onClick={() => handleItemClick(item)}
            >
              {convertToArabicMonth(item.month,isRtl)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MonthsDropdown;
