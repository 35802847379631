import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import AppointmentsList from "../components/ManageAppointment/AppointmentsList";
import RightSidebar from "../components/ManageAppointment/RightSidebar";
import "../components/ManageAppointment/ManageAppointment.css";
import { envVariables } from "../config";
import { useSelector } from "react-redux";
import { selectLanguage } from "../store/languageSlice";
import store from "../store";
import { hideLoader, showLoader } from "../store/globalLoaderError";
import { getSelectLanguage } from "../utilFunctions/getCurrentSelectLanguage";
import useDataFetching from "../utilFunctions/useFetchData";
import { sessionsMiddleWare } from "../utilFunctions/SessionExpire";
import Loader from "../components/UI/Loader";
import moment from "moment";

export const ManageAppointment = (props) => {
    const { apiRoute } = envVariables;
    // const allAppointment = useLoaderData();
    // const [appointmentsList, setAppointmentsList] = useState(allAppointment);

    const {
        data: appointmentsList,
        loading,
        error,
      } = useDataFetching(manageAppointmentLoader);
    const { t } = useSelector(selectLanguage);
  
    const [apptType, setApptType] = useState(0) // 0 for All // 3 for Cancelled // 4 for Completed // 5 for No Show

    return (
        <div className="mngApnts">
            <div className="contains-sidebar">
                <div className="tabs flex flex-wrap gap-4 justify-center">
                    <button className={apptType === 0 ? "active" : ""} onClick={() => setApptType(0)}>{t['All']}</button>
                    <button className={apptType === 4 ? "active" : ""} onClick={() => setApptType(4)}>{t['Completed']}</button>
                    <button className={apptType === 5 ? "active" : ""} onClick={() => setApptType(5)}>{t['No_Show']}</button>
                    <button className={apptType === 3 ? "active" : ""} onClick={() => setApptType(3)}>{t['Cancelled']}</button>
                </div>
                {
                    !!appointmentsList?.length && (
                        <AppointmentsList appointments={appointmentsList} apptType={apptType} />
                    )
                }
                {
                  loading && <div className="w-full justify-center"><Loader roundLoader/> </div>
                }
                {/* <div className="rt-sd">
                    <div className="tabs flex flex-wrap gap-x-4">
                        <button className={apptType === 0 ? "active" : ""} onClick={()=>setApptType(0)}>All</button>
                        <button className={apptType === 4 ? "active" : ""} onClick={()=>setApptType(4)}>Completed</button>
                        <button className={apptType === 5 ? "active" : ""} onClick={()=>setApptType(5)}>No Show</button>
                        <button className={apptType === 3 ? "active" : ""} onClick={()=>setApptType(3)}>Cancelled</button>
                    </div>
                    {
                        appointmentsList && (
                            <AppointmentsList appointments={appointmentsList} apptType={apptType} />
                        )
                    }
                </div>
                <div className="pge-sdbr" style={{opacity: "0"}}>
                    <RightSidebar />
                </div> */}

            </div>
        </div>
    )
};

export const manageAppointmentLoader = async () => {
    const { apiRoute } = envVariables;
    const endpoint = `${apiRoute}api/bookings/all`;

    const { patientId, mrn } = JSON.parse(localStorage.userInfo);
    const hospCode = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).code : "IMC";
    const toDate = moment();
    const fromDate = toDate.clone().subtract(1, 'year').format('DD-MMM-YYYY');

    const reqBody = {
        "hospitalCode": hospCode,
        "patientId": patientId,
        "mrNumber": mrn,
        "fromDate": fromDate,
        "toDate": toDate.format('DD-MMM-YYYY'),
        "bookingStatus": "",
        "physicianCode": "",
        "clinicCode": "",
        "page": "0",
        "itemCount": "10000"
    }

    try {
        // store.dispatch(showLoader())
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok) {
            if(response.status === 403) {
                sessionsMiddleWare()
            }
            throw new Error(`HTTP error! bookings/all: ${response.status}`);
        }

        const data = await response.json();
        // store.dispatch(hideLoader())
        return data.bookings;
    } catch (error) {
        console.error('Error fetching bookings/all:', error);
        throw error;
    }
}