import React from 'react';
import { FaDownload, FaReceipt } from 'react-icons/fa';
import { FiDownload, FiCalendar } from 'react-icons/fi';

const InvoiceCard = () => {
  return (
    <div className="p-4 max-w-md bg-white rounded-lg shadow-md flex items-center justify-between">
      {/* Left section with icon and details */}
      <div className="flex items-center space-x-4">
        <img
          className="iconImg"
          src={`${process.env.PUBLIC_URL}/images/icons/medication.png`}
          alt="icon"
        />
        <div>
          <span className="titleDoc">INV00000001</span>
          <div className="mvalue flex items-center gap-1 text-gray-500 text-sm mt-1">
            <FiCalendar />
            <span>16 July 2024</span>
          </div>
        </div>
      </div>

      {/* Right section with amount and download icon */}
      <div className="flex items-center space-x-4">
        <div className="text-right">
          <span className="text-xm text-gray-500 mb-1">SAR</span>
          <h5 className="text-md font-bold primaryFontColor">172.2</h5>
        </div>
        <div className="downloadButtonIcon">
          <FiDownload />
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
