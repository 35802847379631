import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = (props) => {
    return (
        <div style={{paddingTop: "20px", textAlign: "center"}}>
            404 Page not found. Go back to <Link to="/" className="font-medium text-primary">HOME</Link>
        </div>
    )
};

export default ErrorPage;