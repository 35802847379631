import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

function useDataFetching(apiFunction) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId); 

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setData(null)
      const result = await apiFunction();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [apiFunction]);

  useEffect(() => {
    fetchData();
  }, [hospitalId]);

  return { data, loading, error, refetch: fetchData };
}

export default useDataFetching;
