import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useLoaderData } from 'react-router-dom';
import { Navigation } from '../Navigation';
import Sidebar from '../Sidebar';
import Draggable from 'react-draggable';
import SelectVitalsDropdwon from '../../VitalReport/VitalReportGrid/SelectVitalsDropdwon';
import { envVariables } from '../../../config';
import DrawerPortal from '../Portals/DrawerPortal';
import ModalPortal from '../Portals/ModalPortal';
import WarnigSwitchModal from '../../Profile/FamilyProfie/WarnigSwitchModal';
import ChatModal from '../../ChatModal';
import { hospitalInformationActions, languageActions } from '../../../store';
import { selectLanguage } from '../../../store/languageSlice';
import { modalActions, portalActions } from '../../../store';
import GlobalLoader from '../Loader/GlobalLoader';
import { getSelectLanguage } from '../../../utilFunctions/getCurrentSelectLanguage';
import AppLinks from '../../AppLinks';
import Toast from '../../toast';
import SessionExpireModal from '../Portals/SessionExpireModal';
import AssessmentYearsDropdown from '../../AssessmentReport/AssessmentYearsDropdown';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import ReportSwitch from '../../Reports/ReportSwitch';
import ReactJoyride from 'react-joyride';
// import { getNotificationCount } from "../Navigation/navigationLoader";
// import { getNotificationCount } from "../Navigation";

// export async function loader() {
//     const notificationData = await getNotificationCount();
//     return notificationData;
// }

// const host = process.env.REACT_APP_HOST;
export const layoutLoader = async () => {
  const { apiRoute } = envVariables;

  try {
    const hospitalListReq = await fetch(`${apiRoute}api/hospitals/list`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo'))?.accessToken
          }`,
        "Accept-Language": getSelectLanguage(),


      },
    });
    if (!hospitalListReq.ok) {
      throw new Error(
        `Error getting hospitalListReq: ${hospitalListReq.json().statusText}`
      );
    }
    const hospitalListReqRes = await hospitalListReq.json();
    // console.log("hospitalListReqRes", hospitalListReqRes);
    // localStorage.setItem("hospitalListReqRes", JSON.stringify(tokenRes));
    return hospitalListReqRes;
  } catch (error) {
    console.error('Error fetching data hospitalListReq:', error);
    return null;
  }
};

export const Layout = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isDesktop } = useBreakpoint();
  const sidebarOpen = useSelector((state) => state.sidebar.sidebarOpen);
  const { switchPatient, showUserTour } = useSelector((state) => state.modal);
  const { t, isRtl } = useSelector(selectLanguage);
  const hospitalsList = useLoaderData();
  // console.log("hospitalsList Dashboard", hospitalsList);

  useEffect(() => {
    setTimeout(() => {
      const lang = localStorage.getItem('language') || 'en';
      dispatch(languageActions.changeLanguage(lang));
      dispatch(hospitalInformationActions.updateList(hospitalsList));
    }, 1000);

    let previousUserInfo = JSON.parse(localStorage.getItem('previousUserInfo'));

    if (previousUserInfo != null) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      dispatch(modalActions.setSwitchPatient(true));
      dispatch(portalActions.updatedSelectedDependent(userInfo));
    }
  }, [dispatch]);

  const pageMainHeading =
    location.pathname === '/book-appointment' ? (
      <h1 className={`page-title hidden`}>
        {t.BookAppointment}
      </h1>
    ) : location.pathname === '/manage-appointment' ? (
      <h1 className={`page-title`}>
        {t['Appointments']}
      </h1>
    ) : location.pathname === '/vital-report' ? (
      <div
        className={`flex flex-wrap justify-between items-center`}
      >
        <h1 className="page-title">{t.VitalSigns}</h1>
        <SelectVitalsDropdwon />
      </div>
    ) : location.pathname === '/home-health-care' ? (
      <h1 className={`page-title`}>
        {t.HomeHealthCare}
      </h1>
    ) : location.pathname === '/assessment-report' ? (
      <div className={`flex flex-wrap justify-between`}>
        <h1 className={`page-title`}>
          {t.AssessmentReport}
        </h1>
        <AssessmentYearsDropdown />
      </div>
    ) : location.pathname === '/medication' ? (
      <h1 className={`page-title`}>
        {t.Medication}
      </h1>
    ) : location.pathname === '/reports' ? (
      <div
        className={`flex flex-wrap justify-between`}
      >
        <h1 className="page-title">{t.Reports}</h1>
        {
          !isDesktop && (
            <ReportSwitch />
          )
        }        
      </div>
    ) : location.pathname === '/location-map' ? (
      <h1 className={`page-title`}>
        {t.locationMap}
      </h1>
    ) : location.pathname === '/contact-us' ? (
      <h1 className={`page-title`}>
        {t.contactUs}
      </h1>
    ) : location.pathname === '/dashboard' ? (
      <h1 className={`page-title`}>
        {t.dashboard}
      </h1>
    ) : null;

  return (
    <Fragment>
      <Navigation hospitalsList={hospitalsList} />
      <ReactJoyride
        steps={[{
          target: '.my-first-step',
          content: t.tourStep1,
          // placementBeacon: isRtl ? 'left':'right',
          disableBeacon: true,
        }]}
        locale = {{close: t.Close}}
        hideCloseButton={true}
        styles={{
          options: {
            // arrowColor: '#e3ffeb',
            // backgroundColor: '#e3ffeb',
            primaryColor: '#00B0E6',
            beaconSize: '60px',
          },
          // beacon:{
          //   left:isRtl? '0px':'-38px',
          //   right:isRtl? '-38px':'0px'
          // }
        }}
        // run={true}
        run={showUserTour}
        continuous={false}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
      />
      <div
        // className={`wrapper relative ${
        //   !sidebarOpen ? 'sidebar-collapsed' : ''
        // }  ${isRtl ? 'flex-row-reverse' : ''}`}
        className={`wrapper relative ${!sidebarOpen ? 'sidebar-collapsed' : ''
          }`}
      >
        <Sidebar />
        <div className="page-content">
          {switchPatient && (
            <div className="m-4 max-w-5xl">
              <WarnigSwitchModal />
            </div>
          )}

          <div className="container">
            {/* <Pagination /> */}
            {/* H1 --- page main heading */}
            {pageMainHeading}
          </div>
          {/* page content */}
          <div
            className={`v-scoller${!pageMainHeading ? ' no-page-heading' : ''}`}
          >
            <div className="container">
              <Outlet />
              <GlobalLoader />
            </div>
          </div>
          {/* page content */}
        </div>
      </div>
      <Draggable bounds="parent">
      <div
        className={`fixed bottom-14 z-10  ${isRtl ? 'left-4 sm:left-24' : 'right-4 sm:right-24'}`}
      >
        <ChatModal isRtl={isRtl} />
      </div>
      </Draggable>
      <Toast />
      <SessionExpireModal />
      <AppLinks />
      <DrawerPortal />
      <ModalPortal />
    </Fragment>
  );
};

// export default Layout;
