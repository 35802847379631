import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaXmark } from 'react-icons/fa6';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import { modalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';

import './style.css';

const CustomeModal = (props) => {
  const {
    width,
    height,
    mainClass,
    isOpen,
    onCancel,
    onConfirm,
    cancelText,
    confirmText,
    title,
    icon,
    hideFooter,
    children
  } = props;
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const [showModalClass, setShowModalClass] = useState(null);

  const ModelCloseHandler = () => {
    setShowModalClass(false);
    setTimeout(() => {
      dispatch(modalActions.closeModal());
      onCancel && onCancel();
    }, 200);
  };

  const ModelConfirmHandler = () => {
    onConfirm && onConfirm();
  };

  const stopPropagationHandler = (e) => {
    e.stopPropagation();
  };

  const testingFunc = () => {
    setTimeout(() => {
      setShowModalClass(true);
    }, 200);
  };

  if (isOpen) {
    testingFunc();
  }

  return isOpen ? (
    <Fragment>
      <div
        dir={isRtl ? 'rtl' : ''}
        className={`modal fade ${mainClass ? mainClass : ''} ${showModalClass ? 'show' : ''}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="overlay"
        onClick={ModelCloseHandler}
        style={{
          display: `${isOpen ? 'block' : ''}`,
        }}
      >
        <div
          className={`modal-content ${width ? width : 'sm'} ${height ? height : ''}`}
          onClick={stopPropagationHandler}
        >
          <div className="flex items-center justify-between head">
            <h3 className="flex items-center gap-4 text-gray-700 text-xl">
              <span className="p-2 bg-gray-300 rounded-full">
                {icon || <PiWarningOctagonDuotone color="#374151de" />}
              </span>
              {title || 'Your title'}
            </h3>
            <button onClick={ModelCloseHandler}>
              <FaXmark />
            </button>
          </div>
          <div className="body">{children}</div>
          {!hideFooter ? ( onCancel || onConfirm) && (
            <div className="footer flex justify-end gap-4 p-4">
              {onCancel && <button
                onClick={ModelCloseHandler}
                className="btn-cancel px-4 py-2 bg-gray-300 rounded"
              >
                {cancelText || t.Cancel}
              </button>}
              {onConfirm && <button
                onClick={()=>ModelConfirmHandler()}
                className="btn-confirm px-4 py-2 bg-secondary text-white rounded"
              >
                {confirmText || t.Confirm}
              </button>}
            </div>
          ):null}
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default CustomeModal;
