import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookAppointmentActions, searchbarActions } from '../../../store';
import { removeBadge, selectBadges } from '../../../store/badgesSlice';
import { selectLanguage } from '../../../store/languageSlice';
import { FaXmark } from "react-icons/fa6";

const Badge = ({ data, onItemClick, onItemRemove, isRtl }) => {
  const { textEng, textAr, type } = data;

  const item = {
    id: 'badge-dismiss-default',
    text: isRtl ? textAr : textEng,
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  };

  const handleBadgeClick = () => {
    onItemClick(data);
  };

  const handleRemoveClick = () => {
    onItemRemove();
  };

  return (
    <span
      id={'badge-search'}
      className={`inline-flex items-center px-2 py-1 me-2 text-sm font-medium rounded `}
      // onClick={handleBadgeClick}
    >
      <button onClick={handleBadgeClick}>{item.text}</button>
      <button
        type="button"
        className={`inline-flex items-center p-1 ms-2 text-sm ${item.color} bg-transparent rounded-sm hover:${item.bgColor} hover:text-${item.textColor}`}
        onClick={handleRemoveClick}
        aria-label="Remove"
      >
        <FaXmark />
        {/* <svg
          className="w-2 h-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg> */}
        <span className="sr-only">Remove badge</span>
      </button>
    </span>
  );
};

const BadgesList = ({ onClickItem, onRemoveItem}) => {
  const dispatch = useDispatch();
  const { selectedBadge } = useSelector(selectBadges);
  const { t, isRtl } = useSelector(selectLanguage);

  const handleBadgeClick = (item) => {
    onClickItem(item);
  };

  const handleRemoveClick = () => {
    dispatch(removeBadge());
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(bookAppointmentActions.selectedDocCode(null))
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.updatePrimaryOrLastVisited(null))
    onRemoveItem();
  };

  useEffect(()=>{
    handleRemoveClick();
  },[isRtl])

  return (
    <>
    {/* <div className="my-4"></div> */}
    {selectedBadge && (
    <div className="badgesList flex">      
        <Badge
          data={selectedBadge}
          onItemClick={handleBadgeClick}
          onItemRemove={handleRemoveClick}
          isRtl={isRtl}
        />      
    </div>
    )}
    </>
  );
};

export default BadgesList;
