import React from 'react';
import { FiCalendar, FiDownload } from 'react-icons/fi';
import StatusItem from '../Status';
import { getIconByCode } from '../../../../utilFunctions/Vitals';

import './medications.css';
import { getTValue } from '../../../../translations/common';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

const MedicationItem = ({ index, item }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { medication_description , approval_status, quantity , duration, route, prescription_number } = item;

  const instructions = getTValue(
    item,
    'arabic_instruction',
    'english_instruction',
    isRtl
  );
  return (
    <div className="mresContainer w-full gap-4">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex gap-3">
          <img
            className="iconImg"
            src={`${process.env.PUBLIC_URL}/images/icons/medication.png`}
            alt="icon"
          />
          <div className="flex flex-col gap-1">
            <span className="mname">Prescription Number</span>
            <span className="mvalue">{prescription_number}</span>
            <StatusItem />
          </div>
        </div>
        <div className="downloadButtonIcon">
          <FiDownload />
        </div>
      </div>
      <div className="flex w-full gap-2 flex-col">
        <div className="mlistItem">
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/reporticon.svg`}
            alt="icon"
          />
          <div>Amoxilillin</div>
        </div>
        <div className="cardUse shadow-sm">
          <div className="font-bold" style={{ fontSize: '12px' }}>
            How to use
          </div>
          <div className="font-light" style={{ fontSize: '14px' }}>
            {instructions}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mwrapper" style={{ backgroundColor: '#D94FD533' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/quantityIcon.svg`}
              />
              <div className="flex flex-col gap-1">
                <span className="mWTitle">Quantity</span>
                <span className="mWValue" style={{ color: '#D94FD5' }}>
                  {quantity}
                </span>
              </div>
            </div>
            <div className="mwrapper" style={{ backgroundColor: '#FFD60A33' }}>
              <img src={`${process.env.PUBLIC_URL}/images/icons/fi_list.svg`} />
              <div className="flex flex-col gap-1">
                <span className="mWTitle">Description</span>
                <span className="mWValue" style={{ color: '#FFD60A' }}>
                  {route}
                </span>
              </div>
            </div>
            <div className="mwrapper" style={{ backgroundColor: '#29E7CD33' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/fi_calendar.svg`}
              />
              <div className="flex flex-col gap-1">
                <span className="mWTitle">No. of days</span>
                <span className="mWValue" style={{ color: '#34D1BF' }}>
                  {duration}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicationItem;
