import { envVariables } from "../config";
import { getSelectLanguage } from "./getCurrentSelectLanguage";
import { sessionsMiddleWare } from "./SessionExpire";

// const { apiRoute } = envVariables;

const doctorDetailReq = async (host, hospitalCode, hospitalId, docCode) => {
    const endpoint = `${host}api/doctors/find?hospitalCode=${hospitalCode}&doctorCode=${docCode}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': '1',
                'Host': 'patientportal.imc.med.sa',
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                "Accept-Language": getSelectLanguage(),
            }
        });
        if (!response.ok) {
            if(response.status === 403) {
                sessionsMiddleWare();
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching doctor details:', error);
        return null;
    }
};

export const BookAppointmentDocList = async () => {
    const { apiRoute } = envVariables;
    const host = apiRoute;
    // const host = process.env.REACT_APP_HOST;
    // const hospitalCode = 'IMC'
    const hospitalCode = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).code : "IMC";
    const hospitalId = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).id : "1";

    /* Dr.NAME EN AR
    Dr.CODE
    Speciality Code
    Speciality Desc EN AR */
    const NameAndCodeRes = await fetch(`${host}api/careProvidersList/all?hospitalCode=${hospitalCode}`, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
            'HospitalID': hospitalId,
            'Host': 'patientportal.imc.med.sa',
            "Accept-Language": getSelectLanguage(),
        }
    })
    const NameAndCodeData = await NameAndCodeRes.json();

    const doctorsInfoArray = [];
    NameAndCodeData.doctorListBasedOnSpeciality.forEach(speciality => {
        speciality.doctorList.forEach(doctor => {
            doctorsInfoArray.push({
                docCode: doctor.provider_code,
                docName: doctor.provider_description,
                docNameAR: doctor.arabic_provider_description,
                specialityCode: speciality.specialityCode,
                specialityDescription: speciality.specialityDescription,
                specialityDescriptionAR: speciality.arabicSpecialityDescription
            });
        });
    });


    /*
    Doctor Image
    Clinic Name EN AR
    Education EN AR
    Language EN AR
    */
    const getPersonalInfo = async (doctorsInfoArray) => {
        // const endpoint = `${host}api/doctors/find?hospitalCode=${hospitalCode}`;
        // // const testArray = ["DR1393"]
        // // const promises = testArray.map(doctor => doctorDetailReq(doctor));
        // const promises = doctorsInfoArray.slice(0, 10).map(doctor => doctorDetailReq(host, hospitalCode, hospitalId, doctor.docCode));
        const promises = doctorsInfoArray.map(doctor => doctorDetailReq(host, hospitalCode, hospitalId, doctor.docCode));

        try {
            const results = await Promise.all(promises);
            results.forEach(result => {
                const doctorIndex = doctorsInfoArray.findIndex(doctor => doctor.docCode === result.physicianData.docCode);
                if (doctorIndex !== -1) {
                    // Update doctor information
                    doctorsInfoArray[doctorIndex].image = result?.physicianData.docImg;
                    doctorsInfoArray[doctorIndex].clincName = result?.physicianData.clinicNameEn;
                    doctorsInfoArray[doctorIndex].clincNameAr = result?.physicianData.clincNameAr;
                    doctorsInfoArray[doctorIndex].educationTxt = result?.physicianData.educationTxt;
                    doctorsInfoArray[doctorIndex].educationTxtAr = result?.physicianData.educationTxtAr;
                    doctorsInfoArray[doctorIndex].languagesTxt = result?.physicianData.languagesTxt;
                    doctorsInfoArray[doctorIndex].languagesTxtAr = result?.physicianData.languagesTxtAr;
                    doctorsInfoArray[doctorIndex].specialitiesTxt = result?.physicianData.specialitiesTxt;
                    doctorsInfoArray[doctorIndex].specialitiesTxtAr = result?.physicianData.specialitiesTxtAr;
                }
            });
        } catch (error) {
            console.error('Error fetching personal info:', error);
        }
    };

    const getClinicsList = async () => {
        // const endpoint = `${host}api/bookings/get-speciality?hospitalCode=${hospitalCode}`;
        const endpoint = `${host}api/clinic-details?hospitalCode=${hospitalCode}`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                    'HospitalID': hospitalId,
                    'Host': 'patientportal.imc.med.sa',
                    'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                    'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                    "Accept-Language": getSelectLanguage(),
                }
            });
            if (!response.ok) {
                if(response.status === 403) {
                    sessionsMiddleWare();
                }
                throw new Error(`HTTP error! getting /bookings/get-speciality status: ${response.status}`);
            }
            const data = await response.json();

            localStorage.setItem("clinicsList", JSON.stringify(data.clnicDetails))

            return data.clnicDetails;
            // clnicDetails
            // return data;
        } catch (error) {
            console.error('Error fetching /bookings/get-speciality:', error);
            return null;
        }
    }


    const getSymptomsList = async () => {
        const endpoint = `${host}api/symptom-checker/clinic?hospitalCode=${hospitalCode}&symptom=*`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                    'HospitalID': hospitalId,
                    'Host': 'patientportal.imc.med.sa',
                    'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                    'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                    "Accept-Language": getSelectLanguage(),
                }
            });
            if (!response.ok) {
                if(response.status === 403) {
                    sessionsMiddleWare();
                }
                throw new Error(`HTTP error! symptom-checker: ${response.status}`);
            }
            const data = await response.json();
            localStorage.setItem("symptomsList", JSON.stringify(data))
            return data;
        } catch (error) {
            console.error('Error fetching symptom-checker:', error);
            return null;
        }
    }

    /*
    EarliestSlot
    */
    // const getEarliestSlot = async (doctorsInfoArray) => {
    //     const endpoint = `${host}appointment/docEarliestSlot?`;

    //     // Function to make API requests
    //     const earliestSlotReq = async (specialityCode) => {
    //         const url = new URL(endpoint);

    //         const today = new Date();
    //         const futureDate = new Date();
    //         futureDate.setDate(today.getDate() + 100);
    //         // Format the dates as 'YYYY-MM-DD'
    //         const todayFormatted = formatDate(today);
    //         const futureDateFormatted = formatDate(futureDate);

    //         const params = {
    //             specialtycode: specialityCode,
    //             datefrom: todayFormatted,
    //             dateto: futureDateFormatted
    //         }

    //         // url.searchParams.append('doctorCode', docCode);
    //         for (const key in params) {
    //             url.searchParams.append(key, params[key]);
    //         }

    //         try {
    //             const response = await fetch(url, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
    //                 }
    //             });
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }
    //             return await response.json();
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             return null;
    //         }
    //     };

    //     // const testArray = ["3030"]
    //     // const promises = testArray.map(doctor => earliestSlotReq(doctor));
    //     const promises = doctorsInfoArray.map(doctor => earliestSlotReq(doctor.specialityCode));

    //     // Execute all promises simultaneously
    //     Promise.all(promises)
    //         .then(results => {
    //             // Process results
    //             results.forEach(result => {
    //                 result.docList.forEach(singledoctor => {
    //                     const doctorIndex = doctorsInfoArray.findIndex(doctor => doctor.docCode === singledoctor.providerCode);
    //                     if (doctorIndex !== -1) {
    //                         doctorsInfoArray[doctorIndex].earliestSlotDate = singledoctor.slotDate;
    //                         doctorsInfoArray[doctorIndex].earliestSlotTime = singledoctor.slotTime;
    //                     }
    //                 });
    //             });
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });

    //         console.log("doctorsInfoArray", doctorsInfoArray);
    // }
    // // console.log("doctorsInfoArray", doctorsInfoArray);
    // getEarliestSlot(doctorsInfoArray);
    
    const [docsInfoArray, clinicsList, symptomsList] = await Promise.all([
        getPersonalInfo(doctorsInfoArray),
        getClinicsList(),
        getSymptomsList()
    ]);

    return {
        doctorsInfoArray,
        clinicsList,
        symptomsList
    };

    // await getPersonalInfo(doctorsInfoArray);

    // return doctorsInfoArray;

}

export const ClinicAndSymptomsList = async () => {
    const { apiRoute } = envVariables;
    const host = apiRoute;
    // const host = process.env.REACT_APP_HOST;
    // const hospitalCode = 'IMC'
    const hospitalCode = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).code : "IMC";
    const hospitalId = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).id : "1";
    /*
    Doctor Image
    Clinic Name EN AR
    Education EN AR
    Language EN AR
    */

    const getClinicsList = async () => {
        // const endpoint = `${host}api/bookings/get-speciality?hospitalCode=${hospitalCode}`;
        const endpoint = `${host}api/clinic-details?hospitalCode=${hospitalCode}`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                    'HospitalID': hospitalId,
                    'Host': 'patientportal.imc.med.sa',
                    'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                    'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                    "Accept-Language": getSelectLanguage(),
                }
            });
            if (!response.ok) {
                if(response.status === 403) {
                    sessionsMiddleWare();
                }
                throw new Error(`HTTP error! getting /bookings/get-speciality status: ${response.status}`);
            }
            const data = await response.json();

            return data.clnicDetails;
            // clnicDetails
            // return data;
        } catch (error) {
            console.error('Error fetching /bookings/get-speciality:', error);
            return null;
        }
    }


    const getSymptomsList = async () => {
        const endpoint = `${host}api/symptom-checker/clinic?hospitalCode=${hospitalCode}&symptom=*`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                    'HospitalID': hospitalId,
                    'Host': 'patientportal.imc.med.sa',
                    'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                    'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                    "Accept-Language": getSelectLanguage(),
                }
            });
            if (!response.ok) {
                if(response.status === 403) {
                    sessionsMiddleWare();
                }
                throw new Error(`HTTP error! symptom-checker: ${response.status}`);
            }
            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Error fetching symptom-checker:', error);
            return null;
        }
    }


    
    const [clinicsList, symptomsList] = await Promise.all([
        getClinicsList(),
        getSymptomsList()
    ]);
    
    localStorage.setItem("clinicsList", JSON.stringify(clinicsList))
    localStorage.setItem("symptomsList", JSON.stringify(symptomsList))


    return {
        clinicsList,
        symptomsList
    };
}