import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  authenticationActions,
  bookAppointmentActions,
  modalActions,
  searchbarActions,
} from '../../../store';
import { removeBadge } from '../../../store/badgesSlice';
import { selectLanguage } from '../../../store/languageSlice';
import { clearLocalStorageExcept } from '../../../utilFunctions/SessionExpire';
import CustomeModal from '../../Modal/CustomeModal';

export default function SessionExpireModal() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, isRtl } = useSelector(selectLanguage);
  const { modalSessionVisible } = useSelector((state) => state.modal);

  const handleOnLoginAgainClick = () => {
    clearLocalStorageExcept();
    dispatch(authenticationActions.authenticateToggle(false));
    dispatch(searchbarActions.selectedSpecialityCode(null));
    dispatch(searchbarActions.selectedDoctorCode(null));
    dispatch(searchbarActions.handleSeacrhByClinic(false));
    dispatch(bookAppointmentActions.docAvailableDates(null));
    dispatch(bookAppointmentActions.selectedDocCode(null));
    dispatch(removeBadge());
    dispatch(modalActions.closeModal());
    dispatch(authenticationActions.updateRedirected(location.pathname));
    navigate('/login');
  };

  if (!modalSessionVisible) return null;

  return (
    <CustomeModal
      title={t.Error}
      isOpen={modalSessionVisible}
      onCancel={handleOnLoginAgainClick}
      cancelText={t.ok}
    >
      <p className="appntInfo">{t.sessionExpireMsg}</p>
    </CustomeModal>
  );
}
