import moment from 'moment';
import React, { Fragment, memo, useEffect, useState } from 'react';
import {
  FiDownload,
  FiClock,
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
} from 'react-icons/fi';
import { CgSoftwareDownload } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { selectReports } from '../../../store/reportsSlice';
import { formatDate } from '../../../translations/common';
import { downloadReport, downloadLabSmartReport } from '../../../utilFunctions/reports/reports';
import ResultTable from './ResultTable';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import { timeFormat } from '../../../utilFunctions/DateFormatter';
import { formatToTwoDecimalPlaces } from '../../../utilFunctions/common';
import { NavLink } from 'react-router-dom';

const ReportItem = ({ t, isRtl, item }) => {
  const [showTable, setShowTable] = useState(false);
  const result_list = item?.result_list || [];
  const handleToggleTable = () => {
    setShowTable(!showTable);
  };
  const formatedItem = {
    reportName: item?.order_description,
    value: result_list[0]?.result_value|| '',
    referenceRange: result_list[0]?.result_normal_range || '',
    Intrepatation: result_list[0]?.result_interpretation || '',
    dateTime: `${item?.collected_date} ${timeFormat(item?.collected_time)}`,
  };
  const { isMobile } = useBreakpoint();
  return (
    <>
      {!isMobile && (
        <>
          <div onClick={handleToggleTable} className="cursor-pointer">
            <div className="type">
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/ic_lab_report_hov.svg`}
                alt="report icon"
              />
              {formatedItem.reportName}
            </div>
            <div
              className={`${
                formatedItem.Intrepatation.trim() !== ''
                ? 'text-tertiary'
                  : 'text-green'
                  
              } ${!formatToTwoDecimalPlaces(formatedItem.value).isString ? 'justify-center':''}`}
            >
              {/* {t.Value}:
              <span className="text-secondary font-medium">
                {formatedItem.value}
              </span> */}
              {formatToTwoDecimalPlaces(formatedItem.value).value}
            </div>
            <div className="rng">
              {/* {t.ReferenceRange}:
              <span className="text-secondary font-medium">
                {formatedItem.referenceRange}
              </span> */}
              {formatedItem.referenceRange}
            </div>
            <div className="intp">
              {/* {t.Intrepatation}:
              <span className="text-secondary font-medium">
                {formatedItem.Intrepatation}
              </span> */}
              {formatedItem.Intrepatation}
            </div>
            <div className="clctDate">
              {/* {t.CollectionDateTime}:
              <span className="text-secondary font-medium">
                {formatedItem.dateTime}
              </span> */}
              {formatedItem.dateTime}
            </div>
          </div>
          {showTable && <ResultTable t={t} isRtl={isRtl} data={result_list} />}
        </>
      )}
      {isMobile && (
        <div className="sngl">
          <p className={`${
                formatedItem.Intrepatation.trim() !== ''
                ? 'text-tertiary'
                  : 'text-green'
                  
              }`}>
            <span>{t.Value}:</span>
            {formatedItem.value}
          </p>
          <p>
            <span>{t.ReferenceRange}:</span>
            {formatedItem.referenceRange}
          </p>
          <p>
            <span>{t.Intrepatation}:</span>
            {formatedItem.Intrepatation}
          </p>
          <p>
            <span>{t.CollectionDateTime}:</span>
            {formatedItem.dateTime}
          </p>
          <p className="name">{formatedItem.reportName}</p>
        </div>
      )}
    </>
  );
};

const LabReportCard = ({ listData, currentData }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const [reportData, setReportData] = useState([]);
  const [showRecords, setShowRecord] = useState([]);
  const { oldData } = useSelector(selectReports);
  const { isMobile } = useBreakpoint();

  const handleDownloadReport = async (specimanId) => {
    if (!specimanId || specimanId == '') return;

    await downloadReport(specimanId, oldData);
  };

  const handleDownloadSmartReport = async (orderId) => {
    if (!orderId || orderId == '') return;

    await downloadLabSmartReport(orderId, oldData);
  };

  const toggleShowRecords = (indexId) => {
    if (showRecords.includes(indexId)) {
      setShowRecord(showRecords.filter((x) => x !== indexId));
    } else {
      setShowRecord([...showRecords, indexId]);
    }
  };

  const formatDataGroup = (data) => {
    const sorted = data
      .slice()
      .sort((a, b) => moment(b.collected_date).diff(moment(a.collected_date)));

    const groupedData = sorted.reduce((acc, curr) => {
      const labEpisodeNumber = curr.labepisode_number;
      const existingGroupIndex = acc.findIndex(
        (group) => group[0]?.labepisode_number === labEpisodeNumber
      );
      if (existingGroupIndex === -1) {
        acc.push([curr]);
      } else {
        acc[existingGroupIndex].push(curr);
      }
      return acc;
    }, []);

    return groupedData;
  };

  useEffect(() => {
    if (listData?.length > 0) {
      const group = formatDataGroup(listData);
      setReportData([...reportData, ...group]);
    }
  }, [listData]);

  if (!currentData) return null;

  if (listData.length === 0)
    return (
      <div className="w-full h-full mt-14 flex justify-center">
        <div className="font-semibold text-2xl text-blue-900">
          {t.NoDataFound}
        </div>
      </div>
    );

  return (
    <Fragment key={'RaioLab'}>
      {Object.keys(reportData).map((key, index) => {
        const recordsList = reportData[key];
        const firstItem = recordsList[0];
        const orderItem = {
          date: firstItem?.collected_date,
          oderId: firstItem?.order_id,
          docName: firstItem?.ordered_doctor,
          time: firstItem?.collected_time,
          subRecords: recordsList[0]?.result_list || [],
          isSmartReport: firstItem?.smart_report
          // isSmartReport: true
        };
        const fDate = formatDate(orderItem.date, isRtl);
        const time = moment(orderItem.time, 'HH:mm').format('hh:mm A');
        const show = showRecords.includes(index);
        return (
          <>
            {!isMobile && (
              <div key={'lab' + index} className="sngRpt">
                <div className="info">
                  <div className="no">{index + 1}.</div>
                  <div className="date">
                    <span>{fDate.weekName}</span>
                    {`${fDate.monthDate} ${fDate.monthNameShort}`}
                    {/* <span>{`${fDate.year}`}</span> */}
                  </div>
                  <div className="year">{`${fDate.year}`}</div>
                  <div className="id">{orderItem?.oderId || ''}</div>
                  <div className="by">{orderItem?.docName || ''}</div>
                  <div className="time">{time || ''}</div>
                  <div
                    className="download"
                  >
                    <button onClick={() =>
                      handleDownloadReport(firstItem.specimen_number)
                    }>
                      {t.Download} <CgSoftwareDownload />
                    </button>
                   {orderItem.isSmartReport && <div className='linkButton' onClick={() =>
                      handleDownloadSmartReport(firstItem.order_accession_number)
                    }>
                     {t.SmartReport}
                    </div>}
                  </div>
                  <button
                    onClick={() => toggleShowRecords(index)}
                    className="collapseBtn"
                  >
                    {show ? <FiChevronUp /> : <FiChevronDown />}
                  </button>
                </div>
                {show && (
                  <div className="otherInfo">
                    <div className="otherInfoHead">
                      <div className="type">
                        <div className="opacity-0">type</div>
                      </div>
                      <div className="val">
                        <div>{t.Value}</div>
                      </div>
                      <div className="rng">
                        <div>{t.ReferenceRange}</div>
                      </div>
                      <div className="intp">
                        <div>{t.Intrepatation}</div>
                      </div>
                      <div className="clctDate">
                        <div>{t.CollectionDateTime}</div>
                      </div>
                    </div>
                    {recordsList.map((item, i) => (
                      <ReportItem
                        t={t}
                        isRtl={isRtl}
                        item={item}
                        key={'rt' + i}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {isMobile && (
              <div className="snglRprt">
                <div className="card">
                  <div className="date text-gray-500">
                    <span className="day font-medium text-slate-950">
                      {fDate.monthNameShort}
                    </span>
                    <span className="date font-bold text-24 text-primary">
                      {fDate.monthDate}
                    </span>
                    {fDate.year}
                  </div>
                  <div className="info">
                    <h3 className="text-slate-950">
                      Order ID: {orderItem?.oderId || ''}
                    </h3>
                    <h4 className="text-green">{orderItem?.docName || ''}</h4>
                    <p className=" text-gray-500">
                      <FiClock /> at {time || ''}
                    </p>
                  </div>
                  <div className="btns">
                    <button
                      className="dwnld"
                      onClick={() =>
                        handleDownloadReport(firstItem.specimen_number)
                      }
                    >
                      {t.Download} <CgSoftwareDownload />
                    </button>
                    {orderItem.isSmartReport && <div className='linkButton' onClick={() =>
                      handleDownloadSmartReport(firstItem.order_accession_number)
                    }>
                     {t.SmartReport}
                    </div>}
                    <button
                      className="collapseBtn"
                      onClick={() => toggleShowRecords(index)}
                    >
                      {show ? <FiChevronDown /> : <FiChevronLeft />}
                    </button>
                  </div>
                </div>
                {show && (
                  <div className="moreInfo">
                    {recordsList.map((item, i) => (
                      <ReportItem
                        t={t}
                        isRtl={isRtl}
                        item={item}
                        key={'rt' + i}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        );
      })}
    </Fragment>
  );
};

export default memo(LabReportCard);
