import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDoctorSidebarInfo } from '../../../utilFunctions/getDoctorSidebarInfo';
import { envVariables } from '../../../config';
import './style.css';
import { selectLanguage } from '../../../store/languageSlice';
import { getTValue } from '../../../translations/common';
import { getLanguagesInText } from '../../../utilFunctions/common';
import educationHtmlParser from '../../../utilFunctions/educationHtmlParser';

const RightSidebar = ({ doctorsInfoList }) => {
  const { assetsRoute } = envVariables;
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  // const docCode = useSelector((state) => state.bookAppointment.docCode);
  const docCode = useSelector((state) => state.portal.showDocInfoCode);
  const [selectedDocInfo, setSelectedDocInfo] = useState(null);
  const { t, isRtl } = useSelector(selectLanguage);
  const doctorInfo = async (docInfo) => {
    const infoList = await getDoctorSidebarInfo(docCode);
    const infoArray = { ...docInfo, ...infoList };
    setSelectedDocInfo(infoArray);
  };

  useEffect(() => {
    const doctorsBasicInfoList = JSON.parse(localStorage.getItem("doctorsInfomation"))
    if (docCode) {
      const getDoctorInfo = doctorsBasicInfoList?.filter((doctor) => {
        return doctor.docCode === docCode;
      });
      // const docInfo = getDoctorInfo(docCode);
      // setSelectedDocInfo(getDoctorInfo[0])
      doctorInfo(getDoctorInfo[0]);
    } else {
      setSelectedDocInfo(null);
    }
  }, [docCode]);

  //     clincName: "Allergy Immunology  , Internal Medicine  , Oncology Center"
  // ​​​
  // clincNameAr: "Allergy Immunology  , Internal Medicine  , Oncology Center"
  // ​​​
  // docCode: "DR1393"
  // ​​​
  // docName: "Adeeb Bulkhi"
  // ​​​
  // docNameAR: "أديب بلخي"
  // ​​​
  // educationTxt: "<ul><li>Bachelor’s Degree in Medicine and Surgery, Umm Al Qura University, KSA</li><li>Master’s Degree in Clinical Research Design and Statistical Analysis, University of Michigan, USA</li><li> Fellowship in Allergy and Immunology</li><li> Fellowship in Advance Allergy and Immunology</li><li> American Board of Internal Medicine</li><li> American Board of Allergy and Immunology</li></ul>"
  // ​​​
  // educationTxtAr: '<ul><li class="ql-align-right ql-direction-rtl">درجة البكالوريوس في الطب والجراحة العامة, جامعة أم القرى، المملكة العربية السعودية</li><li class="ql-align-right ql-direction-rtl">درجة الماجستير في تصميم البحوث السريرية والتحليل الإحصائي, جامعة ميتشيجان، أمريكا</li><li class="ql-align-right ql-direction-rtl">, درجة الزمالة في أمراض الحساسية والمناعة</li><li class="ql-align-right ql-direction-rtl">, درجة الزمالة في أمراض الحساسية المتقدمة والمناعة</li><li class="ql-align-right ql-direction-rtl">, البورد الأمريكي للطب الباطني</li><li class="ql-align-right ql-direction-rtl">, البورد الأمريكي لأمراض الحساسية والمناعة</li></ul>'
  // ​​​
  // image: "DR1393.jpg"
  // ​​​
  // languagesTxt: '<ul><li><span class="ql-size-large">English</span></li><li><span class="ql-size-large">Arabic</span></li></ul>'
  // ​​​
  // languagesTxtAr: '<ul><li class="ql-direction-rtl ql-align-right"><span class="ql-size-large">الإنجليزية</span></li><li class="ql-direction-rtl ql-align-right"><span class="ql-size-large">عربى</span></li></ul>'
  // ​​​
  // specialitiesTxt: '<p><span class="ql-size-large">Consultant, Internal Medicine / Immunology Allergy</span></p>'
  // ​​​
  // specialitiesTxtAr: '<p class="ql-align-right"><span class="ql-size-large">استشاري أمراض الحساسية والمناعة</span></p>'
  // ​​​
  // specialityCode: "3030"
  // ​​​
  // specialityDescription: "Allergy Immunology "
  // ​​​
  // specialityDescriptionAR: "طب الحساسيه والمناعه"

  return (
    <>
      {selectedDocInfo && (
        <div className="bg-white p-5 ba-sdbr rounded-lg">
          <div className="docIndoSdbr">
            <div className="imgRw">
              <img
                src={`${assetsRoute}${hospCode}/${docCode}.jpg`}
                onError={(e) => {
                  if (
                    e.target.src !==
                    `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                  ) {
                    e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                  }
                }}
                alt={selectedDocInfo.docName}
              />
              {/* {
                                selectedDocInfo.image ? 
                                <img src={`${assetsRoute}${hospCode}/${selectedDocInfo.image}`} alt={selectedDocInfo.docName} />
                                :
                                <img src={`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`} alt={selectedDocInfo.name} />
                            } */}
              <div className="info">
                {selectedDocInfo.docName ? (
                  <h4>
                    {getTValue(selectedDocInfo, 'docNameAR', 'docName', isRtl)}
                  </h4>
                ) : (
                  <h4>{t.DoctorName}</h4>
                )}
                {selectedDocInfo.specialityDescription ? (
                  <p>
                    {getTValue(
                      selectedDocInfo,
                      'specialityDescriptionAR',
                      'specialityDescription',
                      isRtl
                    )}
                  </p>
                ) : (
                  <h4>{t.ClinicName}</h4>
                )}
                {selectedDocInfo.clinicNameEn ? (
                  <p className='!mt-2 text-green'>
                    {getTValue(
                      selectedDocInfo,
                      'clincNameAr',
                      'clinicNameEn',
                      isRtl
                    )}
                  </p>
                ) : (
                  null
                )}
                {selectedDocInfo.languagesTxt ? (
                  <div className="lngs">
                    <p className='font-medium'>{t.LanguagesSpoken}</p>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: getTValue(
                          selectedDocInfo,
                          'languagesTxtAr',
                          'languagesTxt',
                          isRtl
                        ),
                      }}
                    /> */}
                    <ul>
                        {/* <li><span><img src={`${process.env.PUBLIC_URL}/images/saFlg.svg`} alt="saFlg.svg" /></span> Arabic</li>
                        <li><span><img src={`${process.env.PUBLIC_URL}/images/ukFlg.svg`} alt="ukFlg.svg" /></span> English</li> */}
                        {getLanguagesInText(selectedDocInfo?.languagesTxt).map(
                          (language, index) => (
                            <li key={index}>
                              <span>
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/${language.flag}`}
                                  alt={language.nameEn}
                                />
                              </span>{' '}
                              {isRtl ? language.nameAr : language.nameEn}
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            {selectedDocInfo.specialitiesTxt ? (
              <div className="lsts splts">
                <h5>{t.Specialties}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getTValue(
                      selectedDocInfo,
                      'specialitiesTxtAr',
                      'specialitiesTxt',
                      isRtl
                    ),
                  }}
                />
              </div>
            ) : null}
            {selectedDocInfo.educationTxt ? (
              <div className="lsts edutn">
                <h5>{t.Education}</h5>
                <ul>
                {
                  educationHtmlParser(isRtl ? selectedDocInfo.educationTxtAr : selectedDocInfo.educationTxt).map(
                    (education, index) => (
                      <li key={index}>
                        <p>{education.degree}</p>
                        <p className="text-secondary">{education.institute}</p>
                      </li>
                    )
                  )
                }
                </ul>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: getTValue(
                      selectedDocInfo,
                      'educationTxtAr',
                      'educationTxt',
                      isRtl
                    ),
                  }}
                /> */}
              </div>
            ) : null}
            {/* <div className="lsts brd">
                            <h5>Board</h5>
                            <ul>
                                <li>
                                    <h6>2011</h6>
                                    <p>American Board of Internal Medicine</p>
                                </li>
                                <li>
                                    <h6>2011</h6>
                                    <p>Canadian Board of Internal Medicine</p>
                                </li>
                                <li>
                                    <h6>2011</h6>
                                    <p>American Board of Internal Medicine</p>
                                </li>
                                <li>
                                    <h6>2011</h6>
                                    <p>Canadian Board of Internal Medicine</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lsts wrkExpr">
                            <h5>Work Experience</h5>
                            <ul>
                                <li>
                                    <h6>2016- Present</h6>
                                    <p>Consultant, Endocrinology and Metabolism</p>
                                    <p className="text-secondary">International Medical Centre in Jeddah, Saudi Arabia</p>
                                </li>
                                <li>
                                    <h6>2014- 2018</h6>
                                    <p>Consultant, Endocrinology and Metabolism</p>
                                    <p className="text-secondary">Security Forces Hospital, Makkah, Saudi Arabia</p>
                                </li>
                                <li>
                                    <h6>2016- Present</h6>
                                    <p>Consultant, Endocrinology and Metabolism</p>
                                    <p className="text-secondary">International Medical Centre in Jeddah, Saudi Arabia</p>
                                </li>
                                <li>
                                    <h6>2014- 2018</h6>
                                    <p>Consultant, Endocrinology and Metabolism</p>
                                    <p className="text-secondary">Security Forces Hospital, Makkah, Saudi Arabia</p>
                                </li>
                            </ul>
                        </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default RightSidebar;
