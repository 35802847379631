import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions, portalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';
import { getTValue } from '../../translations/common';
import {
  userProfileDetailReq,
  verifyEmail,
} from '../../utilFunctions/profile/EditProfile';
import {
  getReportTypeDropdown,
  getRequestedReport,
  getVistTypeDropdown,
} from '../../utilFunctions/reports/request-report';
import { submitFormRequest } from '../../utilFunctions/secondOpinion';
import useDataFetching from '../../utilFunctions/useFetchData';
import Modal from '../Modal/Modal';
import Loader from '../UI/Loader';
import ReasonsDropdown from './Dropdown/ReasonsDropdown';
import YesNoDropdown from './Dropdown/YesNoDropdown';
import useFetchData from '../../utilFunctions/useFetchData';
import './style.css';

export default function SecondOpinion() {
  const dispatch = useDispatch();
  const { data: episodesTypeList } = useFetchData(getVistTypeDropdown);
  const { modalType } = useSelector((state) => state.modal);
  const {
    data: profileData,
    loading,
    error,
  } = useDataFetching(userProfileDetailReq);
  const { selectedHospital } = useSelector((state) => state.hospitalInfo);
  const { t, isRtl } = useSelector(selectLanguage);
  const PHONE_CODE = '+966';
  const [messageRequire, setMessageRequire] = useState(false);
  const [laoding, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isVerified, setIsVerified] = useState(true);
  const [showVerifyLoader, setShowVerifyLoader] = useState(false);

  const [responseMsg, setResponseMsg] = useState({
    emailVerify: {
      error: false,
      success: false,
      message: '',
    },
    onRequest: {
      error: false,
      success: false,
      message: '',
    },
  });

  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    mrNumber: '',
    message: '',
    reason: '',
    seenByIMCDoctor: '',
    casesUrgency: '',
  });

  const isValidEmail = (email) => {
    // Regular expression pattern to validate email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is not empty and matches the regex pattern
    return email && email !== '' && emailRegex.test(email);
  };

  const handleVerifyEmail = async () => {
    if (!isValidEmail(formData?.email)) {
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: 'Not Valid Email',
        },
      });

      return;
    }
    setShowVerifyLoader(true);
    const req = await verifyEmail(formData.email);

    setShowVerifyLoader(false);
    if (req && req?.status !== 'false') {
      const validateResponse = {
        newEmail: formData.email,
        patid: profileData?.patientProfile?.patId,
        email: formData.email,
        patient_id: '26',
        otp: '2521',
      };
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: false,
          success: true,
          message: req?.message,
        },
      });

      dispatch(modalActions.validateEmail(validateResponse));
      dispatch(modalActions.opendMayoform(true));
    } else {
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: req?.message,
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    messageRequire && setMessageRequire(false);
  };

  const showSuccessMessageAlert = () => {
    setTimeout(() => {
      dispatch(modalActions.toggle('secondOpinionSuccess'));
    }, 200);
  };

  const handleSendApi = async (event) => {
    event.preventDefault();

    if (!isVerified)
      return setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: t.EmailNotVerifiedYet,
        },
      });
    setApiError(false);
    if (
      formData?.message == '' ||
      formData?.seenByIMCDoctor == '' ||
      formData?.casesUrgency == '' ||
      formData?.reason == ''
    )
      return setMessageRequire(true);

    try {
      const requestDetails = {
        task_type: 'Mayo Clinic Second Opinion',
        imc_checkup: formData?.seenByIMCDoctor,
        case_urgency: formData?.casesUrgency,
        mayo_request_reason: formData?.reason,
      };

      const userDetails = {
        mrNumber: formData?.mrNumber,
        email: formData?.email,
        phone: formData?.phone,
        title: 'Second Opinion for MRN: '+formData?.mrNumber,
        name: formData?.name,
        message: formData?.message,
      };
      setLoading(true);
      const response = await submitFormRequest(requestDetails, userDetails);
      if (!response || response.status !== 'Success') {
        throw new Error('error from response');
      }
      setLoading(false);
      dispatch(portalActions.toggleSecondOpinionDrawer());
      showSuccessMessageAlert();
    } catch (e) {
      setLoading(false);
      setApiError('Could not process your request');
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo || profileData) {
      const firstName = getTValue(userInfo, 'arabic_first_name', 'firstName');
      const lastName = getTValue(userInfo, 'arabic_last_name', 'lastName');

      setFormData({
        ...formData,
        email: profileData?.patientProfile?.email || userInfo?.email || '',
        phone: userInfo?.mobileNumber || '',
        mrNumber: userInfo?.mrn || '',
        name: `${firstName} ${lastName}` || '',
      });

      const isEmailVerified =
        profileData?.patientProfile?.isEmailVerified === '1';
      setIsVerified(isEmailVerified);
    }
  }, [profileData]);

  const validateUrgencyCase = () => {
    const urgenCaseValue = formData?.casesUrgency;
    const isSupportMayoClinicUrgency =
      selectedHospital?.isSupportMayoClinicUrgency;

    // if (urgenCaseValue === 'Yes' && !isSupportMayoClinicUrgency) {
    if (urgenCaseValue === 'Yes') {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className="px-8">
        <form onSubmit={handleSendApi}>
          <label htmlFor="mrNumber">
            {t.MR_Number} <br />
            <input
              id="mrNumber"
              type="text"
              name="mrNumber"
              defaultValue={formData?.mrNumber}
              disabled={true}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="name">
            {t.Name} <br />
            <input
              id="name"
              type="text"
              name="name"
              defaultValue={formData?.name}
              disabled={true}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="email">
            {t.EmailAddress} <br />
            <input
              id="email"
              type="email"
              name="email"
              disabled={true}
              defaultValue={formData?.email}
              onChange={handleChange}
            />
          </label>
          {responseMsg?.emailVerify.message !== '' && (
            <h5
              className="text-blue-700 my-2"
              style={{
                color: responseMsg?.emailVerify.error
                  ? 'var(--color-tertiary)'
                  : 'green',
              }}
            >
              {responseMsg?.emailVerify.message}
            </h5>
          )}
          {!isVerified && (
            <div className="w-full flex justify-end space-x-2">
              {showVerifyLoader ? (
                <Loader roundLoader />
              ) : (
                <button
                  style={{ backgroundColor: '#00B0E6' }}
                  className="px-4 py-1 rounded text-white"
                  // disabled={isValidEmail(formData?.email)}
                  onClick={handleVerifyEmail}
                >
                  {t.Verify}
                </button>
              )}
            </div>
          )}
          <label htmlFor="phone">
            {t.CellPhoneNumber}
            <br />
            <div dir="ltr" className="countrycode">
              <div className="code text-secondary">({PHONE_CODE})</div>
              <input
                id="phone"
                type="number"
                name="phone"
                disabled={true}
                placeholder="5xxxxxxxx"
                defaultValue={formData?.phone}
                onChange={handleChange}
              />
            </div>
          </label>

          <label htmlFor="seenByIMCDoctor">
            {t.SelectEpisode}* <br />
            <div className="selectWithName relative">
              <select
                name="seenByIMCDoctor"
                defaultValue={formData?.visitType}
                id="seenByIMCDoctor"
                onChange={handleChange}
                className="custom-select"
              >
                <option value="">{t.Select}</option>

                {episodesTypeList?.episode_list?.map((x) => {
                  const label = `${x?.episode_date}-${x?.episodeType}-${
                    x.care_provider_description ?? ''
                  }`;
                  const value = x.episode_id;

                  return <option value={value}>{label}</option>;
                })}
              </select>
              <span className="newArrowDropdown">&#9662;</span>
            </div>
          </label>
          <label htmlFor="casesUrgency">
            {t.casesUrgency}* <br />
            <div className="selectWithName relative">
              <select
                name="casesUrgency"
                defaultValue={formData?.casesUrgency}
                id="casesUrgency"
                onChange={handleChange}
                className="custom-select"
              >
                <option value="">{t.Select}</option>

                {[
                  { label: t.Yes, value: 'Yes' },
                  { label: t.No, value: 'No' },
                ]?.map((x) => {
                  return <option value={x.value}>{x.label}</option>;
                })}
              </select>
              <span className="newArrowDropdown">&#9662;</span>
            </div>
            {validateUrgencyCase() && (
              <label className="text-tertiary">{t.errorMsgCasesUrgency}</label>
            )}
          </label>

          <ReasonsDropdown formData={formData} handleChange={handleChange} />

          <label htmlFor="message">
            {t.Message}* <br />
            <textarea
              id="message"
              name="message"
              placeholder={t.TypeMessage}
              defaultValue={formData?.message}
              onChange={handleChange}
            ></textarea>
            <br />
            {messageRequire && (
              <label className="text-tertiary">{t.AllFieldsRequired}</label>
            )}
          </label>

          {apiError && <label className="text-tertiary">{apiError}</label>}
          <div className="flex justify-center mt-3">
            <button type="submit" className="request_btn">
              {laoding ? <Loader size="small" roundLoader /> : t.send}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
