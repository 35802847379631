import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

import DependentItem from './DependentItem';

const MyDependents = React.memo(({ data, onClickDependent }) => { 
  const {t} = useSelector(selectLanguage)
  const dependents = data?.dependants || [];

  return (
    <div className="mt-8">
      <h3 className="pb-2 text-xl text-grey-700">{t.My_Dependents}</h3>
      {dependents?.length <= 0 && (
        <h5 className="mt-2 text-xl  text-grey-700">{t.No_Data}</h5>
      )}
      {dependents?.map((x, index) => {
        return (
          <div key={'gu' + index}>
            <DependentItem data={x} onClickDependent={onClickDependent} />
          </div>
        );
      })}
    </div>
  );
});

export default MyDependents;
