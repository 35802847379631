import ReactDOM from 'react-dom';

export const DrawerPortalDom = ({ children }) => {
  const portalRoot = document.getElementById('drawer-portal-root');
  return ReactDOM.createPortal(children, portalRoot);
};

export const ModalPortalDom = ({ children }) => {
  const portalRoot = document.getElementById('modal-portal-root');
  return ReactDOM.createPortal(children, portalRoot);
};

