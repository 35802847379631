import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from '../../../store';
import { selectLanguage } from '../../../store/languageSlice';
import { selectReports } from '../../../store/reportsSlice';

const ReportSwitch = () => {
  const dispatch = useDispatch();
  const { oldData } = useSelector(selectReports);
  const { t } = useSelector(selectLanguage);
  const handleCheckboxChange = () => {
    // 0 for new
    // 1 for history
    dispatch(reportActions.handleUpdateReportData(oldData === 1 ? 0 : 1));
  };

  useEffect(()=>{

  },[])

  return (
    <>
      <label className={oldData === 0 ? "checked customToggle" : "customToggle"} htmlFor="reportTypeTgle">
        <input
          id='reportTypeTgle'
          type="checkbox"
          checked={oldData === 0 ? true : false}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <span className="text">{oldData === 0 ? t.New : t.History}</span>
        <span className="circle"></span>
      </label>
      {/* <label className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
        <input
          type="checkbox"
          className="sr-only"
          checked={oldData === 0 ? true: false}
          onChange={handleCheckboxChange}
        />
        <span
          className={`flex items-center space-x-[6px] rounded py-1 px-[6px] text-sm font-medium ${
            oldData === 0  ? 'bg-primary text-white' : 'text-body-color'
          }`}
        >
          {t.New}
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded py-1 px-[6px] text-sm font-medium ${
            oldData === 1  ? 'bg-primary text-white' : 'text-body-color'
          }`}
        >
          {t.History}
        </span>
      </label> */}
    </>
  );
};

export default ReportSwitch;
