import { useEffect, useState, useRef, useCallback } from 'react';

const useInView = (options) => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef(null);

    const callback = useCallback((entries) => {
        const [entry] = entries;
        setIsInView(entry.isIntersecting);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);
        const currentElement = ref.current;

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [ref, options, callback]);

    return [ref, isInView];
};

export default useInView;