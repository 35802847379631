import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimeSlotSlider from '../DoctorList/TimeSlotSlider';
import { getDoctorPersonalInfo } from '../../../utilFunctions/bookAppointment/getDoctorPersonalInfo';
import { bookAppointmentActions } from '../../../store';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { envVariables } from '../../../config';
import { selectLanguage } from '../../../store/languageSlice';
import { getTValue } from '../../../translations/common';
import MonthsDropdown from '../MonthsDropdown/MonthsDropdown';
import DatesSlider from '../DoctorList/DatesSlider';
import { getLanguagesInText } from '../../../utilFunctions/common';
import { portalActions } from '../../../store';
import moment from 'moment';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import formatDate from '../../../utilFunctions/DateFormatter';
import { getSelectLanguage } from '../../../utilFunctions/getCurrentSelectLanguage';
import { sessionsMiddleWare } from '../../../utilFunctions/SessionExpire';
import Loader from '../../UI/Loader';
import '../DoctorList/DoctorCard.css';

const SelectedDoctorCard = ({
  docExtraDetails,
  cardIndex = 'DocSlots',
  availableDatesHandler,
}) => {
  const { assetsRoute } = envVariables;
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  const docName = useSelector((state) => state.bookAppointment.doctorName);
  const docNameAr = useSelector((state) => state.bookAppointment.doctorNameAr);
  const selectedDoc = useSelector((state) => state.bookAppointment.docCode);

  const selectedDate = useSelector(
    (state) => state.bookAppointment.selectedDate
  );
  const appointmentType = useSelector(
    (state) => state.bookAppointment.appointmentType
  );
  const { t, isRtl } = useSelector(selectLanguage);
  const [loading, setLoading] = useState(false);
  const [doctorPersonalInfo, setdoctorPersonalInfo] = useState(null);
  const [onlineData, setOnlineData] = useState([]);
  const [offlineData, setOfflineData] = useState([]);

  const findDocService = (type) => {
    // type = 'Online' , 'Offline'
    const foundItem = docExtraDetails?.find(
      (x) => x.providerCode === selectedDoc
    );

    if (foundItem) {
      const foundTele = foundItem.serviceList?.find((x) => x.type === type);

      return foundTele;
    }

    return null;
  };

  const isFoundInExtraDetails = () => {
    const foundItem = docExtraDetails?.find(
      (x) => x.providerCode === selectedDoc
    );

    return foundItem ?? null;
  };

  const getDoctorAvailablity = async (currentFoundService, type) => {
    const currentExtraInfo = isFoundInExtraDetails();
    const { apiRoute } = envVariables;
    const endpoint = `${apiRoute}api/bookings/get-doctor-availability?`;
    const url = new URL(endpoint);

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 100);
    // Format the dates as 'YYYY-MM-DD'
    const todayFormatted = formatDate(today);
    const futureDateFormatted = formatDate(futureDate);

    const params = {
      providercode: currentExtraInfo?.providerCode,
      servicecode: currentFoundService?.serviceCode,
      specialitycode: currentExtraInfo?.specialityCode,
      datefrom: todayFormatted,
      dateto: futureDateFormatted,
    };

    // url.searchParams.append('doctorCode', docCode);
    for (const key in params) {
      url.searchParams.append(key, params[key]);
    }
    setLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('userInfo')).accessToken
          }`,
          'Accept-Language': getSelectLanguage(),
        },
      });
      if (!response.ok || response.status === 403) {
        if (response.status === 403) {
          sessionsMiddleWare();
        }
        throw new Error(
          `HTTP error! status get-doctor-availability: ${response.status}`
        );
      }
      // return await response.json();
      const data = await response.json();
      type === 'Offline' && setOfflineData(data.slotList);
      type === 'Online' && setOnlineData(data.slotList);
      setLoading(false);
      return availableDatesHandler(data.slotList);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data get-doctor-availability:', error);
      return null;
    }
  };

  const changeServiceTypeHandler = async (e, type) => {
    e.stopPropagation();
    const currentFoundService = findDocService(type);
    if (currentFoundService && appointmentType !== type && !loading) {
      dispatch(bookAppointmentActions.selectedAppointmentType(type));
      await getDoctorAvailablity(currentFoundService, type);
    }
  };

  const showDoctorProfileHandler = (e, docCode) => {
    e.stopPropagation();
    dispatch(portalActions.toggleDoctorProfileDrawer(docCode));
  };

  const getDoctorInfo = async () => {
    // if (!doctorInfo) return;
    const info = await getDoctorPersonalInfo(selectedDoc);
    info && setdoctorPersonalInfo(info);
  };

  useEffect(() => {
    getDoctorInfo();
  }, [selectedDoc]);

  const currentExtraInfo = isFoundInExtraDetails();

  return (
    <>
      {docExtraDetails && currentExtraInfo && (
        <div
          key={cardIndex}
          className={`sngDoc sorted card mb-5 fade-in active`}
        >
          {!isMobile && (
            <div className="prfImg">
              <img
                src={`${assetsRoute}${hospCode}/${selectedDoc}.jpg`}
                onError={(e) => {
                  if (
                    e.target.src !==
                    `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                  ) {
                    e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                  }
                }}
              />
            </div>
          )}
          {!isMobile && (
            <div className="docInfoCnt">
              <div className={`docInfo`}>
                <div className="imgRow flex flex-wrap gap-5 justify-between">
                  <div className="imgSd">
                    <div className="info">
                      <h4>{isRtl ? docNameAr : docName}</h4>
                      <p>
                        {getTValue(
                          currentExtraInfo,
                          'specialityDescriptionAr',
                          'specialityDescriptionEn',
                          isRtl
                        )}
                      </p>
                      <p className="text-green">
                        {getTValue(
                          doctorPersonalInfo,
                          'clincNameAr',
                          'clinicNameEn',
                          isRtl
                        )}
                      </p>
                      {docExtraDetails && (
                        <p className="!text-tertiary">
                           {t.EarliestAppointment} : <span dir='ltr'>{`${moment(currentExtraInfo?.slotDate).format('DD-MMM-YYYY')} 
                                ${moment(currentExtraInfo?.slotTime,'HH:mm:ss').format('HH:mm') }`}</span>
                        </p>
                      )}
                      <button
                        onClick={(e) =>
                          showDoctorProfileHandler(
                            e,
                            doctorPersonalInfo.docCode
                          )
                        }
                      >
                        {t.ViewProfile}
                      </button>
                    </div>
                  </div>
                  <div className="lngSd">
                    <p className="font-medium">{t.LanguagesSpoken}</p>
                    <ul>
                      {getLanguagesInText(doctorPersonalInfo?.languagesTxt).map(
                        (language, index) => (
                          <li key={index}>
                            <span>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/${language.flag}`}
                                alt={language.nameEn}
                              />
                            </span>
                            {isRtl ? language.nameAr : language.nameEn}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {currentExtraInfo.serviceList ? (
                <div className="cntctRow flex flex-wrap justify-between">
                  <ul className="flex">
                    {currentExtraInfo.serviceList.some(
                      (service) => service.type === 'Offline'
                    ) && (
                      <li>
                        <label
                          htmlFor={`inPerson_${cardIndex}`}
                          onClick={(e) =>
                            changeServiceTypeHandler(e, 'Offline')
                          }
                        >
                          <input
                            id={`inPerson_${cardIndex}`}
                            type="radio"
                            name={`${cardIndex}_contactTypeInPerson`}
                            checked={appointmentType === 'Offline'}
                          />
                          {t.InPerson}
                        </label>
                      </li>
                    )}
                    {currentExtraInfo.serviceList.some(
                      (service) =>
                        service.type === 'Online' &&
                        service.serviceDescription ===
                          'Consultation - Telemedicine'
                    ) && (
                      // true &&
                      <li>
                        <label
                          className="flex gap-1 items-center"
                          htmlFor={`tele${cardIndex}`}
                          onClick={(e) => changeServiceTypeHandler(e, 'Online')}
                        >
                          <input
                            id={`tele${cardIndex}`}
                            type="radio"
                            name={`${cardIndex}_contactTypeOnline`}
                            checked={appointmentType === 'Online'}
                          />
                          {t.Telemedicine}
                          <HiOutlineVideoCamera
                            className="text-22"
                            style={{ color: '#464A53' }}
                          />
                        </label>
                      </li>
                    )}
                    <li>{selectedDoc && <MonthsDropdown />}</li>
                  </ul>
                </div>
              ) : null}
              {loading && (
                <div className="my-4">
                  <Loader roundLoader={true} size="small" />
                </div>
              )}
              {selectedDate && selectedDoc ? (
                <>
                  <DatesSlider isRtl={isRtl} />
                  <TimeSlotSlider index={cardIndex} />
                </>
              ) : // : selectedCard === doctorInfo.docCode ? <div className="mt-8"><Loader roundLoader/></div> :null
              null}
            </div>
          )}
          {isMobile && (
            <>
              <div className={`docInfo`}>
                <div className="imgRow flex flex-wrap gap-5 justify-between">
                  <div className="imgSd">
                    <img
                      src={`${assetsRoute}${hospCode}/${selectedDoc}.jpg`}
                      onError={(e) => {
                        if (
                          e.target.src !==
                          `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                        ) {
                          e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                        }
                      }}
                    />
                    <div className="info">
                      <h4>{isRtl ? docNameAr : docName}</h4>
                      <p>
                        {getTValue(
                          currentExtraInfo,
                          'specialityDescriptionAr',
                          'specialityDescriptionEn',
                          isRtl
                        )}
                      </p>
                      <p className="text-green">
                        {getTValue(
                          doctorPersonalInfo,
                          'clincNameAr',
                          'clinicNameEn',
                          isRtl
                        )}
                      </p>
                      {docExtraDetails && (
                         <p className="!text-tertiary">
                         {t.EarliestAppointment} : <span dir='ltr'>{`${moment(currentExtraInfo?.slotDate).format('DD-MMM-YYYY')} 
                              ${moment(currentExtraInfo?.slotTime,'HH:mm:ss').format('HH:mm') }`}</span>
                         </p>
                      )}
                      <button
                        onClick={(e) =>
                          showDoctorProfileHandler(
                            e,
                            doctorPersonalInfo.docCode
                          )
                        }
                      >
                        {t.ViewProfile}
                      </button>
                    </div>
                  </div>
                  <div className="lngSd">
                    <p className="font-medium">{t.LanguagesSpoken}</p>
                    <ul>
                      {getLanguagesInText(doctorPersonalInfo?.languagesTxt).map(
                        (language, index) => (
                          <li key={index}>
                            <span>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/${language.flag}`}
                                alt={language.nameEn}
                              />
                            </span>
                            {isRtl ? language.nameAr : language.nameEn}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {currentExtraInfo.serviceList ? (
                <div className="cntctRow flex flex-wrap justify-between">
                  <ul className="flex">
                    {currentExtraInfo.serviceList.some(
                      (service) => service.type === 'Offline'
                    ) && (
                      <li>
                        <label
                          htmlFor={`inPerson_${cardIndex}`}
                          onClick={(e) =>
                            changeServiceTypeHandler(e, 'Offline')
                          }
                        >
                          <input
                            id={`inPerson_${cardIndex}`}
                            type="radio"
                            name={`${cardIndex}_contactType`}
                            checked={appointmentType === 'Offline'}
                          />
                          {t.InPerson}
                        </label>
                      </li>
                    )}
                    {currentExtraInfo.serviceList.some(
                      (service) =>
                        service.type === 'Online' &&
                        service.serviceDescription ===
                          'Consultation - Telemedicine'
                    ) && (
                      // true &&
                      <li>
                        <label
                          className="flex gap-1 items-center"
                          htmlFor={`tele${cardIndex}`}
                          onClick={(e) => changeServiceTypeHandler(e, 'Online')}
                        >
                          <input
                            id={`tele${cardIndex}`}
                            type="radio"
                            name={`${cardIndex}_contactType`}
                            checked={appointmentType === 'Online'}
                          />
                          {t.Telemedicine}
                          <HiOutlineVideoCamera
                            className="text-22"
                            style={{ color: '#464A53' }}
                          />
                        </label>
                      </li>
                    )}
                    <li>{selectedDoc && <MonthsDropdown />}</li>
                  </ul>
                </div>
              ) : null}
              {selectedDate && selectedDoc ? (
                <>
                  <DatesSlider isRtl={isRtl} />
                  <TimeSlotSlider index={cardIndex} />
                </>
              ) : // : selectedCard === doctorInfo.docCode ? <div className="mt-8"><Loader roundLoader/></div> :null
              null}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SelectedDoctorCard;
