import { envVariables } from "../config";
import { sessionsMiddleWare } from "./SessionExpire";
import { getSelectLanguage } from "./getCurrentSelectLanguage";

const { apiRoute } = envVariables;

const doctorDetailReq = async (host, hospitalCode, hospitalId, docCode) => {
    const endpoint = `${host}api/doctors/find?hospitalCode=${hospitalCode}&doctorCode=${docCode}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospitalId,
                'Host': 'patientportal.imc.med.sa',
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                "Accept-Language": getSelectLanguage(),
            }
        });
        if (!response.ok) {
            if(response.status === 403) {
                sessionsMiddleWare();
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching doctor details:', error);
        return null;
    }
};

export const getDoctorSidebarInfo = async (docCode) => {
    const hospitalCode = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).code : "IMC";
    const hospitalId = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).id : "1";
    // const doctorsInfoArray = JSON.parse(localStorage.getItem("doctorsInfomation"));
    const doctorPersonalInfo = await doctorDetailReq(apiRoute, hospitalCode, hospitalId, docCode);

    // const doctorIndex = doctorsInfoArray.findIndex(doctor => doctor.docCode === doctorPersonalInfo.physicianData.docCode);

    // if (doctorIndex !== -1) {
    //     // Update doctor information
    //     doctorsInfoArray[doctorIndex].image = doctorPersonalInfo.physicianData.docImg;
    //     doctorsInfoArray[doctorIndex].clincName = doctorPersonalInfo.physicianData.clinicNameEn;
    //     doctorsInfoArray[doctorIndex].clincNameAr = doctorPersonalInfo.physicianData.clincNameAr;
    //     doctorsInfoArray[doctorIndex].educationTxt = doctorPersonalInfo.physicianData.educationTxt;
    //     doctorsInfoArray[doctorIndex].educationTxtAr = doctorPersonalInfo.physicianData.educationTxtAr;
    //     doctorsInfoArray[doctorIndex].languagesTxt = doctorPersonalInfo.physicianData.languagesTxt;
    //     doctorsInfoArray[doctorIndex].languagesTxtAr = doctorPersonalInfo.physicianData.languagesTxtAr;
    //     doctorsInfoArray[doctorIndex].specialitiesTxt = doctorPersonalInfo.physicianData.specialitiesTxt;
    //     doctorsInfoArray[doctorIndex].specialitiesTxtAr = doctorPersonalInfo.physicianData.specialitiesTxtAr;

    //     // localStorage.removeItem("doctorsInfomation")
    //     localStorage.setItem("doctorsInfomation", JSON.stringify(doctorsInfoArray))
    // }

    return doctorPersonalInfo.physicianData;
};