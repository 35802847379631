import { createSlice } from '@reduxjs/toolkit';
import { AR } from '../translations/ar';
import { EN } from '../translations/en';

const allTranslations = {
  en: EN,
  ar: AR,
};

const rtlLanguages = ['ar'];
const language = localStorage.getItem('language') || 'en';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language,
    t: allTranslations[language],
    isRtl: rtlLanguages.includes(language),
  },

  reducers: {
    changeLanguage(state, action) {
      let lang = action.payload;
      if(lang === "ar") {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
      if (state.language !== lang) {
        state.language = lang;
        state.t = allTranslations[lang];
        state.isRtl = rtlLanguages.includes(lang);
        localStorage.setItem('language', lang);
      }
    },
  },
});

export const selectLanguage = (state) => state.language;

export default languageSlice;
