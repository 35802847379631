import React, { useState } from 'react';
import RightSidebar from '../components/HomeHealthCare/RightSidebar';
import { envVariables } from '../config';
import { useLoaderData } from 'react-router-dom';

import '../components/HomeHealthCare/HomeHealthCare.css';
import QuestionCard from '../components/HomeHealthCare/QuestionCard';
import { hideLoader, showLoader } from '../store/globalLoaderError';
import store from '../store';
import { getSelectLanguage } from '../utilFunctions/getCurrentSelectLanguage';
import useDataFetching from '../utilFunctions/useFetchData';
import Loader from '../components/UI/Loader';

const HomeHealthCare = (props) => {
  // const allQuestions = useLoaderData();
  const {
    data: allQuestions,
    loading,
    error,
  } = useDataFetching(homeHealthCareLoader);

  const [allSelectedQId, setAllSelectedQId] = useState([]);

  const handleSelectedQIds11 = (allSelected, allIds) => {
    let updatedIds = [...allSelectedQId];

    allIds.forEach((subId) => {
      if (updatedIds.includes(subId)) {
        const index = updatedIds.indexOf(subId);
        updatedIds.splice(index, 1);
      }
    });
    const unique = Array.from(new Set([...updatedIds, ...allSelected]));
    setAllSelectedQId(unique);
  };

  const handleSelectedQIds = (data) => {
    const { selectedSubQIds, mainQId, subQsIds, mainSelected } = data;
    const updatedIds = [...allSelectedQId];

    if (mainSelected && subQsIds.length === 0) {
      // If mainSelected is true and subQsIds is empty, add or remove mainQId
      if (updatedIds.includes(mainQId)) {
        // Remove mainQId if it exists
        const index = updatedIds.indexOf(mainQId);
        updatedIds.splice(index, 1);
      } else {
        // Add mainQId if it doesn't exist
        updatedIds.push(mainQId);
      }
    } else if (mainSelected && subQsIds.length > 0) {
      subQsIds.forEach((subId) => {
        if (updatedIds.includes(subId)) {
          const index = updatedIds.indexOf(subId);
          updatedIds.splice(index, 1);
        }
      });

      updatedIds.push(mainQId);
      selectedSubQIds.forEach((subId) => {
        updatedIds.push(subId);
      });
    } else if (!mainSelected) {
      if (updatedIds.includes(mainQId)) {
        const index = updatedIds.indexOf(mainQId);
        updatedIds.splice(index, 1);
      }

      subQsIds.forEach((subId) => {
        if (updatedIds.includes(subId)) {
          const index = updatedIds.indexOf(subId);
          updatedIds.splice(index, 1);
        }
      });
    }

    // Set the updated ids array to state
    const unique = Array.from(new Set(updatedIds));
    setAllSelectedQId(unique);
  };

  if(loading) return <div className="w-full justify-center"><Loader roundLoader/> </div>

  return (
    <div className="contains-sidebar hhc">
      <div className="rt-sd hhcCrdLst">
        {allQuestions?.length > 0 &&
          allQuestions.map((item, index) => {
            return (
              <QuestionCard
                data={item}
                index={index}
                key={index}
                handleSelectedQIds={handleSelectedQIds}
              />
            );
          })}
      </div>
      <div className="pge-sdbr sticky top-0">
        <RightSidebar selectedIds={allSelectedQId} />
      </div>
    </div>
  );
};

export default HomeHealthCare;

export const homeHealthCareLoader = async () => {
  const { apiRoute } = envVariables;
  const endpoint = `${apiRoute}web/hhc_form/question_list`;
  const { patientId, mrn } = JSON.parse(localStorage.userInfo);
  const hospCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';

  const reqBody = {
    HHCFormId: 1,
    hosp: '1',
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
          }`,
        "Accept-Language": getSelectLanguage(),

      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! hhc_form/question_list: ${response.status}`);
    }

    const apiData = await response.json();

    if (!apiData.data) {
      throw new Error(`HTTP error! hhc_form/question_list: ${response.status}`);
    }

    return apiData.data;
  } catch (error) {

    console.error('Error fetching hhc_form/question_list:', error);
    throw error
  }
};
