import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';
import { selectLanguage } from '../../../store/languageSlice';
import { getfilterYears, getYearsFromData } from '../../../utilFunctions/Vitals';

const VitalsYearDropdown = ({onChangeYear, list}) => {
  const dispatch = useDispatch();
  const { t } = useSelector(selectLanguage);
  const filterYears = getYearsFromData(list);
  const [selectedYear, setSelectedYear] = useState(filterYears[0].value);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputContainerRef = useRef();

  useClickOutside(inputContainerRef, () => {
    setDropdownVisible(false);
  });


  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleItemClick = (item) => {
    setSelectedYear(item.value)
    setDropdownVisible(false);
    onChangeYear && onChangeYear(item.value)
  };


  return (
    <div style={{ width: '90px', padding:'0px !important' , cursor:'pointer'}}>
      <div
        className="dropdown1-container1"
        style={{ margin: '0px', height: '34px'}}
      >
        <div onClick={handleFocus} className="input1-wrapper">
          <span className="search1-input">{selectedYear ?? t.Select}</span>
          <span className="dropdown1-arrow">&#9662;</span>
        </div>
        {dropdownVisible && (
          <ul ref={inputContainerRef} className="dropdown1-list">
            {filterYears?.map((item, index) => (
              <li
                key={index}
                className="dropdown1-item"
                onClick={() => handleItemClick(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default VitalsYearDropdown;
