import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Layout, layoutLoader } from '../components/UI/Layout/Layout';
import ErrorPage from '../pages/ErrorPage';
import Dashboard from '../pages/Dashboard';
// import { BookAppointment, bookAppointmentLoader } from '../pages/BookAppointment';
import { BookAppointment } from '../pages/BookAppointment';
import {
  ManageAppointment,
  manageAppointmentLoader,
} from '../pages/ManageAppointment';
import VitalReport from '../pages/VitalReport';
import LocationMap from '../pages/LocationMap';
import AssessmentReport from '../pages/AssessmentReport';
import HomeHealthCare, { homeHealthCareLoader } from '../pages/HomeHealthCare';
import { Medication, medicationLoader } from '../pages/Medication';
import Reports, { reportsLoader } from '../pages/Reports';
import Home from '../pages/Home';
import PrivateRoute from './PrivateRoute';
import LoginForm from '../pages/Login';
import Register, { countriesListLoader } from '../pages/Register';
import OtpVerify from '../pages/OtpVerify';
import { vitalReportLoader } from '../utilFunctions/VitalsReportList';
import { assessemnetLoader } from '../components/AssessmentReport/AssessmentsList';
import VideoCall from '../pages/VideoCall';
import { BASE_NAME_ROUTE } from '../config';
// import { medicationLoader } from '../pages/Medication';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route
          path="/"
          element={<Layout />}
          errorElement={<ErrorPage />}
          loader={layoutLoader}
        >          
          <Route
            index
            element={<Dashboard />}
            // loader={bookAppointmentLoader}
          />
          <Route
            path="book-appointment/*"
            element={<BookAppointment />}
            // loader={bookAppointmentLoader}
          />
          <Route
            path="manage-appointment"
            element={<ManageAppointment />}
            // loader={manageAppointmentLoader}
          />
          <Route
            path="vital-report"
            element={<VitalReport />}
            // loader={vitalReportLoader}
          />
          <Route
            path="assessment-report"
            // loader={assessemnetLoader}
            element={<AssessmentReport />}
          />
          <Route
            path="home-health-care"
            exact
            // loader={homeHealthCareLoader}
            element={<HomeHealthCare />}
          />
          <Route
            path="medication"
            // loader={medicationLoader}
            // action={medicationList}
            element={<Medication />}
          />
          <Route
            path="reports"
            exact
            // loader={reportsLoader}
            element={<Reports />}
          />
          <Route
            path="location-map"
            exact
            element={<LocationMap />}
          />
          <Route
            path="video-call"
            exact
            element={<VideoCall />}
          />
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Route>
      <Route path="/" errorElement={<ErrorPage />}>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<Register />} loader={countriesListLoader}/>
        <Route path="/otp" element={<OtpVerify />} />
        <Route index element={<Home />} />
      </Route>
    </>
  ),
  { basename: BASE_NAME_ROUTE } //----- / imcpp imcportal ----
);
