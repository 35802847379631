import React from 'react';
import { FaGooglePlay, FaAppStore } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import './styles.css'

const AppLinks = () => {
  const location = useLocation();

  if (location.pathname === '/video-call') return null;

  return (
    <div className="app-links">
      <a href="https://play.google.com/store/apps/details?id=sa.med.imc.myimc" target="_blank" rel="noopener noreferrer" className="app-link">
        <FaGooglePlay size={32} />
      </a>
      <a href="https://apps.apple.com/us/app/my-imc/id1491751418" target="_blank" rel="noopener noreferrer" className="app-link">
        <FaAppStore size={32} />
      </a>
    </div>
  );
};

export default AppLinks;
