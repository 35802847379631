import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

const AddGuardian = ({ handleOnAdd }) => {
   const {t} = useSelector(selectLanguage)
  return (
    <>
      <div className="border border-gray-300 shadow flex justify-between items-center rounded-md p-4 max-w-md w-full bg-white">
        <div
          onClick={handleOnAdd}
          className="rounded-md py-1 max-w-md w-full bg-white"
        >
          <div className="flex flex-col items-center space-x-4 gap-y-3.5">
            <button className="btn bg-gray-300 p-1 rounded-md text-xl text-white">
              <FiPlus className="w-5 h-5" />
            </button>
            <h1 className="text-lg text-gray-700">{t["Add_A_Guardian"]}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGuardian;
