const educationHtmlParser = (html) => {
    if(!html) return '';

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    const liElements = doc.querySelectorAll('ul li');
    
    const result = Array.from(liElements).map(li => {
        const text = li.textContent;
        const [degree, rest] = text.split(/,(.+)/);
        return { degree: degree.trim(), institute: rest ? rest.trim() : '' };
    });

    // console.log("education", result);
    return result
}

export default educationHtmlParser;

// // Sample HTML response
// const htmlResponse = `
// <ul>
//   <li>Bachelor’s Degree in Medicine and Surgery, King Abdulaziz University, Jeddah, KSA</li>
//   <li>Canadian fellowship in allergy and immunology, Canadian fellowship</li>
//   <li>Canadian board certification in Internal Medicine, Canadian board</li>
// </ul>
// `;

// // Create a DOM parser
// const parser = new DOMParser();
// const doc = parser.parseFromString(htmlResponse, 'text/html');

// // Select all li elements
// const liElements = doc.querySelectorAll('ul li');

// // Extract and split the text
// const result = Array.from(liElements).map(li => {
//   const text = li.textContent;
//   const [firstPart, ...rest] = text.split(/,(.*)/);
//   return { firstPart: firstPart.trim(), rest: rest.join(',').trim() };
// });

// // Display the result
// console.log(result);
