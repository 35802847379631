import React from 'react';
import VideoChat from '../components/VideoChat';

const VideoCall = (props) => {
  return (
    <>
      <VideoChat />
    </>
  );
};

export default VideoCall;
