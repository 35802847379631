import { envVariables } from '../config';
import { sessionsMiddleWare } from './SessionExpire';
import { getSelectLanguage } from './getCurrentSelectLanguage';
import { mockFetchRequest } from './VitalsReportList';

export const ContactUsSerivce = async (formData) => {
  const { apiRoute } = envVariables;
  const endpoint = `${apiRoute}api/contactus`;

  const userInfo = JSON.parse(localStorage.userInfo);
  const hospCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const reqPayload = {
    task_type: 'Contact Us',
    patient_id: userInfo?.patientId,
    hosp: hospitalId,
    details: {
      mrNumber: userInfo?.mrn,
      email: formData?.email,
      phone: formData?.phone,
      title: formData?.title,
      name: formData?.name,
      platform: 'Web',
      message: formData?.message,
      hosp: hospitalId,
    },
  };

  // return mockFetchRequest({"task_id":"370384","status":"Success"})

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('userInfo')).accessToken
        }`,
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(reqPayload),
    });
    if (!response.ok) {
      if (response.status === 403) {
        sessionsMiddleWare();
      }
      throw new Error(`HTTP error! contactus: ${response.status}`);
    }

    const apiData = await response.json();

    // if (!apiData.data) {
    //   throw new Error(`HTTP error! hhc_form/submissions: ${response.status}`);
    // }

    return apiData;
  } catch (error) {
    console.error('Error POst contactus', error);
    return null;
  }
};
