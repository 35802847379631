import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { apiServerActions } from "../../store";

const ApiSwitcher = (props) => {
    // const server = useSelector(state=>state.apiServer.type)
    const apiServer = localStorage.getItem("apiServer")
    const [server, setServer] = useState(apiServer || "production")
    const dispatch = useDispatch();

    const handleCheckboxChange = () => {
        const currentServer = server === 'production' ? 'test' : 'production';
        // dispatch(apiServerActions.changeApiServe(currentServer))
        localStorage.setItem("apiServer", currentServer)
        setServer(currentServer)
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    useEffect(()=>{
        // console.log("server", server)
    },[server])

    return (
        <label className={server === "production" ? "checked customToggle" : "customToggle"} htmlFor="apiServerTgle">
            <input
            id='apiServerTgle'
            type="checkbox"
            checked={server === "production" ? true : false}
            onChange={handleCheckboxChange}
            className="sr-only"
            />
            <span className="text">{server === "production" ? "Production": "Test"  }</span>
            <span className="circle"></span>
        </label>
    )
};

export default ApiSwitcher;