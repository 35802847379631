import React from 'react';

const ResultTable = ({t, isRtl,  data }) => {

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">{t.Test}</th>
            <th scope="col" className="px-6 py-3">{t.Result}</th>
            <th scope="col" className="px-6 py-3">{t.Flag}</th>
            <th scope="col" className="px-6 py-3">{t.Units}</th>
            <th scope="col" className="px-6 py-3">{t.RefRange}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((result, index) => {
            return (
              <tr key={'tab'+index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  dangerouslySetInnerHTML={{ __html: result?.test_item_description || '' }}
                />
              
                <td className={`px-6 py-4 
                ${result?.result_interpretation.trim() !== ''? 'text-tertiary': 'text-green'}
                  `} dangerouslySetInnerHTML={{ __html: result?.result_value || '' }} />
                <td className="px-6 py-4" dangerouslySetInnerHTML={{ __html: result?.result_flag || '' }} />
                <td className="px-6 py-4" dangerouslySetInnerHTML={{ __html: result?.result_units || '' }} />
                <td className="px-6 py-4" dangerouslySetInnerHTML={{ __html: result?.result_normal_range || '' }} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultTable;
