import React, { useState } from 'react';
import './style.css';

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div 
      className="tooltip-wrapper" 
      onMouseEnter={showTooltip} 
      onMouseLeave={hideTooltip}
      style={{ display: 'inline-block', position: 'relative' }} // Ensure it's inline for wrapping
    >
      {children}
      {visible && (
        <div className="tooltip" 
        // style={{ position: 'absolute', top: '-25px', left: '50%', transform: 'translateX(-50%)' }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
