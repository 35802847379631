import { envVariables } from "../../config";
import { sessionsMiddleWare } from "../SessionExpire";
import { getSelectLanguage } from "../getCurrentSelectLanguage";

export const getReportTypeDropdown = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : "IMC";
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";
  
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/codetable/reporttype
  const endpoint = `${apiRoute}api/codetable/reporttype`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": getSelectLanguage(),
        Platform: "Web",
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: "patientportal.imc.med.sa",
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report codetable/reporttype:`, error);
    return null;
  }
};

export const getVistTypeDropdown = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : "IMC";
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/health/patient/episode?patientId=684297&mrnNumber=704530
  const endpoint = `${apiRoute}api/health/patient/episode?patientId=${userInfo?.patientId}&mrnNumber=${userInfo?.mrn}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": getSelectLanguage(),
        Platform: "Web",
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: "patientportal.imc.med.sa",
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report getVistTypeDropdown:`, error);
    return null;
  }
};

export const getRequestedReport = async (payload) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : "IMC";
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/tc/patient/task/
  const endpoint = `${apiRoute}api/tc/patient/task/`;

  const reqPayload = {
    ...payload,
    hosp: hospitalId,
    patientId: userInfo?.patientId,
    mrNumber: userInfo?.mrn,
  };

  const testPay = {"subject":"Request Report","appointmentDate":"2022-12-26","outPatient":"G-null","message":"teste","pickupId":"","pickupDob":"","episodeNo":"2212260997","episode":"364261","hosp":1,"copyType":"email","pickupName":"","mrNumber":"000348","email":"civaxo7895@paxnw.com","patientId":"22","reportType":"4-Sick Leave Report"}

  // console.log('yyy 111' , {reqPayload,testPay})

  // return;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": getSelectLanguage(),
        Platform: "Web",
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: "patientportal.imc.med.sa",
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
      body: JSON.stringify(reqPayload),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    return null;
  }
};
