import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import { bookAppointmentActions, modalActions } from '../../../store';

const TimeSlotSlider = () => {
  const dispatch = useDispatch();
  const availableDatesArray = useSelector(
    (state) => state.bookAppointment.availableDatesArray
  );
  const selectedDate = useSelector(
    (state) => state.bookAppointment.selectedDate
  );
  const [slotsList, setSlotsList] = useState(
    availableDatesArray?.filter((date) => date.slotDate === selectedDate)
  );
  const [bufferedTimeSlots, setBufferedTimeSlots] = useState(null);

  const serviceType = useSelector(
    (state) => state.bookAppointment.appointmentType
  );

  const timeSlotsBufferHandler = async (slotsList) => {
    const currentTime = new Date();

    function getTimeDifferenceInMinutes(slotTime) {
      var slotDateTime = new Date(slotTime);
      return Math.floor((slotDateTime - currentTime) / (1000 * 60));
    }

    const filteredSlots = slotsList.filter(function (slot) {
      return (
        getTimeDifferenceInMinutes(
          slot.slotDate + 'T' + slot.slotFromTime + ':00'
        ) >= 45
      );
    });

    return await filteredSlots;
  };

  useEffect(() => {
    setSlotsList(
      availableDatesArray?.filter((date) => date.slotDate === selectedDate)
    );
  }, [selectedDate, availableDatesArray]);

  useEffect(() => {
    const bufferDateFun = async () => {
      const timeSlots = await timeSlotsBufferHandler(slotsList);
      setBufferedTimeSlots(timeSlots);
    };
    bufferDateFun();
  }, [slotsList]);

  const slotClickHandler = (e, slotFromTime, slotBookingId) => {
    e.stopPropagation();
    dispatch(
      bookAppointmentActions.selectedSlot({
        time: slotFromTime,
        id: slotBookingId,
      })
    );

    if (serviceType === 'Offline') {
      dispatch(modalActions.toggle('ConfirmBookingModal'));
    } else {
      dispatch(modalActions.toggle('OnlineConfirmBookingModal'));
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: bufferedTimeSlots?.length < 7 ? bufferedTimeSlots?.length : 7,
    slidesToShow: 14,
    slidesToScroll: 1,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <>
      <div className="sldrRow px-4">
        {bufferedTimeSlots && bufferedTimeSlots.length > 0 && (
          <Slider className="avblSltsSld" {...settings}>
            {bufferedTimeSlots.map((slot, index) => (
              <div key={index}>
                <button
                  onClick={(e) => {
                    slotClickHandler(e, slot.slotFromTime, slot.slotBookingId);
                  }}
                >
                  {slot.slotFromTime}
                </button>
              </div>
            ))}
          </Slider>
        )}
        {bufferedTimeSlots?.length < 1 && (
          <p style={{ margin: '10px -16px 0' }}>No Slots Available Today.</p>
        )}
      </div>
    </>
  );
};

export default TimeSlotSlider;
