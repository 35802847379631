import React, { useRef, useLayoutEffect,useEffect, Fragment } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const SingleVRChart = ({index, report}) => {
    const chart = useRef(null);

    useEffect(() => {
        let x = am4core.create(`reportchartdiv${index}`, am4charts.XYChart);

        const transformedData = Object.keys(report)
        .filter(key => key !== 'Vital_Signs')
        .filter(key => key !== 'code') 
        .filter(key => key !== 'description')
        .filter(key => key !== 'uom')
        .map((key, index) => {
            let value = report[key];
            const fomatedValue = value !== '-'
              ? !isNaN(parseFloat(value))
                ? parseFloat(value)
                : ''
              : '';
            // if (value === '-') {
            // value = '';
            // }
            return {
            month: index,
            value: fomatedValue
            };
        });
  
        // let data = [
        //     {
        //         "month": "Jan",
        //         "value": 5
        //     }, {
        //         "month": "Feb",
        //         "value": 20
        //     }, {
        //         "month": "Mar",
        //         "value": 40
        //     }, {
        //         "month": "Apr",
        //         "value": 60
        //     }, {
        //         "month": "May",
        //         "value": 80
        //     }, {
        //         "month": "Jun",
        //         "value": 40
        //     }, {
        //         "month": "Jul",
        //         "value": 35
        //     }, {
        //         "month": "Aug",
        //         "value": 70
        //     }, {
        //         "month": "Sep",
        //         "value": 40
        //     }, {
        //         "month": "Oct",
        //         "value": 50
        //     }, {
        //         "month": "Nov",
        //         "value": 80
        //     }, {
        //         "month": "Dec",
        //         "value": 90
        //     }
        // ];   
        x.data = transformedData.reverse();

        // Create axes
        let monthAxis = x.xAxes.push(new am4charts.CategoryAxis());
        monthAxis.dataFields.category = "month";
        monthAxis.startLocation = 0.5;
        monthAxis.renderer.minGridDistance = 0;
        monthAxis.tooltip.disabled = true;
        monthAxis.renderer.grid.template.disabled = true;
        monthAxis.renderer.labels.template.disabled = true;
        monthAxis.renderer.labels.template.fontSize = 0;
        monthAxis.min = 0;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fontSize = 0;
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.grid.template.disabled = true;

        let series = x.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "month";
        // tooltip
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fillOpacity = 1;
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.background.stroke = am4core.color("#ddd6ff");
        series.tooltip.label.fill = am4core.color("#0086CD");
        series.tooltip.label.fontSize = 14;
        series.tooltip.label.fontWeight = 700;

        series.tooltipText = "{valueY.formatNumber('#')}";
        series.tooltip.pointerOrientation = "vertical";
        series.stroke = new am4core.color("#29E7CD");
        series.strokeWidth = 3;
        series.strokeOpacity = 1;
        series.tensionX = 0.8;
        series.tensionY = 0.8;
    
        // let scrollbarX = new am4charts.XYChartScrollbar();
        // scrollbarX.series.push(series);
        // x.scrollbarX = scrollbarX;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 7;
        bullet.circle.fill = am4core.color("#0086CD");
        // bullet.circle.stroke = am4core.color("#0086CD");
        // bullet.circle.strokeWidth = 5;
        bullet.fillOpacity = 0;
        bullet.strokeOpacity = 0;

        var bulletState = bullet.states.create("hover");
        bulletState.properties.fillOpacity = 1;
        bulletState.properties.strokeOpacity = 1;

        // Cursor
        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "panX";

    
        chart.current = x;
    
        return () => {
            x.dispose();
        };
    }, [report]);

    return (
        <div className='reportChart ml-auto' id={`reportchartdiv${index}`} style={{ width: "50%", height: "100px" }}></div>
    )
};

export default SingleVRChart;