import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';
import { bookAppointmentActions } from '../../../store';
import { SlickNextArrow, SlickPrevArrow } from '../../UI/SlickSlider/SlickSliderArrows.jsx';
import HorizontalSlider from '../../HorizontalSlider';

const ClincSymptonSlider = ({
  onClickItem,
  onRemoveItem,
  list,
  type = 'Clinic',
  isRtl,
  handleSearchDoctors
}) => {
  const dispatch = useDispatch();
  const { lastVisitedDocs, primaryCareDocs } = useSelector(
    (state) => state.bookAppointment
  );
  const onClickHandler = (item) => {

    if(item?.specialityDescription?.toLowerCase().includes('visited')){
      handleSearchDoctors('', {code:null,docCode:null})
      dispatch(bookAppointmentActions.updatePrimaryOrLastVisited('visited'))
    }else if(item?.specialityDescription?.toLowerCase().includes('primary')){
      handleSearchDoctors('', {code:null,docCode:null})
      dispatch(bookAppointmentActions.updatePrimaryOrLastVisited('primary'))
    }else {
      dispatch(bookAppointmentActions.updatePrimaryOrLastVisited(null))
    }

    onClickItem && onClickItem(item);
  };

  let filteredList = list;

  const sortClinicListAlphabatically = (list) => {
    if (isRtl) {
      return list?.sort((a, b) => {
        return a?.arabicSpecialityDescription?.localeCompare(
          b?.arabicSpecialityDescription,
          'ar',
          { sensitivity: 'base' }
        );
      });
    } else {
      return list?.sort((a, b) => {
        return a?.specialityDescription?.localeCompare(
          b?.specialityDescription,
          'en',
          { sensitivity: 'base' }
        );
      });
    }
  };

  if (type == 'Clinic') {
    let fullList = list.flatMap((clinic) => {
      if (clinic.isGroup && clinic.subClinic) {
        return clinic.subClinic;
      }
      return [clinic];
    });

    let sortedList = sortClinicListAlphabatically(fullList);

    if(primaryCareDocs && primaryCareDocs.length>0) {
      const primaryCareTag = {
        arabicSpecialityDescription: 'الرعاية الأولية',
        specialityDescription:'Primary Care',
      }

      sortedList.unshift(primaryCareTag);
    } 

    if(lastVisitedDocs && lastVisitedDocs.length>0) {
      const lastVisted = {
        arabicSpecialityDescription: 'آخر زيارة',
        specialityDescription:'Last Visited',
      }

      sortedList.unshift(lastVisted);
    } 

    filteredList = sortedList;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true, // if rtl don't show variable width
    initialSlide:  0,
    slickGoTo:filteredList?.length-5,
    rtl: isRtl,
    nextArrow: isRtl ? <SlickPrevArrow />: <SlickNextArrow />,
    prevArrow: isRtl ? <SlickNextArrow />: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <>
      <div className="sldrRow">
        {filteredList && filteredList.length > 0 && (
          <HorizontalSlider isRTL={isRtl} className="avblSltsSlds labels" {...settings}>
            {filteredList.map((item, index) => {
              let label = '';

              if (type == 'Clinic') {
                label = isRtl
                  ? item.arabicSpecialityDescription
                  : item.specialityDescription;
              }
              if (type == 'Symptoms') {
                label = isRtl ? item.symptomArabic : item.symptom;
              }

              return (
                <div key={index} className="slider-item">
                 <button onClick={() => onClickHandler(item)}>
                    <span className="">{label}</span>
                  </button>
              </div>
              
              );
            })}
          </HorizontalSlider>
        )}
      </div>
    </>
  );
};

export default ClincSymptonSlider;
