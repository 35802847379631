import { envVariables } from '../../config';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';
import { mockFetchRequest } from '../VitalsReportList';

export const fetchReasons = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/mayo-clinic/second-opinion/reason`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching second-opinion/reason`, error);
    return null;
  }
};

export const submitFormRequest = async (requestDetails, userDetails, ) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/mayo-clinic/second-opinion`;

  const payLoadNew = {
    patient_id: userInfo?.patientId,
    hosp: hospitalId,
    ...requestDetails,
    details: {
      title: 'testing',
      platform: 'web',
      hosp: hospitalId,
      ...userDetails,
    },
  };
  // return await mockFetchRequest({status:'Success'})

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(payLoadNew),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching mayo-clinic/second-opinion:`, error);
    return null;
  }
};
