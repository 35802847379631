import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import DatesSlider from "./DatesSlider";
import SingleVReport from "./SingleVReport";
import VitalsYearDropdown from "./VitalsYearDropdown";
import { selectLanguage } from "../../../store/languageSlice";
import useDataFetching from "../../../utilFunctions/useFetchData";
import { vitalReportLoader } from "../../../utilFunctions/VitalsReportList";
import { formatData, getYearsFromData } from "../../../utilFunctions/Vitals";
import Loader from "../../UI/Loader";
import '../Dashboard.css'
import useFetchVitals from "../../../utilFunctions/useFetchVitals";

const VitalReports = (props) => {
    const { t, isRtl } = useSelector(selectLanguage);
    const [selectedYear, setSelectedYear]= useState();
    const {
        data: vitalsList,
        loading,
        error,
      } = useFetchVitals();
      const filterYears = getYearsFromData(vitalsList);
      const yearName = selectedYear || filterYears[0]?.value || 2024;


    const { columnDefs, rowData } = formatData(vitalsList, yearName);
    const filteredRowData = rowData.filter(item => item.hasOwnProperty('date0'));


    return (
        <div className="db-card vtlRpt asmntRpt">
            <div className="flex flex-wrap justify-between card-head">
            <h3>{t.VitalSigns}</h3>
            {vitalsList.length>0 && <VitalsYearDropdown onChangeYear = {setSelectedYear} list={vitalsList }/>}
            </div>
            {
                vitalsList?.length>0 && (
                    <>
                    {columnDefs?.length > 1 && <DatesSlider selectedMonthDates={columnDefs} />}
                    <ul>
                        {
                            filteredRowData && filteredRowData.length > 0 ? filteredRowData.map((report, index)=> (
                                <li key={index}>
                                    <SingleVReport index={index} report={report} />
                                </li>
                            )):<div className="w-full flex justify-center">
                            <span className="mt-8">{t.NoRecord}</span>
                         </div>
                        }
                    </ul>
                    </>
                )
            }            
            {
              loading && <div className="w-full justify-center"><Loader roundLoader/> </div>
            }
            {
                !loading &&  vitalsList.length === 0 && <div className="w-full flex justify-center">
                    <span className="mt-8">{t.NoRecord}</span>
                 </div>
            }
        </div>
    )
};

export default VitalReports;