// features/badgesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ClinicAndSymptomsList } from '../utilFunctions/BookAppointmentDocList';

// Define async thunks for fetching data from the APIs
export const fetchClinicnsAndSymptomsList = createAsyncThunk(
  'api/fetchclinicnsAndSymptomsList',
  async () => {
    const listsData = await ClinicAndSymptomsList();

    return listsData;
  }
);

const initialState = {
  selectedBadge: null,
  clinicsList: [],
  symptomsList: [],
  status: 'idle',
  error: null,
};

const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    addBadge: (state, action) => {
      state.selectedBadge = action.payload;
    },
    removeBadge: (state, action) => {
      state.selectedBadge = null;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchApiOne
    builder
      .addCase(fetchClinicnsAndSymptomsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClinicnsAndSymptomsList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinicsList = action.payload?.clinicsList || [];
        state.symptomsList = action.payload?.symptomsList || [];
      })
      .addCase(fetchClinicnsAndSymptomsList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { addBadge, removeBadge } = badgesSlice.actions;
export const selectBadges = (state) => state.badges;

export default badgesSlice;
