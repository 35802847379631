import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUpcomingAppointments } from '../utilFunctions/getUpcomingAppointments';

// Define async thunks for fetching data from the APIs
export const fetchUpcommingAppointments = createAsyncThunk('api/upcommingAppointment', async () => {
  const list = await getUpcomingAppointments();
  return list;
});


// Create the slice
const appointmentSlice = createSlice({
  name: 'appointments',
  initialState: {
    bookedAppointments: null,
    selectedAppontemnt:null,
    selectedManageAppointment:null,
    selectedVital:null,
    status: 'idle',
    error: null,
  },
  reducers: {
    updateSelectedAppontemnt: (state, action) => {
      state.selectedAppontemnt = action.payload;
    },
    updateSelectManageAppontemnt: (state, action) => {
      state.selectedManageAppointment = action.payload;
    },
    updateSelectVital: (state, action) => {
      state.selectedVital = action.payload;
    },

  },
  extraReducers: (builder) => {
    // Handle fetchApiOne
    builder
      .addCase(fetchUpcommingAppointments.pending, (state) => {
        state.statusOne = 'loading';
      })
      .addCase(fetchUpcommingAppointments.fulfilled, (state, action) => {
        state.statusOne = 'succeeded';
        state.bookedAppointments = action.payload;
      })
      .addCase(fetchUpcommingAppointments.rejected, (state, action) => {
        state.statusOne = 'failed';
        state.errorOne = action.error.message;
      })
  },
});

export const { updateSelectedAppontemnt, updateSelectManageAppontemnt, updateSelectVital} = appointmentSlice.actions;

export const selectAppointments = (state) => state.appointments;
export const selectBookedAppointments = (state) => state.appointments.bookedAppointments;

export default appointmentSlice;
