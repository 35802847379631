export const getDay = (date) => {
  const getDayVal = new Date(date).getDay();
  let daysArr = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thrusday',
    'Friday',
    'Saturday',
  ];
  return daysArr[getDayVal];
};

export const getDayAr = (date) => {
  const getDayVal = new Date(date).getDay();
  let daysArr = [
    'الأحد',
    'الإثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ];
  return daysArr[getDayVal];
};

export const getDateDay = (date) => {
  const getDayVal = new Date(date).getDate();
  return getDayVal < 10 ? `0${getDayVal}` : getDayVal;
};

export const getFullMonth = (date) => {
  const monthIndex = new Date(date).getMonth();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthIndex];
};

export const getFullMonthAr = (date) => {
  const monthIndex = new Date(date).getMonth();
  const monthNames = [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ];
  return monthNames[monthIndex];
};

