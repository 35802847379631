import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';
import AddGuardian from './AddGuardian';
import EditGuardian from './EditGuardian';
import GuardianItem from './GuardianItem';

const MyGuardians = ({ data, onAddGuadrian, onDeletGuadrian, patientsRelations }) => {
  const { t } = useSelector(selectLanguage);
  const guardianId = data?.guardianId || null;
  const userDetails = data?.userDetails || null;
  const [showEdit, setShowEdit] = useState(false);

  const onAddClick = () => {
    setShowEdit(true);
  };

  const onEditOrAddClick = (editData) => {
    setShowEdit(false);
    onAddGuadrian && onAddGuadrian(editData);
  };

  return (
    <div>
      <h3 className="pb-2 text-xl text-grey-700">{t.My_Guardians}</h3>

      {!guardianId && !showEdit && <AddGuardian handleOnAdd={onAddClick} />}
      {showEdit && (
        <EditGuardian patientsRelations={patientsRelations} data={data} onAddGuadrian={onEditOrAddClick} />
      )}
      {guardianId && !showEdit && data && (
        <GuardianItem
          data={data}
          showEdit={onAddClick}
          handleOnDelete={onDeletGuadrian}
        />
      )}
    </div>
  );
};

export default MyGuardians;
