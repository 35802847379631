import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/languageSlice";
import SingleAppointment from "./SingleAppointment";

// import "./AppointmentsList.css";

const AppointmentsList = ({appointments, apptType}) => {
    const [appointmentsList, setAppointmentsList] = useState(null);
    const {isRtl} = useSelector(selectLanguage);
    const locale = isRtl ? 'ar':'en-US';

    const transfromDatestoDateObjects = (appointments)=> {
      const arrayWithTransformedDates = appointments.map(obj => ({
        ...obj,
        date: new Date(obj.apptDateString)
      }));

      setAppointmentsList(arrayWithTransformedDates)
    }

    useEffect(()=>{
      // transfromDatestoDateObjects(appointments)
      if(apptType !== 0) {
        const filteredAppointmentsList = appointments.filter((appnt)=>{
          return appnt.bookingStatus === apptType
        })
        // setAppointmentsList(filteredAppointmentsList)
        transfromDatestoDateObjects(filteredAppointmentsList)
      } else {
        transfromDatestoDateObjects(appointments)
      }
    },[appointments, apptType])

    // useEffect(()=>{
    // },[appointmentsList])

    // useEffect(()=>{
    //   if(apptType !== null) {
        
    //   }
    // },[apptType])

    // const appointmentsList = [
    //     {
    //       status: "completed",
    //       date: new Date(2024, 1, 24, 1, 15, 0),
    //       doctor: "Dr. Amr T. Banan",
    //       docType: "Consultant",
    //       image: "doc2.svg"
    //     },
    //     {
    //       status: "completed",
    //       date: new Date(2024, 0, 20, 8, 20, 0),
    //       doctor: "Dr. Amr T. Banan",
    //       docType: "Consultant",
    //       image: "doc1.svg"
    //     },
    //     {
    //       status: "cancelled",
    //       date: new Date(2024, 0, 20, 8, 20, 0),
    //       doctor: "Dr. Amr T. Banan",
    //       docType: "Consultant",
    //       image: "doc3.svg"
    //     },
    //     {
    //       status: "completed",
    //       date: new Date(2023, 11, 15, 1, 15, 0),
    //       doctor: "Dr. Amr T. Banan",
    //       docType: "Consultant",
    //       image: "doc4.svg"
    //     },
    //     {
    //       status: "No show",
    //       date: new Date(2023, 10, 14, 10, 20, 0),
    //       doctor: "Dr. Amr T. Banan",
    //       docType: "Consultant",
    //       image: "doc1.svg"
    //     }
    // ]
    const appointmentsByYearMonth = {};
    appointmentsList?.forEach(appointment => {
        const year = appointment.date.getFullYear();
        const month = appointment.date.getMonth() + 1; // getMonth() returns zero-based month
        if (!appointmentsByYearMonth[year]) {
          appointmentsByYearMonth[year] = {};
        }
        if (!appointmentsByYearMonth[year][month]) {
          appointmentsByYearMonth[year][month] = [];
        }
        appointmentsByYearMonth[year][month].push(appointment);
    });

    const sortedYears = Object.keys(appointmentsByYearMonth).sort((a, b) => b - a);

    // const maxAppointmentsToShow = 50;
    // let renderedAppointments = 0;

    return (
        <>
          {/* <div class="apntsLst">
            <div class="yearBlock"><h3>2024</h3><div class="monthBlock"><h4>Feb</h4><ul><li><span class="status" style={{backgroundColor: "red"}}>completed</span><div class="dateTime"><div class="date">24<span>Feb</span></div><div class="time">1 : 15<span>AM</span></div></div><div class="docInfo"><div class="info"><h5>Dr. Amr T. Banan</h5><p>Consultant</p></div><img src="/images/doc2.svg" alt="Dr. Amr T. Banan" /></div></li></ul></div></div>
          </div> */}
          <div className="apntsLst">
              {sortedYears.map(year => (
                  <div className="yearBlock" key={year}>
                      <h3>{year}</h3>
                      {/* Sort months in descending order */}
                      {Object.keys(appointmentsByYearMonth[year])
                      .sort((a, b) => b - a)
                      .map(month => (
                          <div className="monthBlock" key={month}>
                          <h4>{new Intl.DateTimeFormat(locale, { month: 'short' }).format(new Date(year, month - 1, 1))}</h4>
                          <ul>
                              {appointmentsByYearMonth[year][month].map((appointment, index) => (
                                  <SingleAppointment appointment={appointment} index={index} key={index} />
                              ))}
                              {/* {appointmentsByYearMonth[year][month].map((appointment, index) => {
                                if (renderedAppointments < maxAppointmentsToShow) {
                                    renderedAppointments++;
                                    return <SingleAppointment appointment={appointment} index={index} key={index} />;
                                } else {
                                    return null; // Render nothing if the limit is reached
                                }
                              })} */}
                          </ul>
                          </div>
                      ))}
                  </div>
              ))}
          </div>
        </>
    )
};

export default AppointmentsList;