import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../config';
import { getSelectLanguage } from './getCurrentSelectLanguage';

const medicationLoader = async (page = 1) => {
  try {
    let userData = JSON.parse(localStorage.getItem('userInfo'));

    const endpoint = `api/health/patient/medication?patientId=${userData?.patientId}&mrn=${userData?.mrn}&page=${page}`;

    const data = await baseFetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
          }`,
        "Accept-Language": getSelectLanguage(),
      },
    });

    return data;
  } catch (error) {
    console.error('Error on Medication', error);
    throw error;
  }
};

function useFetchMedications() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);

  const fetchData = async () => {
    if (loading || !hasMore) return; // Prevent fetching if already loading or no more data
    if(page < 2 ){
      setLoading(true);
    } 
   
    setError(null); // Reset error state before fetching new data

    try {
      const apiResponse = await medicationLoader(page); // Include hospitalId if needed in the API call
      const listData = apiResponse?.medication_list || [];

      if (listData.length === 0 ) {
        setHasMore(false); // No more data to fetch
      } else {
        setData((prevData) => [...prevData, ...listData]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [hospitalId, page]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchMedications;
