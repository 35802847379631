// http://localhost:3000/book-appointment/doctor/authorized
// http://localhost:3000/book-appointment/doctor/declined

import moment from "moment";
import { envVariables } from "../../config";
import { getSelectLanguage } from "../getCurrentSelectLanguage";

const generatePaymentUrl = async (selectedSlotId, servicesPrice, selectedDate, docName, hospId) => {
    const {apiRoute} = envVariables;
    const endpoint = `${apiRoute}api/payment/generate-payment-url`;
    const url = new URL(endpoint);

    const reqBody = {
        slotBookingId: selectedSlotId,
        is_final_price: "true",
        amount: servicesPrice?.patientShare,
        appointmentDate: selectedDate,
        bookingStatus: "2",
        docName: docName,
        patientId: JSON.parse(localStorage.getItem("userInfo")).patientId,
        mrn: JSON.parse(localStorage.getItem("userInfo")).mrn,
        payerForname: JSON.parse(localStorage.getItem("userInfo")).firstName,
        payerSurname: JSON.parse(localStorage.getItem("userInfo")).lastName,
        payerTitle: JSON.parse(localStorage.getItem("userInfo")).firstName,
        serviceCode: "",
        status: "0",
        teleHealthFlag: "1"
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok && response.status !== 200) {
            throw new Error(`HTTP error! generate-payment-url: ${response.status}`);
        }
        const apiData = await response.json();
        localStorage.setItem('paymentRefNo',JSON.stringify(apiData?.data.ref));
        localStorage.setItem('paymentSlotBookingId',JSON.stringify(selectedSlotId));
        localStorage.setItem('paymentSelectedDate',JSON.stringify(selectedDate));

        return apiData;
    } catch (error) {
        console.error('Error fetching generate-payment-url:', error);
        return null;
    }
};

export const verifyPayment = async () => {
    const {apiRoute} = envVariables;
    const endpoint = `${apiRoute}api/payment/verify-payment`;
    const url = new URL(endpoint);
    const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";

    const reqBody = {
        refId: JSON.parse(localStorage.getItem("paymentRefNo")),
        patientId: JSON.parse(localStorage.getItem("userInfo")).patientId,
        mrn: JSON.parse(localStorage.getItem("userInfo")).mrn,
        hosp: hospitalId
    }

    // return {
    //     "status": true,
    //     "statusCode": 200,
    //     "message": "Payment verified successfully",
    //     "data": {
    //         "method": "check",
    //         "trace": "4000/3221173/670d264b",
    //         "order": {
    //             "ref": "441FED0B266E5BA044264DB0681B35C60CAF3CA582B44366ED21970CAFEF9410",
    //             "cartid": "538434_2181",
    //             "test": "1",
    //             "amount": "287.50",
    //             "currency": "SAR",
    //             "description": "New Booking",
    //             "status": {
    //                 "code": 3,
    //                 "text": "Paid"
    //             },
    //             "transaction": {
    //                 "ref": "030034443610",
    //                 "type": "sale",
    //                 "status": "A",
    //                 "code": "93221126",
    //                 "message": "Authorised",
    //                 "class": "ECom"
    //             }
    //         },
    //         "error": null
    //     }
    // }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospitalId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok && response.status !== 200) {
            throw new Error(`HTTP error! generate-payment-url: ${response.status}`);
        }
        const apiData = await response.json();

        return apiData;
    } catch (error) {
        console.error('Error fetching generate-payment-url:', error);
        return null;
    }
};

export const settlePaymentBill = async (transactionId, amountPaid) => {
    const {apiRoute} = envVariables;
    const endpoint = `${apiRoute}api/bookings/settle-bill`;
    const url = new URL(endpoint);
    const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";

    const slotBookingId = JSON.parse(localStorage.getItem("paymentSlotBookingId"));

    const reqBody = {
        transactionId:slotBookingId,
        amountPaid,
        slotBookingId:slotBookingId,
        paymentDate: moment().format('YYYY-MM-DD'), //'2024-10-14'
        // slotBookingId: JSON.parse(localStorage.getItem("paymentSlotBookingId")),
        // paymentDate: JSON.parse(localStorage.getItem("paymentSelectedDate")),
        hosp: hospitalId,
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospitalId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok && response.status !== 200) {
            throw new Error(`HTTP error! settlePaymentBill: ${response.status}`);
        }
        const apiData = await response.json();
        localStorage.removeItem('paymentSlotBookingId');
        localStorage.removeItem('paymentSelectedDate');
        localStorage.removeItem('paymentRefNo');

        return apiData;
    } catch (error) {
        console.error('Error settlePaymentBill:', error);
        return null;
    }
};

export default generatePaymentUrl;