import { createSlice } from '@reduxjs/toolkit';

const vitalReportSlice = createSlice({
  name: 'vitalReport',
  initialState: {
    vitalCardsData: null,
    vitalCards: [],
    status: 'idle',
    error: null,
    dashboardSelectdDateIndex: 0
  },
  reducers: {
    handleUpdateVitalsCards(state, action) {
      state.vitalCards = action.payload;
    },
    updatdSelectedDateIndex(state, action) {
      state.dashboardSelectdDateIndex = action.payload;
    }
  },
});

export const { handleUpdateVitalsCards, updatdSelectedDateIndex } = vitalReportSlice.actions;

export default vitalReportSlice;
