import React, { useRef } from 'react';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';

export default function RightDrawer({ children, isOpen, setIsOpen, title, id }) {
  const ref = useRef(null);
  const { t, isRtl } = useSelector(selectLanguage);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <main
      dir={isRtl ? 'rtl' : ''}
      ref={ref}
      className={
        'fixed overflow-hidden z-10 bg-opacity-25 inset-0 transform ease-in-out ' +        
        (isOpen
          ? 'transition-opacity opacity-1 duration-500 translate-x-0 '
          : 'transition-all delay-500 opacity-0 ' +
            (isRtl ? 'translate-x-[-100%]' : 'translate-x-full')) +
          (id ? ` ${id}` : '')
      }
    >
      <section
        className={
          'w-screen max-w-md mt-16 rounded-l-xl absolute bg-zinc-100 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform ' +
          (isOpen
            ? 'translate-x-0 '
            : isRtl
            ? 'translate-x-[-100%]'
            : 'translate-x-full') +
          (isRtl ? ' left-0 ' : ' right-0 ')
        }
      >
        <article className="relative w-screen max-w-md pb-10 flex flex-col space-y-4 overflow-y-scroll max-h-screen pb-20">
          <div
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {isRtl ? (
              <BsArrowLeft className="w-10 h-6 mt-4 cursor-pointer" />
            ) : (
              <BsArrowRight className="w-10 h-6 mt-4 cursor-pointer" />
            )}
          </div>
          {
            title && (
              <header className="drawer-title p-4 font-semibold text-2xl">
                {title}
              </header>
            )
          }
          {children}
        </article>
      </section>
      <section
        className="w-screen h-full"
        onClick={() => {
          setIsOpen(false);
        }}
      />
    </main>
  );
}
