import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions, portalActions } from '../../../store';
import { selectModal } from '../../../store/globalModalSlice';
import { selectLanguage } from '../../../store/languageSlice';

export default function DepentedModal() {
  const dispatch = useDispatch();
  const modalVisible = useSelector((state) => state.modal.modalVisible);
  const { selectedDependent } = useSelector((state) => state.portal);
  const { t, isRtl } = useSelector(selectLanguage);
  const { switchPatient } = useSelector(selectModal);
  const handleOnCloseClick = () => {
    dispatch(modalActions.closeModal());
    // dispatch(portalActions.updatedSelectedDependent(null))
  };

  const handleOnYesClick = () => {
    let previousUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    const mappedUser = {
      patientExist: selectedDependent?.patient_exist,
      patientId: selectedDependent?.patient_id,
      mrn: selectedDependent?.mrn,
      nationalId: selectedDependent?.national_id,
      firstName: selectedDependent?.first_name,
      lastName: selectedDependent?.last_name,
      mobileNumber: selectedDependent?.mobile_number,
      insuranceList: selectedDependent?.insurance_list,
      profileImageUrl: selectedDependent?.profileImageUrl,
      arabic_first_name: selectedDependent?.arabic_first_name,
      arabic_last_name: selectedDependent?.arabic_last_name,
    };

    let switchedUserInfo = {
      ...previousUserInfo,
      ...mappedUser,
    };

    localStorage.setItem('userInfo', JSON.stringify(switchedUserInfo));
    localStorage.setItem('previousUserInfo', JSON.stringify(previousUserInfo));

    setTimeout(() => {
      dispatch(portalActions.closeDrawer());
      dispatch(modalActions.closeModal());
      dispatch(modalActions.setSwitchPatient(true));
    }, 1000);

    window.location.reload();
  };

  return (
    <div dir={isRtl ? 'rtl' : ''} className=" w-50 h-50 flex flex-col items-center justify-center gap-5">
      <h2 className="text-lg">{t.DoYouWantToSwitchToDependent}</h2>
      <h1 className="text-primary font-bold">{`${selectedDependent?.first_name}  ${selectedDependent?.last_name}`}</h1>
      <div className="flex justify-center gap-5 items-center mt-4 mb-2">
        <button
          className="text-black border-black border py-1 px-5 rounded"
          onClick={handleOnCloseClick}
        >
          {t.No}
        </button>
        <button
          className="bg-[#1da1f2] text-white py-1 px-5 border-[#1da1f2] border rounded"
          onClick={handleOnYesClick}
        >
          {t.Yes}
        </button>
      </div>
    </div>
  );
}
