import React, { useEffect, useState } from 'react';
import { BiStopwatch } from 'react-icons/bi';
import { FiMousePointer } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { hhcBookSerivce } from '../../../utilFunctions/homwHealthCare';
import Loader from '../../UI/Loader';

import './style.css';

const RightSidebar = ({ selectedIds }) => {
  const userInfo = JSON.parse(localStorage.userInfo);
  const { t, isRtl } = useSelector(selectLanguage);
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);

  // Function to handle form submission
  const handleBookService = async () => {
    // Call the parent component's function
    setMessage();
    setError(false);
    setLoading(true);
    const responseData = await hhcBookSerivce({
      questionIds: selectedIds,
      submittedFor: name,
      contact: '+966' + phoneNo,
      email: email,
    });

    setLoading(false);
    if (responseData?.status == 'true') {
      setMessage(t.HhsSuccess);
    } else {
      setError(true);
      setMessage(t.HhsFailed);
    }
  };

  useEffect(() => {
    if (userInfo) {
      setName(userInfo?.firstName);
      setPhoneNo(userInfo?.mobileNumber);
      setEmail(userInfo?.email);
    }
  }, [userInfo]);

  const showButton =
    selectedIds?.length > 1 && name != '' && phoneNo != '' && email != '';

  return (
    <div className="card">
      {/* <h3 className="mb-8">{t.TBD}</h3> */}
      <div className="media-obj">
        <span>
          <BiStopwatch />
        </span>
        <div className="info">
          <h4>{t.SwiftCallback}</h4>
          <p>{t.SwiftCallbackMessage}</p>
        </div>
      </div>
      <div className="media-obj mt-8">
        <span>
          <FiMousePointer />
        </span>
        <div className="info">
          <h4>{t.InstantHomeHealthcare}</h4>
          <p>{t.InstantHomeHealthcareMsg}</p>
        </div>
      </div>
      {/* <h3 className="my-8">{t.TBD}</h3> */}
      <form id="book-service">
        <label htmlFor="name">
          {t.Name} <br />
          <input
            type="text"
            id="name"
            onChange={(e) => setName(e.target.value)}
            value={userInfo?.firstName}
            placeholder={t.EnterYourFirstName}
          />
        </label>
        <label htmlFor="phoneNo">
          {t.CellPhoneNumber} <br />
          <div dir="ltr" className="countrycode">
            <div className="code text-secondary">(+966)</div>
            <input
              // dir={isRtl ? 'rtl':''}
              id="phoneNo"
              type="number"
              onChange={(e) => setPhoneNo(e.target.value)}
              value={userInfo?.mobileNumber}
              placeholder="5xxxxxxxx"
            />
          </div>
        </label>
        <label htmlFor="email">
          {t.Email} <br />
          <input
            id="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={userInfo?.email}
            placeholder={t.EnterYourEmailAddress}
          />
        </label>
        <button
          type="button"
          onClick={handleBookService}
          disabled={!showButton}
          style={{ opacity: showButton ? 1 : 0.5 }}
        >
          {loading ? <Loader size="small" roundLoader /> : t.BookService}
        </button>
        {message && (
          <p className={`${error ? 'errorMsg': 'successMsg'} my-2`}>
            <span>{message}</span>
          </p>
        )}
      </form>
    </div>
  );
};

export default RightSidebar;
