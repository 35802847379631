import moment from 'moment';
import React, { Fragment, useEffect, memo, useState } from 'react';
import {
  FiDownload,
  FiChevronDown,
  FiChevronUp,
  FiClock,
  FiChevronLeft,
} from 'react-icons/fi';
import { CgSoftwareDownload } from 'react-icons/cg';
import { downloadRadiologyReport } from '../../../utilFunctions/reports/reports';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { formatDate } from '../../../translations/common';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import { timeFormat } from '../../../utilFunctions/DateFormatter';

const ReportItem = ({ t, isRtl, item }) => {
  const { isMobile } = useBreakpoint();

  const formatedItem = {
    reportName: item?.order_description,
    value: item?.order_status || '',
    referenceRange: '',
    Interpretation: item?.authorized_user || '',
    dateTime: `${item?.result_date || ''} ${timeFormat(item?.result_time)}`,
  };
  return (
    <>
      {!isMobile && (
        <div className="cursor-pointer">
          <div className="type">
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/ic_radiology_report_hov.svg`}
              alt="report icon"
            />
            {formatedItem.reportName}
          </div>
          <div className="val">
            {/* {t.Value}:
            <span className="text-secondary font-medium">{formatedItem.value}</span> */}
            {formatedItem.value}
          </div>
          <div className="rng">
            {/* {t.ReferenceRange}:
            <span className="text-secondary font-medium">
              {formatedItem.referenceRange}
            </span> */}
            {formatedItem.referenceRange}
          </div>
          <div className="intp">
            {/* {t.Interpretation}:
            <span className="text-secondary font-medium">
              {formatedItem.Interpretation}
            </span> */}
            {formatedItem.Interpretation}
          </div>
          <div className="clctDate">
            {/* {t.CollectionDateTime}:
            <span className="text-secondary font-medium">
              {formatedItem.dateTime}
            </span> */}
            {formatedItem.dateTime}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="sngl">
          <p>
            <span>{t.Value}:</span>
            {formatedItem.value}
          </p>
          <p>
            <span>{t.ReferenceRange}:</span>
            {formatedItem.referenceRange}
          </p>
          <p>
            <span>{t.Interpretation}:</span>
            {formatedItem.Interpretation}
          </p>
          <p>
            <span>{t.CollectionDateTime}:</span>
            {formatedItem.dateTime}
          </p>
          <p className="name">{formatedItem.reportName}</p>
        </div>
      )}
    </>
  );
};

const RadiologyReport = ({ listData, currentData }) => {
  const { isMobile } = useBreakpoint();
  const { t, isRtl } = useSelector(selectLanguage);

  const [reportData, setReportData] = useState([]);
  const [showRecords, setShowRecord] = useState([]);

  const handleDownloadReport = async (orderId) => {
    if (!orderId && orderId == '') return;

    await downloadRadiologyReport(orderId);
  };

  const toggleShowRecords = (indexId) => {
    if (showRecords.includes(indexId)) {
      setShowRecord(showRecords.filter((x) => x !== indexId));
    } else {
      setShowRecord([...showRecords, indexId]);
    }
  };

  useEffect(() => {
    if (listData?.length > 0) {
      const sortedByDate = listData
        .slice()
        .sort((a, b) =>
          moment(b.collected_date).diff(moment(a.collected_date))
        );

      setReportData([...reportData, ...sortedByDate]);
    }
  }, [listData]);

  if (!currentData) return null;

  if (listData.length === 0)
    return (
      <div className="w-full h-full mt-14 flex justify-center">
        <div className="font-semibold text-2xl text-blue-900">
          {t.NoDataFound}
        </div>
      </div>
    );

  return (
    <Fragment key={'RaioLab'}>
      {reportData.map((item, index) => {
        const orderItem = {
          date: item?.result_date,
          oderId: item?.order_id,
          docName: item?.ordered_doctor,
          time: item?.result_time,
        };

        const fDate = formatDate(orderItem.date, isRtl);
        const time =
          orderItem.time !== ''
            ? moment(orderItem.time, 'HH:mm').format('hh:mm A')
            : '';
        const show = showRecords.includes(index);

        return (
          <>
            {!isMobile && (
              <div key={'radio' + index} className="sngRpt">
                <div className="info">
                  <div className="no">{index + 1}.</div>
                  <div className="date">
                    <span>{fDate.weekName}</span>
                    {`${fDate.monthDate} ${fDate.monthNameShort}`}
                    {/* <span>{`${fDate.year}`}</span> */}
                  </div>
                  <div className="year">{`${fDate.year}`}</div>
                  <div className="id">{orderItem?.oderId || ''}</div>
                  <div className="by">{orderItem?.docName || ''}</div>
                  <div className="time">{time || ''}</div>
                  <div
                    onClick={() => handleDownloadReport(orderItem.oderId)}
                    className="download"
                  >
                    <button>
                      {t.Download} <CgSoftwareDownload />
                    </button>
                  </div>
                  <button
                    onClick={() => toggleShowRecords(index)}
                    className="collapseBtn"
                  >
                    {show ? <FiChevronUp /> : <FiChevronDown />}
                  </button>
                </div>
                {show && (
                  <div className="otherInfo">
                    <div className="otherInfoHead">
                      <div className="type">
                        <div className="opacity-0">type</div>
                      </div>
                      <div className="val">
                        <div>{t.Value}</div>
                      </div>
                      <div className="rng">
                        <div>{t.ReferenceRange}</div>
                      </div>
                      <div className="intp">
                        <div>{t.Intrepatation}</div>
                      </div>
                      <div className="clctDate">
                        <div>{t.CollectionDateTime}</div>
                      </div>
                    </div>
                    <ReportItem t={t} isRtl={isRtl} item={item} key={index} />
                  </div>
                )}
              </div>
            )}
            {isMobile && (
              <div className="snglRprt">
                <div className="card">
                  <div className="date text-gray-500">
                    <span className="day font-medium text-slate-950">
                      {fDate.monthNameShort}
                    </span>
                    <span className="date font-bold text-24 text-primary">
                      {fDate.monthDate}
                    </span>
                    {fDate.year}
                  </div>
                  <div className="info">
                    <h3 className="text-slate-950">
                      Order ID: {orderItem?.oderId || ''}
                    </h3>
                    <h4 className="text-green">{orderItem?.docName || ''}</h4>
                    <p className=" text-gray-500">
                      <FiClock /> at {time || ''}
                    </p>
                  </div>
                  <div className="btns">
                    <button
                      className="dwnld"
                      onClick={() => handleDownloadReport(orderItem.oderId)}
                    >
                      {t.Download} <CgSoftwareDownload />
                    </button>
                    <button
                      className="collapseBtn"
                      onClick={() => toggleShowRecords(index)}
                    >
                      {show ? <FiChevronDown /> : <FiChevronLeft />}
                    </button>
                  </div>
                </div>
                {show && (
                  <div className="moreInfo">
                    <ReportItem t={t} isRtl={isRtl} item={item} key={index} />
                  </div>
                )}
              </div>
            )}
          </>
        );
      })}
    </Fragment>
  );
};

export default memo(RadiologyReport);
