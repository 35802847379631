import { envVariables } from '../../config';
import { getSelectLanguage } from '../getCurrentSelectLanguage';

export const sendLoginRequest = async (loginData) => {
  const { apiRoute } = envVariables;
  const loginReq = await fetch(`${apiRoute}auth-v2/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': getSelectLanguage(),
    },
    body: JSON.stringify(loginData),
  });

  if (!loginReq.ok) {
    const errorText = await loginReq.text();
    throw new Error(errorText || 'Unknown error in auth-v2/login');
  }

  return loginReq.json();
};
