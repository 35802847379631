import React, { useEffect } from 'react';
import VitalReportGrid from '../components/VitalReport/VitalReportGrid';

import '../components/VitalReport/VitalReport.css';
import { handleUpdateVitalsCards } from '../store/vitalReportSlice';

import { useDispatch, useSelector } from 'react-redux';
import { getMostRecntVitalSigns } from '../utilFunctions/Vitals';
import { useLoaderData } from 'react-router-dom';
import { selectLanguage } from '../store/languageSlice';
import { vitalReportLoader } from '../utilFunctions/VitalsReportList';
import useDataFetching from '../utilFunctions/useFetchData';
import Loader from '../components/UI/Loader';
import useFetchVitals from '../utilFunctions/useFetchVitals';

const VitalReport = (props) => {
  const {
    data: vitalsList,
    loading,
    error,
  } = useFetchVitals();

  const {t, isRtl} = useSelector(selectLanguage);
  // const vitalReportData = useLoaderData();
  const dispatch = useDispatch();
  const { vitalCards } = useSelector((state) => state.vitalReport);

  useEffect(() => {
    if (vitalsList.length>0) {
      const cards = getMostRecntVitalSigns(vitalsList,isRtl);
      dispatch(handleUpdateVitalsCards(cards));
    }
  }, [dispatch, vitalsList, isRtl]);

  if(loading && !error) return <div className="w-full justify-center"><Loader roundLoader/> </div>

  if(vitalsList.length === 0 ) return <div className='w-full flex justify-center items-center' style={{height:'50vh'}}><div className='text-xl'>{t.NoVitalsRecord}</div></div>
  
  return (
    <div className="vtlRprt">
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 fourCrds mb-6">
        {vitalCards
          .filter((x) => x.isShow)
          .map((card, index) => {
            const {difference} = card;

            return (
              <div className="card" key={index}>
                <h3>{card.title}</h3>
                <img src={card.icon} alt={`${card.title} icon`} />
                <h4>
                  <div dir='ltr'>{card.value} <span dangerouslySetInnerHTML={{ __html: card.unit.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}}></span></div>
                </h4>
                {card.showDiff && <div className='flex justify-start'><p dir='ltr' dangerouslySetInnerHTML={{ __html: difference.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}}></p></div>}
              </div>
            );
          })}
      </div>
      {/* assesment grid <start> */}
      {vitalsList.length>0 && <VitalReportGrid data={vitalsList} isRtl={isRtl} t={t} />}
    </div>
  );
};

export default VitalReport;
