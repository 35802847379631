import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const breakpoints = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  desktop: '(min-width: 1025px)',
};

const useBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const mediaQueryLists = Object.keys(breakpoints).map((key) => ({
      breakpoint: key,
      mql: window.matchMedia(breakpoints[key]),
    }));

    const getBreakpoint = () => {
      const activeBreakpoint = mediaQueryLists.find(({ mql }) => mql.matches);
      return activeBreakpoint ? activeBreakpoint.breakpoint : 'desktop';
    };

    const updateBreakpoint = () => {
      const breakpoint = getBreakpoint();
      setIsMobile(breakpoint === 'mobile');
      setIsTablet(breakpoint === 'tablet');
      setIsDesktop(breakpoint === 'desktop');
    };

    mediaQueryLists.forEach(({ mql }) => {
      mql.addEventListener('change', updateBreakpoint);
    });

    // Set the initial breakpoint
    updateBreakpoint();

    // Cleanup event listeners on component unmount
    return () => {
      mediaQueryLists.forEach(({ mql }) => {
        mql.removeEventListener('change', updateBreakpoint);
      });
    };
  }, []);

  return { isMobile, isTablet, isDesktop };
};

export default useBreakpoint;
