import store, { modalActions } from "../store";
import { hideLoader } from "../store/globalLoaderError";
import { closeModal } from "../store/globalModalSlice";

export const keysToKeep =['isRememberClicked', 'mrn', 'nationalId','selectedHopitalInfo']

export const sessionsMiddleWare = async (response) => {
  store.dispatch(hideLoader())
  const message = 'Session expired. Please log in again.';
  // localStorage.clear()
  // window.alert(message);
  if(store.getState().modal.modalType !== 'sessionExpire'){
    store.dispatch(modalActions.toggleSessionExpire("sessionExpire"))

    // setTimeout(()=>{
    //   store.dispatch(modalActions.toggleSessionExpire("sessionExpire"))
    // },1000)
  }

  // store.dispatch(modalActions.closeModal()) // close All Open modal
  // setTimeout(()=>{
  //   store.dispatch(modalActions.toggle("sessionExpire"))
  // },1000)
  
  // window.location.href = '/login';

};

export const clearLocalStorageExcept = () => {
  const keysToKeep =['isRememberClicked', 'mrn', 'nationalId','selectedHopitalInfo']
  // Get all keys from localStorage
  const allKeys = Object.keys(localStorage);
  
  // Filter out the keys that should be kept
  const keysToRemove = allKeys.filter(key => !keysToKeep.includes(key));
  
  // Remove the keys that are not in the keysToKeep list
  keysToRemove.forEach(key => localStorage.removeItem(key));
};

export const currentUser = () => {
  // Get all keys from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '');
  
  return userInfo !== '' ? userInfo : null
};
