import { envVariables } from '../../config';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';
import { mockFetchRequest } from '../VitalsReportList';
import { createRoomResponse, generateRoomAccessTokenRespone } from './mockResponse';

export const createRoom = async (doctorId, bookingId, bookingSlotTime) => {
  const { apiChatRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // https://whealth.imc.med.sa:9443/imc_doctor/api/twilio/create-room
  //   const endpoint = `${apiRoute}api/self-registration/yakeen/v5`;
  const endpoint = `${apiChatRoute}api/twilio/create-room`;

  const palyloadTemp = {
    userId: userInfo?.mrn, // '000348'
    doctorId, //'Momer'
    roomName: `${userInfo?.mrn}-${doctorId}`, // '000348-Momer'
    userEmail: userInfo?.email,
    // bookingSlotTime: '2024-06-02 16:00:00.000',
    bookingId, //'3991||13404-66993-1600-11079-1'
    bookingSlotTime, //'2024-06-02 16:00:00.000'
    deviceType: 'Web',
    deviceToken:
      'cIW_CtJxS3aAxzw6jmO8-e:APA91bGM8OuHJqz2w3vWT8T_IpUdAA6eLBLTcb3FN7r0pOMe6H56sAdmGIDfYusnuv8PyjfsTP6IU8s-kwNpB1LSH6eTINJay1pOBY-1VlH8fFFlTtaiTymFn0eVzhxFLA8cUJi2kKoU',
    serviceCode: 'CON011',
    serviceName: 'Consultation - Telemedicine',
  };

  // console.log('roomPayload',JSON.stringify(palyloadTemp))

  // return await mockFetchRequest(createRoomResponse);

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(palyloadTemp),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        //   sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    return null;
  }
};

export const generateRoomAccessToken = async (doctorId) => {
  const { apiChatRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // https://whealth.imc.med.sa:9443/imc_doctor/api/twilio/generate-room-access-token
  //   const endpoint = `${apiRoute}api/self-registration/yakeen/v5`;
  const endpoint = `${apiChatRoute}api/twilio/generate-room-access-token`;

  const palyloadTemp = {
    pageSize: 10,
    pageNumber: 1,
    userEmail: userInfo.email, //'afreen.ghazal@supersoft.com.pk'
    roomName: `${userInfo?.mrn}-${doctorId}`, // '000348-Momer'
  };

  return await mockFetchRequest(generateRoomAccessTokenRespone);

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(palyloadTemp),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        //   sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    return null;
  }
};

export const joiningRequestToDoc = async (doctorId, roomName) => {
  const { apiChatRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // https://whealth.imc.med.sa:9443/imc_doctor/api/twilio/conference-joining-request
  //   const endpoint = `${apiRoute}api/self-registration/yakeen/v5`;
  const endpoint = `${apiChatRoute}api/twilio/conference-joining-request`;

  const palyloadTemp = {
    userId: userInfo?.mrn, // '000348'
    doctorId, //'Momer'
    roomName, // '000348-Momer'
    userEmail: userInfo?.email,
    userName: userInfo?.email,
    patientPlatform: 'Web',
    patientDeviceId:
      'cIW_CtJxS3aAxzw6jmO8-e:APA91bGM8OuHJqz2w3vWT8T_IpUdAA6eLBLTcb3FN7r0pOMe6H56sAdmGIDfYusnuv8PyjfsTP6IU8s-kwNpB1LSH6eTINJay1pOBY-1VlH8fFFlTtaiTymFn0eVzhxFLA8cUJi2kKoU',
    serviceCode: 'CON011',
    serviceName: 'Consultation - Telemedicine',
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(palyloadTemp),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
          sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    throw error
  }
};

export const generateChatAccessToken = async () => {
  const { apiChatRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // https://whealth.imc.med.sa:9443/imc_doctor/api/twilio/chat/get-access-token
  //   const endpoint = `${apiRoute}api/self-registration/yakeen/v5`;
  const endpoint = `${apiChatRoute}api/twilio/chat/get-access-token`;

  const palyloadTemp = {
    identity: userInfo?.mrn, //000348
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
      body: JSON.stringify(palyloadTemp),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
          // sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    throw error;
  }
};
