import React from 'react';

const Dropdown = ({ selectedDate, setSelectedDate }) => {
  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];
  const years = ["2023", "2024", "2025"];

  const handleMonthChange = (e) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(months.indexOf(e.target.value));
    setSelectedDate(newDate);
  };

  const handleYearChange = (e) => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(e.target.value);
    setSelectedDate(newDate);
  };

  return (
    <div className="flex justify-end gap-2 mb-4 w-full">
      <select 
        value={months[selectedDate.getMonth()]} 
        onChange={handleMonthChange} 
        className="border border-blue-300 px-4 py-2 rounded-lg"
      >
        {months.map((month) => (
          <option key={month} value={month}>{month}</option>
        ))}
      </select>
      <select 
        value={selectedDate.getFullYear()} 
        onChange={handleYearChange} 
        className="border border-blue-300 px-4 py-2 rounded-lg"
      >
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
