import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function App() {
  const languageState = useSelector((state) => state.language);

  useEffect(() => {
    if (languageState) {
      localStorage.setItem("language", languageState.language);
      if(languageState.language === "ar") {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
    }
  }, [languageState,languageState.language]);
  return <RouterProvider router={router} />;
}

export default App;
