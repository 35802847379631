import { envVariables } from '../../config';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';

export const updateProfileInfo = async (email, address, addressAr) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/user/updateInfo/`;

  const bodyData = {
    mrNumber: userInfo?.mrn,
    email: email,
    address: address,
    addressAr: addressAr || '',
    hosp: hospitalCode,
  };

  try {

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(bodyData),
    });
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`user/updateInfos: Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error! user/updateInfo: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching gaurdian details:', error);
    return error;
  }
};

export const userProfileDetailReq = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/user/getDetails/${userInfo?.mrn}/${hospitalId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage()
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return error;
  }
};

export const updateProfilePic = async (reqBody) => {
  const { apiRoute } = envVariables;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}upload/patientProfileImage`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return error;
  }
};

export const verifyEmail = async (email) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/verifyemail`;

  const reqBody = {
    patid: userInfo?.mrn,
    email: email,
    hosp: hospitalId,
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};

export const validateEmail = async (reqBody) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const endpoint = `${apiRoute}api/validateemail`;

  const payLoad = {
    ...reqBody,
    // patid: '000348',
    // email: 'email@test.com',
    // patient_id: '26',
    // otp: '2521',
    hosp: hospitalId,
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        "Accept-Language": getSelectLanguage(),
      },
      body: JSON.stringify(payLoad),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
    // await sessionsMiddleWare(data);

    return data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};
