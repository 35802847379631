import { VITALS_REPORT } from './Vitals';
import { envVariables } from '../config';
import { sessionsMiddleWare } from './SessionExpire';
import store from '../store';
import { hideLoader, showLoader } from '../store/globalLoaderError';
import { getSelectLanguage } from './getCurrentSelectLanguage';

const { apiRoute } = envVariables

export const mockFetchRequest = (MOCK_DATA) => {
  return new Promise((resolve, reject) => {
    // Simulate a delay to mimic network request
    setTimeout(() => {
      resolve(MOCK_DATA);
    }, 1000); // Simulate 1 second delay
  });
};

export const vitalReportLoader = async (pageNo) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const selectedHospInfo = JSON.parse(
      localStorage.getItem('selectedHopitalInfo')
    );
    const hosp = selectedHospInfo?.code ?? 'IMC';
    const hospitalId = selectedHospInfo?.id ?? '1';

    // const host = process.env.REACT_APP_HOST;

    // return mockFetchRequest(VITALS_REPORT)
    // return VITALS_REPORT
    // return {vital_list:[],total:1}

    const endpoint = `${apiRoute}api/health/patient/vitals?patientId=${userInfo.patientId}&page=${pageNo}&hosp=${hosp}`;

    const response = await fetch(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo.mrn,
        Patient_ID: userInfo.patientId,
        "Accept-Language": getSelectLanguage(),
      },
    });

    if (!response.ok) {
      if(response.status === 403) {
        sessionsMiddleWare();
    }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Viltal reports HTTP error! status:')
    throw error;    
  }
};
