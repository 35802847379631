import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiChevronsLeft, FiChevronsRight, FiPlus, FiLogOut } from 'react-icons/fi';
import { LuStethoscope } from 'react-icons/lu';
import { TiTick } from "react-icons/ti";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GrGroup,GrMapLocation } from 'react-icons/gr';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { TbActivityHeartbeat, TbNotes } from 'react-icons/tb';
import { LiaPillsSolid, LiaPrescriptionSolid } from 'react-icons/lia';
import { MdOutlineTune, MdRotate90DegreesCcw, MdOutlineDashboardCustomize } from 'react-icons/md';
import { FaHandHoldingMedical } from 'react-icons/fa';
import {
  authenticationActions,
  modalActions,
  portalActions,
  sidebarActions,
} from '../../../store';

import './style.css';
import { selectLanguage } from '../../../store/languageSlice';
import { clearLocalStorageExcept } from '../../../utilFunctions/SessionExpire';
import Tooltip from './Tooltip';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';
import Modal from '../../Modal/Modal';

const Sidebar = (props) => {
  const location = useLocation();
  const { t, isRtl } = useSelector(selectLanguage)
  const { selectedHospital } = useSelector((state) => state.hospitalInfo);
  const { modalType } = useSelector((state) => state.modal);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [hoveredIndex, setHoveredIndex] = useState('/book-appointment');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarOpen, openOnMobile } = useSelector((state) => state.sidebar);
  const toggleFamilyDrawer = () => {
    dispatch(portalActions.toggleMyFamilyDrawer());
  };

  const toggleContactUsDrawer = () => {
    dispatch(portalActions.toggleContactUsDrawer());
  };

  const toggleSecondOpnionDrawer = () => {
    // dispatch(portalActions.toggleSecondOpinionDrawer());
    // dispatch(modalActions.toggle(''));
    dispatch(sidebarActions.toggleMobile());
    setTimeout(() => {
      dispatch(modalActions.toggle('secondOpinion'));
    }, 200);
  };

  const sideRef = useRef();

  useClickOutside(sideRef, () => {
    dispatch(sidebarActions.closeMobile());
  });

  const logoutHandler = () => {
    dispatch(authenticationActions.authenticateToggle(false));
    // localStorage.removeItem("loginTime")
    // localStorage.removeItem("userInfo")
    clearLocalStorageExcept();
    dispatch(modalActions.setSwitchPatient(false));
    dispatch(portalActions.updatedSelectedDependent(null));
    navigate('/login');
  };

  useEffect(() => {
    if (isTablet && sidebarOpen) {
      dispatch(sidebarActions.toggle());
    } else if (isDesktop && !sidebarOpen) {
      dispatch(sidebarActions.toggle());
    }
  }, [isTablet, isDesktop]);

  return (
    <>
    
    <div
      ref={sideRef}
      className={`sidebar ${isMobile ? 'fixed' : ''} ${
        openOnMobile ? 'openMobile' : ''
      }`}
    >
      <button
        onClick={() => {
          dispatch(sidebarActions.toggle());
        }}
      >
        {
          isRtl ?
          <FiChevronsRight />
          : <FiChevronsLeft />
        }
      </button>
      <div>
        <ul className="list">
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <MdOutlineDashboardCustomize />
              </span>
              <span>{t.dashboard}</span>
            </NavLink>
          </li>
          <li
            onMouseEnter={() => setHoveredIndex('/book-appointment')}
            onMouseLeave={() => setHoveredIndex(null)}
            className="list-item"
          >
            <NavLink
              to="/book-appointment"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <LuStethoscope />
              </span>
              <span>{t.BookAppointments}</span>
            </NavLink>
            {/* {hoveredIndex === '/book-appointment' && <Tooltip text={t.BookAppointments} />} */}
          </li>
          <li>
            <NavLink
              to="/reports"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <LiaPrescriptionSolid />
              </span>
              <span>{t.Reports}</span>
            </NavLink>
          </li>
          <li>
            <button onClick={toggleFamilyDrawer}>
              <span className="icon">
                <GrGroup />
              </span>
              <span>{t.Family_Profile}</span>
            </button>
          </li>
          <li>
            <NavLink
              to="/vital-report"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <TbActivityHeartbeat />
              </span>
              <span>{t.VitalSigns}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/medication"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <LiaPillsSolid />
              </span>
              <span>{t.Medication}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/home-health-care"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <TbNotes />
              </span>
              <span>{t.HomeHealthCare}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/location-map"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <GrMapLocation />
              </span>
              <span>{t.locationMap}</span>
            </NavLink>
          </li>
         {selectedHospital?.hospitalCode == "IMC" &&  <li>
            <a
              href="https://my.matterport.com/show/?m=L8BKBzZx2QG"
              target="_blank"
            >
              <span className="icon">
                <MdRotate90DegreesCcw />
              </span>
              <span>{t.VirtualTour}</span>
            </a>
          </li>}
          <li>
            <a href="https://www.imc.med.sa/en/wellness" target="_blank">
              <span className="icon">
                <FaHandHoldingMedical />
              </span>
              <span>{t.IMCWellness}</span>
            </a>
          </li>
          {selectedHospital?.IsMayoSecondOpinionEnabled && <li>
            <button onClick={()=>toggleSecondOpnionDrawer()}>
              <span className="icon">
              <img src={`${process.env.PUBLIC_URL}/images/icons/mayo.svg`} alt={'secondOpinion'} />
              </span>
              <span>{t.secondOpinion}</span>
            </button>
          </li>}
          <li>
          <button onClick={()=>toggleContactUsDrawer()}>
              <span className="icon">
                <TfiHeadphoneAlt />
              </span>
              <span>{t.contactUs}</span>
            </button>
          </li>
          {/* <li>
                        <NavLink to="/" className={({isActive}) => isActive ? "active" : ""}>
                            <span className="icon"><RiLayoutGridLine /></span>
                            <span>Dashboard</span>
                        </NavLink>
                    </li> */}
          {/* <li>
            <NavLink
              to="/manage-appointment"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <FiPlus />
              </span>
              <span>{t.ManageAppointments}</span>
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/assessment-report"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span className="icon">
                <IoDocumentTextOutline />
              </span>
              <span>{t.AssessmentReport}</span>
            </NavLink>
          </li> */}
          
          {/* <li>
                        <NavLink to="/xyz" className={({isActive}) => isActive ? "active" : ""}>
                            <span className="icon"><LiaPillsSolid /></span>
                            <span>Prescription</span>
                        </NavLink>
                    </li> */}
          {/* <li>
                        <NavLink to="/ab" className={({isActive}) => isActive ? "active" : ""}>
                            <span className="icon"><TbNotes /></span>
                            <span>Health Summary</span>
                        </NavLink>
                    </li> */}
          
          
          
          
          
        </ul>
        <ul>
          {/* <li>
                        <NavLink to="/settings" className={({isActive}) => isActive ? "active" : ""}>
                            <span className="icon"><MdOutlineTune /></span>
                            <span>Settings</span>
                        </NavLink>
                    </li> */}
          {/* <li>
                        <button onClick={()=>logoutHandler()}>
                            <span className="icon"><FiLogOut /></span>
                            <span>{t.Logout}</span>
                        </button>
                    </li> */}
        </ul>
        <div className="flex justifyEnd">
          <img
            src={`${process.env.PUBLIC_URL}/images/menu-banner-bottom.svg`}
          />
        </div>
      </div>
    </div>
    {modalType === 'secondOpinion' && (
        <Modal title={t.Confirm}>
          <p className="leading-8">{t.secondOpinionAlertMsg}</p>
          <p className="leading-8 font-semibold">{t.secondOpinionAlertMsg2}</p>
          <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
            <button
              className="bg-tertiary text-white btn"
              onClick={() => {
                dispatch(modalActions.toggle(''));
                navigate("/book-appointment");
              }}
            >
              {t.No}
            </button>
            <button
              className="bg-secondary text-white btn"
              onClick={()=>{
                dispatch(modalActions.toggle(''));
                dispatch(portalActions.toggleSecondOpinionDrawer());  
              }}
            >
              {t.Yes}
            </button>
          </div>
        </Modal>
      )}

       {modalType === 'secondOpinionSuccess' && (
        <Modal title={t.requestSubmitted} customIcon ={<TiTick color='green'/>}>
          <p className="leading-8">{t.secondOpinionSuccess}</p>
          <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
            <button
              className="bg-secondary text-white btn"
              onClick={() => {
                dispatch(modalActions.toggle(''));
              }}
            >
              {t.ok}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Sidebar;
