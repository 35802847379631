import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../store/languageSlice';
import Loader from '../components/UI/Loader';
import LanguageSwitch from '../components/LanguageSwitch';
import ChatModal from '../components/ChatModal';

import '../components/Login/style.css';
import ApiSwitcher from '../components/Login/ApiSwitcher';
import { modalActions } from '../store';
import { sendLoginRequest } from '../utilFunctions/user/loginRequest';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useSelector(selectLanguage);
  const navigate = useNavigate();
  const [mrn, setMrn] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [isRememberClicked, setIsRememberClicked] = useState(false);

  const validateLoginHandler = () => {
    const Regex = /^[0-9]+$/;

    if (mrn && mrn.trim()) {
      if (!Regex.test(mrn.trim())) {
        throw new Error(t.errorInvalidMRN);
      }
    } else if (nationalId && nationalId.trim()) {
      if (!Regex.test(nationalId.trim())) {
        throw new Error(t.errorNID);
      }
    } else {
      // both fields empty
      throw new Error(t.errorMsg);
    }
  };

  const handleClearDependent= () => {
    let previousUserInfo = JSON.parse(localStorage.getItem('previousUserInfo'));
    localStorage.setItem('userInfo', JSON.stringify(previousUserInfo));
    localStorage.removeItem('previousUserInfo');

    setTimeout(() => {
      dispatch(modalActions.setSwitchPatient(false));
    }, 1000);

  };

  const handleRememberClick = () => {
    const newIsRememberClicked = !isRememberClicked;
    setIsRememberClicked(newIsRememberClicked);

    if (newIsRememberClicked) {
      if (mrn) localStorage.setItem('mrn', mrn);
      if (nationalId) localStorage.setItem('nationalId', nationalId);
      localStorage.setItem('isRememberClicked', 'true');
    } else {
      localStorage.removeItem('mrn');
      localStorage.removeItem('nationalId');
      localStorage.setItem('isRememberClicked', 'false');
    }
  };

  const handleOnFocus = () => {
    setShowError(false);
  };

  const loginClickHandler = async (e) => {
    e.preventDefault();
  
    try {
      // Validate MRN or NationalId
      validateLoginHandler();
  
      // Prepare login data
      const loginData = {
        mrn: mrn || '',
        nationalId: nationalId || '',
      };
  
      // Send request to generate OTP
      setLoading(true);
      const response = await sendLoginRequest(loginData);
  
      // Handle the response
      handleLoginResponse(response, loginData);
      navigate('/otp');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginResponse = (response, loginData) => {
    if (!response?.status) throw new Error(t.Something_Wrong);
  
    // Store login data in localStorage
    localStorage.setItem('loginData', JSON.stringify(loginData));
    localStorage.setItem('loginReq', JSON.stringify(response));
  
    handleClearDependent();
    dispatch(modalActions.initiateUserTour());
  };
  
  const handleError = (error) => {
    if (error instanceof TypeError) {
      alert('Network error: ' + error.message);
    } else {
      setShowError(true);
      const msg = error.message === t.errorMsg ? t.errorMsg : t[error.message] ?? t.Something_Wrong;
      setErrorMsg(msg);
    }
  };


  useEffect(() => {
    const storedRemember = localStorage.getItem('isRememberClicked') === 'true';
    setIsRememberClicked(storedRemember);

    if (storedRemember) {
      const storedMrn = localStorage.getItem('mrn');
      const storedNationalId = localStorage.getItem('nationalId');
      if (storedMrn) setMrn(storedMrn);
      if (storedNationalId) setNationalId(storedNationalId);
    }
  }, []);

  return (
    <div className="relative">
      <div className="flex gap-4 absolute right-32 top-16 z-10">
        <ApiSwitcher />
        <LanguageSwitch />
      </div>
      <div className="login_container">
        <div className="login_box">
          <section className="loginForm_Bpx">
            <div className="imc_logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/bigLogo.png`}
                alt="ImcLogo"
              />
            </div>
            <form onSubmit={loginClickHandler}>
              <div className="loginForm">
                <div className="login_box_mr">
                  <label className="login_box_mr_label">{t.MR_Number}</label>
                  <input
                    type="text"
                    placeholder={t.Enter_MR_Number}
                    value={mrn}
                    onFocus={handleOnFocus}
                    className="login_box_mr_input"
                    onChange={(e) => {
                      setMrn(e.target.value);
                      if (isRememberClicked)
                        localStorage.setItem('mrn', e.target.value);
                    }}
                  />
                </div>
                <div className="Or-command">{t.Or}</div>
                <div className="login_box_id">
                  <label className="login_box_id_label">{t.LoginId}</label>
                  <input
                    type="text"
                    className="login_box_id_input"
                    onFocus={handleOnFocus}
                    placeholder={t.NationalIqamaPassport}
                    value={nationalId}
                    onChange={(e) => {
                      setNationalId(e.target.value);
                      if (isRememberClicked)
                        localStorage.setItem('nationalId', e.target.value);
                    }}
                  />
                </div>
                <div className="rememberme_text">
                  <label htmlFor="rememberMe">
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="rememberMe"
                      checked={isRememberClicked}
                      onChange={handleRememberClick}
                      className="remember_me_checkbox"
                    />
                    {t.RememberMe}
                  </label>
                </div>
                {showError && errorMsg && (
                  <p className="errorMsg my-8">
                    <span>{errorMsg}</span>
                  </p>
                )}
                <div className="signBtnDiv">
                  <button type="submit" className="signIn_btn">
                    {loading ? <Loader roundLoader /> : t.SignIn}
                  </button>
                </div>
              </div>
            </form>
            <p className="registerLink mt-8">
              <span>
                {t.NewToIMC} &nbsp; <Link to="/register">{t.Register}</Link>
              </span>
            </p>
          </section>
          <img
            src={`${process.env.PUBLIC_URL}/images/menu-banner-bottom.svg`}
            className="login_banner_bottm"
          />
        </div>
      </div>
      <div className="copywrite_banner_bottm w-full">
        <span>{t.CopyWrite}</span>
      </div>
      <div className="absolute right-4 sm:right-24 bottom-24 z-10 sm:bottom-16">
        <ChatModal />
      </div>
    </div>
  );
};

export default LoginForm;
