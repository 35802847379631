import { envVariables } from "../../config";
import store, { modalActions } from "../../store";
import { getSelectLanguage } from "../getCurrentSelectLanguage";
import { sessionsMiddleWare } from "../SessionExpire";

const bookAppointmentAPI = async (docCode, serviceCode, specialityCode, selectedSlotTime, selectedDate, hospCode, selectedSlotId, appointmentType, docName, docNameAr, hospId) => {
    const {apiRoute} = envVariables;
    
    const endpoint = `${apiRoute}api/appointment/book?`;
    const url = new URL(endpoint);

    const params = {
        providerCode: docCode,
        serviceCode: serviceCode,
        specialityCode: specialityCode,
        slotFromTime: selectedSlotTime,
        slotDate: selectedDate,
        hospitalCode: hospCode
    }

    const reqBody = {
      patientId: JSON.parse(localStorage.userInfo).patientId,
      slotBookingId: selectedSlotId,
      type: appointmentType,
      providerDescription: docName,
      arabicProviderDescription: docNameAr
    }

    // url.searchParams.append
    for (const key in params) {
        url.searchParams.append(key, params[key]);
    }

    // "https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/appointment/book?providerCode=ARammal&serviceCode=CON002&specialityCode=FC-GASTRO&slotFromTime=19%3A00&slotDate=2024-04-29&hospitalCode=FC"

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.userInfo).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok && response.status !== 200) {
            if(response.status === 403) {
                sessionsMiddleWare();
                store.dispatch(modalActions.toggle("sessionExpire"))
            }
            throw new Error(`HTTP error! status /appointment/book: ${response.status}`);
        }
        // return await response.json();
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data /appointment/book:', error);
        return null;
    }
}

export const resheduleAookAppointmentAPI = async (oldSlot,newSlot) => {
    const {apiRoute} = envVariables;
    const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

    const endpoint = `${apiRoute}appointment/reschedule`;

    const reqBody = {
        "old_slot_booking_id": oldSlot,
        "new_slot_booking_id": newSlot
      }


    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'Hospitalid': hospitalId,
                'Mrn': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_id': JSON.parse(localStorage.userInfo).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok && response.status !== 200) {
            if(response.status === 403) {
                sessionsMiddleWare();
                store.dispatch(modalActions.toggle("sessionExpire"))
            }
            throw new Error(`HTTP error! status /appointment/reshedule: ${response.status}`);
        }
        // return await response.json();
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data /appointment/reshedule:', error);
        return null;
    }
}



export default bookAppointmentAPI;