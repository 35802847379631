import { envVariables } from "../../config";
import { getSelectLanguage } from "../getCurrentSelectLanguage";

export const getDoctorsList = async () => {
  // const host = apiRoute;
  const { apiRoute } = envVariables;
  // const host = process.env.REACT_APP_HOST;
  // const hospitalCode = 'IMC'
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : "IMC";
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : "1";

  /* Dr.NAME EN AR
    Dr.CODE
    Speciality Code
    Speciality Desc EN AR */
  const NameAndCodeRes = await fetch(
    `${apiRoute}api/careProvidersList/all?hospitalCode=${hospitalCode}`,
    {
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
        HospitalID: hospitalId,
        Host: "patientportal.imc.med.sa",
        "Accept-Language": getSelectLanguage(),
      },
    }
  );
  const NameAndCodeData = await NameAndCodeRes.json();

  const doctorsInfoArray = [];
  NameAndCodeData.doctorListBasedOnSpeciality.forEach((speciality) => {
    speciality.doctorList.forEach((doctor) => {
      doctorsInfoArray.push({
        docCode: doctor.provider_code,
        docName: doctor.provider_description,
        docNameAR: doctor.arabic_provider_description,
        specialityCode: speciality.specialityCode,
        specialityDescription: speciality.specialityDescription,
        specialityDescriptionAR: speciality.arabicSpecialityDescription,
      });
    });
  });

  localStorage.setItem("doctorsInfomation", JSON.stringify(doctorsInfoArray));

  return doctorsInfoArray;
};
