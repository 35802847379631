import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpcommingAppointments, selectBookedAppointments } from "../../store/appointmentSlice";
import UpcomingAppoitmentSlider from "../BookAppointment/UpcomingAppoitmentSlider";
import getServicePrice from "../../utilFunctions/bookAppointment/getServicePrice";
import generatePaymentUrl from "../../utilFunctions/bookAppointment/generatePaymentUrl";
// import ManageAppointmentsList from "./ManageAppointmentsList";
import ManageAppointmentList from "./ManageAppointmentList";
import { selectLanguage } from "../../store/languageSlice";

const ManageAppointments = (props) => {
    const dispatch = useDispatch();
    const { t, isRtl } = useSelector(selectLanguage);
    const bookedAppointments = useSelector(selectBookedAppointments);
    const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
    const [selectedDoc, setSelectedDoc] = useState();
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [servicePriceInfo, setServicePriceInfo] = useState(null);
    const [loadingPriceINfo, setLoadingPriceINfo] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(null);    

    const upcomingAppointmentsList = async () => {
        // const list = await getUpcomingAppointments();
        dispatch(fetchUpcommingAppointments());
        // setBookedAppointments(list);
    };

    const appointmentConfirmationHandler = async (selectedDoc) => {
        setSelectedDoc(selectedDoc);
        // dispatch(modalActions.toggle(''));
        setTimeout(() => {
          setShowServiceModal(true);
        }, 200);
        setLoadingPriceINfo(true);
        const servicesPrice = await getServicePrice(
          selectedDoc?.selectedSlotId,
          hospitalId
        );
        setServicePriceInfo(servicesPrice);
        setLoadingPriceINfo(false);
    
        if (selectedDoc?.appointmentType === 'Online') {
          const paymentUrlRes = await generatePaymentUrl(
            selectedDoc?.selectedSlotId,
            servicesPrice,
            selectedDoc?.selectedDate,
            selectedDoc?.docName,
            hospitalId
          );
          setPaymentUrl(paymentUrlRes?.data.url);
        }
    };

    useEffect(() => {
        upcomingAppointmentsList();
      }, [hospitalId]);

    return (
        <div className="db-card mngapt">
            <h3>{t.ManageAppointments}</h3>
            {bookedAppointments && bookedAppointments.length > 0 && (
                <>
                {/* <h4>Upcoming Appointments</h4> */}
                <UpcomingAppoitmentSlider
                    title= {t.Upcoming}
                    hideMenu= {true}
                    updateAppointmentsFunc={upcomingAppointmentsList}
                    // doctorsInfoList={doctorsBasicInfoArray}
                    bookedAppointments={bookedAppointments}
                    isInfo={true}
                    appointmentConfirmationHandler={appointmentConfirmationHandler}
                />
                </>
            )}
            <ManageAppointmentList />
        </div>
    )
};

export default ManageAppointments;