import React, { useEffect, useRef, useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { FiEdit as FiEdit } from 'react-icons/fi';
import {
  userProfileDetailReq,
  verifyEmail,
  updateProfilePic,
  updateProfileInfo,
} from '../../../utilFunctions/profile/EditProfile';
import useDataFetching from '../../../utilFunctions/useFetchData';
import InputField from './InputField';
import Loader from '../../UI/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions, portalActions } from '../../../store';
import { envVariables } from '../../../config';
import { selectLanguage } from '../../../store/languageSlice';

const EditProfile = () => {
  const { assetsRouteUser } = envVariables;
  const { t, isRtl } = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const {
    data: profileData,
    loading,
    error,
  } = useDataFetching(userProfileDetailReq);

  const [formData, setFormData] = useState({
    email: '',
    mrNumber: '',
    passportNo: '',
    dob: '',
    address1: '',
    address2: '',
    insurance: '',
    insurance2: '',
  });

  const [selectedFile, setSelectedFile] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showVerifyLoader, setShowVerifyLoader] = useState(false);
  const [responseMsg, setResponseMsg] = useState({
    pic: {
      error: false,
      success: false,
      message: '',
    },
    emailVerify: {
      error: false,
      success: false,
      message: '',
    },
    onUpdate: {
      error: false,
      success: false,
      message: '',
    },
  });

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader.result.split(',')[1]; // Extracting base64 string without the prefix
      const formattedBase64 = `data:image/png;base64,${base64String}`; // Adding the prefix

      const picResponse = await updateProfilePic({
        base64Image: formattedBase64,
      });

      if (picResponse?.status) {
        setResponseMsg({
          ...responseMsg,
          pic: {
            error: false,
            success: true,
            message: t.PicSuccessMessage,
          },
        });
      } else {
        setResponseMsg({
          ...responseMsg,
          pic: {
            error: true,
            success: false,
            message: t.PicErrorMessage,
          },
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // reset allErrorAndMsgs
    setResponseMsg({pic: {
      error: false,
      success: false,
      message: '',
    },
    emailVerify: {
      error: false,
      success: false,
      message: '',
    },
    onUpdate: {
      error: false,
      success: false,
      message: '',
    },})
    // dispatch(modalActions.toggle('dependentModal'));
    // dispatch(modalActions.toggle('emailOtp'))
  };

  const handleSave = async () => {
    if(!isVerified) {
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: t.VerifyEmailMessage,
        },
      });

      return
    }

    setShowLoader(true);
    const resp = await updateProfileInfo(
      formData.email,
      formData.address1,
      formData.address2
    );
    setShowLoader(false);
    if (resp?.status == 'true') {
      setResponseMsg({
        ...responseMsg,
        onUpdate: {
          error: false,
          success: true,
          message: resp?.message,
        },
      });
    } else {
      setResponseMsg({
        ...responseMsg,
        onUpdate: {
          error: true,
          success: false,
          message:  resp?.message || t.Something_Wrong,
        },
      });
    }
  };

  const handleEmailCross = () => {
    setFormData({ ...formData, email: profileData?.patientProfile?.email });
  };

  const handleEmailUpdate = async () => {

    if(!isValidEmail(formData?.email)) {
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: t.NotValidEmail,
        },
      });

      return
    }
    setShowVerifyLoader(true)
    const req = await verifyEmail(formData.email);
    await handleSave();
    setShowVerifyLoader(false)
    if (req && req?.status !== 'false') {
      const validateResponse = {
        newEmail: formData.email,
        patid: profileData?.patientProfile?.patId,
        email: formData.email,
        patient_id: '26',
        otp: '2521',
      };
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: false,
          success: true,
          message: req?.message,
        },
      });

      dispatch(modalActions.validateEmail(validateResponse));
    } else {
      setResponseMsg({
        ...responseMsg,
        emailVerify: {
          error: true,
          success: false,
          message: req?.message,
        },
      });
    }
  };

  const handleUpdateAddress = () => {
    updateProfileInfo(formData.email, formData.address1, formData.address2);
  };

  const handleCancelSave = () => {
    dispatch(portalActions.toggleEditDrawer());
  };

  const isValidEmail=(email)=> {
    // Regular expression pattern to validate email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if the email is not empty and matches the regex pattern
    return email && email !== '' && emailRegex.test(email);
  }

  useEffect(() => {
    if (profileData?.patientProfile) {
      setFormData({
        email: profileData?.patientProfile?.email || '',
        mrNumber: profileData?.patientProfile?.mname || '',
        passportNo: profileData?.patientProfile?.nationality || '',
        dob: profileData?.patientProfile?.dob || '',
        address1: profileData?.patientProfile?.addressEn || '',
        address2: profileData?.patientProfile?.addressAr || '',
        insurance: '',
      });
      const isEmailVerified =
        profileData?.patientProfile?.isEmailVerified === '1';
      setIsVerified(isEmailVerified);
    }
  }, [profileData]);

  if (loading) return <Loader />;

  const fullNameEng = `${profileData?.tcsLoginResponse?.first_name} ${profileData?.tcsLoginResponse?.last_name}`;
  const fullNameAr = `${profileData?.tcsLoginResponse?.arabic_first_name} ${profileData?.tcsLoginResponse?.arabic_last_name}`;
  const isFemale = profileData?.patientProfile?.gender?.toLowerCase() === 'female';
  const imageUrl = profileData?.patientProfile?.profileImage ?  `${assetsRouteUser}${profileData?.patientProfile?.profileImage}` : `${process.env.PUBLIC_URL}/images/${isFemale ? 'female-avatar':'male-avatar'}.png`

  return (
    <div className="px-4">
      <div className="flex flex-col justify-center mx-4">
        <div className="flex w-full items-center justify-center gap-5">
          <div className="relative">
            <div
              style={{ border: '4px solid #005A9C' }}
              className="rounded-full w-16 h-16 overflow-hidden"
            >
              <img
                className="object-cover w-full h-full"
                src={
                  selectedFile ||
                  imageUrl
                }
                alt="user-img"
              />
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
            <button
              onClick={handleButtonClick}
              className=" absolute left-7 transform -translate-y-1/2 p-1  border-2 border-blue-800 bg-white rounded-full w-35 h-35"
            >
              <FiEdit className="text-gray-500" />
            </button>
          </div>

          <div className="flex flex-col justify-center items-start ">
            <h1 className="text-lg text-grey-700 font-semibold">
              {isRtl ? fullNameAr : fullNameEng}
            </h1>
            <div style={{ display: 'flex' }}>
              <h5 className="text-blue-700" style={{ color: '#005A9C' }}>
                {profileData?.patientProfile?.email}
              </h5>
              {profileData?.patientProfile?.isEmailVerified === '1' && (
                <IoMdCheckmarkCircleOutline style={{ color: '#009B41' }} />
              )}
            </div>
            {responseMsg?.pic.message !== '' && (
              <h5
                className="text-blue-700 mt-4"
                style={{ color: responseMsg?.pic.error ? 'var(--color-tertiary)' : 'green' }}
              >
                {responseMsg?.pic.message}
              </h5>
            )}
          </div>
        </div>

        <div className="mt-8 flex flex-col justify-center items-center">
          <InputField
            type="text"
            onChange={handleChange}
            placeholder={t.Enter_Email}
            editable={true}
            name="email"
            label={t.Email}
            onCheckClick={handleEmailUpdate}
            onCrossClick={handleEmailCross}
            value={formData?.email}
          />
          {responseMsg?.emailVerify.message !== '' && (
            <h5
              className="text-blue-700 my-2"
              style={{
                color: responseMsg?.emailVerify.error ? 'var(--color-tertiary)' : 'green',
              }}
            >
              {responseMsg?.emailVerify.message}
            </h5>
          )}
          {(!isVerified ||
            formData?.email !== profileData?.patientProfile?.email) && (
            <div className="w-full flex justify-end space-x-2">
              {showVerifyLoader ? <Loader roundLoader /> : 
              <button
                style={{ backgroundColor: '#00B0E6' }}
                className="px-4 py-1 rounded text-white"
                // disabled={isValidEmail(formData?.email)}
                onClick={handleEmailUpdate}
              >
                {t.Verify}
              </button>}
            </div>
          )}
          <InputField
            type="text"
            onChange={handleChange}
            // placeholder={t.Enter_MR_Number}
            editable={false}
            disabled={true}
            name="mrNumber"
            label={t.MR_Number}
            value={formData?.mrNumber}
          />
          <InputField
            type="text"
            onChange={handleChange}
            // placeholder={t.Enter_Passport_No}
            editable={false}
            disabled={true}
            name="passportNo"
            label={t.NationalIqamaPassport}
            value={formData?.passportNo}
          />

          <InputField
            type="date"
            onChange={handleChange}
            editable={false}
            disabled={true}
            name="dob"
            label={t.Date_of_Birth}
            value={formData?.dob}
          />

          <div className="mb-4 w-full">
            <InputField
              type="text"
              onChange={handleChange}
              placeholder={t.Address_1}
              editable={true}
              name="address1"
              label={t.Address}
              value={formData?.address1}
              onCheckClick={handleUpdateAddress}
            />
            <InputField
              type="text"
              onChange={handleChange}
              placeholder={t.Address_2}
              editable={true}
              name="address2"
              value={formData?.address2}
            />
          </div>
          {/* <InputField
            type="text"
            onChange={handleChange}
            placeholder={t.Enter_Insurance}
            editable={false}
            name="insurance"
            label={t.Insurance}
            disabled={true}
            value={formData?.insurance}
          />
          <InputField
            type="text"
            onChange={handleChange}
            editable={false}
            name="insurance2"
            disabled={true}
            value={formData?.insurance2}
          /> */}
          {responseMsg?.onUpdate.message !== '' && (
            <h5
              className="text-blue-700 my-4"
              style={{ color: responseMsg?.onUpdate.error ? 'var(--color-tertiary)' : 'green' }}
            >
              {responseMsg?.onUpdate.message}
            </h5>
          )}
          <div className="flex justify-end space-x-4">
            <button
              className="bg-white text-black border px-6 py-3 rounded"
              onClick={handleCancelSave}
            >
              {t.Cancel}
            </button>
            <button
              style={{ backgroundColor: '#00B0E6' }}
              className="px-6 py-3 rounded text-white"
              onClick={handleSave}
            >
              {showLoader ? <Loader roundLoader size='small' /> : t.Save}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
