// src/components/Toast.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from '../../store/toastSlice';
import './Toast.css';

const Toast = () => {
  const dispatch = useDispatch();
  const { message, visible, autoClose } = useSelector((state) => state.toast);

  useEffect(() => {
    if (visible && autoClose) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, 3000); // Hide the toast after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [visible, dispatch, autoClose]);

  if (!visible) return null;

  return (
    <div className="toast">
      {message}
    </div>
  );
};

export default Toast;
