import React from "react";
import AssessmentsList from "../components/AssessmentReport/AssessmentsList";
import "../components/AssessmentReport/AssessmentReport.css";
import useDataFetching from "../utilFunctions/useFetchData";
import { envVariables } from "../config";
import { getSelectLanguage } from "../utilFunctions/getCurrentSelectLanguage";
import Loader from "../components/UI/Loader";
import { sessionsMiddleWare } from "../utilFunctions/SessionExpire";

export const assessemnetLoader = async () => {
    try {
      const { apiRoute } = envVariables;
      let userData = JSON.parse(localStorage.getItem('userInfo'));
      const selectedHospInfo = JSON.parse(
        localStorage.getItem('selectedHopitalInfo')
      );
      const hospCode = selectedHospInfo?.code ?? 'IMC';
      const hospitalId = selectedHospInfo?.id ?? '1';
  
      const endpoint = `${apiRoute}api/promisAssessment/completed`;
  
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          patId: userData.mrn,
          page: '1',
          item_count: '10',
          hosp: hospitalId,
        }),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).accessToken
            }`,
          "Accept-Language": getSelectLanguage(),
        },
      });
  
      if (!response.ok) {
        if(response.status === 403) {
          sessionsMiddleWare();
      }
        throw new Error(`HTTP error! status medication: ${response.status}`);
      }
      // return await response.json();
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  };
  

const AssessmentReport = (props) => {
    const {
        data,
        loading,
        error,
      } = useDataFetching(assessemnetLoader);

    return (
        <div className="asmtRprt">
            {data && <AssessmentsList assessementData={data}/>}
            {
              loading && <div className="w-full justify-center"><Loader roundLoader/> </div>
            }
            {/* <div className="contains-sidebar"> */}
                {/* <div className="rt-sd">
                    <AssessmentsList />
                </div> */}
                {/* <div className="pge-sdbr">
                    <RightSidebar />
                </div> */}
            {/* </div> */}
        </div>
    )
};

export default AssessmentReport;