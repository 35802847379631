import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../utilFunctions/useClickOutside';
import { selectLanguage } from '../../store/languageSlice';
import { selectAssessment } from '../../store/assessmentSlice';
import { assessmentActions } from '../../store';

const AssessmentYearsDropdown = () => {
  const dispatch = useDispatch();
  const { t } = useSelector(selectLanguage);
  const { selectedYear, years: items } = useSelector(selectAssessment);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const inputContainerRef = useRef();

  useClickOutside(inputContainerRef, () => {
    setDropdownVisible(false);
  });

  const handleChange = (event) => {
    const value = event.target.value;
    dispatch(assessmentActions.setselectedYear(value));
    setDropdownVisible(true);
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleItemClick = (item) => {
    dispatch(assessmentActions.setselectedYear(item.year));
    setDropdownVisible(false);
  };

  useEffect(() => {
    setSelectedValue(selectedYear);
  }, [selectedYear]);
  
  return (
    <div style={{ width: '150px' }}>
      <div
        className="dropdown1-container1"
        style={{ margin: '0px', height: '34px'}}
      >
        <div onClick={handleFocus} className="input1-wrapper">
          <input
            type="text"
            className="search1-input"
            placeholder={t.Select}
            defaultValue={selectedYear}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          <span className="dropdown1-arrow">&#9662;</span>
        </div>
        {dropdownVisible && (
          <ul ref={inputContainerRef} className="dropdown1-list">
            {items?.map((item, index) => (
              <li
                key={index}
                className="dropdown1-item"
                onClick={() => handleItemClick(item)}
              >
                {item.year}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AssessmentYearsDropdown;
