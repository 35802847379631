import { envVariables } from "../config";
import { sessionsMiddleWare } from "./SessionExpire";
import { getSelectLanguage } from "./getCurrentSelectLanguage";

const cancelAppointment = async (bookingId, hospId) => {
    const {apiRoute} = envVariables;
    const encodedId = encodeURIComponent(bookingId)
    const endpoint = `${apiRoute}api/bookings/cancel/${encodedId}`;
    const url = new URL(endpoint);

    // "https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/appointment/book?providerCode=ARammal&serviceCode=CON002&specialityCode=FC-GASTRO&slotFromTime=19%3A00&slotDate=2024-04-29&hospitalCode=FC"

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                'HospitalID': hospId,
                'MRN': JSON.parse(localStorage.getItem("userInfo")).mrn,
                'isGuardian': 'no',
                'Patient_ID': JSON.parse(localStorage.getItem("userInfo")).patientId,
                'platform': 'web',
                "Accept-Language": getSelectLanguage(),
            }
        });
        if (!response.ok && response.status !== 200) {
            if(response.status === 403) {
                sessionsMiddleWare();
            }
            throw new Error(`HTTP error! api/bookings/cancel: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching api/bookings/cancel:', error);
        return null;
    }
};

export default cancelAppointment;