// import { authenticationActions } from "../store";
// import { useDispatch } from "react-redux";

import { clearLocalStorageExcept } from "./SessionExpire";

export const LoginTimeStamp = (accessToken, newLogin) => {
  // const dispatch = useDispatch();

  let hours = 1; // to clear the localStorage after 1 hour
  let loggedIn = false;
  let now = new Date().getTime();
  let loginTime = localStorage.getItem("loginTime");
  let languageSelect = localStorage.getItem("language");
  if (loginTime == null && accessToken) {
    localStorage.setItem("loginTime", now);
    loggedIn = true;
  } else if (loginTime !== null && accessToken && newLogin) {
    localStorage.setItem("loginTime", now);
    loggedIn = true;
  } else {
    if (now - loginTime > hours * 60 * 60 * 1000) {
      clearLocalStorageExcept()
      if (languageSelect) {
        localStorage.setItem("language", languageSelect);
      }
    } else {
      loggedIn = true;
    }
  }

  return loggedIn;
};
