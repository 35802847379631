import React, { useRef, useState } from 'react';
import { MdOutlineTune } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { handleUpdateVitalsCards } from '../../../store/vitalReportSlice';
import { useClickOutside } from '../../../utilFunctions/useClickOutside';
import CustomeModal from '../../Modal/CustomeModal';

function SelectVitalsDropdwon() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { vitalCards } = useSelector((state) => state.vitalReport);
  const {t,isRtl} = useSelector(selectLanguage)
  const [showDialog,setShowDialog] = useState(false);

  useClickOutside(dropdownRef,()=>{
    setIsOpen(false);
  })
  const handleOptionSelect = (index) => {
    const updatedVitalCards = [...vitalCards];
    updatedVitalCards[index] = {
      ...updatedVitalCards[index],
      isShow: !updatedVitalCards[index].isShow,
    };

    const showCount = updatedVitalCards.filter((card) => card.isShow).length;
    if (showCount == 0) return;
    if (showCount > 4)
      return setShowDialog(true)
      // return alert('You can only select a maximum of 4 vital cards.');

    dispatch(handleUpdateVitalsCards(updatedVitalCards));
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          onClick={toggleMenu}
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
        >
          <MdOutlineTune style={{ fontSize: '28px', rotate: '90deg' }} />
        </button>
      </div>

      {isOpen && (
        <div
          className={`absolute ${isRtl ? 'left-0' : 'right-0'} z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-2" role="none">
            {vitalCards?.map((x, index) => {
              return (
                <div className="space-y-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="height"
                      checked={x.isShow}
                      onChange={() => handleOptionSelect(index)}
                      className="form-checkbox h-4 w-4 text-blue-500"
                    />
                    <span className="text-sm text-gray-700">
                      {x.title}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
    {showDialog && (
      <CustomeModal
        title={t.Error }
        isOpen={true}
        onCancel={() => setShowDialog(false)}
        cancelText={t.Close}
      >
        <p className="appntInfo">{t.vitalCardWarning}</p>
      </CustomeModal>
    )}
    </>
  );
}

export default SelectVitalsDropdwon;
