import { envVariables } from '../../config';
import store from '../../store';
import { hideLoader, showLoader } from '../../store/globalLoaderError';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';
import { REPORT_TYPES } from '../../pages/Reports';
import { hideToast, showToast, showToastNoAutoCLose } from '../../store/toastSlice';
import { mockFetchRequest } from '../VitalsReportList';
import { LabReports } from './mockData';

const DownloadMsg = 'Please wait.... checking if report is available.'
const ErrorMsg = 'Report is unavailable.'
const SuccessMsg = 'Downloading ....'
const DownloadMsgAr= 'يرجى الانتظار.... جاري التحقق مما إذا كان التقرير متاحًا'
const  ErrorMsgAr= 'التقرير غير متاح'
const  SuccessMsgAr= 'جارٍ التنزيل ....'


const getIsRtl = ()=> {
  const lang = getSelectLanguage();
  if(lang == 'ar') {
    return true;
  }

  return false;
}


export const getReportByType1 = async (reportType, oldData, pageNo = 1) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // for new reports
  let endpoint = `${apiRoute}api/reports/${reportType}?patientId=${
    userInfo?.patientId
  }&page=${pageNo}&oldData=${0}&hospitalCode=${hospitalCode}`;

  // for history reports
  if (oldData === 1) {
    endpoint = `${apiRoute}api/reports/${reportType}?patientId=${
      userInfo?.mrn
    }&page=${pageNo}&oldData=${1}`;

    if (reportType === REPORT_TYPES.LAB) {
      endpoint = `${endpoint}&hospitalCode=${hospitalId}`;
    } else {
      endpoint = `${endpoint}&hosp=${hospitalId}`;
    }
  }

  return await mockFetchRequest(LabReports)
  try {
    // store.dispatch(showLoader());
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        // store.dispatch(hideLoader());
        sessionsMiddleWare();
        throw new Error(`reportType: Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP ${reportType} error! status: ${response.status}`);
      }
    }

    const data = await response.json();
    // store.dispatch(hideLoader());

    return {data, reportType};
  } catch (error) {
    // store.dispatch(hideLoader());
    console.error(`Error fetching report ${reportType}:`, error);
    return null;
  }
};

let abortController;

export const getReportByType = async (reportType, oldData, pageNo = 1, loaderCB) => {
  // Cancel the previous request if it exists
  if (abortController) {
    abortController.abort();
  }
  // Create a new AbortController instance for the current request
  abortController = new AbortController();
  const signal = abortController.signal;

  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // for new reports
  let endpoint = `${apiRoute}api/reports/${reportType}?patientId=${
    userInfo?.patientId
  }&page=${pageNo}&oldData=${0}&hospitalCode=${hospitalCode}`;

  // for history reports
  if (oldData === 1) {
    endpoint = `${apiRoute}api/reports/${reportType}?patientId=${
      userInfo?.mrn
    }&page=${pageNo}&oldData=${1}`;

    if (reportType === REPORT_TYPES.LAB) {
      endpoint = `${endpoint}&hospitalCode=${hospitalId}`;
    } else {
      endpoint = `${endpoint}&hosp=${hospitalId}`;
    }
  }

  // return await mockFetchRequest(LabReports)
  
  try {
    // store.dispatch(showLoader());
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
      signal, // Pass the signal to fetch for cancellation
    });

    if (!response.ok) {
      if (response.status === 403) {
        // store.dispatch(hideLoader());
        sessionsMiddleWare();
        throw new Error(`reportType: Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP ${reportType} error! status: ${response.status}`);
      }
    }

    const data = await response.json();
    // store.dispatch(hideLoader());
    return data;
  } catch (error) {
    // Check if the error is due to an abort
    if (error.name === 'AbortError') {
      console.warn(`Request for ${reportType} was canceled.`);
    } else {
      console.error(`Error fetching report ${reportType}:`, error);
    }
    throw error
    return null;
  }
};


export const downloadReport = async (
  specimanId,
  oldData,
  reportType = 'laboratory-report'
) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/reports/${reportType}?orderId=${specimanId}&mrn=${userInfo?.mrn}&oldData=${oldData}&hosp=${hospitalId}`;

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
    });
    // store.dispatch(hideToast('Downloading'));
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(data?.pdf_base64, `${reportType}-${specimanId}`);
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report ${reportType}:`, error);
    return null;
  }
};

export const downloadLabSmartReport = async (
  orderId,
  oldData,
) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/medicus/generatePdf`;

  const payload = JSON.stringify({
    "mrNumber": userInfo?.mrn,
    // "orderNumber": '2302111041-2',
    "orderNumber": orderId,
    "language": getSelectLanguage()
  });

  // const testPay = JSON.stringify({"mrNumber":"654143","orderNumber":"1974690/20","language":"en"})

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        Hospitalid: hospitalId,
        Host: 'patientportal.imc.med.sa',
        Mrn: userInfo?.mrn,
        // Mrn: "654143",
        Patient_id: userInfo?.patientId,
        'Platform': 'web', 
        'Accept-Language': getSelectLanguage(), 
      },
      body:payload
    });
    // store.dispatch(hideToast('Downloading'));
    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status || data.status !== 200) {
      throw new Error(`Error fetching report`);
    }

    if (data?.data.file && data?.message == 'Success') {
      await savePDF(data?.data.file, `smart-report-${orderId}`);
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report smart report:`, error);
    return null;
  }
};

export const downloadRadiologyReport = async (
  orderId,
  oldData,
  orderNoLine
) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const encodedOrderId = encodeURIComponent(orderId);
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/reports/radiology-report?mrn=654143&orderId=3901589%257C%257C2&orderLineNo=null&hosp=1&oldData=0%22
  // let endpoint = `${apiRoute}api/reports/radiology-report?mrn=${userInfo?.mrn}&orderId=${encodedOrderId}&orderLineNo=null&hosp=${hospitalCode}&oldData=0`;
  let endpoint = `${apiRoute}api/reports/radiology-report?mrn=${userInfo?.mrn}&orderId=${encodedOrderId}&hosp=${hospitalCode}`;
  if (oldData && orderNoLine) {
    endpoint = `${endpoint}&orderLineNo=${orderNoLine}&oldData=1`;
  } else {
    endpoint = `${endpoint}&orderLineNo=null&oldData=0`;
  }

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(data?.pdf_base64, `radiology-report-${encodedOrderId}`);
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report radiology-report:`, error);
    return null;
  }
};

export const downloadOperativeReport = async (orderId) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const encodedOrderId = encodeURIComponent(orderId);
  //https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/reports/operative-report-print?documentId=457452%257C%257C1&oldData=0&mrn=000348&reportType=DICTA&hosp=IMC%22
  const endpoint = `${apiRoute}api/reports/operative-report-print?documentId=${encodedOrderId}&oldData=0&mrn=${userInfo?.mrn}&reportType=DICTA&hosp=${hospitalCode}`;

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(data?.pdf_base64, `operative-report-${encodedOrderId}`);
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report operative-report:`, error);
    return null;
  }
};

export const downloadCardiologyReport = async (orderId) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const encodedOrderId = encodeURIComponent(orderId);
  //https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/reports/cardiology-report?orderId=5150870%257C%257C6&mrn=000348&hosp=IMC&oldData=0&attendanceId=%22
  const endpoint = `${apiRoute}api/reports/cardiology-report?orderId=${encodedOrderId}&mrn=${userInfo?.mrn}&hosp=${hospitalCode}&oldData=0&attendanceId=${""}`;

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(data?.pdf_base64, `cardiology-report-${encodedOrderId}`);
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report cardiology-report:`, error);
    return null;
  }
};

export const downloadDischargeReport = async (orderId, reportType) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const encodedOrderId = encodeURIComponent(orderId);
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/reports/discharge-summary-report-print?documentId=222352&oldData=0&mrn=000348&reportType=&hosp=IMC%22
  const endpoint = `${apiRoute}api/reports/discharge-summary-report-print?documentId=${encodedOrderId}&oldData=0&mrn=${userInfo?.mrn}&reportType=${reportType}&hosp=${hospitalCode}`;

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(
        data?.pdf_base64,
        `discharge-summary-report-print-${encodedOrderId}`
      );
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(
      `Error fetching report discharge-summary-report-print:`,
      error
    );
    return null;
  }
};

export const downloadSickReport = async (orderId) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const encodedOrderId = encodeURIComponent(orderId);
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/reports/sick-leave-report-print?documentId=911931%257C%257C13&oldData=0&mrn=000348&diag=1&hosp=IMC%22
  const endpoint = `${apiRoute}api/reports/sick-leave-report-print?documentId=${encodedOrderId}&oldData=0&mrn=${userInfo?.mrn}&diag=1&hosp=${hospitalCode}`;

  try {
    store.dispatch(showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr: DownloadMsg));
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
        'Accept-Language': getSelectLanguage(),
      },
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();
   
    if (!data.status) {
      throw new Error(`Error fetching report`);
    }

    if (data?.pdf_base64 && data?.status == 'Success') {
      await savePDF(
        data?.pdf_base64,
        `sick-leave-report-print-${encodedOrderId}`
      );
    }

    return data;
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr: ErrorMsg));
    console.error(`Error fetching report sick-leave-report-print:`, error);
    return null;
  }
};

export const savePDF = async (base64String, fileName) => {
  store.dispatch(showToast(getIsRtl() ? SuccessMsgAr: SuccessMsg));
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  // window.open(url, '_blank');
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
