import React, { useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { FaXmark } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

const GuardianItem = ({ data, showEdit, handleOnDelete }) => {
  const { t,isRtl } = useSelector(selectLanguage);

  // const first_name= isRtl ? data.arabic_first_name : data.first_name;
  // const last_name= isRtl ? data.arabic_last_name : data.last_name;

  const mapped = {
    // fullName: isRtl ? last_name + first_name : first_name + last_name,
    mrn: data?.guardianId,
    // mobile_number: data?.mobile_number,
    relation: t.Guardian,
  };

  const onDeleteHandler = () => {
    handleOnDelete && handleOnDelete(data);
  };

  return (
    <div className="flex justify-between items-center rounded-md p-4 max-w-md w-full bg-white">
      <div className="flex items-center gap-6 cursor-pointer" onClick={() => showEdit()}>
        <div className="bg-zinc-200 rounded-full w-12 h-12 flex justify-center items-center">
          <FiUser className="w-6 h-6 text-gray-500" />
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold text-lg text-gray-700 leading-4">{`${mapped.mrn}`}</h3>
          {/* <p className="ext-base leading-4 text-gray-700">{mapped.mobile_number}</p> */}
          <p className="ext-base leading-4 text-gray-700">{mapped.relation}</p>
        </div>
      </div>
      <div className="flex items-center" onClick={onDeleteHandler}>
        <FaXmark className="w-7 h-7 " />
      </div>
    </div>
  );
};

export default GuardianItem;
