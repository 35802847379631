import { envVariables } from '../../config';
import formatDate from '../DateFormatter';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';
import moment from 'moment';

const getDoctorListBySpecialityCode = async (specialtycode, hospId) => {
  const { apiRoute } = envVariables;
  const endpoint = `${apiRoute}appointment/docEarliestSlot?`;
  const url = new URL(endpoint);

  // specialtycode=3030&datefrom=2024-04-26&dateto=2024-08-04
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 100);
  // Format the dates as 'YYYY-MM-DD'
  const todayFormatted = formatDate(today);
  const futureDateFormatted = formatDate(futureDate);

  const params = {
    specialtycode: specialtycode,
    datefrom: todayFormatted,
    dateto: futureDateFormatted,
  };

  // const reqBody = {
  //     Hospitalid: hospId,
  //     Mrn: JSON.parse(localStorage.getItem("userInfo")).mrn,
  //     Patient_id: JSON.parse(localStorage.getItem("userInfo")).patientId
  // }

  for (const key in params) {
    url.searchParams.append(key, params[key]);
  }

  // "https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/appointment/book?providerCode=ARammal&serviceCode=CON002&specialityCode=FC-GASTRO&slotFromTime=19%3A00&slotDate=2024-04-29&hospitalCode=FC"

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('userInfo')).accessToken
        }`,
        HospitalID: hospId,
        MRN: JSON.parse(localStorage.getItem('userInfo')).mrn,
        isGuardian: 'no',
        Patient_ID: JSON.parse(localStorage.getItem('userInfo')).patientId,
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
    });
    if (!response.ok || response.status !== 200) {
      if (response.status === 403) {
        sessionsMiddleWare();
      }
      throw new Error(
        `HTTP error! status appointment/docEarliestSlot?: ${response.status}`
      );
    }
    const data = await response.json();
    return data.docList;
  } catch (error) {
    console.error('Error fetching appointment/docEarliestSlot?:', error);
    return null;
  }
};

export const getLastVisitedDoctors = async () => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';
  let endpoint = `${apiRoute}api/bookings/lastVisitedDoctors?hospitalCode=${hospitalCode}`;

  const today = moment().format('YYYY-MM-DD');
  const pastYearDate = moment().subtract(1, 'years').format('YYYY-MM-DD');

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('userInfo')).accessToken
        }`,
        hospitalCode: hospitalCode,
        mrnNumber: JSON.parse(localStorage.getItem('userInfo')).mrn,
        patientId: JSON.parse(localStorage.getItem('userInfo')).patientId,
        datefrom: '2023-06-12',
        dateto: '2024-06-11',
        appointmentStatus: 'Departed',
        numberOfRows: '10',
        platform: 'web',
        'Accept-Language': getSelectLanguage(),
      },
    });
    if (!response.ok || response.status !== 200) {
      if (response.status === 403) {
        sessionsMiddleWare();
      }
      throw new Error(
        `HTTP error! status lastVisitedDoctors: ${response.status}`
      );
    }
    const data = await response.json();
    return data.docList;
  } catch (error) {
    console.error('Error fetching lastVisitedDoctors:', error);
    return null;
  }
};

export const getPrimaryCareDoctors = async () => {
    const { apiRoute } = envVariables;
    const hospitalCode = localStorage.selectedHopitalInfo
      ? JSON.parse(localStorage.selectedHopitalInfo).code
      : 'IMC';
    const hospitalId = localStorage.selectedHopitalInfo
      ? JSON.parse(localStorage.selectedHopitalInfo).id
      : '1';
    let endpoint = `${apiRoute}api/primary-care-doc?hospitalCode=${hospitalCode}`;

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('userInfo')).accessToken
          }`,
          Hospitalid: hospitalId,
          Mrn: JSON.parse(localStorage.getItem('userInfo')).mrn,
          Patient_id: JSON.parse(localStorage.getItem('userInfo')).patientId,
          platform: 'web',
          'Accept-Language': getSelectLanguage(),
        },
      });
      if (!response.ok || response.status !== 200) {
        if (response.status === 403) {
          sessionsMiddleWare();
        }
        throw new Error(
          `HTTP error! status primary-care-doc: ${response.status}`
        );
      }
      const data = await response.json();
      return data.docList;
    } catch (error) {
      console.error('Error fetching primary-care-doc:', error);
      return null;
    }
  };

export default getDoctorListBySpecialityCode;
