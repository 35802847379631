import React from 'react';
import {useSelector } from 'react-redux';


import './styles.css'; // Import the CSS file
import { selectLanguage } from '../../../store/languageSlice';
import { getTValue } from '../../../translations/common';
import { fetchReasons } from '../../../utilFunctions/secondOpinion';
import useDataFetching from '../../../utilFunctions/useFetchData';

const reason = {
  id: 4,
  category: 'MAYO_SECOND_OPINION_FORM',
  hospCode: '1',
  reasonEn: 'I am looking for other options than invasive or high-risk options',
  reasonAr: 'أنا أبحث عن خيارات أخرى غير الخيارات الغازية أو عالية المخاطر',
  isActive: 1,
};

const ReasonsDropdown = ({ formData, handleChange }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { data: resonsList, loading } = useDataFetching(fetchReasons);

  return (

    <label htmlFor="reason">
      {t.SelectReason}* <br />
      <div className="selectWithName relative">
        <select
          name="reason"
          defaultValue={formData?.reason}
          id="reason"
          onChange={handleChange}
          className="custom-select"
        >
          <option value="">{loading ? 'Loading' : t.Select}</option>

          {resonsList?.map((item) => {
            const label = getTValue(item, 'reasonAr', 'reasonEn', isRtl);
            return <option value={label}>{label}</option>;
          })}
        </select>
        <span className="newArrowDropdown">&#9662;</span>
      </div>
    </label>
  );
};

export default ReasonsDropdown;
