import { envVariables } from '../../config';
import { sessionsMiddleWare } from '../SessionExpire';
import { getSelectLanguage } from '../getCurrentSelectLanguage';

export const validateCurrentData = async (payload) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // https://patientportal.imc.med.sa/imc-tc-wrapper-service-v6/api/tc/patient/task/
  const endpoint = `${apiRoute}api/self-registration/yakeen/v5`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept-Language": getSelectLanguage(),
        Platform: 'Web',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        //   sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    const validData = {
      status: 'true',
      message: 'success',
      yakeenData: {
        docType: 'National ID',
        docNumber: '1093542122',
        dob: '1994-11-23',
        familyName: 'علوي',
        familyNameT: 'ALAWI',
        fatherName: 'هشام',
        fatherNameT: 'HESHAM',
        firstName: 'جميل',
        firstNameT: 'JAMIL',
        grandFatherName: 'جميل',
        grandFatherNameT: 'J',
        genderAr: 'ذكر',
        genderEn: 'M',
      },
    };

    return data;
  } catch (error) {
    console.error(`Error fetching report tc/patient/task/:`, error);
    return null;
  }
};

export const registerUser = async (payload) => {
  const { apiRoute } = envVariables;
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const endpoint = `${apiRoute}api/patient/task/registration/v5`;

  const reqPayload = {
    ...payload,
    hosp: hospitalCode,
    addressEn: '',
    addressAr: '',
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept-Language": getSelectLanguage(),
        Platform: 'Web',
        Authorization: `Bearer ${userInfo?.accessToken}`,
        HospitalID: hospitalId,
        Host: 'patientportal.imc.med.sa',
        MRN: userInfo?.mrn,
        Patient_ID: userInfo?.patientId,
      },
      body: JSON.stringify(reqPayload),
    });

    if (!response.ok) {
      //here
      if (response.status === 403) {
        //   sessionsMiddleWare();
        throw new Error(`Session Expired ${response.status}`);
        // useCheckSessionExpiry()
      } else {
        throw new Error(`HTTP error!: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error Posting:`, error);
    return null;
  }
};
