import React, { useEffect, useState } from 'react';
import SubQuestionItem from './SubQuestionItem';

import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io';

import './HomeHealthCare.css';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../store/languageSlice';

const QuestionCard = ({ data, index, handleSelectedQIds }) => {
  const { questionEn, questionAr, linkedHHCFormQuestions, id } = data;
  const { t, isRtl } = useSelector(selectLanguage);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedSubQIds, setSelectedSubQIds] = useState([]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setSelectedSubQIds([]);
  };

  const handleOnSelectChild = (id) => {
    if (selectedSubQIds.includes(id)) {
      const updatedIds = selectedSubQIds.filter((item) => item !== id);
      setSelectedSubQIds(updatedIds);
    } else {
      setSelectedSubQIds([...selectedSubQIds, id]);
    }
  };

  function getAllIdsFromParentId(data, parentId) {
    let ids = [];

    // Define a recursive function to traverse the data
    function traverseData(data, parentId) {
      data.forEach((item) => {
        // Check if the item's parentId matches the target parentId
        if (item.parentId === parentId) {
          ids.push(item.id); // Add the current item's ID to the array
          // Recursively call traverseData for linkedHHCFormQuestions array
          if (
            item.linkedHHCFormQuestions &&
            item.linkedHHCFormQuestions.length > 0
          ) {
            traverseData(item.linkedHHCFormQuestions, item.id);
          }
        }
      });
    }

    // Call the recursive function to start the traversal
    traverseData(data, parentId);

    return ids;
  }

  useEffect(() => {
    handleSelectedQIds({
      selectedSubQIds,
      mainQId: id,
      subQsIds: getAllIdsFromParentId(linkedHHCFormQuestions, id),
      mainSelected: !collapsed,
    });
  }, [selectedSubQIds, collapsed]);

  const question = isRtl ? questionAr : questionEn;
  const keyId = `${id}${question}`;

  return (
    <div className="card mb-6 pl-8 pt-8" key={`${index}${questionEn}`}>
      <div className="flex w-full cursor-pointer justify-between">
        <ul className="mb-4">
          <li>
            <input
              type="checkbox"
              id={keyId}
              defaultChecked={!collapsed}
              onChange={toggleCollapse}
            />
            <label htmlFor={keyId}>
              <span className="check"></span> <h3>{question}</h3>
            </label>
          </li>
        </ul>

        {linkedHHCFormQuestions?.length > 0 && (
          <div>
            {collapsed ? (
              <ArrowDown className="w-6 h-6" />
            ) : (
              <ArrowUp className="w-6 h-6" />
            )}
          </div>
        )}
      </div>
      {/* <p className="mb-4" style={{ color: '#005A9C' }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </p> */}
      <div
        className={`content ${
          collapsed || linkedHHCFormQuestions?.length == 0 ? 'hidden' : ''
        }`}
      >
        <ul>
          {linkedHHCFormQuestions?.length > 0 &&
            linkedHHCFormQuestions?.map((x, i) => {
              return (
                <SubQuestionItem
                  data={x}
                  key={i}
                  isRtl={isRtl}
                  selectedIds={selectedSubQIds}
                  onToggle={handleOnSelectChild}
                />
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default QuestionCard;
