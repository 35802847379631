import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoaderError } from '../../../store/globalLoaderError';
import './style.css';

export default function GlobalLoader(props) {
  const { loading } = useSelector(selectLoaderError);

  if (!loading) return null;

  return (
    <div role="status" className="global-loader-overlay container">
      <div className="global-loader">
        <img
          style={{ height: '120px', width:'100%' }}
          src={`${process.env.PUBLIC_URL}/images/ezgif-loader.gif`}
          alt="ezgif-loader"
        />
      </div>
    </div>
  );
}
