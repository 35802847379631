import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/languageSlice";
import { getSelectLanguage } from "../../../utilFunctions/getCurrentSelectLanguage";
import useDataFetching from "../../../utilFunctions/useFetchData";
import { envVariables } from "../../../config";
import { sessionsMiddleWare } from "../../../utilFunctions/SessionExpire";
import AppointmentsList from "./AppointmentsList";
import Loader from "../../UI/Loader";
import "./style.css";
import moment from "moment";

const ManageAppointmentList = (props) => {
    const [apptType, setApptType] = useState(0) // 0 for All // 3 for Cancelled // 4 for Completed // 5 for No Show
    
    const {
        data: appointmentsList,
        loading,
        error,
      } = useDataFetching(manageAppointmentLoader);
    const { t } = useSelector(selectLanguage);

    return (
        <>            
            {
                !!appointmentsList?.length && (
                    <>
                    <div className="swtAptType flex w-full gap-x-6 items-center text-12">
                        <label htmlFor="completed" className={apptType === 4 ? 'checked' : ''}>
                            <input type="checkbox" id="completed" onChange={() => setApptType(apptType === 4 ? 0 : 4)} /> {t['Completed']}
                        </label>
                        <label htmlFor="noShow" className={apptType === 5 ? 'checked' : ''}>
                            <input type="checkbox" id="noShow" onChange={() => setApptType(apptType === 5 ? 0 : 5)} /> {t['No_Show']}
                        </label>
                        <label htmlFor="cancelled" className={apptType === 3 ? 'checked' : ''}>
                            <input type="checkbox" id="cancelled" onChange={() => setApptType(apptType === 3 ? 0 : 3)} /> {t['Cancelled']}
                        </label>
                    </div>
                    <AppointmentsList appointments={appointmentsList} apptType={apptType} />
                    </>
                )
            }
            {
                loading && <div className="w-full justify-center">
                    <Loader roundLoader/>
                 </div>
            }
            {
                !loading &&  (!appointmentsList || appointmentsList?.length==0) && <div className="w-full flex justify-center">
                    <span className="mt-8">{t.NoRecord}</span>
                 </div>
            }
        </>
    )
};

export default ManageAppointmentList;

export const manageAppointmentLoader = async () => {
    const { apiRoute } = envVariables;
    const endpoint = `${apiRoute}api/bookings/all`;

    const { patientId, mrn } = JSON.parse(localStorage.userInfo);
    const hospCode = localStorage.selectedHopitalInfo ? JSON.parse(localStorage.selectedHopitalInfo).code : "IMC";

    const toDate = moment();
    const fromDate = toDate.clone().subtract(1, 'year').format('DD-MMM-YYYY');

    const reqBody = {
        "hospitalCode": hospCode,
        "patientId": patientId,
        "mrNumber": mrn,
         "fromDate": fromDate,
        "toDate": toDate.format('DD-MMM-YYYY'),
        "bookingStatus": "",
        "physicianCode": "",
        "clinicCode": "",
        "page": "0",
        "itemCount": "10000"
    }

    try {
        // store.dispatch(showLoader())
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userInfo")).accessToken}`,
                "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify(reqBody)
        });
        if (!response.ok) {
            if(response.status === 403) {
                sessionsMiddleWare()
            }
            throw new Error(`HTTP error! bookings/all: ${response.status}`);
        }

        const data = await response.json();
        // store.dispatch(hideLoader())
        return data.bookings;
    } catch (error) {
        console.error('Error fetching bookings/all:', error);
        throw error;
    }
}