
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticationActions } from "../store";
import { LoginTimeStamp } from "../utilFunctions/LoginTimeStamp";
import { getSelectLanguage } from "../utilFunctions/getCurrentSelectLanguage";

const Home = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const host = process.env.REACT_APP_HOST;
    // const host = "http://10.11.28.74:8080/";

    const loginClickHandler = async () => {
        try {
            // Send request to generate OTP
            const loginReq = await fetch(`${host}auth-v2/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "Accept-Language": getSelectLanguage(),
                },
                body: JSON.stringify({
                    "mrn": "000348",
                    "nationalId": ""
                })
            });

            if (!loginReq.ok) {
                throw new Error(`Error sending OTP: ${loginReq.json().statusText}`);
            }
            // const loginRes = await loginReq.json();
            // console.log("loginRes.message", loginRes);
            let otpCode = prompt("Please enter OTP.");

            const tokenReq = await fetch(`${host}auth-v2/login/validate`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "Accept-Language": getSelectLanguage(),
                },
                body: JSON.stringify({
                    "otp": otpCode,
                    "consent": "1",
                    "userId": "000348",
                    "user_type": "USER",
                    "platform": "web",
                    "deviceToken": "gG8aBbFmyMp6bgpJPo7ssrALKRBCg6dXj8SNjfbz",
                    "hosp": "1"
                })
            });

            if (!tokenReq.ok) {
                throw new Error(`Error validating login: ${tokenReq.statusText}`);
            }

            const tokenRes = await tokenReq.json();
            // console.log("tokenRes", tokenRes);
            localStorage.setItem("userInfo", JSON.stringify(tokenRes));
            LoginTimeStamp(tokenRes.accessToken);
            dispatch(authenticationActions.authenticateToggle(true));
            // navigate('/book-appointment');
            navigate('/manage-appointment');
        } catch (error) {
            console.error("Error:", error.message);
        }
    };


    // const loginClickHandler = () => {
    //     dispatch(authenticationActions.authenticateToggle())
    // }

    // useEffect(()=>{
    //     // setTimeout(() => {
    //     //     dispatch(authenticationActions.authenticateToggle())
    //     // }, 2000);
    // },[])

    return (
        <div className="home">
            <div className='pages-list'>
                <p className="text-center py-10">
                    Redirect to <br /><br />
                    <b><Link className="underline text-primary" to={`book-appointment`}>Book Appointment</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`manage-appointment`}>Manage Appointment</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`vital-report`}>Vital Report</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`assessment-report`}>Assessment Report</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`home-health-care`}>Home Health Care</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`medication`}>Medication</Link></b> <br /><br />
                    <b><Link className="underline text-primary" to={`reports`}>Reports</Link></b> <br /><br />
                    <button onClick={loginClickHandler}>Login</button>
                </p>
            </div>
        </div>
    )
};

export default Home;