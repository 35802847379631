import moment from "moment";

// Function to format date as 'YYYY-MM-DD'
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function isWithinBuffer(apptDateString, slotBeforeBufferTime, slotAfterBufferTime) {
    const now = moment();
    const apptDate = moment(apptDateString);

    const bufferStart = apptDate.clone().subtract(slotBeforeBufferTime, 'minutes');
    const bufferEnd = apptDate.clone().add(slotAfterBufferTime, 'minutes');

    return now.isBetween(bufferStart, bufferEnd, null, '[]');
}
export const timeFormat =(time)=> moment(time, 'HH:mm').format('hh:mm A');
export default formatDate;