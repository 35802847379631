import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { vitalReportLoader } from './VitalsReportList';

function useFetchVitals() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);

  const fetchData = async () => {
    if (loading || !hasMore) return; // Prevent fetching if already loading or no more data
    if(page < 2 ){
      setLoading(true);
    } 
   
    setError(null); // Reset error state before fetching new data

    try {
      const apiData = await vitalReportLoader(page); // Include hospitalId if needed in the API call
      const vitalList = apiData?.vital_list || [];

      if (vitalList.length === 0 || apiData.total <= page) {
        setHasMore(false); // No more data to fetch
      } else {
        setData((prevData) => [...prevData, ...vitalList]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [hospitalId, page]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchVitals;
