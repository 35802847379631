import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import closeOpenedDropdown from "../../../utilFunctions/DropdownCloser";
import { modalActions } from "../../../store";
import { selectLanguage } from "../../../store/languageSlice";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import "./HospSwitcherDropdown.css"

const HospSwitcherDropdown = ({hospList, setCurrentSelectedHospital, changeHospitalHandler}) => {
    const dispatch = useDispatch();
    const selectedHospId = useSelector((state) => state.hospitalInfo.hospId);
    const { t, isRtl } = useSelector(selectLanguage);
    const [ isOpen, setIsOpen ] = useState(false)

    return (
        <>
        {isOpen && <div className="backdrop hospSwiter" onClick={()=>setIsOpen(false)}></div>}        
        <div className={`hospSwitcher wide${isOpen ? " open" : ""}`}>
            {/* <div className="selectedHosp">
                {hospList.map((hospital) =>
                    hospital.hospitalId === selectedHospId ? (
                    <img
                        src={hospital.hospitalLogo}
                        alt={hospital.hospitalCode}
                    />
                    ) : null
                )}
            </div> */}
            <div className="hospList">
                <ul>
                    {hospList.map((hospital, index) => (
                      <li key={index}>
                        <button
                          className={
                            selectedHospId === hospital.hospitalId
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            // closeOpenedDropdown();
                            setIsOpen(false)
                            if (hospital.hospitalCode === 'KH') {
                              // dispatch(modalActions.toggle('kaust'));
                              setTimeout(() => {
                                dispatch(modalActions.toggle('kaust'));
                              }, 200);
                              setCurrentSelectedHospital(hospital);
                            } else {
                              changeHospitalHandler(
                                hospital.hospitalName,
                                hospital.hospitalCode,
                                hospital.hospitalId
                              );
                            }
                          }}
                        >
                          <img
                            src={hospital.hospitalLogoSmall}
                            alt={hospital.hospitalCode}
                          />
                          <span>
                            {isRtl
                              ? hospital.hospitalName_ar
                              : hospital.hospitalName}
                          </span>
                        </button>
                      </li>
                    ))}
                </ul>
            </div>
            <button onClick={()=>setIsOpen(!isOpen)}>{isOpen ? <IoChevronUp /> : <IoChevronDown />}</button>
        </div>
        </>
    )
};

export default HospSwitcherDropdown;