import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';
import { getTValue } from '../../translations/common';
import { ContactUsSerivce } from '../../utilFunctions/contactUs';
import Loader from '../UI/Loader';
import './style.css';

export default function ContactUs() {
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const PHONE_CODE = '+966';
  const [messageRequire, setMessageRequire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    title: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    messageRequire && setMessageRequire(false);
  };

  const handleContactUsReport = async (event) => {
    event.preventDefault();
    if (formData?.message == '') return setMessageRequire(true);

    setMessage();
    setError(false);
    setLoading(true);
    const responseData = await ContactUsSerivce(formData);
    // dispatch(portalActions.toggleContactUsDrawer());

    setLoading(false);
    if (responseData?.status == 'Success') {
      setMessage(t.FormSubmitted);
      setTimeout(() => {
        dispatch(portalActions.toggleContactUsDrawer());
      }, 2000);
    } else {
      setError(true);
      setMessage(t.FormFailed);
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      const firstName = getTValue(userInfo, 'arabic_first_name', 'firstName');
      const lastName = getTValue(userInfo, 'arabic_last_name', 'lastName');

      setFormData({
        ...formData,
        email: userInfo?.email || '',
        phone: userInfo?.mobileNumber || '',
        mrNumber: userInfo?.mrn || '',
        name: `${firstName} ${lastName}` || '',
      });
    }
  }, []);

  return (
    <div>
      <div className="px-8">
        <form onSubmit={handleContactUsReport}>
          <label htmlFor="name">
            {t.Name} <br />
            <input
              id="name"
              type="text"
              name="name"
              defaultValue={formData?.name}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="email">
            {t.EmailAddress} <br />
            <input
              id="email"
              type="email"
              name="email"
              defaultValue={formData?.email}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="phone">
            {t.CellPhoneNumber}
            <br />
            <div dir="ltr" className="countrycode">
              <div className="code text-secondary">({PHONE_CODE})</div>
              <input
                id="phone"
                type="number"
                name="phone"
                placeholder="5xxxxxxxx"
                defaultValue={formData?.phone}
                onChange={handleChange}
              />
            </div>
          </label>
          <label htmlFor="title">
            {t.Title} <br />
            <input
              id="title"
              type="text"
              name="title"
              defaultValue={formData?.title}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="message">
            {t.Message}* <br />
            <textarea
              id="message"
              name="message"
              placeholder={t.TypeMessage}
              defaultValue={formData?.message}
              onChange={handleChange}
            ></textarea>
            <br />
            {messageRequire && (
              <label className="text-tertiary">{t.MessageFieldRequired}</label>
            )}
          </label>
          <div className="flex justify-center mt-3">
            <button type="submit" className="request_btn">
              {loading ? <Loader size="small" roundLoader /> : t.send}
            </button>
          </div>
          {message && (
            <p className={`${error ? 'errorMsg' : 'successMsg'} my-2`}>
              <span>{message}</span>
            </p>
          )}
        </form>
      </div>
    </div>
  );
}
