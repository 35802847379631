import React, { useRef } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

const DaysSlider = ({ selectedDate, setSelectedDate }) => {
  const daysInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();
  const daysContainerRef = useRef(null);

  // Get day names and dates for the month
  const days = Array.from({ length: daysInMonth }, (_, i) => {
    const date = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      i + 1
    );
    const dayName = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
    }).format(date);
    return { day: dayName, date: i + 1 };
  });

  // Scroll handler
  const scrollDays = (direction) => {
    if (daysContainerRef.current) {
      const scrollAmount = 150; // Adjust scroll amount as needed
      daysContainerRef.current.scrollBy({
        left: direction === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleDayChange = (day) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(day);
    setSelectedDate(newDate);
  };

  return (
    <div className="flex items-center gap-1 mb-1 rounded-lg px-1 py-2" style={{backgroundColor:'#FAFAFA'}}>
      {/* Previous Button */}
      <button
        onClick={() => scrollDays('prev')}
        className="p-2 bg-blue-500 text-white rounded-full"
      >
        <FiChevronLeft />
      </button>

      {/* Days List */}
      <div
        ref={daysContainerRef}
        className="flex gap-1 overflow-x-auto scrollbar-hide"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {days.map(({ day, date }) => (
          <button
            key={date}
            onClick={() => handleDayChange(date)}
            className={`flex flex-col items-center px-2 py-1 rounded-lg ${
              selectedDate.getDate() === date
                ? 'bg-white text-blue-500'
                : 'text-gray-600'
            }`}
            style={{ scrollSnapAlign: 'start' }}
          >
            <span className="text-xs ">{day}</span>
            <span className="text-sm font-semibold">{date}</span>
          </button>
        ))}
      </div>

      {/* Next Button */}
      <button
        onClick={() => scrollDays('next')}
        className="p-2 bg-blue-500 text-white rounded-full"
      >
        <FiChevronRight />
      </button>
    </div>
  );
};

export default DaysSlider;
