import React from 'react';

const SingleAssessment = ({ t, isRtl, assessment, index }) => {
  let dateObj = new Date(assessment.updateDateTime);
  const locale = isRtl ? 'ar' : 'en-US';
  return (
    <li key={index}>
      <div className="card">
        <h4>{assessment.formName}</h4>
        <div className="scoreInfo mt-3">
          <span>{assessment.result ? assessment.result : 'X'}</span>
          {t.YourScore}
        </div>
        <div className="subStatus">
          <span className="status"
            style={{
              '--status-color':
                assessment.status === 'completed'
                  ? 'var(--color-green)'
                  : assessment.status === 'declined'
                  ? 'var(--color-tertiary)'
                  : 'var(--color-primary)',
            }}
          >
            {t[assessment.status] || assessment.status}
          </span>
          <div className="dateTime">
            <div className="date">
              {dateObj.getDate()}
              <span>{dateObj.toLocaleString(locale, { month: 'short' })}</span>
            </div>
            at
            <div dir='ltr' className="time">
              {
                dateObj
                  .toLocaleString('en-US', { hour: 'numeric', hour12: true })
                  .split(' ')[0]
              }{' '} : {(dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes() + " "}
              <span>
                {
                  dateObj
                    .toLocaleString('en-US', { hour: 'numeric', hour12: true })
                    .split(' ')[1]
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SingleAssessment;
