import moment from 'moment';
import React, { Fragment, memo, useEffect, useState } from 'react';
import {
  FiDownload,
  FiChevronDown,
  FiChevronUp,
  FiClock,
  FiChevronLeft,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { selectReports } from '../../../store/reportsSlice';
import { formatDate } from '../../../translations/common';
import { downloadReport, downloadLabSmartReport } from '../../../utilFunctions/reports/reports';
import ResultTable from './ResultTable';
import useBreakpoint from '../../../utilFunctions/useBreakpoint';

const ReportItem = ({ t, isRtl, item, handleDownloadReport, handleDownloadSmartReport }) => {
  const { isMobile } = useBreakpoint();
  const [showTable, setShowTable] = useState(false);

  const handleToggleTable = () => {
    setShowTable(!showTable);
  };
  const formatedItem = {
    reportName: item?.longName,
    value: '',
    referenceRange: '',
    Intrepatation: '',
    dateTime: `${item?.aptTime}`,
  };
  return (
    <>
      {!isMobile && (
        <div onClick={handleToggleTable} className="cursor-pointer">
          <div className="type">
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/ic_lab_report_hov.svg`}
              alt="report icon"
            />
            {formatedItem.reportName}
          </div>

          <div className="val">
            {/* {t.Value}:
              <span className="text-secondary font-medium">
                {formatedItem.value}
              </span> */}
            {formatedItem.value}
          </div>

          <div className="rng">
            {/* {t.ReferenceRange}:
              <span className="text-secondary font-medium">
                {formatedItem.referenceRange}
              </span> */}
            {formatedItem.referenceRange}
          </div>

          <div className="intp">
            {/* {t.Intrepatation}:
              <span className="text-secondary font-medium">
                {formatedItem.Intrepatation}
              </span> */}
            {formatedItem.Intrepatation}
          </div>

          <div className="clctDate">
            {/* {t.CollectionDateTime}:
              <span className="text-secondary font-medium">
                {formatedItem.dateTime}
              </span> */}
            {formatedItem.dateTime}
          </div>
          <div
            className="download"
          >
            <button  onClick={() => handleDownloadReport(item.specimenNum)}>
              <FiDownload />
            </button>
            {item.smartReport!==0 && <div className='linkButton' onClick={() =>
                      handleDownloadSmartReport(item.accessionNum)
                    }>
                     {t.SmartReport}
                    </div>}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="sngl">
          <p>
            <span>{t.Value}:</span>
            {formatedItem.value}
          </p>
          <p>
            <span>{t.ReferenceRange}:</span>
            {formatedItem.referenceRange}
          </p>
          <p>
            <span>{t.Intrepatation}:</span>
            {formatedItem.Intrepatation}
          </p>
          <p>
            <span>{t.CollectionDateTime}:</span>
            {formatedItem.dateTime}
          </p>
          <p>
            <span>{t.Download}:</span>
            <button onClick={() => handleDownloadReport(item.specimenNum)}>
              <FiDownload />
            </button>
          </p>
          <p className="name">{formatedItem.reportName}</p>
        </div>
      )}
    </>
  );
};

const LabHistory = ({ listData, currentData }) => {
  const { isMobile } = useBreakpoint();
  const { t, isRtl } = useSelector(selectLanguage);
  const [reportData, setReportData] = useState([]);
  const [showRecords, setShowRecord] = useState([]);
  const { oldData } = useSelector(selectReports);

  const handleDownloadReport = async (specimanId) => {
    if (!specimanId && specimanId == '') return;

    const data = await downloadReport(specimanId, oldData);
  };

  const handleDownloadSmartReport = async (orderId) => {
    if (!orderId || orderId == '') return;

    await downloadLabSmartReport(orderId, oldData);
  };

  const toggleShowRecords = (indexId) => {
    if (showRecords.includes(indexId)) {
      setShowRecord(showRecords.filter((x) => x !== indexId));
    } else {
      setShowRecord([...showRecords, indexId]);
    }
  };

  const flattenData = (data) => {
    return data.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      return acc.concat(item[key]);
    }, []);
  };

  const formatDataGroup = (orignalData) => {
    const data = flattenData(orignalData);

    const sorted = data
      .slice()
      .sort(
        (a, b) =>
          moment(b.aptTime, 'DD-MMM-YYYY, hh:mm A').valueOf() -
          moment(a.aptTime, 'DD-MMM-YYYY, hh:mm A').valueOf()
      );

    const groupedData = sorted.reduce((acc, curr) => {
      const labEpisodeNumber = curr.episodeNo;
      const existingGroupIndex = acc.findIndex(
        (group) => group[0]?.episodeNo === labEpisodeNumber
      );
      if (existingGroupIndex === -1) {
        acc.push([curr]);
      } else {
        acc[existingGroupIndex].push(curr);
      }
      return acc;
    }, []);

    return groupedData;
  };

  useEffect(() => {
    if (listData?.length > 0) {
      const group = formatDataGroup(listData);
      setReportData([...reportData, ...group]);
    }
  }, [listData]);

  if (!currentData) return null;

  if (listData.length === 0)
    return (
      <div className="w-full h-full mt-14 flex justify-center">
        <div className="font-semibold text-2xl text-blue-900">
          {t.NoDataFound}
        </div>
      </div>
    );

  return (
    <Fragment key={'RaioLab'}>
      {Object.keys(reportData).map((key, index) => {
        const recordsList = reportData[key];
        const firstItem = recordsList[0];
        const orderItem = {
          date: moment(firstItem.aptTime, 'DD-MMM-YYYY, hh:mm A').format(
            'YYYY-MM-DD'
          ),
          oderId: firstItem?.orderNum,
          docName: isRtl ? firstItem?.docNameAr : firstItem?.docName,
          time: moment(firstItem.aptTime, 'DD-MMM-YYYY, hh:mm A').format(
            'HH:mm'
          ),
          subRecords: recordsList[0]?.result_list || [],
        };
        const fDate = formatDate(orderItem.date, isRtl);
        const time = moment(orderItem.time, 'HH:mm').format('hh:mm A');
        const show = showRecords.includes(index);
        return (
          <>
            {!isMobile && (
              <div key={'lab' + index} className="sngRpt">
                <div className="info">
                  <div className="no">{index + 1}.</div>
                  <div className="date">
                    <span>{fDate.weekName}</span>
                    {`${fDate.monthDate} ${fDate.monthNameShort}`}
                    {/* <span>{`${fDate.year}`}</span> */}
                  </div>
                  <div className="year">{`${fDate.year}`}</div>
                  <div className="id">{orderItem?.oderId || ''}</div>
                  <div className="by">{orderItem?.docName || ''}</div>
                  <div className="time">{time || ''}</div>
                  {/* <div
                    onClick={() => handleDownloadReport(firstItem.specimenNum)}
                    className="download"
                  >
                    <button>
                      <FiDownload />
                    </button>
                  </div> */}
                  <button
                    onClick={() => toggleShowRecords(index)}
                    className="collapseBtn"
                  >
                    {show ? <FiChevronUp /> : <FiChevronDown />}
                  </button>
                </div>
                {show && (
                  <div className="otherInfo">
                    <div className="otherInfoHead">
                      <div className="type">
                        <div className="opacity-0">type</div>
                      </div>
                      <div className="val">
                        <div>{t.Value}</div>
                      </div>
                      <div className="rng">
                        <div>{t.ReferenceRange}</div>
                      </div>
                      <div className="intp">
                        <div>{t.Intrepatation}</div>
                      </div>
                      <div className="clctDate">
                        <div>{t.CollectionDateTime}</div>
                      </div>
                    </div>
                    {recordsList.map((item, i) => (
                      <ReportItem
                        t={t}
                        isRtl={isRtl}
                        item={item}
                        key={'rt' + i}
                        handleDownloadReport={handleDownloadReport}
                        handleDownloadSmartReport={handleDownloadSmartReport}
                        
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {isMobile && (
              <div className="snglRprt">
                <div className="card">
                  <div className="date text-gray-500">
                    <span className="day font-medium text-slate-950">
                      {fDate.monthNameShort}
                    </span>
                    <span className="date font-bold text-24 text-primary">
                      {fDate.monthDate}
                    </span>
                    {fDate.year}
                  </div>
                  <div className="info">
                    <h3 className="text-slate-950">
                      Order ID: {orderItem?.oderId || ''}
                    </h3>
                    <h4 className="text-green">{orderItem?.docName || ''}</h4>
                    <p className=" text-gray-500">
                      <FiClock /> at {time || ''}
                    </p>
                  </div>
                  <div className="btns">
                    {/* <button className="dwnld" onClick={() => handleDownloadReport(firstItem.specimen_number)}>{t.Download} <CgSoftwareDownload /></button> */}
                    <button
                      className="collapseBtn"
                      onClick={() => toggleShowRecords(index)}
                    >
                      {show ? <FiChevronDown /> : <FiChevronLeft />}
                    </button>
                  </div>
                </div>
                {show && (
                  <div className="moreInfo">
                    {recordsList.map((item, i) => (
                      <ReportItem
                        t={t}
                        isRtl={isRtl}
                        item={item}
                        key={'rt' + i}
                        handleDownloadReport={handleDownloadReport}
                        handleDownloadSmartReport={handleDownloadSmartReport}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        );
      })}
    </Fragment>
  );
};

export default memo(LabHistory);
